import React from 'react'

export default function SemiGreenRound() {
  return (
    <React.Fragment>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8.5" cy="8" r="6" stroke="#A1BDCA" strokeWidth="4" />
      </svg>
    </React.Fragment>
  )
}
