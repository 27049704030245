import {
  AgentJobStatisticsResponse,
  AgentsCompletionCodeStatisticsResponse,
  CampaignsCompletionCodeStatisticsResponse,
} from '../../../api/data-contracts'
import {
  AgentsAndCompainsAndCodesType,
  tabStateType,
} from '../SupervisorDashboard'
import SupervisorCompain from './SupervisorCompain/SupervisorCompain'
import SupervisorDashboardContentNav from './SupervisorDashboardContentNav/SupervisorDashboardContentNav'
import SupervisorOperators from './SupervisorOperators/SupervisorOperators'
import SupervisorStatistic from './SupervisorStatistic/SupervisorStatistic'
interface SupervisorDashboardContentProps {
  tabSelect: React.Dispatch<React.SetStateAction<tabStateType>>
  currentTab: tabStateType
  data: {
    stats?: AgentJobStatisticsResponse
    ops?: AgentsCompletionCodeStatisticsResponse[]
    comp?: CampaignsCompletionCodeStatisticsResponse[]
  }
  allData?: AgentsAndCompainsAndCodesType
}
export default function SupervisorDashboardContent({
  tabSelect,
  currentTab,
  data,
  allData,
}: SupervisorDashboardContentProps) {
  return (
    <>
      <SupervisorDashboardContentNav
        tabSelect={tabSelect}
        currentTab={currentTab}
      />
      {(currentTab === 'stats' && (
        <SupervisorStatistic statistic={data.stats} />
      )) ||
        (currentTab === 'ops' && (
          <SupervisorOperators data={data.ops} agents={allData?.agents} />
        )) ||
        (currentTab === 'comp' && <SupervisorCompain data={data.comp} />)}
    </>
  )
}
