import React, { memo } from 'react'
import ArrowUp from '../../../../../../components/Icons/Arrows/ArrowUp'
import ArrowDown from '../../../../../../components/Icons/Arrows/ArrowDown'

interface HeaderRowPropsType {
  header: string[]
  i: number
  name: string
  handleSort: (i: number) => void
  headerArrows: number[]
}

function HeaderRow({
  header,
  i,
  name,
  handleSort,
  headerArrows,
}: HeaderRowPropsType) {
  return (
    <div
      className={`ad-table-header-col${
        header.length - 1 === i ? '-short' : ''
      }`}
    >
      <div
        className="ad-table-header-col-wrapper"
        onClick={() => handleSort(i)}
      >
        <div className="ad-table-header-col-value">{name}</div>
        <div className="ad-table-header-col-arrows">
          <ArrowUp isActive={headerArrows[i] === 1} />
          <ArrowDown isActive={headerArrows[i] === 2} />
        </div>
      </div>
    </div>
  )
}

export default memo(HeaderRow)
