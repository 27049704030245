import { DashboadIconProp } from '../Dashboard/Dasboard'

export default function Stack({ isOpen }: DashboadIconProp) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 24.5L20 29.75L29 24.5"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 20L20 25.25L29 20"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15.5L20 20.75L29 15.5L20 10.25L11 15.5Z"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
