/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AgentLoginRequest, AgentLoginResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Authorize<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags authorization-controller
   * @name AuthorizeUsingPost
   * @summary authorize
   * @request POST:/authorize
   */
  authorizeUsingPost = (
    agentLoginRequest: AgentLoginRequest,
    params: RequestParams = {}
  ) =>
    this.request<AgentLoginResponse, void>({
      path: `/authorize`,
      method: 'POST',
      body: agentLoginRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
