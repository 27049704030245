import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import { useSubscription } from 'react-stomp-hooks'
import { logoff, logoutJtapi, logoutPom } from '../../store/requests/user'
import {
  setJtapiAuthorizeStatus,
  setPomAuthorizeStatus,
} from '../../store/slices/stompSlice'
import { changeState } from '../../store/requests/header'
import { DefaultAgentMessageResponse } from '../../api/data-contracts'
import { Api } from '../../api/Api'
import { Logoff } from '../../api/Logoff'
import { setLogoutingStatus } from '../../store/slices/userSlice'

interface UseLogoutProps {
  pomAuthorize: boolean
  jtapiAuthorize: boolean
  api: Api<unknown>
  logoffApi: Logoff<unknown>
}

export default function useLogout({
  pomAuthorize,
  jtapiAuthorize,
  api,
  logoffApi,
}: UseLogoutProps) {
  const { agentId, role } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  useSubscription(
    pomAuthorize ? `/agent/${agentId}/deregistration/pom` : [],
    (mes) => {
      const body: DefaultAgentMessageResponse = JSON.parse(mes.body)
      if (body.message === 'SUCCESS' || body.message === null) {
        dispatch(setPomAuthorizeStatus(false))
        dispatch(logoutJtapi({ api }))
      }
    }
  )

  useSubscription(
    jtapiAuthorize ? `/agent/${agentId}/deregistration/jtapi` : [],
    (mes) => {
      const body: DefaultAgentMessageResponse = JSON.parse(mes.body)
      if (body.message === 'SUCCESS' || body.message === null) {
        dispatch(setJtapiAuthorizeStatus(false))
        dispatch(logoff({ logoffApi }))
      }
    }
  )

  useSubscription(
    role && role[0] === 'ROLE_USER'
      ? `/agent/${agentId}/notification/emergencyLogout`
      : [],
    async (mes) => {
      const body: DefaultAgentMessageResponse = JSON.parse(mes.body)
      if (body.message === 'JTAPI_EMERGENCY_LOGOUT') {
        await dispatch(
          changeState({
            agentId,
            agentState: 'NotReady',
            api,
            reasonCode: '500',
            reasonName: 'EMERGENCY_LOGOUT',
          })
        )
        await dispatch(logoutPom({ api }))
        dispatch(setLogoutingStatus(true))
      } else if (body.message === 'POM_FORCED_LOGGED_OUT') {
        dispatch(logoutJtapi({ api }))
        dispatch(setLogoutingStatus(true))
      }
    }
  )
}
