import { useSubscription } from 'react-stomp-hooks'
import { AgentStateChangeNotification } from '../../api/data-contracts'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { setAgentStateNotify } from '../../store/slices/headerSlice'
import { userSelector } from '../../store/selectors/user'

export default function useStateChangeTopics(authorizationStatus: boolean) {
  const { agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const stateChangeNotifyUrl = authorizationStatus
    ? `/agent/${agentId}/stateChange/notify`
    : []

  useSubscription(stateChangeNotifyUrl, (mes) => {
    const changeNotify: AgentStateChangeNotification = JSON.parse(mes.body)
    dispatch(setAgentStateNotify(changeNotify.agentState))
  })
}
