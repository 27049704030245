import './Notifier.sass'
import React from 'react'

interface CountProp {
  count?: number
}

export default function Notifier({ count }: CountProp) {
  return <div className="notifier-container">{count}</div>
}
