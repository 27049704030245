import { DashboardDropProp } from '../../../views/Dashboard/types'
import { TopDashboardItems } from '../../../views/Settings/SettingsContent/TopDashEditor/types'

export default function Sucess({ onAddItem }: DashboardDropProp) {
  return (
    <svg
      onClick={() =>
        onAddItem({
          x: 0,
          y: 0,
          w: 2,
          h: 1,
          minH: 1,
          minW: 2,
          i: TopDashboardItems.Sucess,
        })
      }
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M28.25 14.75L17.75 25.2495L12.5 20"
        stroke="#556268"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
