import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from '../../api/Api'
import {
  CallbackInfoListResponse,
  CampaignCardJobStatisticsResponse,
  CampaignCardOperatorResponse,
} from '../../api/data-contracts'
import { addNotification } from '../slices/notificationSlice'

interface CampaignType {
  api: Api<unknown>
  campaignId: number
}

interface CallbacksType {
  api: Api<unknown>
  campaignName: string
  jobId: number
}

interface JobStatusType {
  api: Api<unknown>
  jobId: number
}

interface CampaignCardType {
  api: Api<unknown>
  jobId: number
  campaignId: number
}

export const campaignRequest = createAsyncThunk(
  'campaign/getById',
  async ({ api, campaignId }: CampaignType) => {
    if (campaignId > -1) {
      const { data } = await api.findCampaignByIdUsingGet(campaignId)
      return data
    } else {
      return undefined
    }
  }
)

export const getAllCallbacks = createAsyncThunk(
  'campaign/getCallbacksById',
  async ({ api, campaignName, jobId }: CallbacksType) => {
    const { data } = await api.getAllCallbacksByCampaignNameAndJobIdUsingGet(
      campaignName,
      jobId
    )
    return data as CallbackInfoListResponse
  }
)

export const getAllOperators = createAsyncThunk(
  'campaign/getOperators',
  async ({ api, campaignName, jobId }: CallbacksType) => {
    const { data } = await api.getAllOperatorsByCampaignNameAndJobIdUsingGet(
      campaignName,
      jobId
    )
    return data as CampaignCardOperatorResponse[]
  }
)

export const pauseJob = createAsyncThunk(
  'job/pause',
  async ({ api, jobId }: JobStatusType, { dispatch }) => {
    try {
      const { data } = await api.pauseJobUsingPost(jobId)
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title: 'Оповещение',
          text: `Задача ${jobId} успешно поставлена на паузу`,
        })
      )
      return data
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: `Задачу ${jobId} не удалось поставить на паузу`,
        })
      )
    }
  }
)

export const resumeJob = createAsyncThunk(
  'job/resume',
  async ({ api, jobId }: JobStatusType, { dispatch }) => {
    try {
      const { data } = await api.resumeJobUsingPost(jobId)
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title: 'Оповещение',
          text: `Задача ${jobId} успешно продолжена`,
        })
      )
      return data
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: `Задачу ${jobId} не удалось продолжить`,
        })
      )
    }
  }
)

export const stopJob = createAsyncThunk(
  'job/stop',
  async ({ api, jobId }: JobStatusType, { dispatch }) => {
    try {
      const { data } = await api.stopJobUsingPost(jobId)
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title: 'Оповещение',
          text: `Задача ${jobId} успешно остановлена`,
        })
      )
      return data
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: `Задачу ${jobId} не удалось остановить`,
        })
      )
    }
  }
)

export const getCampaignCard = createAsyncThunk(
  'campaign/card',
  async ({ campaignId, jobId, api }: CampaignCardType) => {
    const { data } = await api.getCampaignCardJobStatisticsByJobIdUsingGet(
      campaignId,
      jobId
    )
    return data as CampaignCardJobStatisticsResponse
  }
)
