import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from '../../api/Api'
import { ChatTypes } from '../slices/chatSlice'
import { UserStore } from '../slices/userSlice'

interface fetchChatRoomsAndMessagesType {
  api: Api<unknown>
  agentId: UserStore['agentId']
}

export const fetchChatRoomsAndMessages = createAsyncThunk(
  'chat/fetchChatRoomsAndMessages',
  async ({ agentId, api }: fetchChatRoomsAndMessagesType) => {
    const chats: ChatTypes = { group: [], oneToOne: [] }
    const { data: oneToOneRoomsData } = await api.getRoomsUsingGet(agentId!)
    for (const room of oneToOneRoomsData) {
      const { data: roomMessages } = await api.findChatMessagesUsingGet(
        room.chatId!
      )
      chats.oneToOne.push({ ...room, messages: roomMessages })
    }
    const { data: groupRoomsData } = await api.getGroupRoomsUsingGet(agentId!)
    for (const room of groupRoomsData) {
      const { data: groupMessages } = await api.findChatMessagesUsingGet1(
        room.chatId!
      )
      chats.group.push({ ...room, messages: groupMessages })
    }
    return chats
  }
)
