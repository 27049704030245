import React, {
  memo,
  useCallback,
  useState,
  MouseEvent,
  useEffect,
} from 'react'
import './Button.sass'
import ButtonArrow from '../../../../../../components/Icons/ButtonArrow/ButtonArrow'
import FinishIcon from '../../../../../../components/Icons/ScriptDev/Pallet/CallFinish'
import { Handle, Position } from 'react-flow-renderer'
import Trash from '../../../../../../components/Icons/ScriptDev/Nodes/Trash'
import Input from '../../../../../../components/Input/Input'
import Accept from '../Accept/Accept'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../store/hooks/hook'
import {
  removeBtn,
  updateBtnTitle,
} from '../../../../../../store/slices/scriptEditorSlice'
import { scriptEditorSelector } from '../../../../../../store/selectors/scriptEditor'

interface BtnProp {
  text: string
  i: number
  finish?: boolean
  step: number
  type?: 'RadioButton' | 'Button'
}

function Button({ text, i, step, finish = false, type }: BtnProp) {
  const dispatch = useAppDispatch()
  const { mode } = useAppSelector(scriptEditorSelector)
  const [editing, setEditing] = useState(false)
  const [currValue, setCurrValue] = useState('')
  const [newValue, setNewValue] = useState('')

  useEffect(() => {
    setCurrValue(text)
  }, [text])

  const onRemoveBtn = useCallback(
    () =>
      mode === 'editing' &&
      dispatch(removeBtn({ step: step || 0, text: currValue })),
    [step, dispatch, currValue, mode]
  )

  const onEdit = useCallback(
    (e: MouseEvent) => {
      if (mode === 'editing')
        if (text !== 'Назначить') {
          const el = e.target as Element
          if (
            (el.className === 'node-button-wrapper' ||
              el.className === 'node-button-text') &&
            !editing
          ) {
            setEditing(true)
            setNewValue('')
          }
        }
    },
    [editing, text, mode]
  )
  const onAcceptEditing = useCallback(() => {
    if (newValue) {
      setCurrValue(newValue)
      dispatch(
        updateBtnTitle({
          step,
          text: newValue,
          oldText: currValue,
          type,
        })
      )
    }
    setEditing(false)
  }, [newValue, currValue, step, dispatch, type])
  const onCancelEditing = useCallback(() => setEditing(false), [])
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.currentTarget.value)
  }, [])

  return (
    <div className="node-button" onClick={(e) => onEdit(e)}>
      <div
        className={`node-button-wrapper${
          editing ? ' node-button-wrapper-input' : ''
        }`}
      >
        {editing ? (
          <div className="node-button-left node-button-left-input">
            <Input span="" placeholder={currValue} onChange={onChange} />
            <div className="accept-wrapper">
              <Accept onAccept={onAcceptEditing} onCancel={onCancelEditing} />
            </div>
          </div>
        ) : (
          <>
            <div className="node-button-left">
              <div className="node-button-icon">
                {finish ? <FinishIcon /> : <ButtonArrow />}
              </div>
              <div className="node-button-text">{currValue}</div>
            </div>
            {!finish && i !== 11 && (
              <div className="node-button-right" onClick={onRemoveBtn}>
                <Trash />
              </div>
            )}
          </>
        )}
      </div>
      {!finish && (
        <Handle
          type="source"
          position={Position.Right}
          id={String(i + 1)}
          isConnectable
          className="ad-handle ad-handle-right"
        />
      )}
    </div>
  )
}

export default memo(Button)
