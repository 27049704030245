import React from 'react'

export default function CampaignTagIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 21C19.9926 21 21 19.9926 21 18.75C21 17.5074 19.9926 16.5 18.75 16.5C17.5074 16.5 16.5 17.5074 16.5 18.75C16.5 19.9926 17.5074 21 18.75 21Z"
        stroke="#A1BDCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 5.25H15.75C16.5456 5.25 17.3087 5.56607 17.8713 6.12868C18.4339 6.69129 18.75 7.45435 18.75 8.25C18.75 9.04565 18.4339 9.80871 17.8713 10.3713C17.3087 10.9339 16.5456 11.25 15.75 11.25H6.75C5.75544 11.25 4.80161 11.6451 4.09835 12.3483C3.39509 13.0516 3 14.0054 3 15C3 15.9946 3.39509 16.9484 4.09835 17.6517C4.80161 18.3549 5.75544 18.75 6.75 18.75H16.5"
        stroke="#A1BDCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
