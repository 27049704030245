import React from 'react'

export default function Clock() {
  return (
    <React.Fragment>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="16" fill="#EBF5F7" />
        <path
          d="M28 21V28H35"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28 39C34.0751 39 39 34.0751 39 28C39 21.9249 34.0751 17 28 17C21.9249 17 17 21.9249 17 28C17 34.0751 21.9249 39 28 39Z"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M36.4854 15.272L40.728 19.5146"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.272 19.5146L19.5146 15.272"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </React.Fragment>
  )
}
