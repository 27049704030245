export default function Phone() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5595 15.6018C12.5968 17.7225 14.3158 19.4336 16.4412 20.4613C16.5967 20.535 16.7687 20.5669 16.9403 20.5539C17.1119 20.5409 17.2771 20.4835 17.4198 20.3872L20.5492 18.3004C20.6877 18.2082 20.8469 18.1518 21.0126 18.1366C21.1782 18.1214 21.3451 18.1478 21.498 18.2133L27.3526 20.7224C27.5515 20.8069 27.7175 20.9537 27.8257 21.1408C27.9339 21.3278 27.9783 21.545 27.9524 21.7595C27.7673 23.2075 27.0608 24.5384 25.9652 25.503C24.8695 26.4676 23.4598 26.9998 22 26.9999C17.4913 26.9999 13.1673 25.2088 9.97919 22.0207C6.79107 18.8326 5 14.5086 5 9.99988C5.00008 8.5401 5.53224 7.13039 6.49685 6.03472C7.46146 4.93905 8.79237 4.23255 10.2404 4.0475C10.4549 4.02154 10.672 4.066 10.8591 4.17418C11.0461 4.28236 11.193 4.4484 11.2775 4.64728L13.7888 10.507C13.8537 10.6586 13.8802 10.8239 13.8658 10.9881C13.8514 11.1524 13.7967 11.3106 13.7064 11.4485L11.6268 14.626C11.5322 14.769 11.4762 14.934 11.4644 15.105C11.4526 15.2761 11.4854 15.4472 11.5595 15.6018V15.6018Z"
        stroke="#A1BDCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
