import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import {
  getInactivityLogoutSettings,
  logoutPom,
} from '../../store/requests/user'
import { changeState } from '../../store/requests/header'
import { Api } from '../../api/Api'
import { setLogoutingStatus } from '../../store/slices/userSlice'

export default function useInactivity(
  authorizationStatus: boolean,
  api: Api<unknown>
) {
  const { role, inactivitySettings, agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  const logoutAction = () => {
    dispatch(
      changeState({
        agentId,
        agentState: 'NotReady',
        api,
        reasonCode: '400',
        reasonName: 'INACTIVITY',
      })
    )
    dispatch(setLogoutingStatus(true))
    dispatch(logoutPom({ api }))
  }

  let logoutTimeout: NodeJS.Timeout | null

  const setTimeouts = () => {
    if (inactivitySettings?.inactivityMaximumTime)
      logoutTimeout = setTimeout(
        logoutAction,
        inactivitySettings.inactivityMaximumTime * 60000
      )
  }

  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  useEffect(() => {
    if (
      authorizationStatus &&
      !inactivitySettings &&
      role &&
      role[0] === 'ROLE_USER'
    ) {
      dispatch(getInactivityLogoutSettings({ api }))
    }

    const resetTimeout = () => {
      clearTimeouts()
      setTimeouts()
    }

    window.addEventListener('click', resetTimeout)

    setTimeouts()

    return () => {
      window.removeEventListener('click', resetTimeout)
      clearTimeouts()
    }
    // eslint-disable-next-line
  }, [
    role,
    setTimeouts,
    inactivitySettings,
    authorizationStatus,
    clearTimeouts,
  ])
}
