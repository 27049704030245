import './CampaignJob.sass'
import { useAppSelector } from '../../../../store/hooks/hook'
import React, { Dispatch, SetStateAction } from 'react'
import { campaignSelector } from '../../../../store/selectors/campaign'
import { ScriptResponse } from '../../../../api/data-contracts'

interface CampaignJobType {
  id?: number
  hasScript: (ScriptResponse | undefined)[]
  setPickedJob: Dispatch<SetStateAction<number | undefined>>
  setPickedCampaign: Dispatch<SetStateAction<string | undefined>>
  setHeaderStatus: Dispatch<SetStateAction<string | undefined>>
}

export default function CampaignJob({
  hasScript,
  setPickedJob,
  setPickedCampaign,
  setHeaderStatus,
}: CampaignJobType) {
  const { currentCampaign } = useAppSelector(campaignSelector)
  return (
    <div className="campaign-job">
      {currentCampaign?.jobs?.length ? (
        <table className="table-container">
          <thead>
            <tr>
              <th>ID</th>
              <th>Название кампании</th>
              <th>Статус</th>
              <th>Скрипт</th>
            </tr>
          </thead>
          <tbody>
            {currentCampaign?.jobs
              ?.map((e) => (
                <tr
                  key={e.jobId}
                  onClick={() => {
                    setPickedJob(e.jobId)
                    setPickedCampaign(e.campaignName)
                    setHeaderStatus(e.jobStatus)
                  }}
                  className="click-row"
                >
                  <td>{e.jobId}</td>
                  <td>{e.campaignName}</td>
                  <td>{e.jobStatus}</td>
                  <td>{hasScript ? 'Да' : 'Нет'}</td>
                </tr>
              ))
              .reverse()}
          </tbody>
        </table>
      ) : (
        <div className="table-container-error">
          Данных нет или что-то пошло не так...
        </div>
      )}
    </div>
  )
}
