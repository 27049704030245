import React from 'react'

export default function Calendar() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 3.5C9.82425 3.5 10.2222 3.90059 10.2222 4.39474V5.28947H13.7778V4.39474C13.7778 3.90059 14.1757 3.5 14.6667 3.5C15.1576 3.5 15.5556 3.90059 15.5556 4.39474V5.28947H18.2222C19.2041 5.28947 20 6.09065 20 7.07895V18.7105C20 19.6988 19.2041 20.5 18.2222 20.5H5.77778C4.79594 20.5 4 19.6988 4 18.7105V7.07895C4 6.09065 4.79594 5.28947 5.77778 5.28947H8.44444V4.39474C8.44444 3.90059 8.84241 3.5 9.33333 3.5ZM8.44444 7.07895H5.77778V9.76316H18.2222V7.07895H15.5556V7.97368C15.5556 8.46783 15.1576 8.86842 14.6667 8.86842C14.1757 8.86842 13.7778 8.46783 13.7778 7.97368V7.07895H10.2222V7.97368C10.2222 8.46783 9.82425 8.86842 9.33333 8.86842C8.84241 8.86842 8.44444 8.46783 8.44444 7.97368V7.07895ZM18.2222 11.5526H5.77778V18.7105H18.2222V11.5526Z"
        fill="#AD3928"
      />
    </svg>
  )
}
