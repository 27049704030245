import { DashboardDropProp } from '../../../views/Dashboard/types'
import { TopDashboardItems } from '../../../views/Settings/SettingsContent/TopDashEditor/types'

export default function Rpc({ onAddItem }: DashboardDropProp) {
  return (
    <svg
      onClick={() =>
        onAddItem({
          x: 0,
          y: 0,
          w: 2,
          h: 1,
          minH: 1,
          minW: 2,
          i: TopDashboardItems.RPC,
        })
      }
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M20 23C22.0711 23 23.75 21.3211 23.75 19.25C23.75 17.1789 22.0711 15.5 20 15.5C17.9289 15.5 16.25 17.1789 16.25 19.25C16.25 21.3211 17.9289 23 20 23Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 11.75H12.5C12.0858 11.75 11.75 12.0858 11.75 12.5V27.5C11.75 27.9142 12.0858 28.25 12.5 28.25H27.5C27.9142 28.25 28.25 27.9142 28.25 27.5V12.5C28.25 12.0858 27.9142 11.75 27.5 11.75Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4175 28.25C13.7577 26.7586 14.5943 25.427 15.7903 24.4732C16.9862 23.5194 18.4705 23 20.0002 23C21.5299 23 23.0142 23.5194 24.2102 24.4732C25.4061 25.427 26.2427 26.7586 26.5829 28.25"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
