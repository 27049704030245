import './Input.sass'
import React from 'react'
import Eye from '../Icons/Eye/Eye'
import EyeClosed from '../Icons/EyeClosed/EyeClosed'

type InputProps = JSX.IntrinsicElements['input'] & {
  autofocus?: boolean
  span: string
  password?: boolean
  innerRef?: React.LegacyRef<HTMLInputElement>
}

export default function Input({
  span,
  autofocus,
  password,
  placeholder,
  onChange,
  onKeyPress,
  innerRef,
  id,
  value,
  ...otherProps
}: InputProps) {
  const [isShow, setIsShow] = React.useState(false)
  const handleEye = React.useCallback(() => setIsShow(!isShow), [isShow])

  return (
    <div className="input-wrapper">
      <span className="input-span">{span}</span>
      {password ? (
        <div>
          <input
            id={id}
            className={`input ${password ? 'input-password' : ''}`}
            type={isShow ? 'text' : 'password'}
            placeholder="••••••••"
            onChange={onChange}
            onKeyPress={onKeyPress}
            ref={innerRef}
            {...otherProps}
          />
          {isShow ? (
            <Eye onClick={handleEye} />
          ) : (
            <EyeClosed onClick={handleEye} />
          )}
        </div>
      ) : (
        <input
          id={id}
          className="input"
          type="text"
          placeholder={placeholder}
          autoFocus={autofocus}
          onChange={onChange}
          onKeyPress={onKeyPress}
          ref={innerRef}
          {...otherProps}
        />
      )}
    </div>
  )
}
