export default function Group() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#C15747"
      />
      <path
        d="M25.7501 37C30.2374 37 33.8751 33.3623 33.8751 28.875C33.8751 24.3877 30.2374 20.75 25.7501 20.75C21.2627 20.75 17.6251 24.3877 17.6251 28.875C17.6251 33.3623 21.2627 37 25.7501 37Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M36.2832 21.0526C37.4007 20.7377 38.5728 20.666 39.7204 20.8422C40.8679 21.0184 41.9644 21.4386 42.936 22.0743C43.9075 22.7099 44.7316 23.5465 45.3526 24.5274C45.9737 25.5084 46.3773 26.6111 46.5363 27.7612C46.6953 28.9113 46.6059 30.0821 46.2743 31.1948C45.9427 32.3075 45.3765 33.3362 44.6139 34.2116C43.8513 35.0871 42.9099 35.7889 41.8531 36.2699C40.7964 36.7508 39.6489 36.9998 38.4878 36.9999"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4994 42.8432C15.7683 41.0382 17.453 39.565 19.411 38.548C21.3691 37.531 23.5431 37.0001 25.7495 37C27.9559 36.9999 30.13 37.5307 32.0881 38.5476C34.0463 39.5644 35.731 41.0375 37.0001 42.8424"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.4878 37C40.6944 36.9984 42.869 37.5285 44.8273 38.5455C46.7856 39.5625 48.47 41.0364 49.7379 42.8424"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
