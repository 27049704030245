import { memo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface PublicRouterProps {
  isAuth: string | null | undefined
}

function PublicRoute({ isAuth }: PublicRouterProps) {
  return isAuth ? <Navigate to="/" replace /> : <Outlet />
}

export default memo(PublicRoute)
