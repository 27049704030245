import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hook'
import { getAllOperators } from '../../../../store/requests/campaign'
import { useApi } from '../../../../store/hooks/request'
import { campaignSelector } from '../../../../store/selectors/campaign'

interface CampaignOperatorsType {
  jobId?: number
  pickedCampaign?: string
}

export default function CampaignOperators({
  jobId,
  pickedCampaign,
}: CampaignOperatorsType) {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { campaignCardOperators } = useAppSelector(campaignSelector)
  useEffect(() => {
    if (jobId && pickedCampaign !== undefined)
      dispatch(
        getAllOperators({
          jobId: jobId,
          campaignName: pickedCampaign,
          api,
        })
      )
    // eslint-disable-next-line
  }, [jobId, pickedCampaign, dispatch])
  return (
    <div className="campaign-operators">
      {campaignCardOperators.length ? (
        <table className="table-container campaign-operators-table">
          <thead>
            <tr className="campaign-table-container">
              <th className="campaign-table-container-label">Оператор</th>
              <th className="campaign-table-container-label">
                Больше не звонить
              </th>
              <th className="campaign-table-container-label">Успех</th>
              <th className="campaign-table-container-label">Отказ</th>
              <th className="campaign-table-container-label">Callback</th>
            </tr>
          </thead>
          <tbody>
            {campaignCardOperators.map((e) => (
              <tr key={e.agentId}>
                <td>{e.agentName}</td>
                <td>{e.callsRpc}</td>
                <td>{e.callsSuccess}</td>
                <td>{e.callsClosure}</td>
                <td>{e.callbackCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="table-container-error">
          Данных нет или что-то пошло не так...
        </div>
      )}
    </div>
  )
}
