import './Collapse.sass'
import React from 'react'

interface PropsType {
  isNode?: boolean
}

export default function CollapseOut({ isNode }: PropsType) {
  return (
    <svg
      className={isNode ? '' : 'icon-collapse'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 4.5H19.5V8.25"
        stroke={isNode ? '#A1BDCA' : '#C15747'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 19.5H4.5V15.75"
        stroke={isNode ? '#A1BDCA' : '#C15747'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 15.75V19.5H15.75"
        stroke={isNode ? '#A1BDCA' : '#C15747'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 8.25V4.5H8.25"
        stroke={isNode ? '#A1BDCA' : '#C15747'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
