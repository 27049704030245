import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AgentLoginRequest,
  AgentProfileEntity,
  AgentStatusForSupervisor,
  SupervisorInfoResponse,
} from '../../api/data-contracts'
import { Authorize } from '../../api/Authorize'
import { Logoff } from '../../api/Logoff'
import {
  deleteAuthToken,
  UserStore,
  setListeningAgent,
  stopListeningAgent,
  setPendingAuthLoginName,
  setAutoFillData,
} from '../slices/userSlice'
import ApiDefaultType from '../types'
import { addNotification } from '../slices/notificationSlice'
import { FailedAuthorization } from '../../api/FailedAuthorization'
import { logout } from '../functions/logout'

interface AuthType {
  credentials: AgentLoginRequest
  authApi: Authorize<unknown>
}

interface FailedAuthType {
  failedAuthApi: FailedAuthorization<unknown>
  login: string
}

interface LogoutType {
  logoffApi: Logoff<unknown>
}

interface ApiAgentType extends ApiDefaultType {
  agentId: string
  callMode?: 'LISTEN_ONLY' | 'LISTEN_TALK' | 'WHISPER'
  name?: string
}

interface CallStatus extends ApiDefaultType {
  agentId: string
}

export const authorize = createAsyncThunk(
  'user/auth',
  async ({ credentials, authApi }: AuthType, { dispatch }) => {
    dispatch(setPendingAuthLoginName(credentials.username || ''))
    const { data, headers } = await authApi.authorizeUsingPost(credentials)
    dispatch(setAutoFillData(credentials))
    return {
      ...data,
      auth: headers?.get('Authorization'),
    } as UserStore
  }
)

export const logoff = createAsyncThunk(
  'user/logoff',
  async ({ logoffApi }: LogoutType, { dispatch }) => {
    await logoffApi.logoffUsingGet()
    dispatch(deleteAuthToken())
    logout(dispatch)
  }
)

export const loginJtapi = createAsyncThunk(
  'user/loginJtapi',
  async ({ api }: ApiDefaultType) => {
    await api.loginUsingGet()
    return
  }
)

export const loginPom = createAsyncThunk(
  'user/loginPom',
  async ({ api }: ApiDefaultType) => {
    await api.loginUsingGet1()
    return
  }
)

export const logoutPom = createAsyncThunk(
  'user/loginPom',
  async ({ api }: ApiDefaultType) => {
    await api.logoutUsingGet1()
    return
  }
)

export const logoutJtapi = createAsyncThunk(
  'user/loginPom',
  async ({ api }: ApiDefaultType) => {
    await api.logoutUsingGet()
    return
  }
)

export const getInactivityLogoutSettings = createAsyncThunk(
  'user/getInactivityLogoutSettings',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.getInactivityLogoutSettingsUsingGet()
    return data
  }
)

export const getAgentCallStatusUsing = createAsyncThunk(
  'user/getAgentCallStatusUsing',
  async ({ agentId, api }: CallStatus) => {
    const { data } = await api.getAgentCallStatusUsingGet(agentId)
    return data
  }
)

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async ({ api, agentId }: ApiAgentType) => {
    const { data } = await api.getAgentInfoUsingGet(agentId)
    return data as AgentProfileEntity
  }
)

export const getSupervisorData = createAsyncThunk(
  'user/getSupervisorData',
  async ({ api, agentId }: ApiAgentType) => {
    const { data } = await api.getSupervisorInfoUsingGet(agentId)
    return data as SupervisorInfoResponse
  }
)

export const sendFailedAuth = createAsyncThunk(
  'user/sendFailedAuth',
  async ({ failedAuthApi, login }: FailedAuthType) => {
    await failedAuthApi.failedAuthorizationUsingPost({
      login,
      type: 'FAILED_AUTHORIZATION',
    })
  }
)

export const getAgents = createAsyncThunk(
  'user/getAgents',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.getAgentsUsingGet()
    return data as AgentStatusForSupervisor[]
  }
)

export const startCall = createAsyncThunk(
  'user/startCall',
  async ({ api, agentId, callMode, name = '' }: ApiAgentType, { dispatch }) => {
    try {
      const rs = await api.makeSupervisorCallToAgentUsingPost({
        agentId,
        callMode,
      })
      if (rs.data.message === 'JTAPI_METHOD_UNSUPPORTED')
        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'warning',
            title: 'Ошибка',
            text: 'Данный режим пока не поддерживается',
          })
        )
      else dispatch(setListeningAgent(name))
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: 'Не удалось подключиться к звонку агента',
        })
      )
    }
    return name
  }
)

export const endCall = createAsyncThunk(
  'user/endCall',
  async ({ api }: ApiDefaultType, { dispatch }) => {
    try {
      await api.endSupervisorCallUsingGet()
      dispatch(stopListeningAgent())
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: 'Не удалось отключиться от звонка агента',
        })
      )
    }
    return
  }
)

export const forceLogoutAgent = createAsyncThunk(
  'user/forceLogoutAgent',
  async ({ api, agentId }: ApiAgentType, { dispatch }) => {
    try {
      await api.forceLogoutAgentUsingPost({ agentId })
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: 'Не удалось произвести форс логаут агента',
        })
      )
    }
    return
  }
)
