import React, { MouseEventHandler } from 'react'

interface SearchIconProps {
  size?: number
  onClick?: MouseEventHandler<HTMLOrSVGElement>
}

export default function SearchIcon(props: SearchIconProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="search-icon"
      onClick={props.onClick}
    >
      <path
        d="M8.875 16.75C13.2242 16.75 16.75 13.2242 16.75 8.875C16.75 4.52576 13.2242 1 8.875 1C4.52576 1 1 4.52576 1 8.875C1 13.2242 4.52576 16.75 8.875 16.75Z"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4431 14.4434L18.9994 18.9997"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
