import React from 'react'
import { IconType } from '../types'

export default function ListenTalk(props: IconType) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M20.5 12C20.5 9.79086 18.7091 8 16.5 8C14.2909 8 12.5 9.79086 12.5 12V14C14.1569 14 15.5 15.3431 15.5 17C15.5 18.6569 14.1569 20 12.5 20"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.5C8 6.80558 11.8056 3 16.5 3C21.1944 3 25 6.80558 25 11.5C25 13.9732 23.9437 16.1997 22.2577 17.753C19.8186 20 18 19.4624 18 22.5C18 25.5376 15.5376 28 12.5 28C9.46243 28 7 25.5376 7 22.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
