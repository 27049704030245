import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'
import DashboardContent from './DashboardContent'
import DashboardUserInfo from './DashboardUserInfo/DashboardUserInfo'

export default function Dashboard() {
  return (
    <ViewWithScript
      SubHeaderComponent={<DashboardUserInfo />}
      ContentComponent={<DashboardContent />}
    />
  )
}
