import { useSubscription } from 'react-stomp-hooks'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import { CallStateChangeNotification } from '../../api/data-contracts'
import { setCallState, setCallStart } from '../../store/slices/scriptSlice'
import { addNotification } from '../../store/slices/notificationSlice'

export default function useCallStateChange(authorizationStatus: boolean) {
  const { agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/call-state-change/notify` : [],
    (mes) => {
      const body: CallStateChangeNotification = JSON.parse(mes.body)
      if (body.callState === 'Talking') dispatch(setCallStart())
      if (body.callState === 'Callback')
        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'info',
            title: `Оповещение`,
            text: `Данный вызов является обратным`,
          })
        )
      if (body.callState) dispatch(setCallState(body.callState))
    }
  )
}
