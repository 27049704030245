import './Header.sass'
import React from 'react'
import PlusCircle from '../../../../components/Icons/ScriptDev/Sider/PlusCircle'
import { useAddNewScript } from '../../hooks/useAddNewScript'

interface headerProps {
  title: string
  isCampaign?: boolean
}

export default function Header({ title, isCampaign }: headerProps) {
  const { toggleOpenModal, renderModal } = useAddNewScript()

  return (
    <div className="script-sider-header">
      <div className="script-sider-header-text">{title}</div>
      {!isCampaign && (
        <div className="script-sider-header-add" onClick={toggleOpenModal}>
          <PlusCircle />
        </div>
      )}
      {renderModal()}
    </div>
  )
}
