import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getSupervisorNotifications,
  proceedNotification,
} from '../requests/notifications'
import { AgentRequestNotification } from '../../api/data-contracts'

export interface Notification {
  id: number
  icon:
    | 'info'
    | 'break'
    | 'warning'
    | 'message'
    | 'whisper'
    | 'listen-talk'
    | 'clock'
    | 'sign-out'
  title: string
  text: string
  hasAction?: boolean
  dataObj?: AgentRequestNotification
}
export interface NotificationList extends Array<Notification> {}

export interface NotificationStore {
  id?: number
  notification?: Notification
  countOfMessages: number
  notificationList: NotificationList
  requestNotifications: Array<AgentRequestNotification>
}

export const notificationReset: Notification = {
  id: 0,
  icon: 'info',
  title: 'Сброс пароля',
  text: 'Запрос отправлен Вашему руководителю.',
}
export const notificationIncorrect: Notification = {
  id: 0,
  icon: 'info',
  title: 'Некорректные данные',
  text: 'Введены некорректные данные',
}

const initialState: NotificationStore = {
  countOfMessages: 0,
  notificationList: [],
  requestNotifications: [],
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    dropNotificationsSlice: () => initialState,
    addNotification(
      state: NotificationStore,
      { payload }: PayloadAction<Notification>
    ) {
      state.notificationList?.push(payload)
    },
    delNotification(
      state: NotificationStore,
      { payload }: PayloadAction<Notification['id']>
    ) {
      state.notificationList = state.notificationList
        ?.filter((notification) => notification.id !== payload)
        .map((el, i) => {
          return { ...el, id: i + 1 }
        })
    },
    pushRequest(
      state: NotificationStore,
      { payload }: PayloadAction<AgentRequestNotification>
    ) {
      state.requestNotifications.push(payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSupervisorNotifications.fulfilled,
      (state, { payload }) => {
        state.requestNotifications = payload
      }
    )
    builder.addCase(proceedNotification.fulfilled, (state, { payload }) => {
      const newRequests = [...state.requestNotifications]
      const index = newRequests.findIndex((el) => el.id === payload)
      newRequests.splice(index, 1)
      state.requestNotifications = newRequests
    })
  },
})

export const {
  delNotification,
  addNotification,
  pushRequest,
  dropNotificationsSlice,
} = notificationsSlice.actions

export const notificationsReducer = notificationsSlice.reducer
