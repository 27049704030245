import React from 'react'
import IconPropsType from './IconPropsType'

export default function Arrow({ isActive }: IconPropsType) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          isActive
            ? 'M23.75 18.5L20 22.25L16.25 18.5'
            : 'M18.5 16.25L22.25 20L18.5 23.75'
        }
        stroke={isActive ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
