import CRUDTable, {
  Fields,
  Field,
  UpdateForm,
  DeleteForm,
  CreateForm,
} from 'react-crud-table'
import { useApi } from '../../../store/hooks/request'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { adminSelector } from '../../../store/selectors/admin'
import React, { useEffect, useState } from 'react'
import {
  deleteAgentGroup,
  findAllAgentGroup,
  findAllSupervisor,
  saveOrUpdateGroup,
} from '../../../store/requests/admin'
import { AdminAgentGroupDto } from '../../../api/data-contracts'
import AssignModal from '../AssignModal'
import UnAssignModal from '../UnAssignModal'

export default function AgentGroupEntity() {
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const { agentGroup } = useAppSelector(adminSelector)
  const [showModal, setShowModal] = useState(false)
  const [showUnAssignModal, setShowUnAssignModal] = useState(false)

  const handleShowModal = () => {
    setShowModal((old) => !old)
  }

  const handleShowUnAssignModal = () => {
    setShowUnAssignModal(!showUnAssignModal)
  }

  useEffect(() => {
    dispatch(findAllAgentGroup({ api }))
    dispatch(findAllSupervisor({ api }))
    //eslint-disable-next-line
  }, [])

  const agentGroupService = {
    create: (task: AdminAgentGroupDto) => {
      dispatch(
        saveOrUpdateGroup({
          api,
          ...task,
        })
      )
      return Promise.resolve(task)
    },
    update: (data: AdminAgentGroupDto) => {
      const task: AdminAgentGroupDto | undefined = agentGroup.find(
        (t) => t.agentGroupId === data.agentGroupId
      )
      dispatch(saveOrUpdateGroup({ ...data, api }))
      return Promise.resolve(task)
    },
    delete: (data: AdminAgentGroupDto) => {
      const task = agentGroup.find((t) => t.agentGroupId !== data.agentGroupId)
      dispatch(deleteAgentGroup({ ...data, api }))
      return Promise.resolve(task)
    },
  }

  const exAgent = agentGroup.map((e) => ({
    ...e,
    supervisorName: (
      <div>
        {e.supervisors?.map((e) => (
          <div key={e.pimUserId}>{e.supervisorName}</div>
        ))}
      </div>
    ),
  }))

  return (
    <div className="table-container">
      <button className="button-supervisor" onClick={handleShowModal}>
        Привязать супервизора
      </button>
      <button
        className="button-supervisor-red"
        onClick={handleShowUnAssignModal}
      >
        Отвязать супервизора
      </button>
      <CRUDTable caption="Группы агентов" items={exAgent || []}>
        <Fields>
          <Field name="agentGroupId" label="id" />
          <Field name="agentGroupName" label="Имя" />
          <Field
            name="supervisors"
            label="Супервизоры"
            hideInCreateForm
            hideInUpdateForm
            tableValueResolver="supervisorName"
          />
        </Fields>
        <CreateForm
          title="Создать"
          message="Создать"
          trigger="Создать"
          submitText="Создать"
          onSubmit={agentGroupService.create}
        />
        <UpdateForm
          title="Обновить"
          message="Обновить"
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={agentGroupService.update}
        />
        <DeleteForm
          title="Удаление"
          message="Вы уверены?"
          trigger="Удалить"
          submitText="Удалить"
          onSubmit={agentGroupService.delete}
        />
      </CRUDTable>
      {showModal && (
        <AssignModal freeClose={handleShowModal} type="supervisor" />
      )}
      {showUnAssignModal && (
        <UnAssignModal freeClose={handleShowUnAssignModal} type="supervisor" />
      )}
    </div>
  )
}
