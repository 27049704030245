import React from 'react'

export default function Phone() {
  return (
    <React.Fragment>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="16" fill="#EBF5F7" />
        <path
          d="M23.5595 27.6018C24.5968 29.7225 26.3158 31.4336 28.4412 32.4613C28.5967 32.535 28.7687 32.5669 28.9403 32.5539C29.1119 32.5409 29.2771 32.4835 29.4198 32.3872L32.5492 30.3004C32.6877 30.2082 32.8469 30.1518 33.0126 30.1366C33.1782 30.1214 33.3451 30.1478 33.498 30.2133L39.3526 32.7224C39.5515 32.8069 39.7175 32.9537 39.8257 33.1408C39.9339 33.3278 39.9783 33.545 39.9524 33.7595C39.7673 35.2075 39.0608 36.5384 37.9652 37.503C36.8695 38.4676 35.4598 38.9998 34 38.9999C29.4913 38.9999 25.1673 37.2088 21.9792 34.0207C18.7911 30.8326 17 26.5086 17 21.9999C17.0001 20.5401 17.5322 19.1304 18.4969 18.0347C19.4615 16.9391 20.7924 16.2326 22.2404 16.0475C22.4549 16.0215 22.672 16.066 22.8591 16.1742C23.0461 16.2824 23.193 16.4484 23.2775 16.6473L25.7888 22.507C25.8537 22.6586 25.8802 22.8239 25.8658 22.9881C25.8514 23.1524 25.7967 23.3106 25.7064 23.4485L23.6268 26.626C23.5322 26.769 23.4762 26.934 23.4644 27.105C23.4526 27.2761 23.4854 27.4472 23.5595 27.6018V27.6018Z"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </React.Fragment>
  )
}
