import { memo, useEffect, useState } from 'react'
import {
  AgentProfileEntity,
  AgentStatisticsNotification,
} from '../../../../../api/data-contracts'
import Clock1 from '../../../../../components/Icons/SupervisorChatDashboardInfo/Clock1'
import Clock2 from '../../../../../components/Icons/SupervisorChatDashboardInfo/Clock2'
import Clock3 from '../../../../../components/Icons/SupervisorChatDashboardInfo/Clock3'
import Clock4 from '../../../../../components/Icons/SupervisorChatDashboardInfo/Clock4'
import Phone from '../../../../../components/Icons/SupervisorChatDashboardInfo/Phone'
import { useApi } from '../../../../../store/hooks/request'
import './OneToOneChatInfo.sass'
interface OneToOneChatInfoProps {
  agentId: string | undefined
}
type agentInfoType = {
  info: AgentProfileEntity | null
  stats: AgentStatisticsNotification | null
}

export const OneToOneChatInfo = memo(({ agentId }: OneToOneChatInfoProps) => {
  const [agentInfo, setAgentInfo] = useState<agentInfoType>({
    info: null,
    stats: null,
  })

  const { api } = useApi()

  useEffect(() => {
    async function getAgentInfo() {
      if (agentId) {

        try {
          const { data: agent } = await api.getAgentInfoUsingGet(agentId)

          const { data: stat } = await api.getStatisticByAgentUsingGet(agentId)

          setAgentInfo({ info: agent, stats: stat })
        } catch (e){
          console.error(e)
        }

      }
    }
    getAgentInfo()
    // eslint-disable-next-line
  }, [agentId])
  return (
    <>
      <div className="agent-chat-card">
        <div className="agent-chat-card-content">
          <div className="agent-chat-card-content-image"></div>
          <div className="agent-chat-card-content-agent-name">
            <span className="agent-name">{agentInfo.info?.agentName}</span>
            <span className="agent-role">{agentInfo.info?.campaignId}</span>
          </div>
          <div className="agent-chat-card-content-skills">
            <div className="agent-skill">{agentInfo.info?.loggedInSkill}</div>
          </div>
        </div>
      </div>
      <div className="agent-chat-dashboard">
        <div className="agent-chat-dashboard-name">Статистика</div>
        <div className="agent-chat-dashboard-content">
          <div className="agent-chat-dashboard-content-dash">
            <Clock1 />
            <span className="value">
              {agentInfo.stats?.loginTimeStamp ?? '--:--'}
            </span>
            <span className="dashname">Начало смены</span>
          </div>
          <div className="agent-chat-dashboard-content-dash">
            <Clock2 />
            <span className="value">
              {agentInfo.stats?.totalJobTime ?? '--:--'}
            </span>
            <span className="dashname">Время работы</span>
          </div>
          <div className="agent-chat-dashboard-content-dash">
            <Phone />
            <span className="value">
              {agentInfo.stats?.callCount ?? '--:--'}
            </span>
            <span className="dashname">Вызовы</span>
          </div>
          <div className="agent-chat-dashboard-content-dash">
            <Clock3 />
            <span className="value">
              {agentInfo.stats?.avgTalkTime ?? '--:--'}
            </span>
            <span className="dashname">Общ.время вызова</span>
          </div>
          <div className="agent-chat-dashboard-content-dash">
            <Clock4 />
            <span className="value">
              {agentInfo.stats?.closureCount ?? '--:--'}
            </span>
            <span className="dashname">Ср.время вызова</span>
          </div>
          <div className="agent-chat-dashboard-content-dash">
            <Clock2 />
            <span className="value">
              {agentInfo.stats?.closureCount ?? '--:--'}
            </span>
            <span className="dashname">Ср. по кампаниям</span>
          </div>
        </div>
      </div>
    </>
  )
})
