import { useEffect, useState } from 'react'
import { AgentGroup, CampaignResponse } from '../../../../api/data-contracts'
import { useApi } from '../../../../store/hooks/request'
import { selectedItem } from '../SettingsContent'
import DropDown from './DropDown/DropDown'
import './SettingsSideDropDown.sass'
import { useAppDispatch } from '../../../../store/hooks/hook'
import { getAllTags } from '../../../../store/requests/tag'

interface SettingsSideDropDownProps {
  handler: (params: selectedItem) => void
  selectedItem?: selectedItem
}
export default function SettingsSideDropDown({
  handler,
  selectedItem,
}: SettingsSideDropDownProps) {
  const [agentsGroup, setAgentsGroup] = useState<AgentGroup[] | undefined>()
  const [campaigns, setCampaigns] = useState<CampaignResponse[] | undefined>()
  const dispatch = useAppDispatch()
  const { api } = useApi()

  useEffect(() => {
    const getAgentsGroups = async () => {
      const { data } = await api.getAgentGroupsUsingGet()
      setAgentsGroup(data.agentGroups)
    }
    const getAllCampaigns = async () => {
      const { data } = await api.findAllCampaignsUsingGet()
      setCampaigns(data)
    }
    dispatch(getAllTags({ api }))
    getAgentsGroups()
    getAllCampaigns()
    // eslint-disable-next-line
  }, [])
  return (
    <div className="settings-side-drop-down-wrapper">
      {agentsGroup && (
        <DropDown
          type="dash"
          groups={agentsGroup}
          hadler={handler}
          selectedItem={selectedItem}
        />
      )}
      <DropDown
        type="topdash"
        hadler={handler}
        groups={agentsGroup}
        selectedItem={selectedItem}
      />
      <DropDown
        type="tags"
        hadler={handler}
        campaigns={campaigns}
        selectedItem={selectedItem}
      />
    </div>
  )
}
