import CRUDTable, {
  CreateForm,
  DeleteForm,
  Field,
  Fields,
  UpdateForm,
} from 'react-crud-table'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { useApi } from '../../../store/hooks/request'
import { useEffect } from 'react'
import {
  deleteChatGroup,
  findAllChatGroup,
  saveOrUpdateChatGroup,
} from '../../../store/requests/admin'
import { adminSelector } from '../../../store/selectors/admin'
import '../AgentGroupEntity/crud-style.sass'
import { AdminChatGroupRoomDto } from '../../../api/data-contracts'

export default function AdminChatGroupRoom() {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { chatGroupRoom } = useAppSelector(adminSelector)
  useEffect(() => {
    dispatch(findAllChatGroup({ api }))
    // eslint-disable-next-line
  }, [])

  const createUsersArray = (memberIds?: string[]): { memberIds: string[] } => ({
    memberIds: [...(memberIds as unknown as string).split(',')],
  })

  const chatGroupService = {
    create: ({ memberIds, ...rest }: AdminChatGroupRoomDto) => {
      const localTask = {
        ...rest,
        ...createUsersArray(memberIds),
      }
      dispatch(
        saveOrUpdateChatGroup({
          api,
          ...localTask,
        })
      )
      return Promise.resolve(localTask)
    },
    update: async ({ memberIds, ...rest }: AdminChatGroupRoomDto) => {
      const { data } = await api.findAllUsingGet3()
      const task: AdminChatGroupRoomDto | undefined = data.find(
        (t) => t.chatId === rest.chatId
      )

      const localTask = {
        ...task,
        ...createUsersArray(memberIds),
      }

      dispatch(saveOrUpdateChatGroup({ ...localTask, api }))
      return Promise.resolve(localTask)
    },
    delete: (data: AdminChatGroupRoomDto) => {
      const task = chatGroupRoom.find((t) => t.chatId !== data.chatId)
      dispatch(
        deleteChatGroup({
          ...data,
          api,
        })
      )
      return Promise.resolve(task)
    },
  }

  const exRooms = chatGroupRoom.map((e) => ({
    ...e,
    memberIds: e.memberIds?.join(', '),
  }))

  return (
    <div className="table-container">
      <CRUDTable caption="Групповые чаты" items={exRooms}>
        <Fields>
          <Field name="chatId" label="id" hideInCreateForm hideInUpdateForm />
          <Field name="campaignName" label="Название кампании" />
          <Field name="chatName" label="Название чата" />
          <Field name="memberIds" label="Id участников" />
        </Fields>
        <CreateForm
          title="Создать"
          message="Создать"
          trigger="Создать"
          submitText="Создать"
          onSubmit={chatGroupService.create}
        />
        <UpdateForm
          title="Обновить"
          message="Обновить"
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={chatGroupService.update}
        />
        <DeleteForm
          title="Удалить"
          message="Уверены?"
          trigger="Удалить"
          submitText="Удалить"
          onSubmit={chatGroupService.delete}
        />
      </CRUDTable>
    </div>
  )
}
