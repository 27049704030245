export enum TopDashboardItems {
  StartShift = 'start-shift',
  WorkTime = 'work-time',
  AverageCallTime = 'average-call-time',
  Calls = 'calls',
  Sucess = 'sucess',
  RPC = 'rpc',
  Deals = 'deals',
}

export const initialLayouts: ReactGridLayout.Layouts = {
  lg: [
    {
      i: TopDashboardItems.StartShift,
      x: 0,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxH: 1,
      maxW: 3,
    },
    {
      i: TopDashboardItems.WorkTime,
      x: 1,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxH: 1,
      maxW: 3,
    },
    {
      i: TopDashboardItems.Calls,
      x: 2,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxH: 1,
      maxW: 3,
    },
    {
      i: TopDashboardItems.AverageCallTime,
      x: 3,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxH: 1,
      maxW: 3,
    },
    {
      i: TopDashboardItems.Sucess,
      x: 4,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxH: 1,
      maxW: 3,
    },
    {
      i: TopDashboardItems.RPC,
      x: 5,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxH: 1,
      maxW: 3,
    },
    {
      i: TopDashboardItems.Deals,
      x: 6,
      y: 0,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxH: 1,
      maxW: 3,
    },
  ],
}
export const allTopDash = Object.values(TopDashboardItems)
