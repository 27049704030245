import { useCallback, useEffect, useMemo, useState } from 'react'
import { Layout, Responsive, WidthProvider } from 'react-grid-layout'
import DeleteDash from '../../../../components/Icons/Dashboard/DeleteDash'
import { useApi } from '../../../../store/hooks/request'
import AverageCallTime from '../../../Dashboard/AverageCallTime/AverageCallTime'
import Calls from '../../../Dashboard/Calls/Calls'
import CompanyProgress from '../../../Dashboard/CompanyProgress/CompanyProgress'
import OperatorStatuses from '../../../Dashboard/OperatorStatuses/OperatorStatuses'
import PlannedCalls from '../../../Dashboard/PlannedCalls/PlannedCalls'
import StartShift from '../../../Dashboard/StartShift/StartShift'
import WorkTime from '../../../Dashboard/WorkTime/WorkTime'
import EditorPanel from './EditorPanel/EditorPanel'
import { allDash, DashboardItems, initialLayouts } from './types'
import './DashbordEditor.sass'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface DashbordEditorProps {
  layout?: ReactGridLayout.Layouts
  id?: number
}

export default function DashbordEditor({ layout, id }: DashbordEditorProps) {
  const [currentLayout, setLayouts] = useState<ReactGridLayout.Layouts>()

  const { api } = useApi()

  const dashboardItems = useMemo(() => {
    return currentLayout?.lg?.map((layout) => layout.i) as DashboardItems[]
  }, [currentLayout])

  const editorItems = useMemo(() => {
    return dashboardItems
      ? allDash.filter((item) => !dashboardItems.includes(item))
      : undefined
  }, [dashboardItems])

  const layoutSave = useCallback(() => {
    const saveLayout = async () => {
      await api.setDashboardStateByGroupIdUsingPost({
        agentGroupId: id,
        dashboardStateList: currentLayout?.lg,
      })
    }
    saveLayout()
    // eslint-disable-next-line
  }, [id, currentLayout])
  const onAddItem = useCallback((addProps: Layout) => {
    setLayouts((prev) => {
      return { ...prev, lg: prev!.lg.concat(addProps) }
    })
  }, [])
  const deletDash = useCallback((name: DashboardItems) => {
    setLayouts((prev) => {
      return { ...prev, lg: prev!.lg.filter((el) => el.i !== name) }
    })
  }, [])

  useEffect(() => {
    layout ? setLayouts(layout) : setLayouts(initialLayouts)
  }, [layout])

  return (
    <div className="dashboard-editor">
      <EditorPanel
        type="dash"
        onSave={layoutSave}
        onAddItem={onAddItem}
        items={editorItems}
      />
      <ResponsiveGridLayout
        layouts={currentLayout}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 8, md: 6, sm: 4, xs: 2, xxs: 1 }}
        rowHeight={250}
        isDraggable
        isResizable
        margin={[32, 32]}
        onLayoutChange={(_, allLayouts) => {
          setLayouts(allLayouts)
        }}
      >
        {dashboardItems?.map((item) => {
          switch (item) {
            case DashboardItems.WorkTime:
              return (
                <div className="widget" key={DashboardItems.WorkTime}>
                  <DeleteDash onDelete={() => deletDash(item)} />
                  <WorkTime />
                </div>
              )
            case DashboardItems.StartShift:
              return (
                <div className="widget" key={DashboardItems.StartShift}>
                  <DeleteDash onDelete={() => deletDash(item)} />
                  <StartShift />
                </div>
              )
            case DashboardItems.AverageCallTime:
              return (
                <div className="widget" key={DashboardItems.AverageCallTime}>
                  <DeleteDash onDelete={() => deletDash(item)} />

                  <AverageCallTime />
                </div>
              )
            case DashboardItems.CompanyProgress:
              return (
                <div className="widget" key={DashboardItems.CompanyProgress}>
                  <DeleteDash onDelete={() => deletDash(item)} />

                  <CompanyProgress />
                </div>
              )
            case DashboardItems.Calls:
              return (
                <div className="widget" key={DashboardItems.Calls}>
                  <DeleteDash onDelete={() => deletDash(item)} />

                  <Calls />
                </div>
              )
            case DashboardItems.OperatorStatuses:
              return (
                <div className="widget" key={DashboardItems.OperatorStatuses}>
                  <DeleteDash onDelete={() => deletDash(item)} />

                  <OperatorStatuses />
                </div>
              )
            case DashboardItems.PlannedCalls:
              return (
                <div className="widget" key={DashboardItems.PlannedCalls}>
                  <DeleteDash onDelete={() => deletDash(item)} />

                  <PlannedCalls />
                </div>
              )

            default:
              return <></>
          }
        })}
      </ResponsiveGridLayout>
    </div>
  )
}
