import { DashboardDropProp } from '../../../views/Dashboard/types'
import { TopDashboardItems } from '../../../views/Settings/SettingsContent/TopDashEditor/types'

export default function Deals({ onAddItem }: DashboardDropProp) {
  return (
    <svg
      onClick={() =>
        onAddItem({
          x: 0,
          y: 0,
          w: 2,
          h: 1,
          minH: 1,
          minW: 2,
          i: TopDashboardItems.Deals,
        })
      }
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M30.5673 19.4172L28.25 20.5759L25.25 14.8307L27.5909 13.6602C27.7667 13.5723 27.9701 13.5569 28.1572 13.6175C28.3443 13.678 28.5001 13.8096 28.5911 13.9839L30.8967 18.3992C30.9428 18.4875 30.9708 18.5841 30.9792 18.6834C30.9876 18.7826 30.9761 18.8825 30.9454 18.9773C30.9148 19.0721 30.8655 19.1598 30.8006 19.2353C30.7357 19.3108 30.6564 19.3727 30.5673 19.4172V19.4172Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7499 20.4753L9.43266 19.3166C9.34357 19.2721 9.26425 19.2103 9.19932 19.1347C9.13439 19.0592 9.08516 18.9715 9.0545 18.8767C9.02383 18.782 9.01235 18.682 9.02072 18.5828C9.02909 18.4835 9.05714 18.387 9.10325 18.2987L11.4088 13.8833C11.4998 13.709 11.6557 13.5774 11.8427 13.5169C12.0298 13.4564 12.2332 13.4717 12.4091 13.5596L14.7499 14.7301L11.7499 20.4753Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.25 20.5761L26.75 22.3309L23.3003 25.7806C23.2087 25.8722 23.095 25.9385 22.9701 25.9731C22.8453 26.0076 22.7137 26.0093 22.588 25.9779L17.1546 24.6195C17.0527 24.594 16.9572 24.5474 16.8745 24.4827L11.75 20.4756"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7498 22.3311L22.6248 19.3311L21.4248 20.2311C20.9055 20.6205 20.2739 20.8311 19.6248 20.8311C18.9757 20.8311 18.3441 20.6205 17.8248 20.2311L17.3166 19.8499C17.2306 19.7854 17.1595 19.7032 17.1081 19.6089C17.0567 19.5146 17.0261 19.4103 17.0185 19.3031C17.0109 19.1959 17.0264 19.0883 17.0639 18.9877C17.1015 18.887 17.1603 18.7956 17.2363 18.7196L20.9051 15.0507C20.9748 14.9811 21.0574 14.9258 21.1484 14.8881C21.2394 14.8505 21.337 14.8311 21.4355 14.8311H25.2498"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8037 14.7305L19.615 13.3278C19.7868 13.2777 19.971 13.2907 20.1341 13.3645L23.3749 14.8309"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 27.9558L15.6744 27.2494C15.5598 27.2207 15.4535 27.1654 15.3643 27.0878L13.25 25.25"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
