import React, { memo, useMemo } from 'react'
import MessageInputArea from './MessageInput/MessageInputArea'
import ChatArea from './Chat/ChatArea'
import {
  GroupChatStoreItem,
  OneToOneChatStoreItem,
} from '../../../../store/slices/chatSlice'
import { useAppSelector } from '../../../../store/hooks/hook'
import { userSelector } from '../../../../store/selectors/user'
import { OneToOneChatInfo } from './SupervisorChatInfo/OneToOneChatInfo'
import GroupChatInfo from './SupervisorChatInfo/GroupChatInfo'
import { SelectedChat } from '../../../../store/slices/chatSlice'
import './Dialog.sass'

type DialogProps = GroupChatStoreItem &
  OneToOneChatStoreItem & { type: SelectedChat['type'] }

function Dialog(props: DialogProps) {
  const { role } = useAppSelector(userSelector)
  const isSupervisor = useMemo(() => {
    return role![0] === 'ROLE_SUPERVISOR'
  }, [role])
  return (
    <>
      <div className="chat-list">
        <ChatArea
          senderId={props.senderId}
          messages={props.messages}
          type={props.type}
        />

        {props.messages && <MessageInputArea {...props} />}
      </div>
      {isSupervisor && props.type && (
        <div className="chat-list-supevisor-info">
          {props.type === 'oneToOne' ? (
            <OneToOneChatInfo agentId={props.senderId} />
          ) : (
            <GroupChatInfo {...props} />
          )}
        </div>
      )}
    </>
  )
}

export default memo(Dialog)
