import './Dropdown.sass'
import React, { ChangeEventHandler } from 'react'
import DDArrowDown from '../../../Icons/DDArrowDown/DDArrowDown'

interface DropdownOption {
  text: string | number
  value: string | number
  key: string | number
  description?: string | number
}

interface DropdownProps {
  options?: DropdownOption[]
  value: string | number
  onChange: ChangeEventHandler<HTMLSelectElement>
  withEmptyValue?: boolean
}

export default function Dropdown({
  options,
  value,
  onChange,
  withEmptyValue,
}: DropdownProps) {
  const finalOptions = !withEmptyValue
    ? options
    : options
    ? [
        { text: 'Не выбрано', value: undefined, key: 'empty-dd-elem' },
        ...options,
      ]
    : []

  return (
    <div className="dropdown-field-wrapper">
      <select className="dropdown-field" value={value} onChange={onChange}>
        {finalOptions?.map((opt) => (
          <option value={opt.value} key={opt.key}>
            {opt.text}
          </option>
        ))}
      </select>
      <div className="dropdown-field-icon">
        <DDArrowDown />
      </div>
    </div>
  )
}
