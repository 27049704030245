import { useSubscription } from 'react-stomp-hooks'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import {
  CallbackDestsPomResponse,
  CallbackTypesPomResponse,
  DefaultAgentMessageResponse,
  PreviewCallbackStatusResponse,
} from '../../api/data-contracts'
import {
  setCallbackDests,
  setCallbackTypes,
} from '../../store/slices/scriptSlice'
import { addNotification } from '../../store/slices/notificationSlice'
import moment from 'moment'

export default function useScriptCallback(authorizationStatus: boolean) {
  const { agentId, role } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const isUser = role && role[0] === 'ROLE_USER'
  useSubscription(
    authorizationStatus && isUser
      ? `/agent/${agentId}/get-callback-dests-for-type`
      : [],
    (mes) => {
      const body: CallbackDestsPomResponse = JSON.parse(mes.body)
      if (body) dispatch(setCallbackDests(body))
    }
  )

  useSubscription(
    authorizationStatus && isUser ? `/agent/${agentId}/get-callback-types` : [],
    (mes) => {
      const body: CallbackTypesPomResponse = JSON.parse(mes.body)
      const isSuccess = body.message === 'SUCCESS'
      if (body && body.types && isSuccess)
        dispatch(setCallbackTypes(body.types))
      if (!isSuccess)
        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'warning',
            title: 'Ошибка',
            text: 'Не удалось получить типы обратного вызова',
          })
        )
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/create-callback` : [],
    (mes) => {
      const body: DefaultAgentMessageResponse = JSON.parse(mes.body)
      if (body.message === 'SUCCESS')
        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'info',
            title: 'Успешно',
            text: 'Обратный вызов запланирован',
          })
        )
      else {
        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'warning',
            title: 'Ошибка',
            text: 'Не удалось запланировать обратный вызов',
          })
        )
      }
    }
  )

  useSubscription(
    authorizationStatus && isUser
      ? `/agent/${agentId}/preview-callback-status`
      : [],
    (mes) => {
      const body: PreviewCallbackStatusResponse = JSON.parse(mes.body)
      if (!(body.message === 'SUCCESS')) return
      const date = moment(body.dueTime, moment.ISO_8601).format(
        'DD.MM.YYYY, HH:mm'
      )
      if (body.status === 'Cancelled')
        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'info',
            title: 'Обратный звонок',
            text: `Обратный звонок в ${date} отменён`,
          })
        )
      else if (body.status === 'Pending')
        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'info',
            title: 'Обратный звонок',
            text: `Обратный звонок состоится в ${date}`,
          })
        )
    }
  )
}
