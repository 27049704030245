import './NotFound.sass'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'

export default function NotFound() {
  const navigate = useNavigate()
  const handleGoBack = React.useCallback(() => navigate(-1), [navigate])
  const handleGoMain = React.useCallback(
    () => navigate('/', { replace: true }),
    [navigate]
  )

  return (
    <div className="notfound">
      <div className="notfound-wrapper">
        <p className="notfound-text">
          К сожалению, страница, которую вы ищете, недоступна.
        </p>
        <div className="notfound-button-wrapper">
          <Button
            text="Назад"
            onClick={handleGoBack}
            className="notfound-button-bottom-margin"
          />
          <Button text="На главную" onClick={handleGoMain} />
        </div>
      </div>
    </div>
  )
}
