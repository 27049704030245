import CampaignCard from '../CampaignCard/CampaignCard'
import './SupervisorCampaignContent.sass'
import { useState } from 'react'
import CampaignCallbacks from '../CampaignCallbacks/CampaignCallbacks'
import CampaignOperators from '../CampaignOperators/CampaignOperators'

interface SupervisorContentType {
  pickedJob: number | undefined
  pickedCampaign: string | undefined
  activeCampaign: number | undefined
}

export default function SupervisorCampaignContent({
  pickedJob,
  activeCampaign,
  pickedCampaign,
}: SupervisorContentType) {
  const [selectedPage, setSelectedPage] = useState('info')

  return (
    <div className="supervisor-campaign-content">
      <div className="supervisor-campaign-content-header">
        <div
          className={
            'supervisor-campaign-content-header-button' +
            (selectedPage === 'info' ? '-active' : '')
          }
          onClick={() => setSelectedPage('info')}
        >
          Информация
        </div>
        <div
          className={
            'supervisor-campaign-content-header-button' +
            (selectedPage === 'operators' ? '-active' : '')
          }
          onClick={() => setSelectedPage('operators')}
        >
          Операторы
        </div>
        <div
          className={
            'supervisor-campaign-content-header-button' +
            (selectedPage === 'callbacks' ? '-active' : '')
          }
          onClick={() => setSelectedPage('callbacks')}
        >
          Callbacks
        </div>
      </div>
      <div className="supervisor-campaign-content-content">
        {selectedPage === 'info' ? (
          <CampaignCard activeCampaign={activeCampaign} pickedJob={pickedJob} />
        ) : selectedPage === 'operators' ? (
          <CampaignOperators
            jobId={pickedJob}
            pickedCampaign={pickedCampaign}
          />
        ) : selectedPage === 'callbacks' ? (
          <CampaignCallbacks
            jobId={pickedJob}
            pickedCampaign={pickedCampaign}
          />
        ) : null}
      </div>
    </div>
  )
}
