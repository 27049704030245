import React, { useCallback, useEffect, useState } from 'react'
import './ChatSubheader.sass'
import InfoIcon from '../../../../../components/Icons/Chat/InfoIcon/InfoIcon'
import InfoItem from '../../../../../components/InfoItem/InfoItem'
import Avatar from '../../../../../components/Avatar/Avatar'
import NeedHelpIcon from '../../../../../components/Icons/Chat/NeedHelp/NeedHelpIcon'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import { chatSelector } from '../../../../../store/selectors/chat'
import { userSelector } from '../../../../../store/selectors/user'
import { sendRequest } from '../../../../../store/requests/header'
import { useApi } from '../../../../../store/hooks/request'
import SearchIcon from '../../../../../components/Icons/Chat/SearchIcon/SearchIcon'
import Input from '../../../../../components/Input/Input'
import Accept from '../../../../Script/Content/Nodes/Components/Accept/Accept'
import { setSearchString } from '../../../../../store/slices/chatSlice'

interface MemberAreaProps {
  // icon: object
  name: string
  status: string
}

export default function ChatSubheader(props: MemberAreaProps) {
  const { selectedChat, searchString } = useAppSelector(chatSelector)
  const { role, agentId, agentName, agentStatus } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const [isSearch, setIsSearch] = useState(false)

  useEffect(() => {
    if (typeof searchString === 'string') setIsSearch(true)
  }, [searchString])

  const handleSendHelpRequest = useCallback(() => {
    dispatch(
      sendRequest({
        agentId,
        api,
        agentName,
        type: 'HELP_REQUEST',
      })
    )
    // eslint-disable-next-line
  }, [agentId, agentName])
  const handleSearch = useCallback(() => {
    setIsSearch(true)
    dispatch(setSearchString(''))
  }, [dispatch])
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setSearchString(e.currentTarget.value))
    },
    [dispatch]
  )
  const onCancelSearching = useCallback(() => {
    setIsSearch(false)
    dispatch(setSearchString(null))
  }, [dispatch])

  return (
    <div className="chat-subheader">
      {isSearch && searchString !== null ? (
        <>
          <Input
            span=""
            placeholder="Поиск по сообщениям"
            onChange={onChange}
            value={searchString || ''}
          />
          <div className="accept-wrapper">
            <Accept
              onlyCancel
              onAccept={() => {}}
              onCancel={onCancelSearching}
            />
          </div>
        </>
      ) : (
        props.name !== '' && (
          <>
            <div className="chat-subheader-summary">
              <Avatar hasStatus={false} />
              <InfoItem data={props.name} title={props.status} />
            </div>
            <div className="chat-subheader-summary-icons">
              <div
                className="chat-subheader-summary-icon"
                onClick={handleSearch}
              >
                <SearchIcon size={20} />
              </div>
              <div className="chat-subheader-summary-icon">
                <InfoIcon size={20} />
              </div>
              {selectedChat?.type === 'oneToOne' &&
                role &&
                role[0] === 'ROLE_USER' &&
                agentStatus && (
                  <div className="chat-subheader-summary-icon">
                    <NeedHelpIcon size={20} onClick={handleSendHelpRequest} />
                  </div>
                )}
            </div>
          </>
        )
      )}
    </div>
  )
}
