import { CampaignsCompletionCodeStatisticsResponse } from '../../../../api/data-contracts'
import './SupervisorCompain.sass'
interface SupervisorCompainProps {
  data?: CampaignsCompletionCodeStatisticsResponse[]
}
const complitionRender = (
  compains: CampaignsCompletionCodeStatisticsResponse['completionCode']
) => {
  return Object.entries(compains!).map((entry) => {
    return (
      <div className="supervisor-operators-row-counter">
        <span>{entry[0]}</span>
        <span>{entry[1]}</span>
      </div>
    )
  })
}

export default function SupervisorCompain({ data }: SupervisorCompainProps) {
  return (
    <div className="supervisor-compain-container">
      {data?.map((compain) => {
        return (
          <div className="supervisor-compain-row" key={compain.campaignName}>
            <div className="supervisor-compain-row-operator">
              {compain.campaignName}
            </div>
            <div className="supervisor-compain-row-content">
              {complitionRender(compain.completionCode)}
            </div>
          </div>
        )
      })}
    </div>
  )
}
