import { FC } from 'react'
import { useAppSelector } from '../../store/hooks/hook'
import { headerSelector } from '../../store/selectors/header'

const ContentLayout: FC = ({ children }) => {
  const { isCollapsed } = useAppSelector(headerSelector)
  return (
    <div
      className={`content-layout-content ${
        isCollapsed ? 'content-layout-content-full' : ''
      }`}
    >
      {children}
    </div>
  )
}

export default ContentLayout
