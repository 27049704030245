import { AgentStatusForSupervisor } from '../../../../../api/data-contracts'
import Yellow from './Icons/Yellow'
import Blue from './Icons/Blue'
import Green from './Icons/Green'
import Grey from './Icons/Grey'
import Red from './Icons/Red'

export const helper = (state: AgentStatusForSupervisor['state']) => {
  switch (state) {
    case 'IDLE':
      return {
        tagText: 'idle',
        tagColor: 'yellow',
        icon: Yellow(),
      }

    case 'PENDING_CALL':
      return {
        tagText: 'ожидает звонок',
        tagColor: 'blue',
        icon: Blue(),
      }

    case 'ONCALL':
      return {
        tagText: 'разговаривает',
        tagColor: 'green',
        icon: Green(),
      }

    case 'WRAPUP':
      return {
        tagText: 'завершение вызова',
        tagColor: 'green',
        icon: Green(),
      }

    case 'WRAPUP_COMPLETE':
      return {
        tagText: 'вызов завершен',
        tagColor: 'blue',
        icon: Blue(),
      }

    case 'PREVIEW':
      return {
        tagText: 'просмотр контакта',
        tagColor: 'grey',
        icon: Grey(),
      }

    case 'CALLBACK':
      return {
        tagText: 'callback',
        tagColor: 'blue',
        icon: Blue(),
      }

    case 'DIALING':
      return {
        tagText: 'набирает',
        tagColor: 'blue',
        icon: Blue(),
      }

    case 'CALL_HELD':
      return {
        tagText: 'звонок на удержании',
        tagColor: 'grey',
        icon: Grey(),
      }

    case 'CONSULT_INITIATED':
      return {
        tagText: 'unsupported status',
        tagColor: 'red',
        icon: Red(),
      }

    case 'CONSULT_ANSWERED':
      return {
        tagText: 'unsupported status',
        tagColor: 'red',
        icon: Red(),
      }

    case 'CONFERENCED':
      return {
        tagText: 'unsupported status',
        tagColor: 'red',
        icon: Red(),
      }

    case 'CONFERENCE_ENDED':
      return {
        tagText: 'unsupported status',
        tagColor: 'red',
        icon: Red(),
      }

    case 'CONFERENCE_HELD':
      return {
        tagText: 'unsupported status',
        tagColor: 'red',
        icon: Red(),
      }

    case 'CALL_TRANSFERRED':
      return {
        tagText: 'unsupported status',
        tagColor: 'red',
        icon: Red(),
      }

    // TODO: добавить статус LOGOFF на бэке
    // @ts-ignore
    case 'LOGOFF':
      return {
        tagText: 'отсутствует',
        tagColor: 'red',
        icon: Red(),
      }
    // TODO: добавить статус FORCE_LOGOUT на бэке
    // @ts-ignore
    case 'FORCE_LOGOUT':
      return {
        tagText: 'не в системе',
        tagColor: 'red',
        icon: Red(),
      }
    // TODO: добавить статус LOGOUT на бэке
    // @ts-ignore
    case 'LOGOUT':
      return {
        tagText: 'не в системе',
        tagColor: 'red',
        icon: Red(),
      }

    case 'UNKNOWN':
      return {
        tagText: 'неизвестно',
        tagColor: 'red',
        icon: Red(),
      }
  }
}
