import React from 'react'

export default function CallTime() {
  return (
    <React.Fragment>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="16" fill="#FFEDEA" />
        <path
          d="M28 22V28"
          stroke="#C15747"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.1962 31L28 28"
          stroke="#C15747"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.021 24.4646H40.021V19.4646"
          stroke="#C15747"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.7782 35.7782C34.2398 37.3166 32.2798 38.3642 30.146 38.7886C28.0122 39.2131 25.8005 38.9952 23.7905 38.1627C21.7805 37.3301 20.0625 35.9202 18.8538 34.1113C17.6451 32.3023 17 30.1756 17 28C17 25.8244 17.6451 23.6977 18.8538 21.8887C20.0625 20.0798 21.7805 18.6699 23.7905 17.8373C25.8005 17.0048 28.0122 16.7869 30.146 17.2114C32.2798 17.6358 34.2398 18.6835 35.7782 20.2218L40.0208 24.4645"
          stroke="#C15747"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </React.Fragment>
  )
}
