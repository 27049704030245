import CRUDTable, {
  Fields,
  Field,
  UpdateForm,
  DeleteForm,
  CreateForm,
} from 'react-crud-table'
import { useApi } from '../../../store/hooks/request'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { adminSelector } from '../../../store/selectors/admin'
import React, { useEffect, useState } from 'react'
import {
  deleteSupervisor,
  findAllSupervisor,
  saveOrUpdateSupervisor,
} from '../../../store/requests/admin'
import { AdminSupervisorDto } from '../../../api/data-contracts'
import AssignModal from '../AssignModal'
import UnAssignModal from '../UnAssignModal'

export default function Supervisor() {
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const { supervisor } = useAppSelector(adminSelector)
  const [showModal, setShowModal] = useState(false)
  const [showUnAssignModal, setShowUnAssignModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  const handleShowUnAssignModal = () => {
    setShowUnAssignModal(!showUnAssignModal)
  }
  useEffect(() => {
    dispatch(findAllSupervisor({ api }))
    //eslint-disable-next-line
  }, [])

  let supervisorService = {
    create: (task: AdminSupervisorDto) => {
      dispatch(saveOrUpdateSupervisor({ ...task, api }))
      return Promise.resolve(task)
    },
    update: (data: AdminSupervisorDto) => {
      const task: AdminSupervisorDto | undefined = supervisor.find(
        (t) => t.pimUserId === data.pimUserId
      )
      dispatch(saveOrUpdateSupervisor({ api, ...data }))
      return Promise.resolve(task)
    },
    delete: (data: AdminSupervisorDto) => {
      const task = supervisor.find((t) => t.pimUserId === data.pimUserId)
      dispatch(deleteSupervisor({ ...data, api }))
      return Promise.resolve(task)
    },
  }

  const exSuper = supervisor.map((e) => ({
    ...e,
    agentGroupName: (
      <div>
        {e.agentGroups?.map((e) => (
          <div key={e.agentGroupId}>{e.agentGroupName}</div>
        ))}
      </div>
    ),
  }))

  return (
    <div className="table-container">
      <button className="button-supervisor" onClick={handleShowModal}>
        Привязать группу
      </button>
      <button
        className="button-supervisor-red"
        onClick={handleShowUnAssignModal}
      >
        Отвязать группу
      </button>
      <CRUDTable caption="Supervisor" items={exSuper || []}>
        <Fields>
          <Field name="pimUserId" label="id" />
          <Field name="userName" label="Имя" />
          <Field name="orgName" label="Организация" />
          <Field name="supervisorName" type="list" label="Имя супервизора" />
          <Field
            name="agentGroups"
            label="Группы"
            tableValueResolver="agentGroupName"
            hideInCreateForm
            hideInUpdateForm
          />
        </Fields>
        <CreateForm
          title="Создать"
          message="Создать"
          trigger="Создать"
          submitText="Создать"
          onSubmit={supervisorService.create}
        />
        <UpdateForm
          title="Task Update Process"
          message="Update task"
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={supervisorService.update}
        />
        <DeleteForm
          title="Удалить"
          message="Уверены?"
          trigger="Удалить"
          submitText="Удалить"
          onSubmit={supervisorService.delete}
        />
      </CRUDTable>
      {showModal && (
        <AssignModal freeClose={handleShowModal} type="agentGroup" />
      )}
      {showUnAssignModal && (
        <UnAssignModal freeClose={handleShowUnAssignModal} type="agentGroup" />
      )}
    </div>
  )
}
