import React from 'react'

export default function CallBack() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66965 11.7024C9.44762 13.2929 10.7369 14.5763 12.3309 15.347C12.4475 15.4023 12.5765 15.4262 12.7052 15.4165C12.8339 15.4067 12.9579 15.3636 13.0648 15.2915L15.4119 13.7264C15.5157 13.6572 15.6352 13.6149 15.7594 13.6035C15.8837 13.5921 16.0088 13.6119 16.1235 13.661L20.5144 15.5428C20.6636 15.6062 20.7881 15.7163 20.8693 15.8566C20.9504 15.9969 20.9838 16.1598 20.9643 16.3207C20.8255 17.4067 20.2956 18.4049 19.4739 19.1283C18.6521 19.8518 17.5948 20.2509 16.5 20.2509C13.1185 20.2509 9.87548 18.9076 7.48439 16.5166C5.0933 14.1255 3.75 10.8825 3.75 7.50095C3.75006 6.40611 4.14918 5.34883 4.87264 4.52708C5.5961 3.70533 6.59428 3.17545 7.68028 3.03667C7.84117 3.0172 8.00403 3.05054 8.14432 3.13167C8.28461 3.21281 8.39473 3.33734 8.4581 3.4865L10.3416 7.8813C10.3903 7.99497 10.4101 8.11893 10.3994 8.24214C10.3886 8.36534 10.3475 8.48397 10.2798 8.58745L8.72011 10.9705C8.64912 11.0778 8.60716 11.2015 8.59831 11.3298C8.58947 11.4581 8.61405 11.5864 8.66965 11.7024V11.7024Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 5.25V9H18.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 9L19.5 4.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
