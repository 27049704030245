import React from 'react'

export default function SelectArrow() {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0L5 6L0 0H10Z" fill="#556268" />
    </svg>
  )
}
