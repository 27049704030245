import React from 'react'
import { IconType } from '../types'

export default function Warning(props: IconType) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M16 13V18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.27 4.99809L3.27296 23.9971C3.09699 24.3011 3.0042 24.6461 3.00391 24.9974C3.00362 25.3486 3.09584 25.6938 3.27131 25.9981C3.44677 26.3024 3.69928 26.5551 4.00343 26.7308C4.30758 26.9065 4.65265 26.999 5.00391 26.999H26.998C27.3492 26.999 27.6943 26.9065 27.9985 26.7308C28.3026 26.5551 28.5551 26.3024 28.7306 25.9981C28.906 25.6938 28.9983 25.3486 28.998 24.9974C28.9977 24.6461 28.9049 24.3011 28.7289 23.9971L17.7319 4.99809C17.5562 4.69457 17.3038 4.44258 17 4.2674C16.6962 4.09222 16.3516 4 16.0009 4C15.6502 4 15.3057 4.09222 15.0019 4.2674C14.6981 4.44258 14.4457 4.69457 14.27 4.99809V4.99809Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z"
        fill="white"
      />
    </svg>
  )
}
