interface CheckmarkProps {
  stroke: string
}

export default function Checkmark({ stroke }: CheckmarkProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4142 6.41421L8.70711 18.1213L3 12.4142L4.41421 11L8.70711 15.2929L19 5L20.4142 6.41421Z"
        fill={stroke || 'white'}
      />
    </svg>
  )
}
