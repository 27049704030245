import React, { ReactElement } from 'react'
import './CallButton.sass'

interface CallButtonProps {
  icon: ReactElement
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default function CallButton({
  icon,
  className,
  onClick,
}: CallButtonProps) {
  return (
    <button
      className={className ? className + ' call-button' : 'call-button'}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}
