import { memo } from 'react'
import { AgentJobStatisticsNotification } from '../../../api/data-contracts'
import For from '../../../components/Icons/Dashboard/For'
import Total from '../../../components/Icons/Dashboard/Total'
import './PlannedCalls.sass'
interface PlannedCallsProps {
  data?: {
    total: AgentJobStatisticsNotification['currentCallbackCount']
    filtered: AgentJobStatisticsNotification['agentSpecificCallbackCount']
  }
}
function PlannedCalls({ data }: PlannedCallsProps) {
  return (
    <div className="dashboard-widget-container planned-calls-container">
      <div className="planned-calls-header">
        <span>
          Запланированные
          <br /> вызовы
        </span>
      </div>
      <div className="planned-calls-content">
        <div className="total">
          <span>Запланрованно всего:</span>
          <div className="total-counters">
            <Total />
            <h3>{data?.total ?? '--'}</h3>
          </div>
        </div>
        <div className="for">
          <span>Для Вас:</span>
          <div className="for-counters">
            <For />
            <h3>{data?.filtered ?? '--'}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(PlannedCalls)
