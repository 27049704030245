import { DashboardItems } from '../../Settings/SettingsContent/DashbordEditor/types'
import { DashboardDropProp } from '../types'

export default function CompanyProgressDrop({ onAddItem }: DashboardDropProp) {
  return (
    <svg
      onClick={() =>
        onAddItem({
          x: 0,
          y: 0,
          w: 5,
          h: 1,
          minW: 4,
          i: DashboardItems.CompanyProgress,
        })
      }
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M29 27.5H11V12.5"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 14L20 21.5L17 18.5L11 24.5"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 17.75V14H23.75"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
