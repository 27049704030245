import './Login.sass'
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject,
  KeyboardEvent,
  KeyboardEventHandler,
  ChangeEvent,
} from 'react'
import Ghost from '../../components/Icons/Ghost/Ghost'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import NotificationModal from '../../components/Notifications/NotificationModal/NotificationModal'
import OktaMainLogo from '../../components/Icons/AvayaMainLogo/OktaMainLogo'
import { setVisibilityNotifications } from '../../store/slices/headerSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { authorize, sendFailedAuth } from '../../store/requests/user'
import { notificationReset } from '../../store/slices/notificationSlice'
import { AgentLoginRequest } from '../../api/data-contracts'
import { useApi } from '../../store/hooks/request'
import { addLoginNotification } from '../../store/slices/userSlice'
import { userSelector } from '../../store/selectors/user'
import { setOnlyJtapi } from '../../store/slices/userSlice'

const initialCreds: AgentLoginRequest = {
  username: '',
  extension: '',
  password: '',
}

export default function Login() {
  const refId = useRef() as MutableRefObject<HTMLInputElement>
  const refPass = useRef() as MutableRefObject<HTMLInputElement>
  const dispatch = useAppDispatch()
  const { isOnlyJtapi, wrongLoginCount, pendingAuthLoginName } =
    useAppSelector(userSelector)
  const { authApi, failedAuthApi } = useApi()

  const [modalForgot, setModalForgot] = useState<boolean>(false)
  const [credentials, setCredentials] = useState(initialCreds)

  const handleLogin = useCallback(() => {
    dispatch(authorize({ credentials, authApi }))
  }, [credentials, dispatch, authApi])
  const handleEnterLogin: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => e.key === 'Enter' && handleLogin(),
    [handleLogin]
  )

  const handleSendFailedAuthorization = useCallback(() => {
    dispatch(sendFailedAuth({ failedAuthApi, login: pendingAuthLoginName }))
    // eslint-disable-next-line
  }, [pendingAuthLoginName])

  useEffect(() => {
    if (wrongLoginCount > 1) handleSendFailedAuthorization()
  }, [wrongLoginCount, handleSendFailedAuthorization])

  const forgotPassword = useCallback(
    () => setModalForgot(!modalForgot),
    [modalForgot]
  )

  const handleCancelModal = useCallback(() => setModalForgot(false), [])
  const handleOkModal = useCallback(() => {
    setModalForgot(false)
    //Вместо запроса имитация в виде setTimeout
    setTimeout(() => {
      dispatch(setVisibilityNotifications(true))
      dispatch(addLoginNotification({ ...notificationReset, id: Date.now() }))
    }, 500)
  }, [dispatch])

  const handleNext = useCallback(
    (
      e: KeyboardEvent<HTMLInputElement>,
      ref: MutableRefObject<HTMLInputElement>
    ) => e.key === 'Enter' && ref.current?.focus(),
    []
  )

  const handleInput = useCallback(
    (
      e: ChangeEvent<HTMLInputElement>,
      name: 'username' | 'password' | 'extension'
    ) => setCredentials({ ...credentials, [name]: e.currentTarget.value }),
    [credentials]
  )

  const handleSetOnlyJtapi = useCallback(
    () => dispatch(setOnlyJtapi(!isOnlyJtapi)),
    [isOnlyJtapi, dispatch]
  )

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-header">
            <OktaMainLogo />
          </div>
          <form className="login-form-wrapper">
            <Input
              id="ad-input-number"
              autofocus
              placeholder="00000"
              span="Телефон"
              onKeyPress={(e) => handleNext(e, refId)}
              onChange={(e) => handleInput(e, 'extension')}
            />
            <Input
              id="ad-input-login"
              placeholder="MirovoyMO"
              span="ID"
              innerRef={refId}
              onKeyPress={(e) => handleNext(e, refPass)}
              onChange={(e) => handleInput(e, 'username')}
            />
            <Input
              id="ad-input-pass"
              span="Пароль"
              password
              innerRef={refPass}
              onKeyPress={handleEnterLogin}
              onChange={(e) => handleInput(e, 'password')}
            />
            <p className="login-forgot" onClick={forgotPassword}>
              Забыли пароль?
            </p>
            <Button text="Войти" onClick={handleLogin} />
            <div className="login-input-checkbox" onClick={handleSetOnlyJtapi}>
              <input
                type="checkbox"
                name="CC-Elite-checkbox"
                checked={isOnlyJtapi}
                onChange={handleSetOnlyJtapi}
              />
              <div>Войти только в CC_Elite</div>
            </div>
          </form>
        </div>
      </div>
      <div className="login-image-container">
        <Ghost />
      </div>

      {modalForgot && (
        <Modal
          title="Сброс пароля"
          body="Запрос на сброс пароля будет отправлен вашему руководителю."
          onOk={handleOkModal}
          onCancel={handleCancelModal}
        />
      )}
      <NotificationModal view="login" />
    </div>
  )
}
