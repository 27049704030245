import CompanyProgress from './CompanyProgress/CompanyProgress'
import OperatorStatuses from './OperatorStatuses/OperatorStatuses'
import PlannedCalls from './PlannedCalls/PlannedCalls'
import StartShift from './StartShift/StartShift'
import WorkTime from './WorkTime/WorkTime'
import Calls from './Calls/Calls'
import AverageCallTime from './AverageCallTime/AverageCallTime'
import { useAppSelector } from '../../store/hooks/hook'
import { dashboardSelector } from '../../store/selectors/dashboard'
import { Layouts, Responsive, WidthProvider, Layout } from 'react-grid-layout'
import './Dashboard.sass'
import { useEffect, useState } from 'react'
import { useApi } from '../../store/hooks/request'
import { userSelector } from '../../store/selectors/user'
import { DashboardItems } from '../Settings/SettingsContent/DashbordEditor/types'
const ResponsiveGridLayout = WidthProvider(Responsive)

export default function DashboardContent() {
  const { jobStatistic } = useAppSelector(dashboardSelector)
  const { agentGroupId } = useAppSelector(userSelector)
  const { api } = useApi()
  const [layout, setLayout] = useState<Layouts>()

  useEffect(() => {
    const getAgentLayout = async () => {
      const { data } = await api.getDashboardWidgetStateUsingGet(agentGroupId!)
      setLayout({ lg: data.dashboardStateList as unknown as Layout[] })
    }
    getAgentLayout()
    // eslint-disable-next-line
  }, [agentGroupId])
  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-title">Статистика</div>
      <ResponsiveGridLayout
        layouts={layout}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 8, md: 6, sm: 4, xs: 2, xxs: 1 }}
        rowHeight={250}
        isDraggable={false}
        isResizable={false}
        margin={[32, 32]}
      >
        {layout?.lg.map((dash) => {
          switch (dash.i) {
            case DashboardItems.WorkTime:
              return (
                <div className="widget" key={DashboardItems.WorkTime}>
                  <WorkTime data={jobStatistic.totalJobTime} />
                </div>
              )
            case DashboardItems.StartShift:
              return (
                <div className="widget" key={DashboardItems.StartShift}>
                  <StartShift data={jobStatistic.loginTimeStamp} />
                </div>
              )
            case DashboardItems.AverageCallTime:
              return (
                <div className="widget" key={DashboardItems.AverageCallTime}>
                  <AverageCallTime
                    data={{
                      total: jobStatistic.talkTime,
                      company: jobStatistic.avgTalkTime,
                    }}
                  />
                </div>
              )
            case DashboardItems.CompanyProgress:
              return (
                <div className="widget" key={DashboardItems.CompanyProgress}>
                  <CompanyProgress
                    data={{
                      filtered: jobStatistic.filteredContactCount,
                      processed: jobStatistic.processedContactCount,
                      notDone: jobStatistic.contactsNotDoneCount,
                    }}
                  />
                </div>
              )
            case DashboardItems.Calls:
              return (
                <div className="widget" key={DashboardItems.Calls}>
                  <Calls
                    data={{
                      total: jobStatistic.callCount,
                      succes: jobStatistic.callsSuccess,
                      RTC: jobStatistic.callsRpc,
                      deals: jobStatistic.callsClosure,
                    }}
                  />
                </div>
              )
            case DashboardItems.OperatorStatuses:
              return (
                <div className="widget" key={DashboardItems.OperatorStatuses}>
                  <OperatorStatuses
                    data={jobStatistic.callStatePercentageMap}
                  />
                </div>
              )
            case DashboardItems.PlannedCalls:
              return (
                <div className="widget" key={DashboardItems.PlannedCalls}>
                  <PlannedCalls
                    data={{
                      filtered: jobStatistic.agentSpecificCallbackCount,
                      total: jobStatistic.currentCallbackCount,
                    }}
                  />
                </div>
              )

            default:
              return <></>
          }
        })}
      </ResponsiveGridLayout>
    </div>
  )
}
