import './Header.sass'
import { ChangeEvent, useCallback, useState } from 'react'
import OktaMainLogo from '../Icons/AvayaMainLogo/OktaMainLogo'
import Bell from './Bell/Bell'
import Notifier from './Notifier/Notifier'
import Button from '../Button/Button'
import Avatar from '../Avatar/Avatar'
import Modal from '../Modal/Modal'
import { headerSelector } from '../../store/selectors/header'
import { userSelector } from '../../store/selectors/user'
import { setVisibilityNotifications } from '../../store/slices/headerSlice'
import { notificationSelector } from '../../store/selectors/notification'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { useApi } from '../../store/hooks/request'
import { changeUserStatus } from '../../store/requests/userStatus'
import ProfileModal from '../ProfileModal/ProfileModal'
import NotificationModal from '../Notifications/NotificationModal/NotificationModal'
import { sendRequest } from '../../store/requests/header'
import { loginPom } from '../../store/requests/user'

interface HeaderProps {
  className?: string
}

export default function Header(props: HeaderProps) {
  const dispatch = useAppDispatch()

  const { api } = useApi()

  const {
    isCollapsed,
    visibilityNotifications,
    agentStateNotify,
    visibilityProfileModal,
    stateChangeReasons,
    pendingRequests,
  } = useAppSelector(headerSelector)

  const { notificationList, requestNotifications } =
    useAppSelector(notificationSelector)
  const { role, agentId, agentName, isOnlyJtapi } = useAppSelector(userSelector)
  const [breakModal, setBreakModal] = useState(false)
  const [pending, setPending] = useState(false)
  const [breakReason, setBreakReason] = useState<string | null>(null)

  const handleSendRequest = useCallback(() => {
    setBreakModal(false)
    const reason = stateChangeReasons.find(
      (item) => item.reasonId === Number(breakReason)
    )
    dispatch(
      sendRequest({
        agentId,
        api,
        agentName,
        type: 'BREAK_REQUEST',
        reasonName: reason?.reasonName!,
        id: reason?.reasonId!,
      })
    )
    // eslint-disable-next-line
  }, [breakReason, stateChangeReasons, agentId, agentName])

  const handleChangeBreakRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setBreakReason(e.target.value)
  }

  const handleLoginPOM = useCallback(() => {
    dispatch(loginPom({ api }))
    // eslint-disable-next-line
  }, [])

  const changeAgentState = useCallback(() => {
    if (pending) return
    if (agentStateNotify === 'NotReady' || agentStateNotify === null) {
      dispatch(
        changeUserStatus({
          api,
          agentId,
          agentState: 'Ready',
          reasonCode: '1',
          reasonName: 'AVAILABLE',
        })
      )
      setPending(true)
    } else if (agentStateNotify === 'Ready') {
      setBreakModal(true)
    }
    setTimeout(() => setPending(false), 3000)
  }, [dispatch, agentStateNotify, agentId, api, pending])

  const handleNotifications = useCallback(
    () => dispatch(setVisibilityNotifications(!visibilityNotifications)),
    [dispatch, visibilityNotifications]
  )

  const handleModalCancel = useCallback(() => setBreakModal(false), [])

  const notifiesCount = notificationList?.length + requestNotifications?.length

  return (
    <div
      className={`header ${props.className} ${
        isCollapsed ? 'header-hidden' : ''
      }`}
    >
      <OktaMainLogo />
      <div className="right-interface">
        {role && role[0] === 'ROLE_ADMIN' ? null : (
          <div className="bell-container" onClick={handleNotifications}>
            {notifiesCount > 0 && (
              <div className="bell-notifier">
                <Notifier count={notifiesCount} />
              </div>
            )}
            <div className="bell">
              <Bell />
            </div>
          </div>
        )}
        {isOnlyJtapi && role && role[0] === 'ROLE_USER' && (
          <div className="button-container pom">
            <Button
              disabled={pending || !!pendingRequests.break_rq}
              text="Войти в POM"
              onClick={handleLoginPOM}
              type="outline"
            />
          </div>
        )}
        {role && role[0] === 'ROLE_USER' && (
          <div className="button-container">
            <Button
              disabled={pending || !!pendingRequests.break_rq}
              text={
                agentStateNotify !== 'NotReady' && agentStateNotify !== null
                  ? 'Перерыв'
                  : 'Готов'
              }
              onClick={changeAgentState}
            />
          </div>
        )}
        {role && role[0] !== 'ROLE_USER' ? null : (
          <div className="status-header">
            <div
              className={`status-header-led ${
                agentStateNotify !== 'NotReady' && agentStateNotify !== null
                  ? 'status-header-led-green'
                  : 'status-header-led-yellow'
              }`}
            />
            <div className="status-header-text">
              {agentStateNotify !== 'NotReady' && agentStateNotify !== null
                ? 'Готов'
                : 'Не готов'}
            </div>
          </div>
        )}
        <Avatar />
      </div>
      {breakModal && (
        <Modal
          title="Запрос на перерыв"
          variant="radio"
          type="break"
          body="Укажите причину ухода на перерыв"
          onCancel={handleModalCancel}
          onOk={handleSendRequest}
          onRadioChange={handleChangeBreakRadio}
        />
      )}
      {visibilityProfileModal && <ProfileModal />}
      {visibilityNotifications && !isCollapsed && (
        <NotificationModal view="main" />
      )}
    </div>
  )
}
