import React, { useState } from 'react'
import './TagBar.sass'
import Search from '../../../components/Search/Search'
import Tag from '../../../components/Tag/Tag'
import { tagSelector } from '../../../store/selectors/tag'
import { useAppSelector } from '../../../store/hooks/hook'
import SearchIcon from '../../../components/Icons/Chat/SearchIcon/SearchIcon'
import { SelectedChat } from '../../../store/slices/chatSlice'

interface TagBarProps {
  type: SelectedChat['type']
  tagHandler: (text: string, type: SelectedChat['type']) => void
}

export default function TagBar({ tagHandler, type }: TagBarProps) {
  const { tagBar } = useAppSelector(tagSelector)
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <div className="tag-bar-container">
      <Search
        icon={<SearchIcon />}
        onChange={(event: { target: { value: string } }) => {
          setSearchTerm(event.target.value)
        }}
      />
      <div className="unselected-tags">
        {tagBar
          ?.filter((el) => {
            if (searchTerm === '') {
              return el
            } else if (
              el
                .tagDescription!.toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return el
            } else return ''
          })
          ?.map((el) => (
            <Tag
              key={el.tagId}
              type="chat"
              obj={el}
              onClick={() => tagHandler(el.tagDescription!, type)}
            />
          ))}
      </div>
    </div>
  )
}
