import React from 'react'
import './CaretArrow.sass'

interface PropsType {
  isActive?: boolean
}

export default function CaretArrow({ isActive }: PropsType) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isActive ? 'ad-caret-active' : ''}
    >
      <path
        d="M4.5 2.25L8.25 6L4.5 9.75"
        stroke="#A1BDCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
