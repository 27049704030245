import { tabStateType } from '../../SupervisorDashboard'
import './SupervisorDashboardContentNav.sass'
interface SupervisorDashboardContentNavProps {
  tabSelect: React.Dispatch<React.SetStateAction<tabStateType>>
  currentTab: tabStateType
}
export default function SupervisorDashboardContentNav({
  tabSelect,
  currentTab,
}: SupervisorDashboardContentNavProps) {
  return (
    <div className="supervisor-dashboard-content-nav">
      <div
        className={
          currentTab === 'stats'
            ? 'supervisor-dashboard-content-nav-tab selected'
            : 'supervisor-dashboard-content-nav-tab'
        }
        onClick={() => tabSelect('stats')}
      >
        Статистика
      </div>
      <div
        className={
          currentTab === 'ops'
            ? 'supervisor-dashboard-content-nav-tab selected'
            : 'supervisor-dashboard-content-nav-tab'
        }
        onClick={() => tabSelect('ops')}
      >
        Операторы
      </div>
      <div
        className={
          currentTab === 'comp'
            ? 'supervisor-dashboard-content-nav-tab selected'
            : 'supervisor-dashboard-content-nav-tab'
        }
        onClick={() => tabSelect('comp')}
      >
        Кампания
      </div>
    </div>
  )
}
