import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AgentJobStatisticsNotification,
  AgentStatisticsNotification,
} from '../../api/data-contracts'

export interface DashboardStore {
  statistic: AgentStatisticsNotification
  jobStatistic: AgentJobStatisticsNotification
}
const initialState: DashboardStore = {
  statistic: {},
  jobStatistic: {},
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dropDashboardSlice: () => initialState,
    updateStatistic: (
      state,
      { payload }: PayloadAction<AgentStatisticsNotification>
    ) => {
      Object.assign(state.statistic, payload)
    },
    updateJobStatistic: (
      state,
      { payload }: PayloadAction<AgentJobStatisticsNotification>
    ) => {
      Object.assign(state.jobStatistic, payload)
    },
  },
})

export const dashboardReducer = dashboardSlice.reducer
export const { updateStatistic, updateJobStatistic, dropDashboardSlice } =
  dashboardSlice.actions
