import { DashboardItems } from '../../Settings/SettingsContent/DashbordEditor/types'
import { TopDashboardItems } from '../../Settings/SettingsContent/TopDashEditor/types'
import { DashboardDropProp } from '../types'

export default function StartShiftDrop({ onAddItem, type }: DashboardDropProp) {
  const dropLayout = {
    x: 0,
    y: 0,
    w: type === 'dash' ? 2 : 2,
    h: 1,
    minW: type === 'dash' ? 2 : 2,
    i:
      type === 'dash'
        ? DashboardItems.StartShift
        : TopDashboardItems.StartShift,
  }
  return (
    <svg
      onClick={() => onAddItem(dropLayout)}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M20 14.75V20H25.25"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 28.25C24.5563 28.25 28.25 24.5563 28.25 20C28.25 15.4437 24.5563 11.75 20 11.75C15.4437 11.75 11.75 15.4437 11.75 20C11.75 24.5563 15.4437 28.25 20 28.25Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M26.3638 10.4541L29.5458 13.6361"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4541 13.6361L13.6361 10.4541"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
