import React from 'react'
import './Actions.sass'

interface DateInputActionsProps {
  onClear(): void
  onAccept(): void
}

export default function Actions({ onClear, onAccept }: DateInputActionsProps) {
  return (
    <div className="action-buttons-wrapper">
      <div className="action-buttons-left" onClick={onClear}>
        Очистить
      </div>
      <div className="action-buttons-right" onClick={onAccept}>
        Принять
      </div>
    </div>
  )
}
