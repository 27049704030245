import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { adminSelector } from '../../store/selectors/admin'
import { assignGroup, assignSupervisor } from '../../store/requests/admin'
import { useApi } from '../../store/hooks/request'
import './AgentGroupEntity/crud-style.sass'

interface AssignModalType {
  type: 'supervisor' | 'agentGroup'
  freeClose: () => void
}

export default function AssignModal(props: AssignModalType) {
  const { supervisor, agentGroup } = useAppSelector(adminSelector)
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const [superValue, setSuperValue] = useState('')
  const [groupValue, setGroupValue] = useState(0)

  const handleSuperChange = (event: any) => {
    setSuperValue(event.target.value)
  }

  const handleGroupChange = (event: any) => {
    setGroupValue(event.target.value)
    parseInt(event.target.value, 10)
  }
  const handleAssignSupervisor = () => {
    dispatch(
      assignSupervisor({
        api,
        agentGroupId: parseInt(String(groupValue), 10),
        supervisorUserName: superValue,
      })
    )
    props.freeClose()
  }

  const handleAssignGroup = () => {
    dispatch(
      assignGroup({
        api,
        agentGroupId: parseInt(String(groupValue), 10),
        supervisorUserName: superValue,
      })
    )
    props.freeClose()
  }

  return (
    <div className="crud-modal-wrapper">
      <div className="crud-modal-wrapper__background" onClick={props.freeClose}>
        <div
          className="crud-modal-wrapper__modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="crud-modal-form">
            <label>
              Выберите супервизора
              <select value={superValue} onChange={handleSuperChange}>
                <option></option>
                {supervisor.map((e, index) => (
                  <option
                    key={index}
                    value={e.userName}
                    label={e.supervisorName}
                  >
                    {}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Выберете группу
              <select value={groupValue} onChange={handleGroupChange}>
                <option></option>
                {agentGroup.map((e, key) => (
                  <option
                    key={key}
                    value={e.agentGroupId}
                    label={e.agentGroupName}
                  ></option>
                ))}
              </select>
            </label>
            {props.type === 'supervisor' ? (
              <button
                onClick={handleAssignSupervisor}
                className="button-supervisor"
                // onClick={props.assign}
              >
                Привязать
              </button>
            ) : (
              <button onClick={handleAssignGroup} className="button-supervisor">
                Привязать
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
