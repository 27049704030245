import ApiDefaultType from '../types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AdminStore } from '../slices/adminSlice'
import { Api } from '../../api/Api'
import {
  AdminAgentGroupDto,
  AdminAgentProfileDto,
  AdminCampaignDto,
  AdminChatGroupRoomDto,
  AdminChatRoomDto,
  AdminSupervisorAgentGroupLinkRequest,
  AdminSupervisorDto,
  AdminUserDto,
  AdminUserToSaveRequest,
  CommonSettingsChangeRequest,
  CommonSettingsResponse,
} from '../../api/data-contracts'

interface DefaultRequestType {
  api: Api<unknown>
}

interface AgentGroupType extends DefaultRequestType, AdminAgentGroupDto {}

interface AgentProfileType extends DefaultRequestType, AdminAgentProfileDto {}

interface CampaignControllerType extends DefaultRequestType, AdminCampaignDto {}

interface ChatGroupRoomControllerType
  extends DefaultRequestType,
    AdminChatGroupRoomDto {}

interface ChatRoomControllerType extends DefaultRequestType, AdminChatRoomDto {}

interface SupervisorControllerType
  extends DefaultRequestType,
    AdminSupervisorDto,
    AdminSupervisorAgentGroupLinkRequest {}

interface UserToSave extends DefaultRequestType, AdminUserToSaveRequest {}

interface UserToUpdate extends DefaultRequestType, AdminUserDto {}

interface CommonSettingsType
  extends DefaultRequestType,
    CommonSettingsResponse,
    CommonSettingsChangeRequest {}

interface assignUnAssignType
  extends DefaultRequestType,
    AdminSupervisorAgentGroupLinkRequest {}

interface ClearExtensionType extends DefaultRequestType {
  id: string
}
///AgentGroupEntity

export const findAllAgentGroup = createAsyncThunk(
  'admin/agent-group/find-all',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet()
    return data as AdminStore['agentGroup']
  }
)

export const deleteAgentGroup = createAsyncThunk(
  'admin/agent-group/delete',
  async ({ api, agentGroupId }: AgentGroupType) => {
    if (agentGroupId != null) {
      await api.deleteUsingGet(agentGroupId)
      return agentGroupId
    }
  }
)

export const saveOrUpdateGroup = createAsyncThunk(
  'admin/agent-group/save-or-update',
  async ({ api, ...requestData }: AgentGroupType) => {
    await api.saveOrUpdateUsingPost(requestData)
    return requestData
  }
)

//AgentProfileEntity

export const findAllAgentProfile = createAsyncThunk(
  'admin/agent-profile/find-all',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet1()
    return data as AdminStore['agentProfile']
  }
)

export const deleteAgentProfile = createAsyncThunk(
  'admin/agent-profile/delete',
  async ({ api, agentId }: AgentProfileType) => {
    if (agentId != null) {
      await api.deleteUsingGet1(agentId)
      return agentId
    }
  }
)

export const saveOrUpdateProfile = createAsyncThunk(
  'admin/agent-profile/save-or-update',
  async ({ api, ...responseData }: AgentProfileType) => {
    await api.saveOrUpdateUsingPost1(responseData)
    return responseData
  }
)

// CampaignController

export const findAllCampaign = createAsyncThunk(
  'admin/campaign/find-all',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet2()
    return data as AdminStore['agentCampaigns']
  }
)

export const deleteCampaign = createAsyncThunk(
  'admin/campaign/delete',
  async ({ api, campaignId }: CampaignControllerType) => {
    if (campaignId) {
      await api.deleteUsingGet2(campaignId)
      return campaignId
    }
  }
)

export const saveOrUpdateCampaign = createAsyncThunk(
  'admin/campaign/save-or-update',
  async ({ api, ...responseData }: CampaignControllerType) => {
    await api.saveOrUpdateUsingPost2(responseData)
    return responseData
  }
)

//ChatRoomGroupController

export const findAllChatGroup = createAsyncThunk(
  'admin/chat-group-room/find-all',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet3()
    return data as AdminStore['chatGroupRoom']
  }
)

export const deleteChatGroup = createAsyncThunk(
  'admin/chat-group-room/delete',
  async ({ api, chatId }: ChatGroupRoomControllerType) => {
    if (chatId != null) {
      await api.deleteUsingGet3(chatId)
      return chatId
    }
  }
)

export const saveOrUpdateChatGroup = createAsyncThunk(
  'admin/chat-group-room/save-or-update',
  async ({ api, ...responseData }: ChatGroupRoomControllerType) => {
    await api.saveOrUpdateUsingPost3(responseData)
    return responseData
  }
)

//ChatRoomController

export const findAllChatRoom = createAsyncThunk(
  'admin/chat-room/find-all',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet4()
    return data as AdminStore['chatRoom']
  }
)

export const deleteChatRoom = createAsyncThunk(
  'admin/chat-room/delete',
  async ({ api, chatId }: ChatRoomControllerType) => {
    if (chatId != null) {
      await api.deleteUsingGet4(chatId)
      return chatId
    }
  }
)

export const saveOrUpdateChatRoom = createAsyncThunk(
  'admin/chat-room/save-or-update',
  async ({ api, ...responseData }: ChatRoomControllerType) => {
    api.saveOrUpdateUsingPost4(responseData)
    return responseData
  }
)

//SupervisorController

export const findAllSupervisor = createAsyncThunk(
  'admin/supervisor/find-all',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet5()
    return data as AdminStore['supervisor']
  }
)

export const deleteSupervisor = createAsyncThunk(
  'admin/supervisor/delete',
  async ({ api, pimUserId }: SupervisorControllerType) => {
    if (pimUserId != null) {
      await api.deleteUsingGet5(pimUserId)
      return pimUserId
    }
  }
)

export const saveOrUpdateSupervisor = createAsyncThunk(
  'admin/supervisor/save-or-update',
  async ({ api, ...responseData }: SupervisorControllerType) => {
    await api.saveOrUpdateUsingPost5(responseData)
    return responseData
  }
)

//UserController

export const findAllUser = createAsyncThunk(
  'admin/user/find-all',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet6()
    return data as AdminStore['user']
  }
)

export const deleteUser = createAsyncThunk(
  'admin/user/delete',
  async ({ api, login }: UserToSave) => {
    if (login != null) {
      api.deleteUsingGet6(login)
      return login
    }
  }
)

export const saveUser = createAsyncThunk(
  'admin/user/save',
  async ({ api, ...responseData }: UserToSave) => {
    await api.saveUsingPost(responseData)
    return responseData
  }
)

export const updateUser = createAsyncThunk(
  'admin/user/update',
  async ({ api, ...responseData }: UserToUpdate) => {
    await api.updateUsingPost(responseData)
    return responseData
  }
)

//CommonSetting

export const getCommonSettings = createAsyncThunk(
  'admin/get-all-common-settings',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.getAllSettingsUsingGet()
    return data as AdminStore['commonSettings']
  }
)

export const changeCommonSettings = createAsyncThunk(
  'admin/change-common-settings',
  async ({ api, ...responseData }: CommonSettingsType) => {
    await api.changeSettingUsingPost(responseData)
    return responseData
  }
)

export const assignSupervisor = createAsyncThunk(
  'admin/supervisor/assign',
  async ({ api, ...requestData }: assignUnAssignType, { dispatch }) => {
    await api.assignSupervisorUsingPost(requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(findAllAgentGroup({ api }))
    return requestData
  }
)

export const unAssignSupervisor = createAsyncThunk(
  'admin/supervisor/assign',
  async ({ api, ...requestData }: assignUnAssignType, { dispatch }) => {
    await api.unassignSupervisorUsingPost(requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(findAllAgentGroup({ api }))
    return requestData
  }
)

export const assignGroup = createAsyncThunk(
  'admin/supervisor/assign',
  async ({ api, ...requestData }: assignUnAssignType, { dispatch }) => {
    await api.assignAgentGroupUsingPost(requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(findAllSupervisor({ api }))
    return requestData
  }
)

export const unAssignGroup = createAsyncThunk(
  'admin/supervisor/assign',
  async ({ api, ...requestData }: assignUnAssignType, { dispatch }) => {
    await api.unassignAgentGroupUsingPost(requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(findAllSupervisor({ api }))
    return requestData
  }
)

export const clearExtension = createAsyncThunk(
  'admin/user/clear-extension',
  async ({ api, id }: ClearExtensionType) => {
    if (id !== null) {
      api.clearExtensionUsingGet(id)
      return id
    }
  }
)
