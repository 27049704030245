import React from 'react'

interface PropsType {
  type?: 'white'
}

export default function Plus({ type }: PropsType) {
  return (
    <svg
      width={type === 'white' ? 32 : 24}
      height={type === 'white' ? 32 : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 12H20.25"
        stroke={type === 'white' ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.75V20.25"
        stroke={type === 'white' ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
