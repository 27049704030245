import React, { useCallback, useEffect, useState } from 'react'
import Default from './Default'
import Message from '../Components/Message/Message'
import Button from '../Components/Button/Button'
import { NodePropsType } from './Talking'
import ActionHeader from '../Components/ActionHeader/ActionHeader'
import Div from '../Components/Division/Division'
import AddBtn from '../Components/AddBtn/AddBtn'
import AddAction from '../Components/AddAction/AddAction'
import Textarea from 'react-textarea-autosize'
import { changeActions } from '../../../../../store/slices/scriptEditorSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import { scriptEditorSelector } from '../../../../../store/selectors/scriptEditor'

function Custom({ data }: NodePropsType) {
  const dispatch = useAppDispatch()
  const { mode } = useAppSelector(scriptEditorSelector)
  const [elems, setElems] = useState(data.elements)
  const [btn, setBtn] = useState<any | undefined>(undefined)
  const [input, setInput] = useState<any | undefined>(undefined)
  const [btns, setBtns] = useState<any | undefined>(undefined)

  //todo поменять тип
  const handleRemoveAction = useCallback(
    (action) => {
      if (mode === 'editing') {
        const els = elems?.filter(
          ({ elementName }: any) => elementName !== action
        )
        dispatch(
          changeActions({
            els,
            step: data.orderNumber || 0,
          })
        )
        setElems(els)
        setBtn(undefined)
        setBtns(undefined)
        setInput(undefined)
      }
    },
    [data.orderNumber, elems, dispatch, mode]
  )

  useEffect(() => {
    setElems(data.elements)
  }, [data.elements])

  useEffect(() => {
    if (elems) {
      //todo поменять тип
      elems.forEach((el: any) => {
        switch (el.elementName) {
          case 'RadioButton':
            setBtns({ ...el })
            break
          case 'Button':
            setBtn({ ...el })
            break
          case 'InputField':
            setInput({ ...el })
            break
        }
      })
    }
  }, [elems])

  return (
    <Default data={data} type="custom">
      <div className="ad-node-custom-body">
        <Message firstText msg={data.text} step={data.orderNumber || 0} />
        {input && (
          <>
            <ActionHeader
              text="Ввод"
              removeAction={() => handleRemoveAction('InputField')}
            />
            <Message
              step={data.orderNumber || 0}
              msg={input.inputFieldHeader}
              customElement="input"
            />
            <Textarea
              placeholder="Поле для ввода текста"
              className="node-message-body ad-node-input-field"
              disabled
            />
          </>
        )}
        {btn && (
          <>
            <ActionHeader
              text="Кнопка"
              removeAction={() => handleRemoveAction('Button')}
            />
            <Button
              step={data.orderNumber || 0}
              text={btn.buttonHeader}
              i={11}
              type="Button"
            />
          </>
        )}
        {btns && (
          <>
            <ActionHeader
              text="Переключатель"
              removeAction={() => handleRemoveAction('RadioButton')}
            />
            <Message
              step={data.orderNumber || 0}
              msg={btns.radioButtonHeader}
              customElement="radio"
            />
            {Object.keys(btns.radioButtonOptions).map((key, i) => (
              <React.Fragment key={i}>
                <Button
                  step={data.orderNumber || 0}
                  text={key}
                  i={i}
                  type="RadioButton"
                />
                <Div />
              </React.Fragment>
            ))}
            <AddBtn step={data.orderNumber || 0} />
          </>
        )}
        <AddAction
          els={data.elements || []}
          step={data.orderNumber || 0}
          actions={[!!btn || !!btns, !!btn || !!btns, !!input]}
        />
      </div>
    </Default>
  )
}

export default React.memo(Custom)
