import React, { memo, useCallback, useEffect, useState } from 'react'
import './Default.sass'
import { Handle, Position } from 'react-flow-renderer'
import Header from '../Components/Header/Header'
import SelectArrow from '../../../../../components/Icons/ScriptDev/Nodes/SelectArrow'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import { scriptEditorSelector } from '../../../../../store/selectors/scriptEditor'
import Message from '../Components/Message/Message'
import Button from '../Components/Button/Button'
import { Button as Btn } from '../../../../../api/data-contracts'
import { NodePropsType } from '../InputOutputNodes/Talking'
import { updateCompletionCode } from '../../../../../store/slices/scriptEditorSlice'

interface InputNodePropsType extends NodePropsType {
  type: 'finish' | 'callback'
}

function Default({ data, type }: InputNodePropsType) {
  const [showOptions, setShowOptions] = useState(false)
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [isActive, setIsActive] = useState(false)
  const [btnText, setBtnText] = useState<string>('')
  const { completionCodeList } = useAppSelector(scriptEditorSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data.completionCode?.name) {
      setTitle(data.completionCode.name)
      setIsActive(true)
    }
    const btn =
      data.elements && (data.elements[type === 'finish' ? 0 : 1] as Btn)
    setBtnText(
      btn?.buttonHeader || (type === 'finish' ? 'Завершить' : 'Назначить')
    )
  }, [data.elements, data.completionCode?.name, type])

  const handleSelect = useCallback(
    () => setShowOptions(!showOptions),
    [showOptions]
  )

  const handleOption = useCallback(
    (el) => {
      setTitle(el.codeName)
      setIsActive(true)
      setShowOptions(false)
      dispatch(
        updateCompletionCode({
          step: data.orderNumber || 0,
          completionCode: el,
        })
      )
    },
    [dispatch, data.orderNumber]
  )

  return (
    <div className="ad-node-input">
      <Handle
        type="target"
        position={Position.Left}
        style={{ top: 30 }}
        isConnectable
        className="ad-handle ad-handle-left"
      />
      <Header type={type} title={data.title} step={data.orderNumber || 0} />
      <div className="ad-node-input-body">
        <Message msg={data.text} step={data.orderNumber || 0} firstText />
        <div className="node-select-header">Код окончания</div>
        <div className="node-select" onClick={handleSelect}>
          <div
            className={`node-select-title ${
              isActive ? 'node-select-title-active' : ''
            }`}
          >
            {isActive ? title : 'Выберите код окончания'}
          </div>
          <div className="node-select-icon">
            <SelectArrow />
          </div>
        </div>
        {showOptions && (
          <div className="node-select-options">
            {completionCodeList.map((el, i) => (
              <div
                key={el.id}
                className={`node-select-option ${
                  i === 0 ? 'node-select-option-first' : ''
                } ${
                  i === completionCodeList.length - 1
                    ? 'node-select-option-last'
                    : ''
                }`}
                onClick={() => handleOption(el)}
              >
                {el.codeName}
              </div>
            ))}
          </div>
        )}
        <Button step={data.orderNumber || 0} text={btnText} i={0} finish />
      </div>
    </div>
  )
}

export default memo(Default)
