import React, { MouseEventHandler } from 'react'

interface InfoIconProps {
  size?: number
  onClick?: MouseEventHandler<HTMLOrSVGElement>
}

export default function InfoIcon(props: InfoIconProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 9.25H10V14.5H10.75"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7C10.6213 7 11.125 6.49632 11.125 5.875C11.125 5.25368 10.6213 4.75 10 4.75C9.37868 4.75 8.875 5.25368 8.875 5.875C8.875 6.49632 9.37868 7 10 7Z"
        fill="#A1BDCA"
        className="ad-info-icon-dot"
      />
    </svg>
  )
}
