import { useEffect, useMemo } from 'react'
import { useSubscription } from 'react-stomp-hooks'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { stompSelector } from '../../store/selectors/stomp'
import {
  setJtapiAuthorizeStatus,
  setPomAuthorizeStatus,
} from '../../store/slices/stompSlice'
import { userSelector } from '../../store/selectors/user'
import {
  getSupervisorData,
  getUserData,
  loginPom,
  loginJtapi,
  logoff,
} from '../../store/requests/user'
import { useApi } from '../../store/hooks/request'
import { addLoginNotification } from '../../store/slices/userSlice'
import { setVisibilityNotifications } from '../../store/slices/headerSlice'

export default function usePomJtapiRegistrationStatus() {
  const dispatch = useAppDispatch()
  const { api, logoffApi } = useApi()

  const { connected, pomAuthorize, jtapiAuthorize } =
    useAppSelector(stompSelector)
  const { agentId, role, isOnlyJtapi, isLogouting } =
    useAppSelector(userSelector)

  const isUser = useMemo(() => role && role[0] === 'ROLE_USER', [role])

  useSubscription(isUser ? `/agent/${agentId}/registration/pom` : [], (mes) => {
    const message = JSON.parse(mes.body).message
    if (message === 'SUCCESS') {
      dispatch(setPomAuthorizeStatus(true))
    } else {
      dispatch(
        addLoginNotification({
          icon: 'info',
          id: Date.now(),
          title: 'Ошибка',
          text: 'Cерверная ошибка обратитесь к администратору',
        })
      )
      dispatch(setVisibilityNotifications(true))
      dispatch(logoff({ logoffApi }))
    }
  })

  useSubscription(
    isUser ? `/agent/${agentId}/registration/jtapi` : [],
    (mes) => {
      const message = JSON.parse(mes.body).message
      if (message === 'SUCCESS') {
        dispatch(setJtapiAuthorizeStatus(true))
        if (!isOnlyJtapi) dispatch(loginPom({ api }))
      } else {
        dispatch(
          addLoginNotification({
            icon: 'info',
            id: Date.now(),
            title: 'Ошибка',
            text: 'Cерверная ошибка обратитесь к администратору',
          })
        )
        dispatch(setVisibilityNotifications(true))
        dispatch(logoff({ logoffApi }))
      }
    }
  )

  if (!isUser) {
    dispatch(setPomAuthorizeStatus(true))
    dispatch(setJtapiAuthorizeStatus(true))
  }

  useEffect(() => {
    if (!jtapiAuthorize && role && role[0] === 'ROLE_USER' && !isLogouting) {
      dispatch(loginJtapi({ api }))
    }
    if (connected && pomAuthorize && jtapiAuthorize) {
      if (isUser) dispatch(getUserData({ agentId: agentId || '', api }))
      else dispatch(getSupervisorData({ api, agentId: agentId || '' }))
    }
    // eslint-disable-next-line
  }, [connected, jtapiAuthorize, pomAuthorize, agentId, role])

  return { pomAuthorize, jtapiAuthorize }
}
