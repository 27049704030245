export enum DashboardItems {
  StartShift = 'start-shift',
  WorkTime = 'work-time',
  PlannedCalls = 'planned-calls',
  Calls = 'calls',
  OperatorStatuses = 'operator-statuses',
  CompanyProgress = 'company-progress',
  AverageCallTime = 'average-call-time',
}

export const initialLayouts: ReactGridLayout.Layouts = {
  lg: [
    {
      i: DashboardItems.StartShift,
      x: 0,
      y: 0,
      w: 2,
      h: 1,
      minW: 2,
      minH: 1,
    },
    {
      i: DashboardItems.WorkTime,
      x: 0,
      y: 1,
      w: 2,
      h: 1,
      minW: 2,
      minH: 1,
    },
    { i: DashboardItems.PlannedCalls, x: 2, y: 0, w: 6, h: 1, minW: 3 },
    { i: DashboardItems.Calls, x: 2, y: 1, w: 6, h: 1, minH: 1, minW: 3 },
    { i: DashboardItems.CompanyProgress, x: 0, y: 2, w: 5, h: 1, minW: 4 },
    { i: DashboardItems.AverageCallTime, x: 0, y: 3, w: 5, h: 1, minW: 3 },
    {
      i: DashboardItems.OperatorStatuses,
      x: 5,
      y: 2,
      w: 3,
      h: 2,
      minW: 2,
      minH: 2,
    },
  ],
}
export const allDash = Object.values(DashboardItems)
