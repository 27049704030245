import { useCallback, useEffect, useState } from 'react'
import { Layout } from 'react-grid-layout'
import { useApi } from '../../../store/hooks/request'
import DashbordEditor from './DashbordEditor/DashbordEditor'
import './SettingsContent.sass'
import SettingsSideDropDown from './SettingsSideDropDown/SettingsSideDropDown'
import TopDashEditor from './TopDashEditor/TopDashEditor'
import TagEditor from './TagEditor/TagEditor'
import { useAppSelector } from '../../../store/hooks/hook'
import { tagSelector } from '../../../store/selectors/tag'

export interface selectedItem {
  type?: 'dash' | 'topdash' | 'tags' | 'tags-temps'
  id?: number
}
export default function SettingsContent() {
  const [selectedItem, setSelectedItem] = useState<selectedItem>()
  const { allTags } = useAppSelector(tagSelector)
  const [layout, setLayout] = useState<ReactGridLayout.Layouts>()

  const { api } = useApi()

  useEffect(() => {
    const getLayout = async () => {
      if (
        selectedItem?.id &&
        (selectedItem.type === 'dash' || selectedItem.type === 'topdash')
      ) {
        const { data } = await api.getDashboardWidgetStateUsingGet(
          selectedItem.id
        )
        if (selectedItem.type === 'dash') {
          setLayout({
            lg: data.dashboardStateList as unknown as Layout[],
          })
        }
        if (selectedItem.type === 'topdash') {
          setLayout({
            lg: data.widgetStateList as unknown as Layout[],
          })
        }
      }
    }
    getLayout()
    //eslint-disable-next-line
  }, [selectedItem])

  const handleDDselect = useCallback((params: selectedItem) => {
    setSelectedItem(params)
  }, [])

  return (
    <div className="settings-wrapper">
      <SettingsSideDropDown
        handler={handleDDselect}
        selectedItem={selectedItem}
      />
      {selectedItem?.type === 'dash' && layout && (
        <DashbordEditor layout={layout} id={selectedItem.id} />
      )}
      {selectedItem?.type === 'topdash' && layout && (
        <TopDashEditor id={selectedItem.id} layout={layout} />
      )}
      {selectedItem?.type === 'tags' && (
        <TagEditor
          tags={allTags.filter((tag) => tag.campaignId === selectedItem?.id)}
          campaignId={selectedItem.id}
          isTemplate={false}
        />
      )}
      {selectedItem?.type === 'tags-temps' && (
        <TagEditor
          tags={allTags.filter((tag) => tag.template === true)}
          campaignId={selectedItem.id}
          isTemplate={true}
        />
      )}
    </div>
  )
}
