import React, { memo } from 'react'
import './ActionHeader.sass'
import Trash from '../../../../../../components/Icons/ScriptDev/Nodes/Trash'

export interface PropsType {
  text: string
  removeAction?: () => void
}

function ActionHeader({ text, removeAction }: PropsType) {
  return (
    <div className="node-action-header">
      <div
        className={`node-action-header-title${
          text === 'Переключатель' ? ' node-action-header-title-big' : ''
        }`}
      >
        {text}
      </div>
      {removeAction !== undefined ? (
        <>
          <div className="node-action-header-div node-action-header-div-short" />
          <div className="node-action-header-icon" onClick={removeAction}>
            <Trash />
          </div>
        </>
      ) : (
        <div className="node-action-header-div" />
      )}
    </div>
  )
}

export default memo(ActionHeader)
