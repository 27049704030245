import React from 'react'

export default function CallFinish() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.566 9.49224C12.8912 8.9177 11.0721 8.92184 9.39997 9.50401C9.27843 9.5474 9.17027 9.62169 9.08616 9.71957C9.00205 9.81745 8.94487 9.93555 8.92026 10.0622L8.3673 12.8286C8.34285 12.9509 8.28826 13.0652 8.20847 13.1612C8.12869 13.2571 8.02624 13.3316 7.9104 13.3779L3.47484 15.1522C3.32456 15.2128 3.15864 15.223 3.00207 15.1812C2.8455 15.1394 2.70676 15.0478 2.60676 14.9202C1.93698 14.0542 1.60584 12.9737 1.67534 11.8811C1.74485 10.7884 2.21023 9.75859 2.98436 8.98439C5.37545 6.5933 8.61846 5.25 12 5.25C15.3815 5.25 18.6245 6.5933 21.0156 8.98439C21.7897 9.75859 22.2551 10.7884 22.3246 11.8811C22.3941 12.9737 22.063 14.0542 21.3932 14.9202C21.2932 15.0478 21.1544 15.1394 20.9979 15.1812C20.8413 15.223 20.6754 15.2128 20.5251 15.1522L16.0857 13.3764C15.9708 13.3305 15.8692 13.2568 15.7897 13.1621C15.7102 13.0674 15.6553 12.9544 15.6301 12.8334L15.0478 10.0454C15.0222 9.9194 14.9643 9.8022 14.8799 9.70525C14.7954 9.60829 14.6873 9.5349 14.566 9.49224Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 18.75H20.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
