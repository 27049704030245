import React, { MouseEventHandler } from 'react'

interface PlaneProps {
  size?: number
  onClick?: MouseEventHandler<HTMLOrSVGElement>
}

export default function Plane(props: PlaneProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="plane-icon"
      onClick={props.onClick}
    >
      <path
        d="M19.7109 3.36312L2.24269 8.29006C2.09574 8.33151 1.96493 8.41683 1.86776 8.53461C1.77059 8.65239 1.71169 8.79702 1.69892 8.94917C1.68615 9.10133 1.72012 9.25375 1.7963 9.38608C1.87248 9.51841 1.98723 9.62434 2.12523 9.6897L10.1512 13.4915C10.3078 13.5656 10.4338 13.6917 10.508 13.8482L14.3098 21.8742C14.3751 22.0122 14.4811 22.127 14.6134 22.2032C14.7457 22.2794 14.8981 22.3133 15.0503 22.3006C15.2025 22.2878 15.3471 22.2289 15.4649 22.1317C15.5826 22.0345 15.668 21.9037 15.7094 21.7568L20.6363 4.28856C20.6725 4.16024 20.6739 4.0246 20.6402 3.8956C20.6066 3.7666 20.5391 3.6489 20.4448 3.55463C20.3506 3.46036 20.2329 3.39292 20.1039 3.35926C19.9749 3.3256 19.8392 3.32693 19.7109 3.36312Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3933 13.6069L14.636 9.36426"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
