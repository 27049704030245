import React from 'react'
import Login from '../../views/Login/Login'
import NotFound from '../../views/NotFound/NotFound'
import Chat from '../../views/Chat/Chat'
import Call from '../../views/Call/Call'
import Dashboard from '../../views/Dashboard/Dashboard'
import Settings from '../../views/Settings/Settings'
import Campaign from '../../views/Campaign/Campaign'
import Script from '../../views/Script/Script'
import { Routes as Switch, Route } from 'react-router-dom'
import { ReactFlowProvider } from 'react-flow-renderer'
import PrivateRoute from './PrivateRouter'
import PublicRoute from './PublicRouter'
import { useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import AgentGroupEntity from '../../views/Admin/AgentGroupEntity/AgentGroupEntity'
import AgentProfileEntity from '../../views/Admin/AgentProfileEntity/AgentProfileEntity'
import CampaignController from '../../views/Admin/CampaignController/CampaignController'
import ChatGroupRoom from '../../views/Admin/ChatGroupRoom/ChatGroupRoom'
import ChatRoomController from '../../views/Admin/ChatRoomController/ChatRoomController'
import Supervisor from '../../views/Admin/Supervisor/Supervisor'
import User from '../../views/Admin/User/User'
import AdminCommonSettings from '../../views/Admin/CommonSettings/CommonSettings'
import SupervisorDashboard from '../../views/SupervisorDashboard/SupervisorDashboard'

export default function Routes() {
  const { auth, role } = useAppSelector(userSelector)

  return (
    <Switch>
      <Route element={<PublicRoute isAuth={auth} />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<PrivateRoute isAuth={auth} />}>
        <Route
          path="/"
          element={
            (role && role[0] === 'ROLE_ADMIN' && <AgentGroupEntity />) ||
            (role && role[0] === 'ROLE_SUPERVISOR' && (
              <SupervisorDashboard />
            )) ||
            (role && role[0] === 'ROLE_USER' && <Dashboard />)
          }
        />
        {role && role[0] !== 'ROLE_ADMIN' ? (
          <>
            <Route path="/settings" element={<Settings />} />
            <Route path="/call" element={<Call />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/campaign" element={<Campaign />} />
            <Route
              path="/script"
              element={
                <ReactFlowProvider>
                  <Script />
                </ReactFlowProvider>
              }
            />
          </>
        ) : (
          <>
            <Route path="/agent-group-entity" element={<AgentGroupEntity />} />
            <Route
              path="agent-profile-entity"
              element={<AgentProfileEntity />}
            />
            <Route
              path="campaign-controller"
              element={<CampaignController />}
            />
            <Route
              path="chat-group-room-controller"
              element={<ChatGroupRoom />}
            />
            <Route
              path="chat-room-controller"
              element={<ChatRoomController />}
            />
            <Route path="supervisor-controller" element={<Supervisor />} />
            <Route path="user-controller" element={<User />} />
            <Route path="common-settings" element={<AdminCommonSettings />} />
          </>
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Switch>
  )
}
