import React, { useEffect, useRef, useState } from 'react'
import {
  AgentStatusForSupervisor,
  CampaignResponse,
  CompletionCodeEntity,
} from '../../../../api/data-contracts'
import { AgentsAndCompainsAndCodesType } from '../../SupervisorDashboard'
import './OperatorsDropDown.sass'
interface OperatorsDropDownProps {
  operators?: AgentsAndCompainsAndCodesType['agents']
  handleSelectFilters: (
    selected:
      | Pick<AgentStatusForSupervisor, 'agentId' | 'agentName'>
      | Pick<CampaignResponse, 'campaignId' | 'campaignName'>
      | Pick<CompletionCodeEntity, 'codeId' | 'codeName'>,
    type: 'agent' | 'comp' | 'code'
  ) => void
  selectedOperators: (string | undefined)[]
}
export default function OperatorsDropDown({
  operators,
  handleSelectFilters,
  selectedOperators,
}: OperatorsDropDownProps) {
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)
  const handleClickOutside: EventListener = (event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  return (
    <div className="operators-dd-container" ref={ref}>
      <div className="operators-dd-header" onClick={() => setIsOpen(!isOpen)}>
        Оператор:{selectedOperators.join(',')}
      </div>
      <div
        className={
          isOpen ? 'operators-dd-content open' : 'operators-dd-content'
        }
      >
        {operators
          ?.filter(
            (operator) => !selectedOperators.includes(operator.agentName)
          )
          .map((operator) => {
            return (
              <div
                key={operator.agentId}
                className="operators-dd-content-item"
                onClick={() => {
                  handleSelectFilters(
                    {
                      agentId: operator.agentId,
                      agentName: operator.agentName,
                    },
                    'agent'
                  )
                  setIsOpen(!isOpen)
                }}
              >
                {operator.agentName}
              </div>
            )
          })}
      </div>
    </div>
  )
}
