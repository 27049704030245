import './Script.sass'
import React, { memo, useCallback } from 'react'
import { scriptSelector } from '../../store/selectors/script'
import {
  setCallbackWindowOpen,
  setCallToEnd,
  setScriptHistoryPop,
  setScriptHistoryPush,
} from '../../store/slices/scriptSlice'
import { Step as StepModel } from '../../api/data-contracts'
import CallButton from './Elements/Buttons/CallButtons/CallButton'
import Step from './Step/Step'
import EndCall from '../Icons/EndCall/EndCall'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import Preview from './Preview/Preview'
import CompletionCodes from './CompletionCodes/CompletionCodes'
import Callback from './Callback/Callback'
import { releaseLine } from '../../store/requests/script'
import { userSelector } from '../../store/selectors/user'
import { useApi } from '../../store/hooks/request'

function Script() {
  const {
    isExpanded,
    currentScript,
    currentScriptHistory,
    customerDetails,
    callInfo,
    callState,
    completionCodeChoose,
    callbackWindowOpen,
  } = useAppSelector(scriptSelector)
  const { agentId } = useAppSelector(userSelector)
  const { api } = useApi()

  const dispatch = useAppDispatch()

  const handleSetStep = (step: number) => {
    if (step === -1) handleSetCallToEnd()
    dispatch(setScriptHistoryPush(step))
  }

  const handleGoBack = () => {
    const newScriptHistory = [...currentScriptHistory]
    newScriptHistory.splice(newScriptHistory.length - 1, 1)
    dispatch(setScriptHistoryPop())
  }

  const handleSetCallToEnd = useCallback(() => {
    dispatch(setCallToEnd())
    // eslint-disable-next-line
  }, [agentId])

  const handleReleaseLine = useCallback(() => {
    dispatch(releaseLine({ api, agentId: agentId! }))
    // eslint-disable-next-line
  }, [agentId])

  const steps = currentScript?.steps?.map((step: StepModel) => {
    return (
      <Step
        key={`${currentScript.campaignId}-${step.orderNumber}`}
        step={step}
        setStep={handleSetStep}
        goBack={handleGoBack}
      />
    )
  })

  const step =
    steps && steps[currentScriptHistory[currentScriptHistory?.length - 1] || 0]

  const completionCodes = (
    <CompletionCodes
      currentScript={currentScript}
      customerDetails={customerDetails}
    />
  )

  const isInCall = callState === 'Talking'
  const isPreview =
    callState === 'Preview' ||
    callState === 'Callback' ||
    (callInfo?.contactTypeField === 'Manual' && callState === 'Dialing')
  const isWrapup = callState === 'Wrapup'

  const releaseLineButtonVisible =
    isInCall ||
    (callInfo?.contactTypeField !== 'Manual' && callState === 'Dialing')

  const handleCloseCallback = useCallback(() => {
    dispatch(setCallbackWindowOpen(false))
  }, [dispatch])
  let stepBlock = <div />
  if (callbackWindowOpen) stepBlock = <Callback onClose={handleCloseCallback} />
  else if (completionCodeChoose) stepBlock = completionCodes
  else if (callInfo && customerDetails && isPreview) stepBlock = <Preview />
  else if (isInCall || isWrapup) stepBlock = step || <div />

  return (
    <div
      className={
        'script-content-wrapper' + (isExpanded ? '-expanded' : '-collapsed')
      }
      style={{ background: !isInCall ? '#397FBF' : '#BA4433' }}
    >
      <div className="script-content-body">{stepBlock}</div>
      {releaseLineButtonVisible && (
        <div className="script-call-buttons">
          <CallButton icon={<EndCall />} onClick={handleReleaseLine} />
        </div>
      )}
    </div>
  )
}

export default memo(Script)
