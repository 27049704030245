import React from 'react'
import { useAppSelector } from '../../../../store/hooks/hook'
import { scriptEditorSelector } from '../../../../store/selectors/scriptEditor'

export default function Drag() {
  const { mode } = useAppSelector(scriptEditorSelector)
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mode === 'editing' ? 'ad-drag-icon' : ''}
    >
      <path
        d="M19.5358 25.4648L16.0003 29.0003L12.4648 25.4648"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20V29"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4648 6.5355L16.0003 3L19.5358 6.5355"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12V3"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5355 19.5358L3 16.0003L6.5355 12.4648"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16H3"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4648 12.4648L29.0003 16.0003L25.4648 19.5358"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 16H29"
        stroke="#C15747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
