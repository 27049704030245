import { useSubscription } from 'react-stomp-hooks'
import { onJobAttachedGetScript } from '../../store/requests/script'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import {
  setCallInfo,
  setCallToEnd,
  setCustomerDetails,
  setReleaseLineAnswer,
  extendTime,
  chooseContactNumber,
  setBlendingStatus,
} from '../../store/slices/scriptSlice'
import { ContactPomNotification } from '../../api/data-contracts'
import { addNotification } from '../../store/slices/notificationSlice'
import { Api } from '../../api/Api'

export default function useJobAttached(
  authorizationStatus: boolean,
  api: Api<unknown>
) {
  const { agentId } = useAppSelector(userSelector)

  const dispatch = useAppDispatch()

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/jobAttached/notify` : [],
    (mes) => {
      const campaignName = JSON.parse(mes.body)?.campaignName

      if (campaignName) {
        dispatch(onJobAttachedGetScript({ campaignName, api }))
      }
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/call-notify` : [],
    (mes) => {
      const body: ContactPomNotification = JSON.parse(mes.body)
      dispatch(setCallInfo(body))
      const numbers = body?.contactNumbersField
      if (numbers) {
        const defaultNumber = numbers.find((num) => num.defaultField)
        if (defaultNumber) dispatch(chooseContactNumber(defaultNumber))
        else dispatch(chooseContactNumber(numbers[0]))
      }
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/get/customer-details` : [],
    (mes) => {
      dispatch(setCustomerDetails(JSON.parse(mes.body)))
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/release-line` : [],
    (mes) => {
      const body = JSON.parse(mes.body)

      dispatch(setReleaseLineAnswer(body))

      if (body?.message === 'FAILURE') dispatch(setCallToEnd())
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/extend-wrapup` : [],
    (mes) => {
      const body = JSON.parse(mes.body)

      dispatch(extendTime(body))
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/blending-status` : [],
    (mes) => {
      const body = JSON.parse(mes.body)

      let message = ''
      if (body.message === 'BLEND_TO_INBOUND')
        message = 'Вы переведены на входящие'
      if (body.message === 'BLEND_TO_OUTBOUND')
        message = 'Вы переведены на исходящие'

      if (
        body.message === 'BLEND_TO_INBOUND' ||
        body.message === 'BLEND_TO_OUTBOUND'
      ) {
        dispatch(setBlendingStatus(body))
        if (message) {
          dispatch(
            addNotification({
              id: Date.now(),
              icon: 'info',
              title: 'Оповещение',
              text: message,
            })
          )
        }
      }
    }
  )
}
