import './Content.sass'
import React, { useCallback, useEffect } from 'react'
import Pallet from './Pallet/Pallet'
import ReactFlow, {
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  useViewport,
  useNodes,
  applyNodeChanges,
  applyEdgeChanges,
  MarkerType,
} from 'react-flow-renderer'
import Finish from './Nodes/InputNodes/Finish'
import Start from './Nodes/OutputNodes/Start'
import Talking from './Nodes/InputOutputNodes/Talking'
import Custom from './Nodes/InputOutputNodes/Custom'
import Callback from './Nodes/InputNodes/Callback'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { getCompletionCodeList } from '../../../store/requests/script'
import { Position, RadioButton } from '../../../api/data-contracts'
import {
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  NodePositionChange,
} from 'react-flow-renderer/dist/esm/types'
import {
  updateEdge as updatingEdge,
  addStep,
  removeEdge,
  removeStep,
  updateStepPosition,
  changeMode,
  changeIsStart,
} from '../../../store/slices/scriptEditorSlice'
import { useApi } from '../../../store/hooks/request'
import { scriptEditorSelector } from '../../../store/selectors/scriptEditor'
import ButtonEdge from './Edges/DefaultEdge'
import ConnectionEdge from './Edges/ConnectionEdge'

const nodeTypes = {
  finish: Finish,
  callback: Callback,
  talking: Talking,
  custom: Custom,
  start: Start,
}

const edgeTypes = {
  buttonEdge: ButtonEdge,
}

const markerEnd = {
  type: MarkerType.ArrowClosed,
}

const position = {
  x: 1080,
  y: 230,
}

export default function Content() {
  const state = useReactFlow()
  const viewport = useViewport()
  const nodesForMiniMap = useNodes()
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { scripts, currentCampaign, currentTemplate, mode } =
    useAppSelector(scriptEditorSelector)
  const [nodes, setNodes] = useNodesState([])
  const [edges, setEdges] = useEdgesState([])

  // ↓↓↓ Метод для удаления стрелки ↓↓↓
  const onDelEdge = useCallback(
    (id: string) => {
      if (mode === 'editing') {
        if (id.split('-')[0] === 'e0') {
          // dispatch(
          //     changeIsStart({
          //         step: +id.split('-')[2],
          //         type: 'delete',
          //     })
          // )
        } else {
          dispatch(removeEdge(id))
          setEdges((eds) =>
            applyEdgeChanges(
              [
                {
                  id,
                  type: 'remove',
                },
              ],
              eds
            )
          )
        }
      }
    },
    [dispatch, setEdges, mode]
  )
  // ↑↑↑ Метод для удаления стрелки ↑↑↑

  // ↓↓↓ Обновление CompletionCode'ов и сброс режима ↓↓↓
  useEffect(() => {
    if (currentCampaign)
      dispatch(getCompletionCodeList({ api, campaignId: currentCampaign }))
    dispatch(changeMode('view'))
    //eslint-disable-next-line
  }, [dispatch, currentCampaign])
  // ↑↑↑ Обновление CompletionCode'ов и сброс режима ↑↑↑

  // ↓↓↓ Инициализация шагов ↓↓↓
  useEffect(() => {
    const nodes =
      (scripts
        .find((script) =>
          currentTemplate
            ? script.id === currentTemplate
            : script.campaignId === currentCampaign
        )
        ?.steps?.map((el) =>
          !el.isStart
            ? {
                id: String(el.orderNumber),
                position: el.position,
                dragHandle: '.ad-drag-icon',
                type: el.type,
                data: el,
              }
            : [
                {
                  id: '0',
                  type: 'start',
                  data: null,
                  position: {
                    x: el.position?.x ? el.position.x - 234 : 0,
                    y: el.position?.y ? el.position.y - 6 : 0,
                  },
                },
                {
                  id: String(el.orderNumber),
                  position: el.position,
                  dragHandle: '.ad-drag-icon',
                  type: el.type,
                  data: el,
                },
              ]
        )
        .flat() as Node[]) || []
    setNodes(
      nodes.length
        ? nodes
        : [
            {
              id: '0',
              type: 'start',
              data: null,
              position,
            },
          ]
    )
  }, [scripts, currentCampaign, setNodes, currentTemplate])
  // ↑↑↑ Инициализация шагов ↑↑↑

  // ↓↓↓ Инициализация стрелок ↓↓↓
  useEffect(() => {
    let edges: Edge[] = []
    scripts
      .find((script) =>
        currentTemplate
          ? script.id === currentTemplate
          : script.campaignId === currentCampaign
      )
      ?.steps?.forEach((el) => {
        if (el.isStart) {
          const id = `e0-1-${el.orderNumber}`
          edges.push({
            id,
            source: '0',
            sourceHandle: '1',
            target: String(el.orderNumber),
            type: 'buttonEdge',
            data: {
              onDelEdge: () => onDelEdge(id),
            },
            markerEnd,
          })
        }
        switch (el.type) {
          case 'talking':
            const radio = el.elements && (el.elements[0] as RadioButton)
            radio?.radioButtonOptions &&
              Object.values(radio.radioButtonOptions).forEach((value, i) => {
                const id = `e${el.orderNumber}-${value}-${i + 1}`
                !!value &&
                  edges.push({
                    id,
                    source: String(el.orderNumber),
                    sourceHandle: String(i + 1),
                    target: String(value),
                    type: 'buttonEdge',
                    data: {
                      onDelEdge: () => onDelEdge(id),
                    },
                    markerEnd,
                  })
              })
            break
          case 'custom':
            const elements = el.elements
            if (elements) {
              elements.forEach((element: any) => {
                if (element.elementName === 'RadioButton') {
                  element?.radioButtonOptions &&
                    Object.values(element.radioButtonOptions).forEach(
                      (value, i) => {
                        const id = `e${el.orderNumber}-${value}-${i + 1}`
                        !!value &&
                          edges.push({
                            id,
                            source: String(el.orderNumber),
                            sourceHandle: String(i + 1),
                            target: String(value),
                            type: 'buttonEdge',
                            data: {
                              onDelEdge: () => onDelEdge(id),
                            },
                            markerEnd,
                          })
                      }
                    )
                } else if (element.elementName === 'Button') {
                  const id = `e${el.orderNumber}-${element.buttonNextStep}-1`
                  !!element.buttonNextStep &&
                    edges.push({
                      id,
                      source: String(el.orderNumber),
                      sourceHandle: '1',
                      target: String(element.buttonNextStep),
                      type: 'buttonEdge',
                      data: {
                        onDelEdge: () => onDelEdge(id),
                      },
                      markerEnd,
                    })
                }
              })
            }
            break
        }
      })
    setEdges(edges)
  }, [currentCampaign, currentTemplate, dispatch, onDelEdge, scripts, setEdges])
  // ↑↑↑ Инициализация стрелок ↑↑↑

  // ↓↓↓ Обновление шагов ↓↓↓
  const onNodesChange = useCallback(
    (changes) => {
      if (mode === 'editing') {
        changes.forEach((change: NodeChange) => {
          const changeWithId = change as NodePositionChange
          if (+changeWithId.id > 0) {
            if (change.type === 'position' && change.dragging) {
              const position = {
                x: change.position && Math.floor(change.position?.x),
                y: change.position && Math.floor(change.position?.y),
              } as Position
              dispatch(
                updateStepPosition({
                  position,
                  step: +change.id,
                })
              )
            }
            if (change.type === 'remove') {
              dispatch(removeStep(+change.id))
            }
          }
        })
        setNodes((ns) => applyNodeChanges(changes, ns))
      }
    },
    [setNodes, dispatch, mode]
  )
  // ↑↑↑ Обновление шагов ↑↑↑

  // ↓↓↓ Обновление стрелок ↓↓↓
  const onEdgesChange = useCallback(
    (changes) => {
      if (mode === 'editing') {
        changes.forEach((change: EdgeChange) => {
          if (change.type === 'remove') {
            dispatch(removeEdge(change.id))
          }
        })
        setEdges((eds) => applyEdgeChanges(changes, eds))
      }
    },
    [setEdges, dispatch, mode]
  )
  const onConnect = useCallback(
    (params) => {
      if (mode === 'editing') {
        const { source, target, sourceHandle } = params
        if (!+source) {
          // dispatch(
          //     changeIsStart({
          //         step: +target,
          //         type: 'add',
          //     })
          // )
        } else {
          dispatch(updatingEdge(params))
          const id = `e${source}-${target}-${sourceHandle}`
          setEdges((eds) =>
            addEdge(
              {
                id,
                markerEnd,
                source,
                sourceHandle,
                target,
                data: { onDelEdge: () => onDelEdge(id) },
                type: 'buttonEdge',
              },
              eds
            )
          )
        }
      }
    },
    [setEdges, dispatch, onDelEdge, mode]
  )
  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      if (mode === 'editing') {
        if (!+newConnection.source) {
          dispatch(
            changeIsStart({
              step: +newConnection.target,
              type: 'update',
            })
          )
        }
        if (oldEdge.sourceHandle === newConnection.sourceHandle) {
          const { source, target, sourceHandle } = newConnection
          const id = `e${source}-${target}-${sourceHandle}`
          dispatch(updatingEdge(newConnection))
          setEdges((eds) =>
            applyEdgeChanges(
              [
                {
                  id: oldEdge.id,
                  type: 'remove',
                },
              ],
              eds
            )
          )
          setEdges((eds) =>
            addEdge(
              {
                id,
                markerEnd,
                source,
                sourceHandle,
                target,
                data: { onDelEdge: () => onDelEdge(id) },
                type: 'buttonEdge',
              },
              eds
            )
          )
        } else return
      }
    },
    [setEdges, dispatch, onDelEdge, mode]
  )
  // ↑↑↑ Обновление стрелок ↑↑↑

  // ↓↓↓ Добавление шагов ↓↓↓
  const handleAddNode = useCallback(
    (type) => {
      if (mode === 'editing') {
        const orderNumber = nodes.length ? +nodes[nodes.length - 1].id + 1 : 1
        // todo: убрать any
        const addNode = (node: any) => {
          dispatch(addStep(node))
        }
        switch (type) {
          case 'talking':
            const talkingNode = {
              orderNumber,
              position,
              type: 'talking',
              text: '',
              elements: [
                {
                  elementName: 'RadioButton',
                  radioButtonHeader: '',
                  variableValue: '',
                  radioButtonOptions: {},
                },
              ],
              completionCode: { id: undefined, name: undefined },
              title: {
                name: 'Разговор',
                editable: false,
              },
              isStart: nodes.length > 1 ? false : nodes[0].id === '0',
            }
            addNode(talkingNode)
            break
          case 'custom':
            const customNode = {
              orderNumber,
              position,
              type: 'custom',
              text: '',
              elements: [],
              completionCode: {},
              title: {
                name: 'Свой блок',
                editable: true,
              },
              isStart: nodes.length > 1 ? false : nodes[0].id === '0',
            }
            addNode(customNode)
            break
          case 'callback':
            const callbackNode = {
              orderNumber,
              position,
              type: 'callback',
              text: '',
              elements: [
                {
                  elementName: 'Button',
                  buttonHeader: 'Назначить',
                  buttonNextStep: 0,
                },
              ],
              completionCode: {},
              title: {
                name: 'Обратный вызов',
                editable: false,
              },
              isStart: nodes.length > 1 ? false : nodes[0].id === '0',
            }
            addNode(callbackNode)
            break
          case 'finish':
            const finishNode = {
              orderNumber,
              position,
              type: 'finish',
              text: '',
              elements: [
                {
                  elementName: 'Button',
                  buttonHeader: 'Завершить звонок',
                  buttonNextStep: 0,
                },
              ],
              completionCode: {},
              title: {
                name: 'Завершение вызова',
                editable: false,
              },
              isStart: nodes.length > 1 ? false : nodes[0].id === '0',
            }
            addNode(finishNode)
            break
        }
      }
    },
    [nodes, dispatch, mode]
  )
  // ↑↑↑ Добавление шагов ↑↑↑

  // ↓↓↓ Перерисовка миникарты ↓↓↓
  useEffect(() => {
    const parent = document.getElementsByClassName('ad-minimap')[0]
    const target = document.getElementsByClassName(
      'react-flow__minimap-mask'
    )[0]
    if (target && parent) {
      const splitValue = target.attributes[1].value.split('\n')
      const value = splitValue[splitValue.length - 1]?.trim()
      if (value) {
        target.attributes[1].value = value
      }
    }
  }, [state, viewport, nodesForMiniMap, scripts])
  // ↑↑↑ Перерисовка миникарты ↑↑↑

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onEdgeUpdate={onEdgeUpdate}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      fitView
      maxZoom={4}
      minZoom={0.1}
      connectionLineComponent={ConnectionEdge}
    >
      <MiniMap
        className="ad-minimap"
        nodeColor="#CCDFE5"
        nodeStrokeColor="#CCDFE5"
        nodeBorderRadius={4}
        maskColor="rgba(0, 0, 0, 0)"
      />
      <Controls showInteractive={false} />
      <Pallet onClick={handleAddNode} />
    </ReactFlow>
  )
}
