import './Accept.sass'
import React, { memo } from 'react'
import Check from '../../../../../../components/Icons/AgentRequest/Check/Check'
import Cross from '../../../../../../components/Icons/AgentRequest/Cross/Cross'

interface AcceptProps {
  onAccept: () => void
  onCancel: () => void
  onlyCancel?: boolean
}

function Accept({ onAccept, onCancel, onlyCancel }: AcceptProps) {
  return (
    <>
      {!onlyCancel && (
        <div className="ad-node-accept" onClick={onAccept}>
          <Check size={24} />
        </div>
      )}
      <div className="ad-node-decline" onClick={onCancel}>
        <Cross size={34} />
      </div>
    </>
  )
}

export default memo(Accept)
