import React from 'react'

interface ButtonCloseProps {
  size?: number
  color?: string
}

export default function ButtonClose({ size, color }: ButtonCloseProps) {
  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.16638 6.16602L17.8336 17.8333"
        stroke={color || '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8336 6.16602L6.16636 17.8333"
        stroke={color || '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
