import './CampaignSubSider.sass'
import Search from '../../../../components/Search/Search'
import SearchIcon from '../../../../components/Icons/Chat/SearchIcon/SearchIcon'
import Sliders from '../../../../components/Icons/ScriptDev/Sider/Sliders'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hook'
import { scriptEditorSelector } from '../../../../store/selectors/scriptEditor'
import { HandlePropsType } from '../../../Script/Script'
import { CampaignResponse } from '../../../../api/data-contracts'
import CaretArrow from '../../../../components/Icons/CaretArrow/CaretArrow'
import { useApi } from '../../../../store/hooks/request'
import { campaignSelector } from '../../../../store/selectors/campaign'
import { getAllCampaigns } from '../../../../store/requests/script'

interface CampaignSubSiderPropsType extends HandlePropsType {
  campaignId?: CampaignResponse['campaignId']
  templateId?: string
  pickedJob?: number
  handleTemplate?: (id: string | undefined) => void
  setPickedJob: Dispatch<SetStateAction<number | undefined>>
  setPickedCampaign: Dispatch<SetStateAction<string | undefined>>
  setHeaderStatus: Dispatch<SetStateAction<string | undefined>>
}

export default function CampaignSubSider({
  campaignId,
  handleCampaign,
  setPickedJob,
  pickedJob,
  setPickedCampaign,
  setHeaderStatus,
}: CampaignSubSiderPropsType) {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { currentCampaign } = useAppSelector(campaignSelector)
  const { campaigns } = useAppSelector(scriptEditorSelector)
  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback((e) => setSearch(e.target.value), [])

  useEffect(() => {
    dispatch(getAllCampaigns({ api }))
    // eslint-disable-next-line
  }, [dispatch])

  return (
    <div className="campaign-sider-content">
      <div className="campaign-sider-content-search">
        <div className="search-wrapper">
          <Search
            icon={<SearchIcon />}
            autoFocus={false}
            onChange={handleSearchChange}
          />
        </div>
        <div className="icon-wrapper">
          <Sliders />
        </div>
      </div>
      <div className="campaign-dd-container">
        {campaigns
          .filter(({ campaignName }) =>
            campaignName?.toLowerCase().includes(search.toLowerCase())
          )
          .map((el) => {
            return (
              <React.Fragment key={el.campaignId}>
                <div
                  className={
                    'dd-el' +
                    (campaignId === el.campaignId && !pickedJob
                      ? ' dd-el-active'
                      : '')
                  }
                  onClick={() => {
                    handleCampaign(el.campaignId)
                    setPickedCampaign(el.campaignName)
                  }}
                >
                  <div className="dd-el-left">
                    <div className="dd-el-left-text">{el.campaignName}</div>
                  </div>

                  <div className="dd-el-right">
                    <CaretArrow isActive={campaignId === el.campaignId} />
                  </div>
                </div>
                {campaignId === el.campaignId && (
                  <div className="dd-els-wrapper">
                    {currentCampaign?.jobs
                      ?.map(({ jobId, jobStatus }) => (
                        <div
                          key={jobId}
                          className={`sub-dd-el ${
                            jobId === pickedJob ? ' sub-dd-el-picked' : ''
                          }`}
                          onClick={() => {
                            setPickedCampaign(el.campaignName)
                            handleCampaign(el.campaignId)
                            setPickedJob(jobId)
                            setHeaderStatus(jobStatus)
                          }}
                        >
                          {jobId}
                        </div>
                      ))
                      .slice(
                        currentCampaign?.jobs?.length - 5,
                        currentCampaign?.jobs?.length
                      )
                      .reverse()}
                  </div>
                )}
              </React.Fragment>
            )
          })}
      </div>
    </div>
  )
}
