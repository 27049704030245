import { memo, useCallback, useState, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import { useStompClient } from 'react-stomp-hooks'
import {
  ChatGroupMessage,
  ChatMessage,
} from '../../../../../api/data-contracts'
import { addIncomingMessage } from '../../../../../store/slices/chatSlice'
import { userSelector } from '../../../../../store/selectors/user'
import Lightning from '../../../../../components/Icons/Chat/Lightning/Lightning'
import Plane from '../../../../../components/Icons/Chat/Plane/Plane'
import TagBar from '../../../TagBar/TagBar'
import './MessageInputArea.sass'
import { SelectedChat } from '../../../../../store/slices/chatSlice'

type MessageInputAreaProps = ChatGroupMessage &
  ChatMessage & { type: SelectedChat['type'] }

function MessageInputArea({
  chatId,
  recipientId,
  senderId,
  recipientName,
  senderName,
  type,
}: MessageInputAreaProps) {
  const stomp = useStompClient()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [messageValue, setMessageValue] = useState('')
  const { agentId } = useAppSelector(userSelector)
  const senderRecipientMeta = useMemo(() => {
    const isSender = senderId === agentId
    return {
      senderId: isSender ? senderId : recipientId,
      senderName: isSender ? senderName : recipientName,
      recipientId: isSender ? recipientId : senderId,
      recipientName: isSender ? recipientName : senderName,
    }
  }, [agentId, senderId, senderName, recipientId, recipientName])

  const openTagBar = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const sendMessageHandler = useCallback(
    (text: string, type: SelectedChat['type']) => {
      if (text.length > 0) {
        const message: ChatMessage | ChatGroupMessage =
          type === 'oneToOne'
            ? {
                chatId,
                content: text,
                timestamp: new Date(Date.now()).toISOString(),
                ...senderRecipientMeta,
              }
            : {
                chatId,
                content: text,
                timestamp: new Date(Date.now()).toISOString(),
                senderId: agentId,
                senderName: agentId,
              }

        stomp?.publish({
          destination:
            type === 'oneToOne' ? '/sockets/chat' : '/sockets/chat-group',
          body: JSON.stringify(message),
        })
        dispatch(addIncomingMessage({ message, roomType: type! }))
        setMessageValue('')
      }
    },
    [chatId, stomp, dispatch, senderRecipientMeta, agentId]
  )

  return (
    <>
      <div className="message-input-container">
        <button className="lightning-button" onClick={openTagBar}>
          <Lightning />
        </button>
        <input
          className="input-field"
          placeholder="Напишите сообщение"
          autoFocus={true}
          value={messageValue}
          onChange={(e) => setMessageValue(e.target.value)}
          onKeyPress={(e) => {
            e.key === 'Enter' && sendMessageHandler(messageValue, type)
          }}
        />
        <button
          className="send-message-button"
          onClick={() => sendMessageHandler(messageValue, type)}
        >
          <Plane />
        </button>
      </div>
      <div>
        {isOpen && <TagBar tagHandler={sendMessageHandler} type={type} />}
      </div>
    </>
  )
}
export default memo(MessageInputArea)
