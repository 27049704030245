import { headerReducer } from './slices/headerSlice'
import { userReducer } from './slices/userSlice'
import { scriptReducer } from './slices/scriptSlice'
import { notificationsReducer } from './slices/notificationSlice'
import { tagReducer } from './slices/tagSlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { stompReducer } from './slices/stompSlice'
import { dashboardReducer } from './slices/dashboardSlice'
import { chatReducer } from './slices/chatSlice'
import { campaignReducer } from './slices/campaignSlice'
import { scriptEditorReducer } from './slices/scriptEditorSlice'
import { adminReducer } from './slices/adminSlice'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
}

const reducers = combineReducers({
  header: headerReducer,
  user: userReducer,
  script: scriptReducer,
  scriptEditor: scriptEditorReducer,
  notifications: notificationsReducer,
  tag: tagReducer,
  stomp: stompReducer,
  dashboard: dashboardReducer,
  chat: chatReducer,
  campaign: campaignReducer,
  admin: adminReducer,
})
const persistedReducer = persistReducer<ReturnType<typeof reducers>>(
  persistConfig,
  reducers
)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
