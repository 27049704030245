import { CommonSettingsResponse } from '../../../api/data-contracts'
import {
  changeCommonSettings,
  getCommonSettings,
} from '../../../store/requests/admin'
import CRUDTable, { Field, Fields, UpdateForm } from 'react-crud-table'
import { useApi } from '../../../store/hooks/request'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { adminSelector } from '../../../store/selectors/admin'
import { useEffect } from 'react'

export default function AdminCommonSettings() {
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const { commonSettings } = useAppSelector(adminSelector)

  useEffect(() => {
    dispatch(getCommonSettings({ api }))
    //eslint-disable-next-line
  }, [])

  const settingsService = {
    update: (data: CommonSettingsResponse) => {
      const task: CommonSettingsResponse | undefined = commonSettings.find(
        (t) => t.id === data.id
      )
      dispatch(changeCommonSettings({ ...data, api }))
      return Promise.resolve(task)
    },
  }

  return (
    <div className="table-container">
      <CRUDTable caption="Common settings" items={commonSettings}>
        <Fields>
          <Field name="id" label="id" hideInUpdateForm />
          <Field name="name" label="Название" />
          <Field name="value" label="Значение" />
          <Field name="valueType" label="Тип" />
        </Fields>
        <UpdateForm
          title="Обновить"
          message="Обновить"
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={settingsService.update}
        />
      </CRUDTable>
    </div>
  )
}
