import './Header.sass'
import React, {
  memo,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import Info from '../../../../../components/Icons/ScriptDev/Header/Info'
import Edit from '../../../../../components/Icons/ScriptDev/Header/Pencil'
import Bind from '../../../../../components/Icons/ScriptDev/Header/Bind'
import Save from '../../../../../components/Icons/ScriptDev/Header/Save'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import {
  createScript,
  deleteScript,
  linkScriptTemplateToCampaign,
  updateScript,
} from '../../../../../store/requests/script'
import { useApi } from '../../../../../store/hooks/request'
import {
  ScriptRequest,
  ScriptResponse,
} from '../../../../../api/data-contracts'
import { scriptEditorSelector } from '../../../../../store/selectors/scriptEditor'
import Plus from '../../../../../components/Icons/ScriptDev/Header/Plus'
import Cancel from '../../../../../components/Icons/ScriptDev/Header/Cancel'
import Trash from '../../../../../components/Icons/ScriptDev/Header/Trash'
import {
  addInterimScripts,
  changeMode,
  handlingCampaignModal,
} from '../../../../../store/slices/scriptEditorSlice'
import ScriptTitle from './ScriptTitle/ScriptTitle'
// import Tag from '../../../../../components/Tag/Tag'

export interface TargetHeaderPropsType {
  title?: string
  tag?: string
}
interface icon {
  id: number
  Icon: () => JSX.Element
  tip: string
}

const initialIcons = [
  {
    id: 2,
    Icon: Edit,
    tip: 'Режим редактирования',
  },
  {
    id: 3,
    Icon: Bind,
    tip: 'Создать шаблон',
  },
]
const anotherIcons = [
  {
    id: 2,
    Icon: Save,
    tip: 'Сохранить',
  },
  {
    id: 0,
    Icon: Cancel,
    tip: 'Режим просмотра',
  },
]
const campaignInfoIcon = {
  id: 1,
  Icon: Info,
  tip: 'Информация о кампании',
}
const addTemplateIcon = {
  id: 1,
  Icon: Plus,
  tip: 'Привязать шаблон',
}
const deleteTemplateIcon = {
  id: 3,
  Icon: Trash,
  tip: 'Удалить',
}

function Header({ title }: TargetHeaderPropsType) {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { scripts, currentCampaign, currentTemplate, mode, campaigns } =
    useAppSelector(scriptEditorSelector)
  const [icons, setIcons] = useState<icon[]>(initialIcons)
  const [isOpen, setIsOpen] = useState(false)
  const [templates, setTemplates] = useState<ScriptResponse[] | []>([])

  useEffect(
    () => setTemplates(scripts.filter(({ isTemplate }) => isTemplate)),
    [scripts]
  )
  useEffect(() => {
    const script = scripts.find(
      ({ campaignId }) =>
        campaigns.find(({ campaignId }) => campaignId === currentCampaign)
          ?.campaignId === campaignId
    )
    setIcons(
      mode === 'view'
        ? currentCampaign
          ? [campaignInfoIcon, ...initialIcons]
          : initialIcons
        : currentCampaign && script && script?.id?.includes('interim')
        ? [addTemplateIcon, ...anotherIcons]
        : [...anotherIcons, deleteTemplateIcon]
    )
  }, [mode, currentTemplate, currentCampaign, campaigns, scripts])

  const handleClose: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    const target = e.target as Element
    if (target.classList.contains('s-header-modal')) {
      setIsOpen(false)
    }
  }, [])

  const handleBind = useCallback(
    (template) => {
      const campaign = campaigns.find(
        ({ campaignId }) => campaignId === currentCampaign
      )
      setIsOpen(false)
      campaign &&
        dispatch(
          linkScriptTemplateToCampaign({
            api,
            scriptRequest: {
              ...template,
              campaignName: campaign.campaignName,
              campaignId: campaign.campaignId,
            },
          })
        )
    },
    //eslint-disable-next-line
    [campaigns, currentCampaign, dispatch]
  )

  const handleClick = useCallback(
    (id) => {
      switch (id) {
        case 0:
          dispatch(changeMode())
          break
        case 1:
          if (mode === 'view') dispatch(handlingCampaignModal())
          else {
            setIsOpen(true)
          }
          break
        case 2:
          if (mode === 'view') dispatch(changeMode())
          else {
            const script = scripts.find((script) =>
              currentTemplate
                ? script.id === currentTemplate
                : script.campaignId === currentCampaign
            ) as ScriptRequest
            if (script.id?.includes('interim') || script.id?.includes('new_')) {
              dispatch(
                createScript({
                  api,
                  scriptRequest: {
                    ...script,
                    id: undefined,
                  },
                })
              )
            } else {
              dispatch(
                updateScript({
                  api,
                  scriptRequest: script,
                })
              )
            }
          }
          break
        case 3:
          if (mode === 'view') {
            const { stepAmount, steps } = scripts.find((script) =>
              currentTemplate
                ? script.id === currentTemplate
                : script.campaignId === currentCampaign
            ) as ScriptRequest
            dispatch(
              createScript({
                api,
                scriptRequest: {
                  stepAmount,
                  steps,
                  isTemplate: true,
                  templateName: 'Новый шаблон ИЗ',
                },
              })
            )
          } else {
            const { id } = scripts.find((script) =>
              currentTemplate
                ? script.id === currentTemplate
                : script.campaignId === currentCampaign
            ) as ScriptRequest
            if (id) {
              dispatch(deleteScript({ id, api })).then(() =>
                dispatch(addInterimScripts())
              )
            }
          }
          break
      }
    },
    [api, dispatch, scripts, currentCampaign, currentTemplate, mode]
  )

  return (
    <div className="s-header">
      <div className="s-header-left">
        <div className="s-header-left-tags">
          <ScriptTitle title={title} />
          {/*<Tag*/}
          {/*    type="script-header"*/}
          {/*    obj={{*/}
          {/*        id: 1,*/}
          {/*        color: 'grey',*/}
          {/*        text: 'Какой-то тег',*/}
          {/*    }}*/}
          {/*/>*/}
        </div>
      </div>
      <div className="s-header-right">
        {icons.map(({ id, Icon, tip }) => (
          <div
            className="s-header-right-icon"
            key={id}
            onClick={() => handleClick(id)}
            title={tip}
          >
            <Icon />
          </div>
        ))}
      </div>
      {isOpen && (
        <div className="s-header-modal" onClick={handleClose}>
          <div className="s-header-modal-inner">
            <div className="s-header-modal-inner-title">
              Выберите шаблон для привязки к кампании
            </div>
            {templates.length ? (
              templates.map((template) => (
                <div
                  className="s-header-modal-inner-template"
                  key={template.id}
                  onClick={() => handleBind(template)}
                >
                  {template.templateName}
                </div>
              ))
            ) : (
              <div className="s-header-modal-inner-text">
                Нет шаблонов для привязки
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(Header)
