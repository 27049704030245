import { memo } from 'react'
import { AgentJobStatisticsNotification } from '../../../api/data-contracts'
import Phone from '../../../components/Icons/Dashboard/Call'
import './Calls.sass'
interface CallsProps {
  data?: {
    succes: AgentJobStatisticsNotification['callsSuccess']
    total: AgentJobStatisticsNotification['callCount']
    deals: AgentJobStatisticsNotification['callsClosure']
    RTC: AgentJobStatisticsNotification['callsRpc']
  }
}
function Calls({ data }: CallsProps) {
  return (
    <div className="dashboard-widget-container calls-container">
      <div className="calls-header">
        <span>Вызовы</span>
      </div>
      <div className="calls-content">
        <div className="total">
          <span>Всего:</span>
          <div className="total-counters">
            <Phone />
            <span>{data?.total ?? '-'}</span>
          </div>
        </div>
        <div className="calls-content-counters">
          <div className="calls-content-counters-content">
            <span>Успешных:</span>
            <span>{data?.succes ?? '-'}</span>
          </div>
          <div className="calls-content-counters-content">
            <span>RTC:</span>
            <span>{data?.RTC ?? '-'}</span>
          </div>
          <div className="calls-content-counters-content">
            <span>Сделок:</span>
            <span>{data?.deals ?? '-'}</span>
          </div>
        </div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
export default memo(Calls)
