import * as React from 'react'
import { CommonTwoButtonsModal } from '../../../components/Modal/TwoButtonsModal/TwoButtonsModal'
import Input from '../../../components/Input/Input'
import { useAppDispatch } from '../../../store/hooks/hook'
import { useCallback, useState } from 'react'
import { useApi } from '../../../store/hooks/request'
import { createScript } from '../../../store/requests/script'

export const useAddNewScript = () => {
  const dispatch = useAppDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [templateName, setTemplateName] = useState('')

  const { api } = useApi()

  const handleClick = useCallback(
    () =>
      dispatch(
        createScript({
          api,
          scriptRequest: {
            isTemplate: true,
            steps: [],
            templateName,
            stepAmount: 0,
          },
        })
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, templateName]
  )

  const toggleOpenModal = () => setOpenModal((old) => !old)

  const renderModal = () => (
    <CommonTwoButtonsModal
      rightButton={{
        label: 'Добавить',
        onClick() {
          handleClick()
          toggleOpenModal()
          setTemplateName('')
        },
        disabled: !templateName.length,
      }}
      leftButton={{
        label: 'Отмена',
        type: 'outline',
        onClick() {
          toggleOpenModal()
        },
      }}
      opened={openModal}
      title="Добавление скрипта"
      onClose={toggleOpenModal}
      description={
        <div>
          <Input
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            autofocus
            span="Название:"
            placeholder="Введите название скрипта"
          />
        </div>
      }
    />
  )

  return {
    renderModal,
    toggleOpenModal,
  }
}
