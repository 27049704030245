import CRUDTable, {
  Fields,
  Field,
  UpdateForm,
  DeleteForm,
  CreateForm,
} from 'react-crud-table'
import { useApi } from '../../../store/hooks/request'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { adminSelector } from '../../../store/selectors/admin'
import { useEffect } from 'react'
import {
  deleteChatRoom,
  findAllChatRoom,
  saveOrUpdateChatRoom,
} from '../../../store/requests/admin'
import { AdminChatRoomDto } from '../../../api/data-contracts'

export default function ChatRoomController() {
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const { chatRoom } = useAppSelector(adminSelector)

  useEffect(() => {
    dispatch(findAllChatRoom({ api }))
    //eslint-disable-next-line
  }, [])

  const chatRoomService = {
    create: (task: AdminChatRoomDto) => {
      dispatch(saveOrUpdateChatRoom({ ...task, api }))
    },
    update: (data: AdminChatRoomDto) => {
      const task: AdminChatRoomDto | undefined = chatRoom.find(
        (t) => t.chatId === data.chatId
      )
      dispatch(saveOrUpdateChatRoom({ ...data, api }))
      return Promise.resolve(task)
    },
    delete: (data: AdminChatRoomDto) => {
      const task = chatRoom.find((t) => t.chatId !== data.chatId)
      dispatch(
        deleteChatRoom({
          ...data,
          api,
        })
      )
      return Promise.resolve(task)
    },
  }

  return (
    <div className="table-container">
      <CRUDTable caption="1-1 чаты" items={chatRoom}>
        <Fields>
          <Field name="chatId" label="id" />
          <Field name="senderId" label="Id отправителя" />
          <Field name="senderName" label="Имя отправителя" />
          <Field name="recipientId" label="Id получателя" />
          <Field name="recipientName" label="Имя получателя" />
        </Fields>
        <CreateForm
          title="Создать"
          message="Создать"
          trigger="Создать"
          submitText="Создать"
          onSubmit={chatRoomService.create}
        />
        <UpdateForm
          title="Обновить"
          message="Обновить"
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={chatRoomService.update}
        />
        <DeleteForm
          title="Удалить"
          message="Уверены?"
          trigger="Удалить"
          submitText="Удалить"
          onSubmit={chatRoomService.delete}
        />
      </CRUDTable>
    </div>
  )
}
