import React, { MouseEventHandler } from 'react'
import './ButtonArrow.sass'

interface ButtonArrowProps {
  size?: number
  onClick?: MouseEventHandler<HTMLOrSVGElement>
  color?: string
}

export default function ButtonArrow({
  size,
  color,
  onClick,
}: ButtonArrowProps) {
  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M6 18L18 6"
        stroke={color || '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 6H18V15.75"
        stroke={color || '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
