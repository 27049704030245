import { Layout } from 'react-grid-layout'
import Deals from '../../../../../components/Icons/Dashboard/Deals'
import Rpc from '../../../../../components/Icons/Dashboard/Rpc'
import SaveDashboard from '../../../../../components/Icons/Dashboard/SaveDashboard'
import Sucess from '../../../../../components/Icons/Dashboard/Sucess'
import AverageCallTimeDrop from '../../../../Dashboard/AverageCallTime/AverageCallTimeDrop'
import CallsDrop from '../../../../Dashboard/Calls/CallsDrop'
import CompanyProgressDrop from '../../../../Dashboard/CompanyProgress/CompanyProgressDrop'
import OperatorStatusesDrop from '../../../../Dashboard/OperatorStatuses/OperatorStatusesDrop'
import PlannedCallsDrop from '../../../../Dashboard/PlannedCalls/PlannedCallsDrop'
import StartShiftDrop from '../../../../Dashboard/StartShift/StartShiftDrop'
import WorkTimeDrop from '../../../../Dashboard/WorkTime/WorkTimeDrop'
import { TopDashboardItems } from '../../TopDashEditor/types'
import { DashboardItems } from '../types'
import './EditorPanel.sass'
interface EditorPanelProps {
  type: 'dash' | 'topdash'
  onSave: () => void
  onAddItem: (addProps: Layout) => void
  items?: DashboardItems[] | TopDashboardItems[]
}
export default function EditorPanel({
  onSave,
  onAddItem,
  items,
  type,
}: EditorPanelProps) {
  const renderDashboardItems = () => {
    return items?.map((item) => {
      switch (item as DashboardItems) {
        case DashboardItems.AverageCallTime:
          return (
            <AverageCallTimeDrop type="dash" onAddItem={onAddItem} key={item} />
          )
        case DashboardItems.Calls:
          return <CallsDrop type="dash" onAddItem={onAddItem} key={item} />
        case DashboardItems.CompanyProgress:
          return (
            <CompanyProgressDrop type="dash" onAddItem={onAddItem} key={item} />
          )
        case DashboardItems.OperatorStatuses:
          return (
            <OperatorStatusesDrop
              type="dash"
              onAddItem={onAddItem}
              key={item}
            />
          )
        case DashboardItems.PlannedCalls:
          return (
            <PlannedCallsDrop type="dash" onAddItem={onAddItem} key={item} />
          )
        case DashboardItems.StartShift:
          return <StartShiftDrop type="dash" onAddItem={onAddItem} key={item} />
        case DashboardItems.WorkTime:
          return <WorkTimeDrop type="dash" onAddItem={onAddItem} key={item} />

        default:
          return <></>
      }
    })
  }
  const renderTopDashItems = () => {
    return items?.map((item) => {
      switch (item as TopDashboardItems) {
        case TopDashboardItems.AverageCallTime:
          return (
            <AverageCallTimeDrop
              type="topdash"
              onAddItem={onAddItem}
              key={item}
            />
          )
        case TopDashboardItems.StartShift:
          return (
            <StartShiftDrop type="topdash" onAddItem={onAddItem} key={item} />
          )
        case TopDashboardItems.WorkTime:
          return (
            <WorkTimeDrop type="topdash" onAddItem={onAddItem} key={item} />
          )
        case TopDashboardItems.Calls:
          return <CallsDrop type="topdash" onAddItem={onAddItem} key={item} />
        case TopDashboardItems.RPC:
          return <Rpc type="topdash" onAddItem={onAddItem} key={item} />
        case TopDashboardItems.Deals:
          return <Deals type="topdash" onAddItem={onAddItem} key={item} />
        case TopDashboardItems.Sucess:
          return <Sucess type="topdash" onAddItem={onAddItem} key={item} />

        default:
          return <></>
      }
    })
  }
  return (
    <div className="editor-panel-wrapper">
      <div className="editor-panel-content">
        {(type === 'dash' && renderDashboardItems()) ||
          (type === 'topdash' && renderTopDashItems())}

        <SaveDashboard onSave={onSave} />
      </div>
    </div>
  )
}
