import React, { MouseEventHandler } from 'react'

interface NeedHelpIconProps {
  size?: number
  onClick: MouseEventHandler<HTMLOrSVGElement>
}

export default function NeedHelpIcon(props: NeedHelpIconProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M2.19421 11.1954C1.35557 9.78163 1.06187 8.11036 1.36826 6.49539C1.67466 4.88042 2.56007 3.43285 3.85823 2.4245C5.15639 1.41614 6.77801 0.916377 8.41858 1.01904C10.0591 1.1217 11.6058 1.81973 12.7681 2.98206C13.9305 4.14438 14.6285 5.69104 14.7312 7.33161C14.8338 8.97218 14.3341 10.5938 13.3257 11.892C12.3174 13.1901 10.8698 14.0755 9.25486 14.382C7.63989 14.6884 5.96862 14.3947 4.55486 13.556L4.55488 13.556L2.22356 14.222C2.1271 14.2496 2.02504 14.2509 1.92793 14.2257C1.83082 14.2005 1.74221 14.1499 1.67128 14.0789C1.60035 14.008 1.54967 13.9194 1.52451 13.8223C1.49935 13.7252 1.50061 13.6231 1.52817 13.5267L2.19426 11.1953L2.19421 11.1954Z"
        stroke="#C15747"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.5V9.625"
        stroke="#C15747"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.0625 7.5625L5.9375 7.5625"
        stroke="#C15747"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.20166 16.9979C9.9044 17.7076 10.7558 18.2526 11.6945 18.5936C12.6332 18.9347 13.6358 19.0633 14.6302 18.9703C15.6246 18.8772 16.586 18.5648 17.4451 18.0555L19.7764 18.7215C19.8729 18.7491 19.9749 18.7503 20.072 18.7252C20.1692 18.7 20.2578 18.6493 20.3287 18.5784C20.3996 18.5075 20.4503 18.4188 20.4755 18.3217C20.5006 18.2246 20.4994 18.1226 20.4718 18.0261L19.8057 15.6948C20.3975 14.6967 20.7222 13.5629 20.7483 12.4028C20.7743 11.2427 20.5009 10.0955 19.9545 9.07182C19.4081 8.04814 18.6071 7.18256 17.6288 6.55859"
        stroke="#C15747"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
