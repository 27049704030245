import './Navigator.sass'
import React, { useEffect } from 'react'
import Dashboard from '../../Icons/Navigation/Dashboard'
import Call from '../../Icons/Navigation/Call'
import Chat from '../../Icons/Navigation/Chat'
import Script from '../../Icons/Navigation/Script'
import Campaign from '../../Icons/Navigation/Сampaign'
import Settings from '../../Icons/Navigation/Settings'
import { useNavigate, useLocation } from 'react-router-dom'
import Notifier from '../../Header/Notifier/Notifier'
import { notificationSelector } from '../../../store/selectors/notification'
import { userSelector } from '../../../store/selectors/user'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import AgentGroupEntityIcon from '../../Icons/AdminIcon/AgentGroupEntityIcon'
import AgentProfileEntityIcon from '../../Icons/AdminIcon/AgentProfileEntityIcon'
import CampaignControllerIcon from '../../Icons/AdminIcon/CampaignControllerIcon'
import ChatGroupRoomControllerIcon from '../../Icons/AdminIcon/ChatGroupRoomControllerIcon'
import ChatRoomControllerIcon from '../../Icons/AdminIcon/ChatRoomControllerIcon'
import SupervisorControllerIcon from '../../Icons/AdminIcon/SupervisorControllerIcon'
import UserControllerIcon from '../../Icons/AdminIcon/UserControllerIcon'
import CommonSettingsIcon from '../../Icons/AdminIcon/CommonSettingsIcon'
import Tooltip from '../../Tooltip/Tooltip'
import { fetchChatRoomsAndMessages } from '../../../store/requests/chat'
import { useApi } from '../../../store/hooks/request'

export default function Navigator() {
  const navigate = useNavigate()
  const location = useLocation()
  const { countOfMessages } = useAppSelector(notificationSelector)
  const { role, agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const { api } = useApi()

  useEffect(() => {
    if (location.pathname === '/chat')
      dispatch(fetchChatRoomsAndMessages({ api, agentId }))
    // eslint-disable-next-line
  }, [location, agentId])

  const iconList = [
    {
      Component: Dashboard,
      path: '/',
      visible: role && role[0] !== 'ROLE_ADMIN',
    },
    {
      Component: Call,
      path: '/call',
      visible: role && role[0] !== 'ROLE_ADMIN',
    },
    {
      Component: Chat,
      path: '/chat',
      visible: role && role[0] !== 'ROLE_ADMIN',
    },
    {
      Component: Script,
      path: '/script',
      visible: role && role[0] === 'ROLE_SUPERVISOR',
    },
    {
      Component: Campaign,
      path: '/campaign',
      visible: role && role[0] !== 'ROLE_ADMIN',
    },
    {
      Component: Settings,
      path: '/settings',
      visible: role && role[0] === 'ROLE_SUPERVISOR',
    },
    {
      Component: AgentGroupEntityIcon,
      path: '/agent-group-entity',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Группы агентов',
    },
    {
      Component: AgentProfileEntityIcon,
      path: '/agent-profile-entity',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Профили агентов',
    },
    {
      Component: CampaignControllerIcon,
      path: '/campaign-controller',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Кампании',
    },
    {
      Component: ChatGroupRoomControllerIcon,
      path: '/chat-group-room-controller',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Групповые чаты',
    },
    {
      Component: ChatRoomControllerIcon,
      path: '/chat-room-controller',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Чаты',
    },
    {
      Component: SupervisorControllerIcon,
      path: '/supervisor-controller',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Супервизоры',
    },
    {
      Component: UserControllerIcon,
      path: '/user-controller',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Пользователи',
    },
    {
      Component: CommonSettingsIcon,
      path: '/common-settings',
      visible: role && role[0] === 'ROLE_ADMIN',
      tipName: 'Общие настройки',
    },
  ]

  const handleClick = React.useCallback(
    (path: string) => navigate(path),
    [navigate]
  )

  return (
    <React.Fragment>
      {iconList.map((el, index) => {
        const component = (
          <React.Fragment key={`${el.path}-${index}`}>
            <div
              className={
                'navigator-container ' +
                (location.pathname === el.path ? 'navigator-active' : '')
              }
              onClick={() => handleClick(el.path)}
            >
              <div className="navigator-wrapper">
                <el.Component isActive={location.pathname === el.path} />
              </div>
              {countOfMessages > 0 && el.path === '/chat' && (
                <div
                  className={`sider-notifier ${
                    countOfMessages > 99 ? 'sider-notifier-big' : ''
                  }`}
                >
                  <Notifier count={countOfMessages} />
                </div>
              )}
            </div>
          </React.Fragment>
        )
        if (el.tipName)
          return (
            el.visible && (
              <Tooltip key={index} text={el.tipName} component={component} />
            )
          )
        else return el.visible && component
      })}
    </React.Fragment>
  )
}
