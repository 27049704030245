import React, { MouseEventHandler } from 'react'
import './RectangleButton.sass'
import ButtonArrow from '../../../../Icons/ButtonArrow/ButtonArrow'
import ButtonClose from '../../../../Icons/ButtonClose/ButtonClose'

interface RectangleButtonProps {
  label: string
  onClick: MouseEventHandler<HTMLButtonElement>
  className?: string
  isBlue?: boolean
  negative?: boolean
}

export default function RectangleButton({
  label,
  onClick,
  className,
  isBlue,
  negative,
}: RectangleButtonProps) {
  const icon = negative ? (
    <ButtonClose color={isBlue ? '#397FBF' : ''} />
  ) : (
    <ButtonArrow color={isBlue ? '#397FBF' : ''} />
  )

  return (
    <button
      className={
        className ? className + ' rectangle-button' : 'rectangle-button'
      }
      onClick={onClick}
    >
      <span className={isBlue ? 'preview-span' : ''}>{label}</span>
      <div>{icon}</div>
    </button>
  )
}
