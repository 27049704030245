import React from 'react'

interface MessageStatusProps {
  size?: number
}

export default function MessageStatus(props: MessageStatusProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="message-status"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2715 0.464259C10.5319 0.727803 10.5319 1.15509 10.2715 1.41864L4.22725 7.53561C3.96684 7.79915 3.54463 7.79915 3.28422 7.53561L0.262105 4.47712C0.00169468 4.21358 0.00169468 3.78629 0.262105 3.52275C0.522515 3.2592 0.944723 3.2592 1.20513 3.52275L3.75574 6.10405L9.32846 0.464259C9.58887 0.200716 10.0111 0.200716 10.2715 0.464259Z"
        fill="#BA4433"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.84375 6.84343L6.54704 7.53561C6.81481 7.79915 7.24895 7.79915 7.51672 7.53561L13.7318 1.41864C13.9996 1.15509 13.9996 0.727803 13.7318 0.464259C13.464 0.200716 13.0299 0.200716 12.7621 0.464259L7.03188 6.10405L6.81343 5.88905L5.84375 6.84343Z"
        fill="#BA4433"
      />
    </svg>
  )
}
