import React, {
  ChangeEventHandler,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import Dropdown from '../Elements/Dropdown/Dropdown'
import {
  CustomerPomDetailsResponse,
  ScriptResponse,
} from '../../../api/data-contracts'
import RectangleButton from '../Elements/Buttons/RectangleButton/RectangleButton'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { endCall } from '../../../store/requests/script'
import { useApi } from '../../../store/hooks/request'
import { userSelector } from '../../../store/selectors/user'
import { extendWrapup } from '../../../store/requests/script'
import { scriptSelector } from '../../../store/selectors/script'
import { setCallbackWindowOpen } from '../../../store/slices/scriptSlice'

interface CompletionCodesProps {
  currentScript: ScriptResponse | null
  customerDetails: CustomerPomDetailsResponse | null
}

export default function CompletionCodes({
  currentScript,
  customerDetails,
}: CompletionCodesProps) {
  const [code, setCode] = useState<number | string | null>(null)
  const { isExtendable, releaseLineAnswer } = useAppSelector(scriptSelector)
  const { agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const { api } = useApi()

  const completionCodes = useMemo(
    () =>
      currentScript?.steps
        ?.filter(
          (step) => !!step.completionCode?.id && !!step.completionCode?.name
        )
        ?.map((step) => ({
          text: step.completionCode?.name || '',
          value: step.completionCode?.id || '',
          key: step.completionCode?.id || '',
        })),
    [currentScript]
  )

  useEffect(() => {
    if (!code && !!completionCodes?.length) setCode(completionCodes[0].value)
  }, [code, completionCodes])

  const handleChooseCode: ChangeEventHandler<HTMLSelectElement> | undefined = (
    e
  ) => {
    const code = completionCodes?.find(
      (code) => code.value === Number(e.target.value)
    )?.value
    setCode(code || null)
  }

  const handleGoToNextCall = useCallback(() => {
    if (customerDetails?.agentId) {
      const codeObj = completionCodes?.find(
        (compCode) => compCode.value === code
      )
      if (codeObj) {
        const completionCode = {
          codeIDField: String(codeObj.value),
          codeValueField: codeObj?.text,
        }
        dispatch(
          endCall({
            api,
            agentId: customerDetails?.agentId,
            completionCode,
          })
        )
      }
    }
  }, [api, code, completionCodes, customerDetails, dispatch])

  const handleExtendCall = useCallback(() => {
    dispatch(extendWrapup({ api, agentId: agentId! }))
  }, [api, agentId, dispatch])

  const handleOpenCallbackWindow = useCallback(() => {
    dispatch(setCallbackWindowOpen(true))
  }, [dispatch])

  // const handleAddToDNC = useCallback(() => {
  //     dispatch(addToDNC({ api }))
  // }, [dispatch, api])

  return (
    <div className="step">
      <div className="step-header">
        <div className="step-title-name">Вызов завершён</div>
      </div>
      <div className="step-phone-choose">
        <div className="step-phone-choose-header">Код завершения:</div>
        <Dropdown
          options={completionCodes}
          value={code || ''}
          onChange={handleChooseCode}
        />
      </div>
      <div className="step-bottom-buttons">
        {releaseLineAnswer && (
          <>
            <RectangleButton
              label="Следующий вызов"
              onClick={handleGoToNextCall}
              isBlue
            />
            {/*<RectangleButton*/}
            {/*    label="Клиент просил не звонить"*/}
            {/*    onClick={handleAddToDNC}*/}
            {/*    isBlue*/}
            {/*    className="step-bottom-buttons-lower"*/}
            {/*/>*/}
            <RectangleButton
              label="Запланировать callback"
              onClick={handleOpenCallbackWindow}
              isBlue
              className="step-bottom-buttons-lower"
            />
          </>
        )}
        {isExtendable && (
          <RectangleButton
            label="Отложить след. вызов"
            onClick={handleExtendCall}
            isBlue
            className="step-bottom-buttons-lower"
          />
        )}
      </div>
    </div>
  )
}
