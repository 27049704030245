import React from 'react'

interface CloseProps {
  onClose?: React.MouseEventHandler<SVGSVGElement>
  size?: number
  stroke: string
}

export default function CloseIcon({ onClose, size, stroke }: CloseProps) {
  return (
    <svg
      width={size ? size : '24'}
      height={size ? size : '24'}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="chat-close-icon"
      onClick={onClose}
    >
      <path
        d="M6.16602 6.16602L17.8333 17.8333"
        stroke={`${
          stroke === 'red'
            ? '#FFEDEA'
            : stroke === 'blue'
            ? '#EAFEFF'
            : stroke === 'yellow'
            ? '#FFF8E4'
            : stroke === 'green'
            ? '#E4FFF2'
            : stroke === 'purple'
            ? '#FAE4FF'
            : '#F4FAFB'
        }`}
        strokeWidth={'2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.834 6.16602L6.16672 17.8333"
        stroke={`${
          stroke === 'red'
            ? '#FFEDEA'
            : stroke === 'blue'
            ? '#EAFEFF'
            : stroke === 'yellow'
            ? '#FFF8E4'
            : stroke === 'green'
            ? '#E4FFF2'
            : stroke === 'purple'
            ? '#FAE4FF'
            : '#F4FAFB'
        }`}
        strokeWidth={'2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
