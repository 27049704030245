import './Notification.sass'
import React, { useCallback } from 'react'
import Close from '../../Icons/Close/Close'
import Info from '../../Icons/Notifications/Info/Info'
import Warning from '../../Icons/Notifications/Warning/Warning'
import Break from '../../Icons/Notifications/Break/Break'
import Message from '../../Icons/Notifications/Message/Message'
import ListenTalk from '../../Icons/Notifications/ListenTalk/ListenTalk'
import {
  delNotification,
  Notification as NotificationType,
} from '../../../store/slices/notificationSlice'
import { useAppDispatch } from '../../../store/hooks/hook'
import {
  delLoginNotification,
  setAgentProcessing,
  setCallAgentModalOpen,
} from '../../../store/slices/userSlice'
import { proceedNotification } from '../../../store/requests/notifications'
import { useApi } from '../../../store/hooks/request'
import Check from '../../Icons/AgentRequest/Check/Check'
import Cross from '../../Icons/AgentRequest/Cross/Cross'
import SignOut from '../../Icons/Notifications/SignOut/SignOut'
import Clock from '../../Icons/Notifications/Clock/Clock'
import { useNavigate } from 'react-router-dom'

interface NotificationProps {
  obj: NotificationType
  isLogin?: boolean
}

export default function Notification({ obj, isLogin }: NotificationProps) {
  const { id, icon, title, text, dataObj } = obj
  const navigate = useNavigate()
  let iconBackgroundClass, NotificationIcon
  const dispatch = useAppDispatch()
  const { api } = useApi()

  const onClose = React.useCallback(() => {
    if (isLogin) dispatch(delLoginNotification(id))
    else dispatch(delNotification(id))
  }, [dispatch, id, isLogin])

  const proceedRequest = useCallback(
    (result: boolean) => {
      if (!!dataObj) {
        if (dataObj.type === 'HELP_REQUEST' && result) {
          navigate('/call')
          dispatch(
            setAgentProcessing({
              id: dataObj.agentId || '',
              name: dataObj.agentName || '',
            })
          )
          dispatch(setCallAgentModalOpen(true))
        }
        return dispatch(
          proceedNotification({
            api,
            notificationId: dataObj.id,
            agentId: dataObj.agentId,
            result,
          })
        )
      }
    },
    // eslint-disable-next-line
    [dataObj, obj]
  )

  const handleCloseUnprocessableNotification = useCallback(() => {
    proceedRequest(true)
    onClose()
  }, [onClose, proceedRequest])

  switch (icon) {
    case 'info':
      iconBackgroundClass = 'notification-icon-background-grey'
      NotificationIcon = Info
      break
    case 'break':
      iconBackgroundClass = 'notification-icon-background-green'
      NotificationIcon = Break
      break
    case 'warning':
      iconBackgroundClass = 'notification-icon-background-red'
      NotificationIcon = Warning
      break
    case 'message':
      iconBackgroundClass = 'notification-icon-background-blue'
      NotificationIcon = Message
      break
    case 'listen-talk':
      iconBackgroundClass = 'notification-icon-background-grey'
      NotificationIcon = ListenTalk
      break
    case 'sign-out':
      iconBackgroundClass = 'notification-icon-background-red'
      NotificationIcon = SignOut
      break
    case 'clock':
      iconBackgroundClass = 'notification-icon-background-orange'
      NotificationIcon = Clock
      break
    default:
      iconBackgroundClass = 'notification-icon-background-grey'
      NotificationIcon = Info
  }

  return (
    <div className="notification">
      <div className={'notification-icon ' + iconBackgroundClass}>
        <NotificationIcon />
      </div>
      <div className="notification-articles">
        <p className="notification-articles-title">{title}</p>
        <p className="notification-articles-body">{text}</p>
      </div>
      {!!dataObj && dataObj.isProcessable ? (
        <div className="agent-request-buttons">
          <div
            className="agent-request-button accept"
            onClick={() => proceedRequest(true)}
          >
            <Check />
          </div>
          <div
            className="agent-request-button decline"
            onClick={() => proceedRequest(false)}
          >
            <Cross />
          </div>
        </div>
      ) : (
        <div className="notification-close">
          <Close onClose={handleCloseUnprocessableNotification} />
        </div>
      )}
    </div>
  )
}
