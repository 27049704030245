import React from 'react'

export default function Bind() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.81805 11.0679L12 14.2498L15.182 11.0679"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.75V14.25"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.74945 15H7.18879C7.28728 15 7.38481 15.0194 7.4758 15.0571C7.5668 15.0948 7.64948 15.15 7.71912 15.2197L9.52978 17.0303C9.59942 17.1 9.6821 17.1552 9.7731 17.1929C9.86409 17.2306 9.96162 17.25 10.0601 17.25H13.9388C14.0373 17.25 14.1348 17.2306 14.2258 17.1929C14.3168 17.1552 14.3995 17.1 14.4691 17.0303L16.2798 15.2197C16.3494 15.15 16.4321 15.0948 16.5231 15.0571C16.6141 15.0194 16.7116 15 16.8101 15H20.2495"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
