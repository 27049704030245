import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CustomerPomDetailsResponse,
  ContactPomNotification,
  ScriptResponse,
  WrapupPomUniversalResponse,
  CallbackDestPom,
  ContactNumber,
  CallbackDestsPomResponse,
} from '../../api/data-contracts'
import { cancelDial, endCall, onJobAttachedGetScript } from '../requests/script'
import moment from 'moment'

export interface ScriptStore {
  isExpanded: boolean
  currentScript: ScriptResponse | null
  currentScriptHistory: Array<number>
  callInfo: ContactPomNotification | null
  customerDetails: CustomerPomDetailsResponse | null
  callStart: number | null
  completionCodeChoose: boolean
  releaseLineAnswer?: WrapupPomUniversalResponse | null
  nextCallTimer?: number | null
  isExtendable?: boolean
  callState:
    | 'NoState'
    | 'Preview'
    | 'Idle'
    | 'Dialing'
    | 'Talking'
    | 'Held'
    | 'Wrapup'
    | 'Consult'
    | 'ConferenceOwner'
    | 'ConferencePassive'
    | 'Callback'
  callbackTypes: string[]
  callbackDests: CallbackDestsPomResponse | null
  chosenContactNumber: ContactNumber | null
  chosenCallbackType: string | null
  chosenCallbackDest: CallbackDestPom | null
  callbackWindowOpen: boolean
  blendingStatus: 'BLEND_TO_INBOUND' | 'BLEND_TO_OUTBOUND' | null
  manualStarted: boolean
}

const initialState: ScriptStore = {
  isExpanded: false,
  currentScript: null,
  currentScriptHistory: [],
  callInfo: null,
  customerDetails: null,
  callStart: null,
  completionCodeChoose: false,
  callState: 'NoState',
  callbackTypes: [],
  callbackDests: null,
  chosenContactNumber: null,
  chosenCallbackType: null,
  chosenCallbackDest: null,
  callbackWindowOpen: false,
  blendingStatus: null,
  manualStarted: false,
}

export const scriptSlice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    dropScriptSlice: () => initialState,
    setExpanded(
      state: ScriptStore,
      { payload }: PayloadAction<ScriptStore['isExpanded']>
    ) {
      state.isExpanded = payload
    },
    setScriptHistoryPush(
      state: ScriptStore,
      { payload }: PayloadAction<number>
    ) {
      state.currentScriptHistory.push(payload)
    },
    setScriptHistoryPop(state: ScriptStore) {
      state.currentScriptHistory.pop()
    },
    setCallInfo(state, { payload }: PayloadAction<ScriptStore['callInfo']>) {
      state.callInfo = payload
    },
    setCallStart(state) {
      state.callStart = moment().unix()
    },
    setCustomerDetails(
      state,
      { payload }: PayloadAction<ScriptStore['customerDetails']>
    ) {
      state.customerDetails = payload
    },
    setReleaseLineAnswer(
      state,
      { payload }: PayloadAction<ScriptStore['releaseLineAnswer']>
    ) {
      if (payload) {
        state.releaseLineAnswer = payload
        state.isExtendable = payload.acwExtendable
        state.nextCallTimer = moment().add(payload.acwMaxTime, 'seconds').unix()
      }
    },
    setCallToEnd(state) {
      state.completionCodeChoose = true
    },
    extendTime(
      state,
      { payload }: PayloadAction<ScriptStore['releaseLineAnswer']>
    ) {
      const { nextCallTimer } = state
      if (payload && nextCallTimer) {
        state.nextCallTimer = moment
          .unix(nextCallTimer)
          .add(payload.acwMaxTime, 'seconds')
          .unix()
        state.isExtendable = payload.acwExtendable
      }
    },
    setCallState(state, { payload }: PayloadAction<ScriptStore['callState']>) {
      state.callState = payload
    },
    setCallbackTypes(
      state,
      { payload }: PayloadAction<ScriptStore['callbackTypes']>
    ) {
      state.callbackTypes = payload
    },
    setCallbackDests(
      state,
      { payload }: PayloadAction<ScriptStore['callbackDests']>
    ) {
      state.callbackDests = payload
    },
    chooseCallbackType(
      state,
      { payload }: PayloadAction<ScriptStore['chosenCallbackType']>
    ) {
      state.chosenCallbackType = payload
    },
    chooseCallbackDest(
      state,
      { payload }: PayloadAction<ScriptStore['chosenCallbackDest']>
    ) {
      state.chosenCallbackDest = payload
    },
    chooseContactNumber(
      state,
      { payload }: PayloadAction<ScriptStore['chosenContactNumber']>
    ) {
      state.chosenContactNumber = payload
    },
    setCallbackWindowOpen(
      state,
      { payload }: PayloadAction<ScriptStore['callbackWindowOpen']>
    ) {
      state.callbackWindowOpen = payload
    },
    setBlendingStatus(
      state,
      { payload }: PayloadAction<ScriptStore['blendingStatus']>
    ) {
      state.blendingStatus = payload
    },
    setManualStarted(
      state,
      { payload }: PayloadAction<ScriptStore['manualStarted']>
    ) {
      state.manualStarted = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onJobAttachedGetScript.fulfilled, (state, { payload }) => {
      state.currentScript = payload
    })
    builder.addCase(endCall.fulfilled, (state) => {
      state.callInfo = null
      state.customerDetails = null
      state.completionCodeChoose = false
      state.callStart = null
      state.currentScriptHistory = []
      state.releaseLineAnswer = null
      state.nextCallTimer = null
      state.chosenContactNumber = null
      state.callbackWindowOpen = false
      state.manualStarted = false
    })
    builder.addCase(cancelDial.fulfilled, (state) => {
      state.completionCodeChoose = true
    })
  },
})

export const {
  setExpanded,
  setScriptHistoryPush,
  setScriptHistoryPop,
  setCallInfo,
  setCustomerDetails,
  setCallToEnd,
  setReleaseLineAnswer,
  extendTime,
  setCallState,
  setCallbackTypes,
  setCallbackDests,
  chooseCallbackType,
  chooseCallbackDest,
  chooseContactNumber,
  setCallbackWindowOpen,
  dropScriptSlice,
  setBlendingStatus,
  setManualStarted,
  setCallStart,
} = scriptSlice.actions
export const scriptReducer = scriptSlice.reducer
