export interface ColorType {
  background: string
  color: string
}

export const colors: ColorType[] = [
  { background: '#F4FAFB', color: '#556268' },
  { background: '#E4FFF2', color: '#31A16B' },
  { background: '#FFEDEA', color: '#AD3928' },
  { background: '#EAFEFF', color: '#286DAD' },
  { background: '#FFF8E4', color: '#943005' },
  { background: '#FAE4FF', color: '#31A16B' },
]
