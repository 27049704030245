import './CampaignModal.sass'
import Tag from '../../../components/Tag/Tag'
import CampaignTagIcon from '../../../components/Icons/Campaign/CampaignTagIcon'

interface CampaignModalProps {
  title?: string
  description?: string
  strategy?: string
  type?: string
  contactList?: [] | undefined | string
}

export default function CampaignModal(props: CampaignModalProps) {
  const tagText = props.type === 'Finite' ? 'Конечная' : ''

  return (
    <div className="campaign-modal-container">
      <div className="campaign-modal-container-title">{props.title}</div>
      <span>Описание кампании:</span>
      <div className="campaign-modal-container-description">
        {props.description}
      </div>
      <span>Тип кампании:</span>
      <div className="campaign-modal-container-type">
        <Tag
          type={'campaign'}
          obj={{ id: 1, color: 'grey', text: tagText }}
          icon={{ element: <CampaignTagIcon />, position: 'left' }}
        />
      </div>
    </div>
  )
}
