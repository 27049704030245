import React, { useState, useRef, RefObject, SyntheticEvent } from 'react'
import DatePicker, { registerLocale, ReactDatePicker } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import Calendar from '../../Icons/Calendar/Calendar'
import moment from 'moment'
import Actions from '../Actions/Actions'
import DateInputProps from '../types'
import ReactInputMask from 'react-input-mask'

import 'react-datepicker/dist/react-datepicker.css'
import '../DateInput.sass'

registerLocale('ru', ru)

export default function DateTimeInput({ onChange }: DateInputProps) {
  const [pickerDate, setPickerDate] = useState<Date | null>(new Date())
  const [date, setDate] = useState<string | undefined>(undefined)
  const calendarRef: RefObject<ReactDatePicker> = useRef<ReactDatePicker>(null)

  const handleChangeDate = (
    date: Date | null,
    e: SyntheticEvent<Event> | undefined
  ) => {
    onChange(date, e)
    setPickerDate(date)
    setDate(moment(date).format('HH:mm  |  DD.MM.YYYY'))
  }

  const handleAccept = () => {
    calendarRef?.current?.setOpen(false)
  }

  const handleOpenPicker = () => {
    calendarRef?.current?.setOpen(true)
  }

  const handleClearPicker = () => {
    setPickerDate(null)
    setDate(undefined)
  }

  return (
    <div className="date-picker-wrapper">
      <DatePicker
        ref={calendarRef}
        onChange={handleChangeDate}
        locale="ru"
        dateFormat="HH:mm  |  dd.MM.yyyy"
        value={date}
        selected={pickerDate}
        showTimeInput
        timeInputLabel="Указать время:"
        shouldCloseOnSelect={false}
        placeholderText="__:__  |  __.__.____"
        customInput={
          <ReactInputMask
            type="text"
            mask={[
              /[0-2]/,
              /\d/,
              ':',
              /[0-5]/,
              /\d/,
              ' ',
              ' ',
              '|',
              ' ',
              ' ',
              /[0-3]/,
              /\d/,
              '.',
              /[0-2]/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
          />
        }
      >
        <Actions onClear={handleClearPicker} onAccept={handleAccept} />
      </DatePicker>
      <div className="date-picker-wrapper-icon" onClick={handleOpenPicker}>
        <Calendar />
      </div>
    </div>
  )
}
