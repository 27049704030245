import React, {
  useEffect,
  useState,
  ChangeEventHandler,
  useCallback,
} from 'react'
import Button from '../../../../../components/Button/Button'
import { CampaignResponse } from '../../../../../api/data-contracts'
import { useApi } from '../../../../../store/hooks/request'
import { saveUpdateTag } from '../../../../../store/requests/tag'
import { useAppDispatch } from '../../../../../store/hooks/hook'
import { TagType } from '../../../../../store/slices/tagSlice'

interface TagCompanyModalProps {
  onClose(): void
  tag: TagType
}
export default function TagCompanyModal({
  onClose,
  tag,
}: TagCompanyModalProps) {
  const [chosenCampaign, setChosenCampaign] =
    useState<CampaignResponse['campaignId']>()
  const [campaigns, setCampaigns] = useState<CampaignResponse[]>([])
  const { api } = useApi()
  const dispatch = useAppDispatch()

  const handleCreate = useCallback(() => {
    const finalTag = { ...tag }
    delete finalTag.tagColor
    dispatch(
      saveUpdateTag({
        isTemplate: false,
        tag: { ...finalTag, campaignId: chosenCampaign },
        api,
      })
    )
    onClose()
    //eslint-disable-next-line
  }, [chosenCampaign, tag, onClose])

  const handleChooseCampaign: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setChosenCampaign(Number(e.target.value))

  useEffect(() => {
    const getAllCampaigns = async () => {
      const { data } = await api.findAllCampaignsUsingGet()
      setCampaigns(data)
      setChosenCampaign(data[0].campaignId)
    }
    getAllCampaigns()
    //eslint-disable-next-line
  }, [])

  return (
    <div className="modal">
      <div className="modal-container" style={{ height: '210px' }}>
        <div className="modal-articles">
          <p className="modal-articles-title">Привязка к кампании</p>
          <div className="modal-articles-body">
            <div>
              <label>
                Выберите кампанию
                <select
                  value={chosenCampaign}
                  onChange={handleChooseCampaign}
                  className="input"
                >
                  {campaigns?.map((campaign) => (
                    <option
                      value={campaign.campaignId}
                      key={campaign.campaignId}
                    >
                      {campaign.campaignName}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        </div>
        <div></div>
        <div className="modal-btns-group">
          <div className="modal-btns-wrapper">
            <Button text="Привязать" onClick={handleCreate} />
          </div>
          <div className="modal-btns-wrapper">
            <Button text="Отмена" type="outline" onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  )
}
