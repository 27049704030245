import { memo } from 'react'
import { AgentJobStatisticsNotification } from '../../../api/data-contracts'
import Clock from '../../../components/Icons/Dashboard/Clock'
import './StartShift.sass'

interface StartShiftProps {
  data?: AgentJobStatisticsNotification['loginTimeStamp']
}

function StartShift({ data }: StartShiftProps) {
  return (
    <div className="dashboard-widget-container start-shift-container">
      <div className="start-shift-header">
        <span>Начало смены</span>
      </div>
      <div className="start-shift-content">
        <Clock />
        <h3>{data ?? '--:--'}</h3>
      </div>
    </div>
  )
}
export default memo(StartShift)
