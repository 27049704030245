import React from 'react'

export default function Red() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 3.75L19.5 20.25"
        stroke="#AD3928"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7805 10.6589C10.3107 10.7321 9.84861 10.8477 9.39973 11.0042C9.27818 11.0476 9.17003 11.1219 9.08592 11.2198C9.0018 11.3177 8.94463 11.4358 8.92002 11.5624L8.36706 14.3288C8.3426 14.4511 8.28801 14.5654 8.20823 14.6614C8.12845 14.7573 8.02599 14.8318 7.91015 14.8781L3.4746 16.6524C3.32432 16.713 3.1584 16.7232 3.00183 16.6814C2.84525 16.6396 2.70652 16.548 2.60652 16.4204C1.93674 15.5544 1.6056 14.4739 1.6751 13.3813C1.7446 12.2886 2.20999 11.2588 2.98411 10.4846C4.35362 9.1111 6.01956 8.06958 7.85411 7.43994"
        stroke="#AD3928"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2988 6.76903C13.0888 6.66852 14.8797 6.94755 16.5542 7.58783C18.2287 8.22811 19.7489 9.21517 21.0152 10.4843C21.7893 11.2585 22.2547 12.2883 22.3242 13.3809C22.3937 14.4736 22.0625 15.5541 21.3928 16.4201C21.2928 16.5477 21.154 16.6392 20.9975 16.6811C20.8409 16.7229 20.675 16.7127 20.5247 16.652L20.1451 16.5002"
        stroke="#AD3928"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
