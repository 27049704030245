import React from 'react'
import { PropsType } from '../Check/Check'

export default function Cross({ size }: PropsType) {
  return (
    <svg
      width={size ? size : '24'}
      height={size ? size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.11133 8.11133L15.8895 15.8895"
        stroke="#AD3928"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8887 8.11133L8.1105 15.8895"
        stroke="#AD3928"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
