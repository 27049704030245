import React, { memo, useCallback, useEffect, useState } from 'react'
import Input from '../../../../../../components/Input/Input'
import Accept from '../../../Nodes/Components/Accept/Accept'
import { TargetHeaderPropsType } from '../Header'
import { changeTemplateName } from '../../../../../../store/slices/scriptEditorSlice'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../store/hooks/hook'
import { scriptEditorSelector } from '../../../../../../store/selectors/scriptEditor'

function ScriptTitle({ title }: TargetHeaderPropsType) {
  const dispatch = useAppDispatch()
  const { currentCampaign } = useAppSelector(scriptEditorSelector)
  const [isEdit, setIsEdit] = useState(false)
  const [currTitle, setCurrTitle] = useState('')
  const [newTitle, setNewTitle] = useState('')

  useEffect(() => {
    setCurrTitle(title || '')
  }, [title])

  const handleTitle = useCallback(() => setIsEdit(true), [])
  const onAcceptEditing = useCallback(() => {
    if (newTitle) {
      setCurrTitle(newTitle)
      dispatch(changeTemplateName(newTitle))
    }
    setIsEdit(false)
  }, [newTitle, dispatch])
  const onCancelEditing = useCallback(() => setIsEdit(false), [])
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.currentTarget.value)
  }, [])
  return (
    <>
      {isEdit && currentCampaign === undefined && (
        <>
          <Input span="" placeholder={currTitle} onChange={onChange} />
          <div className="accept-wrapper">
            <Accept onAccept={onAcceptEditing} onCancel={onCancelEditing} />
          </div>
        </>
      )}
      {!isEdit && currentCampaign === undefined && (
        <div
          className="s-header-left-title s-header-left-title-click"
          onClick={handleTitle}
        >
          {currTitle}
        </div>
      )}
      {currentCampaign !== undefined && (
        <div className="s-header-left-title">{title}</div>
      )}
    </>
  )
}

export default memo(ScriptTitle)
