import React from 'react'

export default function Grey() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5658 9.49224C12.891 8.9177 11.0719 8.92184 9.39973 9.50401C9.27818 9.5474 9.17003 9.62169 9.08592 9.71957C9.0018 9.81745 8.94463 9.93555 8.92002 10.0622L8.36706 12.8286C8.3426 12.9509 8.28801 13.0652 8.20823 13.1612C8.12845 13.2571 8.02599 13.3316 7.91015 13.3779L3.4746 15.1522C3.32432 15.2128 3.1584 15.223 3.00183 15.1812C2.84525 15.1394 2.70652 15.0478 2.60652 14.9202C1.93674 14.0542 1.6056 12.9737 1.6751 11.8811C1.7446 10.7884 2.20999 9.75859 2.98411 8.98439C5.3752 6.5933 8.61821 5.25 11.9997 5.25C15.3812 5.25 18.6242 6.5933 21.0153 8.98439C21.7895 9.75859 22.2548 10.7884 22.3243 11.8811C22.3939 12.9737 22.0627 14.0542 21.3929 14.9202C21.2929 15.0478 21.1542 15.1394 20.9976 15.1812C20.8411 15.223 20.6751 15.2128 20.5248 15.1522L16.0854 13.3764C15.9706 13.3305 15.8689 13.2568 15.7894 13.1621C15.7099 13.0674 15.6551 12.9544 15.6298 12.8334L15.0476 10.0454C15.022 9.9194 14.9641 9.8022 14.8796 9.70525C14.7952 9.60829 14.687 9.5349 14.5658 9.49224Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 18.75H20.25"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
