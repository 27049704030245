import { IconType } from '../Navigation/types'

export default function UserControllerIcon({ isActive }: IconType) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.87305 26.9988C5.1025 24.8708 6.87031 23.1037 8.9989 21.8752C11.1275 20.6467 13.5419 20 15.9996 20C18.4572 20 20.8716 20.6468 23.0002 21.8754C25.1288 23.1039 26.8965 24.871 28.1259 26.9991"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
