import './Collapse.sass'
import React from 'react'

interface PropsType {
  isNode?: boolean
}

export default function CollapseIn({ isNode }: PropsType) {
  return (
    <svg
      className={isNode ? '' : 'icon-collapse'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 9H15V4.5"
        stroke={isNode ? '#A1BDCA' : '#556268'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 15H9V19.5"
        stroke={isNode ? '#A1BDCA' : '#556268'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 19.5V15H19.5"
        stroke={isNode ? '#A1BDCA' : '#556268'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.5V9H4.5"
        stroke={isNode ? '#A1BDCA' : '#556268'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
