import React, { useCallback, useState } from 'react'
import './AddAction.sass'
import Arrow from '../../../../../../components/Icons/ScriptDev/Sider/Arrow'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../store/hooks/hook'
import { changeActions } from '../../../../../../store/slices/scriptEditorSlice'
import { scriptEditorSelector } from '../../../../../../store/selectors/scriptEditor'

interface PropsType {
  els: object[] | []
  step: number
  actions: boolean[]
}

const actionsName = ['Кнопка', 'Кнопки', 'Поле ввода']

export default function AddAction({ els, step, actions }: PropsType) {
  const dispatch = useAppDispatch()
  const { mode } = useAppSelector(scriptEditorSelector)
  const [active, setActive] = useState(false)
  const handleOpen = useCallback(
    () => mode === 'editing' && setActive(!active),
    [active, mode]
  )

  const handleAddAction = useCallback(
    (action) => {
      if (mode === 'editing') {
        setActive(false)
        switch (action) {
          case actionsName[0]:
            dispatch(
              changeActions({
                els: [
                  ...els,
                  {
                    elementName: 'Button',
                    buttonHeader: 'Далее',
                    buttonNextStep: null,
                  },
                ],
                step,
              })
            )
            break
          case actionsName[1]:
            dispatch(
              changeActions({
                els: [
                  ...els,
                  {
                    elementName: 'RadioButton',
                    radioButtonHeader: '',
                    variableValue: null,
                    radioButtonOptions: {},
                  },
                ],
                step,
              })
            )
            break
          case actionsName[2]:
            dispatch(
              changeActions({
                els: [
                  ...els,
                  {
                    elementName: 'InputField',
                    inputFieldHeader: null,
                    inputFieldMask: '[A-Za-z\\s]',
                    variableValue: '$сlient_reason_optional',
                  },
                ],
                step,
              })
            )
            break
        }
      }
    },
    [els, step, dispatch, mode]
  )

  return (
    <>
      <div
        className={`node-add-action${active ? ' node-add-action-active' : ''}`}
        onClick={handleOpen}
      >
        <div className="node-add-action-title">Добавить действие</div>
        <div className="node-add-action-arrow">
          <Arrow isActive={active} />
        </div>
      </div>
      {active && (
        <div className="node-add-action-content">
          {actionsName.map((name, i) => (
            <div
              className={`node-add-action-content-el${
                actions[i] ? ' node-add-action-content-el-disabled' : ''
              }`}
              key={name}
              title={`${
                actions[i]
                  ? 'Действие уже есть'
                  : 'Добавить ' + name.toLowerCase()
              }`}
              onClick={actions[i] ? undefined : () => handleAddAction(name)}
            >
              {name === 'Кнопки' ? 'Переключатель' : name}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
