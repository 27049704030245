import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CampaignResponse,
  ScriptResponse,
  Step,
  Button as ButtonType,
  RadioButton as RadioButtonType,
  Position,
  CompletionCodeInfo,
  CompletionCode,
} from '../../api/data-contracts'
import {
  createScript,
  deleteScript,
  getAllCampaigns,
  getAllScripts,
  getCompletionCodeList,
  linkScriptTemplateToCampaign,
} from '../requests/script'
import { Edge } from 'react-flow-renderer/dist/esm/types'

interface CompletionCodes extends CompletionCodeInfo, CompletionCode {
  codeId?: number
}

export interface ScriptEditorStore {
  scripts: ScriptResponse[]
  campaigns: CampaignResponse[]
  completionCodeList: CompletionCodes[]
  currentCampaign?: CampaignResponse['campaignId']
  currentTemplate?: ScriptResponse['id']
  mode: 'editing' | 'view'
  campaignIsOpen: boolean
}

const initialState: ScriptEditorStore = {
  scripts: [],
  campaigns: [],
  completionCodeList: [],
  mode: 'view',
  campaignIsOpen: false,
}

interface StepDefaultPayload {
  step: number
}

export interface UpdateStepTextPayload extends StepDefaultPayload {
  text: string
  oldText?: string
  firstText?: boolean
  type?: string
  customElement?: 'input' | 'radio'
}
interface UpdateStepPositionPayload extends StepDefaultPayload {
  position: Position
}
interface ChangeIsStartPayload extends StepDefaultPayload {
  type: 'add' | 'delete' | 'update'
}
interface UpdateStepCompletionCodePayload extends StepDefaultPayload {
  completionCode: CompletionCodes
}

export const scriptEditorSlice = createSlice({
  name: 'scriptEditor',
  initialState,
  reducers: {
    dropScriptEditorSlice: () => initialState,
    changeMode(
      state: ScriptEditorStore,
      { payload }: PayloadAction<ScriptEditorStore['mode'] | undefined>
    ) {
      payload
        ? (state.mode = payload)
        : state.mode === 'view'
        ? (state.mode = 'editing')
        : (state.mode = 'view')
    },
    setCurrCampaign(
      state: ScriptEditorStore,
      { payload }: PayloadAction<ScriptEditorStore['currentCampaign']>
    ) {
      state.currentCampaign = payload
    },
    setCurrTemplate(
      state: ScriptEditorStore,
      { payload }: PayloadAction<ScriptEditorStore['currentTemplate']>
    ) {
      state.currentTemplate = payload
    },
    handlingCampaignModal(
      state: ScriptEditorStore,
      { payload }: PayloadAction<boolean | undefined>
    ) {
      payload === undefined
        ? (state.campaignIsOpen = !state.campaignIsOpen)
        : (state.campaignIsOpen = payload)
    },
    addInterimScripts(state: ScriptEditorStore) {
      const defaultValues = {
        stepAmount: 0,
        steps: [],
        isTemplate: false,
        templateName: null,
      }
      const interimScripts = state.campaigns.reduce(
        (prev: any, curr) =>
          state.scripts.find(
            (script) => script.campaignName === curr.campaignName
          ) === undefined
            ? [
                ...prev,
                {
                  id: 'interim_' + curr.campaignId,
                  campaignId: curr.campaignId,
                  campaignName: curr.campaignName,
                  ...defaultValues,
                },
              ]
            : prev,
        []
      )
      state.scripts = [...state.scripts, ...interimScripts]
    },
    changeTemplateName(
      state: ScriptEditorStore,
      { payload }: PayloadAction<string>
    ) {
      const script = state.scripts.find(
        (script) => script.id === state.currentTemplate
      )
      script && (script.templateName = payload)
    },
    // ↓↓↓ Обновление шагов из редактора ↓↓↓
    addStep(state: ScriptEditorStore, { payload }: PayloadAction<Step>) {
      const script = state.scripts.find((script) =>
        state.currentTemplate
          ? script.id === state.currentTemplate
          : script.campaignId === state.currentCampaign
      )
      script!.steps = script?.steps ? script?.steps?.concat(payload) : [payload]
      script!.stepAmount = script!.steps?.length
    },
    removeStep(
      state: ScriptEditorStore,
      { payload }: PayloadAction<Step['orderNumber']>
    ) {
      const script = state.scripts.find((script) =>
        state.currentTemplate
          ? script.id === state.currentTemplate
          : script.campaignId === state.currentCampaign
      )
      script!.steps = state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )
        ?.steps?.filter((step) => step.orderNumber !== payload)
      script!.stepAmount = script!.steps?.length
    },
    updateStepTitle(
      state: ScriptEditorStore,
      { payload }: PayloadAction<UpdateStepTextPayload>
    ) {
      state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find((step) => step.orderNumber === payload.step)!.title = {
        name: payload.text,
        editable: true,
      }
    },
    updateStepText(
      state: ScriptEditorStore,
      { payload }: PayloadAction<UpdateStepTextPayload>
    ) {
      if (payload.firstText)
        state.scripts
          .find((script) =>
            state.currentTemplate
              ? script.id === state.currentTemplate
              : script.campaignId === state.currentCampaign
          )!
          .steps!.find((step) => step.orderNumber === payload.step)!.text =
          payload.text
      else {
        let elements = state.scripts
          .find((script) =>
            state.currentTemplate
              ? script.id === state.currentTemplate
              : script.campaignId === state.currentCampaign
          )!
          .steps!.find((step) => step.orderNumber === payload.step)!
          .elements as any
        elements &&
          (elements = elements.map((el: any) => {
            if (
              el.elementName === 'RadioButton' &&
              (payload.customElement === 'radio' || !payload.customElement)
            ) {
              return Object.assign(el, {
                radioButtonHeader: payload.text,
              })
            } else if (
              el.elementName === 'InputField' &&
              (payload.customElement === 'input' || !payload.customElement)
            ) {
              return Object.assign(el, {
                inputFieldHeader: payload.text,
              })
            } else {
              return el
            }
          }))
      }
    },
    updateStepPosition(
      state: ScriptEditorStore,
      { payload }: PayloadAction<UpdateStepPositionPayload>
    ) {
      state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find((step) => step.orderNumber === payload.step)!.position =
        payload.position
    },
    updateCompletionCode(
      state: ScriptEditorStore,
      { payload }: PayloadAction<UpdateStepCompletionCodePayload>
    ) {
      state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find(
          (step) => step.orderNumber === payload.step
        )!.completionCode = {
        id: payload.completionCode.codeId,
        name: payload.completionCode.codeName,
      }
    },
    changeActions(state: ScriptEditorStore, { payload }: PayloadAction<any>) {
      const step = state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find(({ orderNumber }) => orderNumber === payload.step)
      step!.elements = payload.els
    },
    // ↑↑↑ Обновление шагов из редактора ↑↑↑

    // ↓↓↓ Обновление кнопок ↓↓↓
    updateBtnTitle(
      state: ScriptEditorStore,
      { payload }: PayloadAction<UpdateStepTextPayload>
    ) {
      const step = state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find((step) => step.orderNumber === payload.step)
      switch (step?.type) {
        case 'talking':
          const elemsTalking =
            step.elements && (step.elements[0] as RadioButtonType)
          elemsTalking?.radioButtonOptions &&
            (elemsTalking.radioButtonOptions = Object.fromEntries(
              Object.entries(elemsTalking.radioButtonOptions).map((el) =>
                el[0] === payload.oldText ? [payload.text, el[1]] : el
              )
            ))
          break
        case 'custom':
          let elements = state.scripts
            .find((script) =>
              state.currentTemplate
                ? script.id === state.currentTemplate
                : script.campaignId === state.currentCampaign
            )!
            .steps!.find((step) => step.orderNumber === payload.step)!.elements
          elements &&
            (elements = elements.map((element: any) =>
              element.elementName === payload.type
                ? payload.type === 'RadioButton'
                  ? (element.radioButtonOptions = Object.fromEntries(
                      Object.entries(element.radioButtonOptions).map((el) =>
                        el[0] === payload.oldText ? [payload.text, el[1]] : el
                      )
                    ))
                  : Object.assign(element, {
                      buttonHeader: payload.text,
                    })
                : element
            ))
          break
        case 'finish':
        case 'callback':
          const elem = step.elements && (step.elements[0] as ButtonType)
          elem && (elem.buttonHeader = payload.text)
      }
    },
    addBtn(
      state: ScriptEditorStore,
      { payload }: PayloadAction<UpdateStepTextPayload>
    ) {
      const step = state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find((step) => step.orderNumber === payload.step)
      switch (step?.type) {
        case 'talking':
          const elemsTalking =
            step.elements && (step.elements[0] as RadioButtonType)
          if (elemsTalking?.radioButtonOptions) {
            elemsTalking.radioButtonOptions = Object.fromEntries([
              ...Object.entries(elemsTalking.radioButtonOptions),
              [payload.text, 0],
            ])
          }
          break
        case 'custom':
          let elements = state.scripts
            .find((script) =>
              state.currentTemplate
                ? script.id === state.currentTemplate
                : script.campaignId === state.currentCampaign
            )!
            .steps!.find((step) => step.orderNumber === payload.step)!.elements
          elements &&
            (elements = elements.map((element: any) =>
              element.elementName === 'RadioButton'
                ? (element.radioButtonOptions = Object.fromEntries([
                    ...Object.entries(element.radioButtonOptions),
                    [payload.text, 0],
                  ]))
                : element
            ))
          break
      }
    },
    removeBtn(
      state: ScriptEditorStore,
      { payload }: PayloadAction<UpdateStepTextPayload>
    ) {
      const step = state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find((step) => step.orderNumber === payload.step)
      switch (step?.type) {
        case 'talking':
          const elemsTalking =
            step.elements && (step.elements[0] as RadioButtonType)
          if (elemsTalking?.radioButtonOptions) {
            delete elemsTalking.radioButtonOptions[payload.text]
          }
          break
        case 'custom':
          let elements = state.scripts
            .find((script) =>
              state.currentTemplate
                ? script.id === state.currentTemplate
                : script.campaignId === state.currentCampaign
            )!
            .steps!.find((step) => step.orderNumber === payload.step)!.elements
          elements &&
            (elements = elements.map((element: any) =>
              element.elementName === 'RadioButton'
                ? delete element.radioButtonOptions[payload.text]
                : element
            ))
          break
      }
    },
    // ↑↑↑ Обновление кнопок ↑↑↑

    // ↓↓↓ Обновление параметра isStart ↓↓↓
    changeIsStart(
      state: ScriptEditorStore,
      { payload }: PayloadAction<ChangeIsStartPayload>
    ) {
      let steps = state.scripts.find((script) =>
        state.currentTemplate
          ? script.id === state.currentTemplate
          : script.campaignId === state.currentCampaign
      )!.steps
      const step = steps!.find((step) => step.orderNumber === payload.step)
      // if (payload.type === 'add') {
      //     step!.isStart = true
      // } else if (payload.type === 'delete') {
      //     step!.isStart = false
      // } else {
      steps = steps!.map((step) =>
        step.isStart ? Object.assign(step, { isStart: false }) : step
      )
      step!.isStart = true
      // }
    },
    // ↑↑↑ Обновление параметра isStart ↑↑↑

    // ↓↓↓ Обновление стрелок между шагами из редактора ↓↓↓
    updateEdge(
      state: ScriptEditorStore,
      { payload }: PayloadAction<Edge<any>>
    ) {
      const step = state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find((step) => step.orderNumber === +payload.source)
      const sourceHandle = payload.sourceHandle ? +payload.sourceHandle : 1
      switch (step?.type) {
        case 'talking':
          const elemsTalking =
            step.elements && (step.elements[0] as RadioButtonType)
          const ops = elemsTalking?.radioButtonOptions as any
          const key = Object.keys(ops)[sourceHandle - 1]
          ops[key] = +payload.target
          break
        case 'custom':
          let elemsCustom = step.elements
          elemsCustom &&
            (elemsCustom = elemsCustom.map((element: any) =>
              element.elementName === 'Button'
                ? Object.assign(element, {
                    buttonNextStep: +payload.target,
                  })
                : element.elementName === 'RadioButton'
                ? (element.radioButtonOptions[
                    Object.keys(element.radioButtonOptions)[sourceHandle - 1]
                  ] = +payload.target)
                : element
            ))
          break
      }
    },
    removeEdge(state: ScriptEditorStore, { payload }: PayloadAction<string>) {
      const arr = payload
        .split('-')
        .map((el, i) => (i === 0 ? +el.substr(1) : +el))
      const step = state.scripts
        .find((script) =>
          state.currentTemplate
            ? script.id === state.currentTemplate
            : script.campaignId === state.currentCampaign
        )!
        .steps!.find((step) => step.orderNumber === arr[0])
      switch (step?.type) {
        case 'talking':
          const elemsTalking =
            step.elements && (step.elements[0] as RadioButtonType)
          const ops = elemsTalking?.radioButtonOptions as any
          const key = Object.keys(ops)[arr[2] - 1]
          ops[key] = null
          break
        case 'custom':
          let elemsCustom = step.elements
          elemsCustom &&
            (elemsCustom = elemsCustom.map((element: any) =>
              element.elementName === 'Button'
                ? Object.assign(element, {
                    buttonNextStep: 0,
                  })
                : element.elementName === 'RadioButton'
                ? (element.radioButtonOptions[
                    Object.keys(element.radioButtonOptions)[arr[2] - 1]
                  ] = 0)
                : element
            ))
          break
      }
    },
    // ↑↑↑ Обновление стрелок между шагами из редактора ↑↑↑
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllScripts.fulfilled,
      (
        state: ScriptEditorStore,
        { payload }: PayloadAction<ScriptResponse[]>
      ) => {
        state.scripts = payload
      }
    )
    builder.addCase(
      createScript.fulfilled,
      (
        state: ScriptEditorStore,
        { payload }: PayloadAction<ScriptResponse | undefined>
      ) => {
        if (payload)
          state.scripts = [
            ...state.scripts.filter(
              (script) =>
                script.id !== 'interim_' + payload?.campaignId &&
                script.id !== 'new_' + payload.id
            ),
            payload,
          ]
      }
    )
    builder.addCase(
      getAllCampaigns.fulfilled,
      (
        state: ScriptEditorStore,
        { payload }: PayloadAction<CampaignResponse[]>
      ) => {
        state.campaigns = payload
      }
    )
    builder.addCase(
      getCompletionCodeList.fulfilled,
      (
        state: ScriptEditorStore,
        { payload }: PayloadAction<CompletionCodes[]>
      ) => {
        state.completionCodeList = payload
      }
    )
    builder.addCase(
      deleteScript.fulfilled,
      (
        state: ScriptEditorStore,
        { payload }: PayloadAction<string | undefined>
      ) => {
        if (payload)
          state.scripts = state.scripts.filter(({ id }) => id !== payload)
      }
    )
    builder.addCase(
      linkScriptTemplateToCampaign.fulfilled,
      (
        state: ScriptEditorStore,
        { payload }: PayloadAction<ScriptResponse | undefined>
      ) => {
        if (payload) {
          const scripts = state.scripts.filter(
            ({ campaignId, id }) =>
              campaignId !== payload.campaignId &&
              id !== `interim_${payload.campaignId}`
          )
          state.scripts = [...scripts, payload]
        }
      }
    )
  },
})

export const {
  changeMode,
  setCurrCampaign,
  setCurrTemplate,
  addStep,
  removeStep,
  updateStepTitle,
  updateStepText,
  updateStepPosition,
  updateCompletionCode,
  updateEdge,
  removeEdge,
  updateBtnTitle,
  removeBtn,
  addBtn,
  changeActions,
  dropScriptEditorSlice,
  changeIsStart,
  handlingCampaignModal,
  addInterimScripts,
  changeTemplateName,
} = scriptEditorSlice.actions
export const scriptEditorReducer = scriptEditorSlice.reducer
