import { ChatMessage } from '../../../../api/data-contracts'
import {
  GroupChatStoreItem,
  OneToOneChatStoreItem,
} from '../../../../store/slices/chatSlice'

/**
 * Функция для получения времени последнего сообщения, чтобы отобразить в списке чатов
 * @param timeStamp - время последнего сообщения
 */
export const getTimeFromLastMessage = (timeStamp: Date | undefined) => {
  const today = new Date()

  if (timeStamp && timeStamp?.getDate() < today.getDate()) {
    const day = timeStamp.getDate().toString().padStart(2, '0')
    const month = (timeStamp.getMonth() + 1).toString().padStart(2, '0')
    const year = timeStamp.getFullYear().toString()
    const hours = timeStamp.getHours().toString().padStart(2, '0')
    const minutes = timeStamp.getMinutes().toString().padStart(2, '0')

    return `${day}.${month}.${year} ${hours}:${minutes}`
  }

  return timeStamp
    ? timeStamp.getHours() +
        ':' +
        (timeStamp.getMinutes() < 10
          ? '0' + timeStamp.getMinutes()
          : timeStamp.getMinutes())
    : undefined
}
/**
 * Функция для получения отметки времени последнего сообщения
 * @param messages - массив сообщений в чате
 */
export const getLastMessageTimestamp = (messages?: ChatMessage[]) => {
  return messages?.length
    ? new Date(messages![messages!.length - 1]?.timestamp!)
    : undefined
}

/**
 * Функция для последнего сообщения
 * @param messages - массив сообщений в чате
 */
export const getLastMessage = (messages?: ChatMessage[]) => {
  return messages?.length ? messages[messages?.length - 1].content : undefined
}

/**
 * Тут делается перегрузка функций, чтобы всегда знать какой тип возвращается
 */
export function sortedMessages(
  chats: OneToOneChatStoreItem[]
): OneToOneChatStoreItem[]
export function sortedMessages(
  chats: GroupChatStoreItem[]
): GroupChatStoreItem[]

/**
 * Функция сортировки чатов
 * @param chats - чаты
 */
export function sortedMessages(
  chats: OneToOneChatStoreItem[] | GroupChatStoreItem[]
): OneToOneChatStoreItem[] | GroupChatStoreItem[] {
  return [...(chats ?? [])]?.sort((a, b) => {
    const lastMessageA = getLastMessageTimestamp(a?.messages)
    const lastMessageB = getLastMessageTimestamp(b?.messages)
    const dateA = new Date(lastMessageA ?? 1)
    const dateB = new Date(lastMessageB ?? 1)

    if (dateA < dateB) {
      return 1
    } else if (dateA > dateB) {
      return -1
    } else {
      return 0
    }
  })
}
