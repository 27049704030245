import { useSubscription } from 'react-stomp-hooks'
import {
  AgentJobStatisticsNotification,
  AgentStatisticsNotification,
} from '../../api/data-contracts'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import {
  updateJobStatistic,
  updateStatistic,
} from '../../store/slices/dashboardSlice'

export default function useJobStatisticSubscriptions(
  authorizationStatus: boolean
) {
  const dispatch = useAppDispatch()
  const { agentId } = useAppSelector(userSelector)
  const jobStatisticNotifyUrl = authorizationStatus
    ? `/agent/${agentId}/agent-job-statistics-notify`
    : []
  const statisticNotify = authorizationStatus
    ? `/agent/${agentId}/agent-statistics-notify`
    : []
  useSubscription(jobStatisticNotifyUrl, (mes) => {
    const message: AgentJobStatisticsNotification = JSON.parse(mes.body)
    dispatch(updateJobStatistic(message))
  })
  useSubscription(statisticNotify, (mes) => {
    const message: AgentStatisticsNotification = JSON.parse(mes.body)
    dispatch(updateStatistic(message))
  })
}
