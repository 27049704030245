import React, { useMemo } from 'react'
import './NotificationModal.sass'
import Notification from '../Notification/Notification'
import { Notification as NotificationType } from '../../../store/slices/notificationSlice'
import { notificationSelector } from '../../../store/selectors/notification'
import Close from '../../Icons/Close/Close'
import { setVisibilityNotifications } from '../../../store/slices/headerSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { userSelector } from '../../../store/selectors/user'
import { headerSelector } from '../../../store/selectors/header'

interface NotificationModalProps {
  view: 'login' | 'main'
}

export default function NotificationModal({ view }: NotificationModalProps) {
  const { notificationList, requestNotifications } =
    useAppSelector(notificationSelector)
  const { loginNotifications, role } = useAppSelector(userSelector)
  const { stateChangeReasons } = useAppSelector(headerSelector)
  const dispatch = useAppDispatch()

  const notificationArr = useMemo(
    () => (view === 'login' ? loginNotifications : notificationList || []),
    [view, loginNotifications, notificationList]
  )

  const requestsArr: NotificationType[] = useMemo(() => {
    if (view === 'login') return []
    return requestNotifications.map((el) => {
      const reason = stateChangeReasons.find(
        (reason) => reason.reasonName === el.reasonName
      )?.reasonLocalizedName

      let icon:
        | 'info'
        | 'break'
        | 'warning'
        | 'message'
        | 'listen-talk'
        | 'clock'
        | 'sign-out' = 'info'
      let text = 'Запрос'

      switch (el.type) {
        case 'BREAK_REQUEST':
          icon = 'clock'
          text = `Запрос на перерыв (${reason})`
          break
        case 'LOGOUT_REQUEST':
          icon = 'sign-out'
          text = 'Запрос на выход из системы'
          break
        case 'HELP_REQUEST':
          icon = 'info'
          text = 'Запрос на помощь'
      }

      return {
        dataObj: el,
        id: el.id!,
        icon: icon,
        title: `Агент ${el.agentName || ''}`,
        text: text,
      }
    })
  }, [view, requestNotifications, stateChangeReasons])

  const onClose = React.useCallback(
    () => dispatch(setVisibilityNotifications(false)),
    [dispatch]
  )

  const allNotifications: Array<JSX.Element> = useMemo(() => {
    return requestsArr
      .map((el) => (
        <Notification key={`rq-${el.id}`} obj={el} isLogin={view === 'login'} />
      ))
      .concat(
        notificationArr.map((el) => (
          <Notification
            key={`notif-${el.id}`}
            obj={el}
            isLogin={view === 'login'}
          />
        ))
      )
  }, [notificationArr, requestsArr, view])

  return (
    <>
      {allNotifications?.length ? (
        <div
          className={`notification-container notification-container-${view}${
            role && role[0] === 'ROLE_SUPERVISOR'
              ? ' notification-container-super'
              : ''
          }`}
        >
          {view === 'main' ? (
            <div className="notification-container-close" onClick={onClose}>
              <Close dark size={16} />
            </div>
          ) : null}
          {allNotifications.slice(0, 3)}
        </div>
      ) : null}
    </>
  )
}
