interface DeleteTopDashProps {
  onDelete: () => void
}
export default function DeleteTopDash({ onDelete }: DeleteTopDashProps) {
  return (
    <svg
      onClick={onDelete}
      className="delete-top-dash"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 3.5L3.5 12.5"
        stroke="#C15747"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 12.5L3.5 3.5"
        stroke="#C15747"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
