import './RadioInput.sass'
import React, { ChangeEventHandler } from 'react'

interface RadioInputProps {
  name: string
  value: string | number
  className: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  isChecked?: boolean
}

export default function RadioInput({
  name,
  value,
  className,
  onChange,
  isChecked,
}: RadioInputProps) {
  return isChecked !== undefined ? (
    <input
      className={`ad-radio ${className}`}
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      checked={isChecked}
    />
  ) : (
    <input
      className={`ad-radio ${className}`}
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
    />
  )
}
