import './Table.sass'
import React, { useCallback, useEffect, useState } from 'react'
import { AgentStatusForSupervisor } from '../../../../../api/data-contracts'
import { helper } from './helpers'
import { TagType } from '../../../../../store/slices/tagSlice'
import Modal from '../../../../../components/Modal/Modal'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import { useApi } from '../../../../../store/hooks/request'
import { forceLogoutAgent, startCall } from '../../../../../store/requests/user'
import BodyRows from './BodyRows/BodyRows'
import HeaderRow from './HeaderRow/HeaderRow'
import { userSelector } from '../../../../../store/selectors/user'
import {
  setAgentProcessing,
  setCallAgentModalOpen,
  setLogoutAgentModalOpen,
} from '../../../../../store/slices/userSlice'

interface TablePropsType {
  header: string[]
  body?: AgentStatusForSupervisor[]
}
export interface body extends AgentStatusForSupervisor {
  tagText?: string
  tagColor?: TagType['color']
  icon?: JSX.Element
}

const left = { left: '-64px' }

export default function Table({ header, body }: TablePropsType) {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { callAgentModalOpen, logoutAgentModalOpen, agentProcessing } =
    useAppSelector(userSelector)
  const [bodyRows, setBodyRows] = useState<body[]>([])

  const [mode, setMode] = useState<'LISTEN_ONLY' | 'LISTEN_TALK'>('LISTEN_ONLY')

  const onCall = useCallback(
    (id, name) => {
      dispatch(setAgentProcessing({ id, name }))
      dispatch(setCallAgentModalOpen(true))
    },
    [dispatch]
  )

  const onLogout = useCallback(
    (id, name) => {
      dispatch(setAgentProcessing({ id, name }))
      dispatch(setLogoutAgentModalOpen(true))
    },
    [dispatch]
  )

  const onCancel = useCallback(() => {
    dispatch(setCallAgentModalOpen(false))
    dispatch(setLogoutAgentModalOpen(false))
    dispatch(setAgentProcessing(null))
  }, [dispatch])

  const [headerArrows, setHeaderArrows] = useState(header.map(() => 0))
  const handleChangeMode = useCallback((e) => setMode(e.target.value), [])

  const onConnect = useCallback(() => {
    dispatch(
      startCall({
        api,
        callMode: mode,
        agentId: agentProcessing?.id || '',
        name: agentProcessing?.name,
      })
    )
    onCancel()
    // eslint-disable-next-line
  }, [agentProcessing, onCancel, dispatch, mode])

  const handleLogout = useCallback(() => {
    dispatch(forceLogoutAgent({ api, agentId: agentProcessing?.id || '' }))
    onCancel()
    // eslint-disable-next-line
  }, [dispatch, agentProcessing])

  useEffect(() => {
    setBodyRows(
      (body &&
        body.map((el) =>
          el.state
            ? {
                ...el,
                ...helper(el.state),
              }
            : el
        )) ||
        []
    )
  }, [body])

  const sorter = useCallback(
    (key: 'agentName' | 'campaignName' | 'tagText', i) => {
      if (headerArrows[i] === 0 || headerArrows[i] === 1)
        setHeaderArrows(headerArrows.map((el, j) => (j === i ? el + 1 : 0)))
      else setHeaderArrows(headerArrows.map((el, j) => (j === i ? 0 : 0)))
      return headerArrows[i] !== 2
        ? [...bodyRows].sort((a, b) =>
            headerArrows[i] === 1
              ? (a[key] || '') < (b[key] || '')
                ? 1
                : (b[key] || '') < (a[key] || '')
                ? -1
                : 0
              : (a[key] || '') > (b[key] || '')
              ? 1
              : (b[key] || '') > (a[key] || '')
              ? -1
              : 0
          )
        : (body &&
            body.map((el) =>
              el.state
                ? {
                    ...el,
                    ...helper(el.state),
                  }
                : el
            )) ||
            []
    },
    [bodyRows, headerArrows, body]
  )

  const handleSort = useCallback(
    (i) => {
      switch (i) {
        case 0:
          return setBodyRows(sorter('agentName', i))
        case 1:
          return setBodyRows(sorter('campaignName', i))
        case 2:
          return setBodyRows(sorter('tagText', i))
      }
    },
    [sorter]
  )

  return (
    <div className="ad-table">
      {bodyRows.length ? (
        <>
          <div className="ad-table-header">
            {header.map((name, i) => (
              <HeaderRow
                key={name}
                name={name}
                i={i}
                header={header}
                handleSort={handleSort}
                headerArrows={headerArrows}
              />
            ))}
          </div>
          <div className="ad-table-body">
            <BodyRows bodyRows={bodyRows} onLogout={onLogout} onCall={onCall} />
          </div>
        </>
      ) : (
        <div className="ad-table-error">
          Операторов нет или что-то пошло не так...
        </div>
      )}
      {callAgentModalOpen && (
        <Modal
          title="Подключение к звонку"
          body={`Хотите присоединиться к звонку с ${
            agentProcessing?.name || 'агентом'
          }?`}
          variant="radio"
          type="join_call"
          onCancel={onCancel}
          onOk={onConnect}
          style={left}
          onRadioChange={handleChangeMode}
          curValue={mode}
        />
      )}
      {logoutAgentModalOpen && (
        <Modal
          title="Выход агента"
          body={`Вы подтверждаете форс логаут агента${
            agentProcessing?.name ? ': ' + agentProcessing?.name : ''
          }?`}
          onCancel={onCancel}
          onOk={handleLogout}
          style={left}
        />
      )}
    </div>
  )
}
