import React from 'react'

export default function Total() {
  return (
    <React.Fragment>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="16" fill="#EBF5F7" />
        <path
          d="M38 19H18C16.8954 19 16 19.8954 16 21V35C16 36.1046 16.8954 37 18 37H38C39.1046 37 40 36.1046 40 35V21C40 19.8954 39.1046 19 38 19Z"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 27H23.1012C23.3316 27 23.5549 27.0796 23.7333 27.2254C23.9117 27.3711 24.0342 27.5741 24.08 27.7999C24.2646 28.7033 24.7555 29.5153 25.4698 30.0984C26.1841 30.6815 27.0779 31 28 31C28.9221 31 29.8159 30.6815 30.5302 30.0984C31.2445 29.5153 31.7354 28.7033 31.92 27.7999C31.9658 27.5741 32.0883 27.3711 32.2667 27.2254C32.4451 27.0796 32.6684 27 32.8988 27H40"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 23H40"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </React.Fragment>
  )
}
