/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FailedAuthorizationNotification,
  MonoResponseEntityVoid,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class FailedAuthorization<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags authorization-controller
   * @name FailedAuthorizationUsingPost
   * @summary Получение от фронта уведомлений о провальной попытке входа в систему
   * @request POST:/failed-authorization
   */
  failedAuthorizationUsingPost = (
    notification: FailedAuthorizationNotification,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/failed-authorization`,
      method: 'POST',
      body: notification,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
