import React from 'react'
import { IconType } from './types'

export default function Script({ isActive }: IconType) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.375 20.25C7.82475 20.25 9 19.0747 9 17.625C9 16.1753 7.82475 15 6.375 15C4.92525 15 3.75 16.1753 3.75 17.625C3.75 19.0747 4.92525 20.25 6.375 20.25Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.625 8.99976C19.0747 8.99976 20.25 7.8245 20.25 6.37476C20.25 4.92501 19.0747 3.74976 17.625 3.74976C16.1753 3.74976 15 4.92501 15 6.37476C15 7.8245 16.1753 8.99976 17.625 8.99976Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 8.99976C7.82475 8.99976 9 7.8245 9 6.37476C9 4.92501 7.82475 3.74976 6.375 3.74976C4.92525 3.74976 3.75 4.92501 3.75 6.37476C3.75 7.8245 4.92525 8.99976 6.375 8.99976Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 15V14.25C6.37501 13.6533 6.61206 13.081 7.03401 12.6591C7.45596 12.2371 8.02824 12.0001 8.62495 12.0001L15.375 11.9999C15.9718 11.9999 16.544 11.7629 16.966 11.3409C17.3879 10.919 17.625 10.3467 17.625 9.75002V9"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 9V15"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
