import CloseIcon from '../../../../components/Icons/Chat/CloseIcon/CloseIcon'

interface PlannedCallType {
  handleClose: () => void
}

export default function PlannedCall({ handleClose }: PlannedCallType) {
  return (
    <div className="planned-call">
      <span>Запланированный вызов</span>
      <div>id задачи: </div>
      <div className="planned-call-sesion">
        <span>Создал callback: </span>
        <span>Session ID: </span>
        <span>Callback назначен на: </span>
      </div>
      <div className="planned-call-client">
        <span>ФИО</span>
        <span>ID клиента</span>
      </div>
      <div className="planned-call-contact-info">
        <span>Тип контактных данных: </span>
        <span>Контактные данные: </span>
      </div>
      <div className="planned-call-campaign-title">Название кампании: </div>
      <div className="planned-call-dates">
        <span>DAte </span>
        <span>Следующая попытка: </span>
        <span>ID запланированного вызова: </span>
      </div>
      <div className="planned-call-loyal-clients">
        <span>Лояльные клиенты (более 3 лет) </span>
        <span>ID списка контактов: </span>
      </div>
      <CloseIcon stroke="30" onClose={handleClose} />
    </div>
  )
}
