import React, { memo } from 'react'
import './Default.sass'
import { Handle, Position } from 'react-flow-renderer'
import Header from '../Components/Header/Header'
import { NodePropsType } from './Talking'

interface InputOutputNodePropsType extends NodePropsType {
  type: 'talking' | 'custom' | 'callback'
  children?: any
}

function Default({ data, type, children }: InputOutputNodePropsType) {
  return (
    <div className={`ad-node-default ad-node-default-${type}`}>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable
        className="ad-handle ad-handle-left"
        style={{ top: 30 }}
      />
      <Header type={type} title={data.title} step={data.orderNumber || 0} />
      {children}
    </div>
  )
}

export default memo(Default)
