import './SubSider.sass'
import React, { useState, useCallback } from 'react'
import Search from '../../../components/Search/Search'
import Sliders from '../../../components/Icons/ScriptDev/Sider/Sliders'
import Campaign from '../../../components/Icons/ScriptDev/Sider/Campaign'
import Template from '../../../components/Icons/ScriptDev/Sider/Template'
import Arrow from '../../../components/Icons/ScriptDev/Sider/Arrow'
import { useAppSelector } from '../../../store/hooks/hook'
import { HandlePropsType } from '../Script'
import { CampaignResponse } from '../../../api/data-contracts'
import { scriptEditorSelector } from '../../../store/selectors/scriptEditor'
import SearchIcon from '../../../components/Icons/Chat/SearchIcon/SearchIcon'

interface SubSiderPropsType extends HandlePropsType {
  campaignId: CampaignResponse['campaignId']
  templateId?: string
  handleTemplate: (id: string | undefined) => void
}

export default function SubSider({
  handleCampaign,
  handleTemplate,
  campaignId,
  templateId,
}: SubSiderPropsType) {
  const { scripts } = useAppSelector(scriptEditorSelector)
  const [campaignDD, setCampaignDD] = useState(false)
  const [scriptDD, setScriptDD] = useState(false)
  const [search, setSearch] = useState('')

  const scriptDDHandle = useCallback(() => setScriptDD(!scriptDD), [scriptDD])
  const handleSearchChange = useCallback(
    (e) => {
      setSearch(e.target.value)
      if (
        scripts.find(({ campaignName }) =>
          campaignName?.toLowerCase().includes(e.target.value)
        )
      )
        setCampaignDD(true)
      if (
        scripts.find(({ templateName }) =>
          templateName?.toLowerCase().includes(e.target.value)
        )
      )
        setScriptDD(true)
    },
    [scripts]
  )
  const campaignDDHandle = useCallback(
    () => setCampaignDD(!campaignDD),
    [campaignDD]
  )

  return (
    <div className="script-sider-content">
      <div className="script-sider-content-search">
        <div className="search-wrapper">
          <Search
            icon={<SearchIcon />}
            autoFocus={false}
            onChange={handleSearchChange}
          />
        </div>
        <div className="icon-wrapper">
          <Sliders />
        </div>
      </div>
      <div className="script-sider-content-filling">
        <div className="sider-dd" onClick={campaignDDHandle}>
          <div className="sider-dd-wrapper">
            <div className="sider-dd-logo">
              <Campaign isActive={campaignDD} />
            </div>
            <div className="sider-dd-text">Кампании</div>
          </div>
          <div
            className={
              'sider-dd-arrow' + (campaignDD ? ' sider-dd-arrow-down' : '')
            }
          >
            <Arrow isActive={campaignDD} />
          </div>
        </div>
        {campaignDD && (
          <div className="sider-dd-container">
            {scripts
              .filter((script) => !script.isTemplate)
              .filter(({ campaignName }) =>
                campaignName?.toLowerCase().includes(search.toLowerCase())
              )
              .map((el) => {
                const hasScript = !el.id?.includes('interim')

                return (
                  <div
                    key={el.id}
                    className={
                      'dd-el' +
                      (campaignId === el.campaignId ? ' dd-el-active' : '')
                    }
                    onClick={() => handleCampaign(el.campaignId)}
                  >
                    <div className="dd-el-left">
                      <div className="dd-el-left-text">{el.campaignName}</div>
                      <div
                        className={`dd-el-left-status ${
                          hasScript
                            ? 'dd-el-left-status-green'
                            : 'dd-el-left-status-red'
                        }`}
                      >
                        {hasScript ? 'Скрипт привязан' : 'Нет скрипта'}
                      </div>
                    </div>
                    {!hasScript && <div className="dd-el-right" />}
                  </div>
                )
              })}
          </div>
        )}
        <div className="sider-dd" onClick={scriptDDHandle}>
          <div className="sider-dd-wrapper">
            <div className="sider-dd-logo">
              <Template isActive={scriptDD} />
            </div>
            <div className="sider-dd-text">Скрипты</div>
          </div>
          <div
            className={
              'sider-dd-arrow' + (scriptDD ? ' sider-dd-arrow-down' : '')
            }
          >
            <Arrow isActive={scriptDD} />
          </div>
        </div>
        {scriptDD && (
          <div className="sider-dd-container">
            {scripts
              .filter(({ isTemplate }) => isTemplate)
              .map(({ id, templateName }) => (
                <div
                  key={id}
                  className={
                    'dd-el' + (templateId === id ? ' dd-el-active' : '')
                  }
                  onClick={() => handleTemplate(id)}
                >
                  <div className="dd-el-left">
                    <div className="dd-el-left-text dd-el-left-text-script">
                      {templateName || 'Шаблон'}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}
