import React from 'react'

export default function End() {
  return (
    <svg
      className="call-controls-modes-item-end"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35806 2.04085L8.86963 7.90118L6.79124 11.0769C6.60218 11.3634 6.4904 11.694 6.46679 12.0365C6.4431 12.3801 6.50894 12.7239 6.65788 13.0345L7.55954 12.602L6.66124 13.0414C7.79692 15.3632 9.67896 17.2367 12.0059 18.3618L12.013 18.3652C12.3254 18.5132 12.671 18.5774 13.0157 18.5513C13.3594 18.5253 13.6904 18.4106 13.9764 18.2183L13.9791 18.2164L17.104 16.1327L22.9587 18.6418C22.8027 19.845 22.2152 20.9508 21.3044 21.7527C20.3913 22.5566 19.2165 23 18 23.0001M8.46342 12.1741L10.5431 8.99641C10.7237 8.72046 10.8333 8.40412 10.862 8.07558C10.8908 7.74703 10.8378 7.41647 10.7079 7.11334L8.19786 1.25655L8.19712 1.25482C8.02776 0.857158 7.73392 0.525174 7.35973 0.308769C6.985 0.09205 6.54998 0.00298521 6.12023 0.0549919L6.11361 0.055816C4.42431 0.271701 2.87163 1.09593 1.74628 2.37418C0.620932 3.65243 8.95262e-05 5.29704 0 7.00007C0 11.774 1.89642 16.3524 5.27208 19.728C8.64773 23.1037 13.2261 25.0001 18 25.0001C19.703 25 21.3477 24.3792 22.6259 23.2538C23.9042 22.1285 24.7284 20.5758 24.9443 18.8865L24.9451 18.8799C24.9971 18.4501 24.9081 18.0151 24.6913 17.6404C24.4749 17.2661 24.1428 16.9722 23.745 16.8029L23.7436 16.8023L17.8919 14.2944C17.5861 14.1633 17.2524 14.1106 16.9211 14.1411C16.5898 14.1715 16.2713 14.2841 15.9945 14.4687L12.865 16.5555C10.9504 15.6266 9.40101 14.0844 8.46342 12.1741ZM6.35806 2.04085C5.15486 2.19685 4.04928 2.78496 3.24742 3.69576C2.44355 4.60885 2.00006 5.78365 2 7.00018C2 11.2436 3.68571 15.3132 6.68629 18.3138C9.68686 21.3144 13.7566 23.0001 18 23.0001"
        fill="#AD3928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0059 4.18262V8.18262H21.0059V4.18262H17.0059ZM16.0059 2.18262C15.4536 2.18262 15.0059 2.63033 15.0059 3.18262V9.18262C15.0059 9.7349 15.4536 10.1826 16.0059 10.1826H22.0059C22.5581 10.1826 23.0059 9.7349 23.0059 9.18262V3.18262C23.0059 2.63033 22.5581 2.18262 22.0059 2.18262H16.0059Z"
        fill="#AD3928"
      />
    </svg>
  )
}
