import { useCallback, useEffect, useMemo, useState } from 'react'
import { Layout, Responsive, WidthProvider } from 'react-grid-layout'
import InfoItem from '../../../../components/InfoItem/InfoItem'
import { useApi } from '../../../../store/hooks/request'
import EditorPanel from '../DashbordEditor/EditorPanel/EditorPanel'
import { TopDashboardItems, initialLayouts, allTopDash } from './types'
import './TopDashEditor.sass'
import DeleteTopDash from '../../../../components/Icons/Dashboard/DeleteTopDash'

const ResponsiveGridLayout = WidthProvider(Responsive)
interface TopDashEditorProps {
  layout?: ReactGridLayout.Layouts
  id?: number
}
export default function TopDashEditor({ id, layout }: TopDashEditorProps) {
  const [currentLayout, setLayouts] = useState<ReactGridLayout.Layouts>()
  const { api } = useApi()
  const topDashItems = useMemo(() => {
    return currentLayout?.lg?.map((layout) => layout.i) as TopDashboardItems[]
  }, [currentLayout])
  const onAddItem = useCallback((addProps: Layout) => {
    setLayouts((prev) => {
      return { ...prev, lg: prev!.lg.concat(addProps) }
    })
  }, [])
  const deletDash = useCallback((name: TopDashboardItems) => {
    setLayouts((prev) => {
      return { ...prev, lg: prev!.lg.filter((el) => el.i !== name) }
    })
  }, [])
  const layoutSave = useCallback(() => {
    const saveLayout = async () => {
      await api.setWidgetStateByGroupIdUsingPost({
        agentGroupId: id,
        widgetStateList: currentLayout?.lg,
      })
    }
    saveLayout()
    // eslint-disable-next-line
  }, [id, currentLayout])
  const editorItems = useMemo(() => {
    return topDashItems
      ? allTopDash.filter((item) => !topDashItems.includes(item))
      : undefined
  }, [topDashItems])
  useEffect(() => {
    layout ? setLayouts(layout) : setLayouts(initialLayouts)
  }, [layout])

  return (
    <div className="top-dash-editor">
      <EditorPanel
        type="topdash"
        onSave={layoutSave}
        onAddItem={onAddItem}
        items={editorItems}
      />
      <ResponsiveGridLayout
        layouts={currentLayout}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 20, md: 10, sm: 8, xs: 6, xxs: 4 }}
        rowHeight={40}
        compactType="horizontal"
        isDraggable
        isResizable
        isBounded
        onLayoutChange={(_, allLayouts) => {
          setLayouts(allLayouts)
        }}
      >
        {topDashItems?.map((item) => {
          switch (item) {
            case TopDashboardItems.WorkTime:
              return (
                <div className="widget" key={TopDashboardItems.WorkTime}>
                  <DeleteTopDash onDelete={() => deletDash(item)} />
                  <InfoItem data={'--:--'} title="Время работы" active />
                </div>
              )
            case TopDashboardItems.StartShift:
              return (
                <div className="widget" key={TopDashboardItems.StartShift}>
                  <DeleteTopDash onDelete={() => deletDash(item)} />

                  <InfoItem data={'--:--'} title="Начало смены" active />
                </div>
              )
            case TopDashboardItems.AverageCallTime:
              return (
                <div className="widget" key={TopDashboardItems.AverageCallTime}>
                  <DeleteTopDash onDelete={() => deletDash(item)} />

                  <InfoItem
                    data={'--:--'}
                    title="Среднее время вызова"
                    active
                  />
                </div>
              )
            case TopDashboardItems.Calls:
              return (
                <div className="widget" key={TopDashboardItems.Calls}>
                  <DeleteTopDash onDelete={() => deletDash(item)} />

                  <InfoItem data={'--:--'} title="Вызовы" active />
                </div>
              )
            case TopDashboardItems.RPC:
              return (
                <div className="widget" key={TopDashboardItems.RPC}>
                  <DeleteTopDash onDelete={() => deletDash(item)} />

                  <InfoItem data={'--:--'} title="RPC" active />
                </div>
              )
            case TopDashboardItems.Deals:
              return (
                <div className="widget" key={TopDashboardItems.Deals}>
                  <DeleteTopDash onDelete={() => deletDash(item)} />

                  <InfoItem data={'--:--'} title="Сделки" active />
                </div>
              )
            case TopDashboardItems.Sucess:
              return (
                <div className="widget" key={TopDashboardItems.Sucess}>
                  <DeleteTopDash onDelete={() => deletDash(item)} />

                  <InfoItem data={'--:--'} title="Успешных" active />
                </div>
              )
            default:
              return <></>
          }
        })}
      </ResponsiveGridLayout>
    </div>
  )
}
