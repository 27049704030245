import React from 'react'
import { IconType } from './types'

export default function Chat({ isActive }: IconType) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25895 16.5936C3.14076 14.7086 2.74916 12.4802 3.15768 10.3269C3.56621 8.17365 4.74675 6.24356 6.47764 4.89909C8.20853 3.55462 10.3707 2.88826 12.5581 3.02514C14.7455 3.16202 16.8078 4.09273 18.3575 5.6425C19.9073 7.19226 20.838 9.25448 20.9749 11.4419C21.1118 13.6293 20.4455 15.7915 19.101 17.5224C17.7565 19.2533 15.8264 20.4338 13.6732 20.8424C11.5199 21.2509 9.29149 20.8593 7.40649 19.7411L7.40651 19.741L4.29808 20.6292C4.16947 20.6659 4.03338 20.6676 3.90391 20.634C3.77443 20.6005 3.65628 20.5329 3.5617 20.4384C3.46713 20.3438 3.39956 20.2256 3.36601 20.0961C3.33246 19.9667 3.33415 19.8306 3.37089 19.702L4.25901 16.5935L4.25895 16.5936Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0625 12C13.0625 12.5868 12.5868 13.0625 12 13.0625C11.4132 13.0625 10.9375 12.5868 10.9375 12C10.9375 11.4132 11.4132 10.9375 12 10.9375C12.5868 10.9375 13.0625 11.4132 13.0625 12Z"
        fill={isActive ? '#ad3928' : '#545454'}
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="0.125"
      />
      <path
        d="M7.5 13.125C8.12132 13.125 8.625 12.6213 8.625 12C8.625 11.3787 8.12132 10.875 7.5 10.875C6.87868 10.875 6.375 11.3787 6.375 12C6.375 12.6213 6.87868 13.125 7.5 13.125Z"
        fill={isActive ? '#ad3928' : '#545454'}
      />
      <path
        d="M16.5 13.125C17.1213 13.125 17.625 12.6213 17.625 12C17.625 11.3787 17.1213 10.875 16.5 10.875C15.8787 10.875 15.375 11.3787 15.375 12C15.375 12.6213 15.8787 13.125 16.5 13.125Z"
        fill={isActive ? '#ad3928' : '#545454'}
      />
    </svg>
  )
}
