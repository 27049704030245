import '../Step/Step.sass'
import React, { ChangeEventHandler, useCallback, useMemo } from 'react'
import { useAppSelector } from '../../../store/hooks/hook'
import { scriptSelector } from '../../../store/selectors/script'
import { ContactNumber } from '../../../api/data-contracts'
import Dropdown from '../Elements/Dropdown/Dropdown'
import RectangleButton from '../Elements/Buttons/RectangleButton/RectangleButton'
import {
  cancelDial,
  previewDial,
  manualCallConnect,
  releaseLine,
} from '../../../store/requests/script'
import { useAppDispatch } from '../../../store/hooks/hook'
import { useApi } from '../../../store/hooks/request'
import {
  chooseContactNumber,
  setCallbackWindowOpen,
  setManualStarted,
} from '../../../store/slices/scriptSlice'

export default function Preview() {
  const { customerDetails, callInfo, chosenContactNumber, manualStarted } =
    useAppSelector(scriptSelector)
  const dispatch = useAppDispatch()
  const { api } = useApi()

  const isManual = callInfo?.contactTypeField === 'Manual'
  const agentId = customerDetails?.agentId
  const numbers = callInfo?.contactNumbersField
  const addresses = customerDetails?.address
  const name =
    customerDetails?.lastName + ' ' + customerDetails?.firstName || ''

  const setChosenNumber = useCallback(
    (number: ContactNumber | null) => {
      dispatch(chooseContactNumber(number))
    },
    [dispatch]
  )

  const handleChooseNumber: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const number = numbers?.find((num) => num.numberField === e.target.value)
    setChosenNumber(number || null)
  }

  const ddOptions = useMemo(
    () =>
      numbers?.map((number) => ({
        text:
          (number.defaultField
            ? number.numberField + ' (default)'
            : number.numberField) || '',
        value: number.numberField || '',
        key: number.numberField || '',
      })) || [],
    [numbers]
  )

  const handleCancelDial = useCallback(() => {
    if (agentId) dispatch(cancelDial({ api, agentId }))
    // eslint-disable-next-line
  }, [agentId, dispatch])

  const handleReleaseLine = useCallback(() => {
    if (agentId) dispatch(releaseLine({ api, agentId }))
    // eslint-disable-next-line
  }, [agentId, dispatch])

  const handlePreviewDial = useCallback(() => {
    if (agentId && chosenContactNumber)
      dispatch(
        previewDial({
          api,
          agentId,
          contactNumber: chosenContactNumber,
        })
      )
    if (isManual) dispatch(setManualStarted(true))
    // eslint-disable-next-line
  }, [agentId, chosenContactNumber, dispatch, isManual])

  const handleOpenCallbackWindow = useCallback(() => {
    dispatch(setCallbackWindowOpen(true))
  }, [dispatch])

  const connectManual = useCallback(() => {
    if (agentId) dispatch(manualCallConnect({ api, agentId }))
    // eslint-disable-next-line
  }, [agentId, dispatch])

  const headerMessage = isManual ? 'Ручной режим' : 'Превью'
  const manualMessage = manualStarted
    ? 'Подтвердите соединение с клиентом'
    : 'Наберите номер с помощью внешнего устройства или приложения'

  const previewButtons = chosenContactNumber && (
    <div className="step-bottom-buttons">
      <RectangleButton
        label="Начать вызов"
        onClick={handlePreviewDial}
        isBlue
      />
      <RectangleButton
        label="Отмена вызова"
        onClick={handleCancelDial}
        isBlue
        negative
        className="step-bottom-buttons-lower"
      />
      <RectangleButton
        label="Запланировать callback"
        onClick={handleOpenCallbackWindow}
        isBlue
        className="step-bottom-buttons-lower"
      />
    </div>
  )

  const manualStartedButtons = (
    <div className="step-bottom-buttons">
      <RectangleButton
        label="Соединение установлено"
        onClick={connectManual}
        isBlue
      />
      <RectangleButton
        label="Вызов завершён"
        onClick={handleReleaseLine}
        isBlue
        negative
        className="step-bottom-buttons-lower"
      />
    </div>
  )

  return (
    <div className="step">
      <div className="step-header">
        <div className="step-title-name">{headerMessage}</div>
      </div>
      {isManual && (
        <div className="step-summary">
          <div className="step-summary-header">{manualMessage}</div>
        </div>
      )}
      <div className="step-summary">
        <div className="step-summary-header">Клиент:</div>
        <div className="step-summary-data">{name}</div>
      </div>
      <div className="step-summary">
        {addresses && (
          <>
            <div className="step-summary-header">Адрес:</div>
            {addresses.map((address) => {
              const { country, ...lines } = address
              const linesValues = Object.values(lines)
              const linesBlock = linesValues
                .filter((item) => !!item)
                .map((item) => (
                  <div className="step-summary-data" key={item}>
                    {item}
                  </div>
                ))
              return (
                <div key={JSON.stringify(linesValues)}>
                  <div className="step-summary-data">{country}</div>
                  {linesBlock}
                </div>
              )
            })}
          </>
        )}
        {customerDetails?.emails?.map((email) => (
          <div className="step-summary-data" key={email}>
            {email}
          </div>
        ))}
      </div>
      {ddOptions && (
        <div className="step-phone-choose">
          <div className="step-phone-choose-header">Номер телефона:</div>
          {manualStarted ? (
            <div className="step-phone-choose-header">
              {chosenContactNumber?.numberField}
            </div>
          ) : (
            <Dropdown
              options={ddOptions}
              value={chosenContactNumber?.numberField || ''}
              onChange={handleChooseNumber}
            />
          )}
        </div>
      )}
      {manualStarted ? manualStartedButtons : previewButtons}
    </div>
  )
}
