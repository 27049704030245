import React from 'react'
import './Eye.sass'

interface EyeProps {
  size?: number
  onClick: React.MouseEventHandler<SVGSVGElement>
}

export default function Eye(props: EyeProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="eye"
      onClick={props.onClick}
    >
      <path
        d="M16 6.99902C6 6.99902 2 16 2 16C2 16 6 24.999 16 24.999C26 24.999 30 16 30 16C30 16 26 6.99902 16 6.99902Z"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
