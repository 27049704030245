import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'
import SettingsContent from './SettingsContent/SettingsContent'
import './Settings.sass'

export default function Settings() {
  return (
    <ViewWithScript
      ContentComponent={<SettingsContent />}
      SubHeaderComponent={
        <div className="settings-subheader">
          <div className="settings-subheader-title">Рабочая область</div>
        </div>
      }
    ></ViewWithScript>
  )
}
