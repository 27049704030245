import React from 'react'
import { IconType } from './IconType'

export default function MicrophoneSlash({ isActive }: IconType) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5667 28.3252C26.0327 29.7194 24.1432 30.6627 22.107 31.0509C20.0707 31.4391 17.9668 31.2573 16.0273 30.5254C14.0879 29.7935 12.3884 28.54 11.1163 26.9032C9.84428 25.2665 9.04914 23.3101 8.81866 21.25"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2035 24.6255C23.0543 25.6728 21.5549 26.2524 20 26.2502H20C18.3424 26.2502 16.7527 25.5917 15.5806 24.4196C14.4085 23.2475 13.75 21.6578 13.75 20.0002V13.1255"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 31.25V36.25"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 6.25L32.5 33.75"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6811 6.7163C15.2409 5.80907 16.0235 5.0602 16.9545 4.54097C17.8856 4.02175 18.934 3.74945 20 3.75H20C20.8208 3.75 21.6335 3.91166 22.3918 4.22575C23.1501 4.53984 23.8391 5.00021 24.4194 5.58058C24.9998 6.16094 25.4602 6.84994 25.7743 7.60822C26.0883 8.3665 26.25 9.17923 26.25 9.99999V19.442"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1813 21.25C31.0738 22.2253 30.8384 23.1823 30.481 24.0961"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
