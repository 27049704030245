import './ProfileModal.sass'
import React, { MouseEventHandler } from 'react'
import { headerSelector } from '../../store/selectors/header'
import { userSelector } from '../../store/selectors/user'
import { setVisibilityProfileModal } from '../../store/slices/headerSlice'
import Avatar from '../Avatar/Avatar'
import Tag from '../Tag/Tag'
import Button from './Button/Button'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { TagType } from '../../store/slices/tagSlice'

export default function ProfileModal() {
  const { isCollapsed, agentStateNotify, pendingRequests } =
    useAppSelector(headerSelector)
  const { agentName, agentOrg, role, supervisorName, orgName } =
    useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  const competence: TagType[] = [
    { id: 1, color: 'grey', text: 'Интернет' },
    { id: 2, color: 'grey', text: 'Ростелеком' },
    { id: 3, color: 'grey', text: 'Тех. поддержка' },
    { id: 4, color: 'grey', text: 'Финансы' },
    { id: 5, color: 'grey', text: 'Безопасность' },
  ]

  const handleClose: MouseEventHandler<HTMLDivElement> = React.useCallback(
    (e) => {
      const target = e.target as Element
      if (target.classList.contains('profile-modal-container')) {
        dispatch(setVisibilityProfileModal(false))
      }
    },
    [dispatch]
  )

  const org = agentOrg || orgName

  return (
    <div className="profile-modal-container" onClick={handleClose}>
      <div className={'profile-modal' + (isCollapsed ? '-sider' : '')}>
        {role && role[0] !== 'ROLE_ADMIN' ? (
          <>
            <div className="profile-modal-header profile-modal-div">
              <div className="profile-modal-header-avatar">
                <Avatar />
              </div>
              <div className="profile-modal-header-text">
                <div className="profile-modal-header-title">
                  {agentName || supervisorName}
                </div>
                <div className="profile-modal-header-body">
                  {(org ? org + ' • ' : '') +
                    (role && role[0] === 'ROLE_USER'
                      ? 'Оператор'
                      : 'Супервайзер')}
                </div>
              </div>
            </div>
            <div className="profile-modal-content profile-modal-div">
              <div className="profile-modal-content-title">Компетенция</div>
              <div className="profile-modal-content-competence">
                {competence.map((el) => (
                  <Tag type="profile" obj={el} key={el.id} />
                ))}
              </div>
            </div>
          </>
        ) : null}
        <div className="profile-modal-footer">
          <div className="profile-modal-button">
            <Button
              disabled={
                !!pendingRequests.logout_rq ||
                (role && role[0] !== 'ROLE_USER'
                  ? false
                  : agentStateNotify !== 'NotReady' &&
                    agentStateNotify !== null)
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
