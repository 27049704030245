import React from 'react'
import IconPropsType from '../ScriptDev/Sider/IconPropsType'

export default function PhoneOutgoing({ className }: IconPropsType) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M7.22471 9.75133C7.87302 11.0767 8.94739 12.1462 10.2758 12.7885C10.3729 12.8346 10.4804 12.8545 10.5877 12.8464C10.6949 12.8383 10.7982 12.8024 10.8874 12.7422L12.8433 11.438C12.9298 11.3803 13.0293 11.3451 13.1328 11.3356C13.2364 11.3261 13.3407 11.3426 13.4362 11.3835L17.0954 12.9517C17.2197 13.0045 17.3234 13.0963 17.3911 13.2132C17.4587 13.3301 17.4865 13.4658 17.4702 13.5999C17.3546 14.5049 16.913 15.3367 16.2282 15.9396C15.5434 16.5425 14.6624 16.8751 13.75 16.8751C10.9321 16.8751 8.22956 15.7557 6.23699 13.7631C4.24442 11.7706 3.125 9.06806 3.125 6.25014C3.12505 5.33777 3.45765 4.45671 4.06053 3.77192C4.66341 3.08712 5.49523 2.64556 6.40023 2.5299C6.53431 2.51368 6.67002 2.54146 6.78693 2.60908C6.90384 2.67669 6.99561 2.78046 7.04841 2.90476L8.61798 6.5671C8.65858 6.66183 8.67512 6.76513 8.66614 6.8678C8.65715 6.97047 8.62291 7.06932 8.56647 7.15556L7.26676 9.14147C7.2076 9.23081 7.17263 9.33396 7.16526 9.44085C7.15789 9.54775 7.17837 9.65472 7.22471 9.75133V9.75133Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 3.75H16.25V6.875"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 7.5L16.25 3.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
