import { createAsyncThunk } from '@reduxjs/toolkit'
import ApiDefaultType from '../types'
import {
  CampaignResponse,
  ScriptRequest,
  ScriptResponse,
  ContactNumber,
  CompletionCodePom,
  CompletionCode,
  CreateCallbackRequest,
  WrapupPomUniversalResponse,
} from '../../api/data-contracts'
import { setCampaign, setCurrentCampaign } from '../slices/campaignSlice'
import { setTags } from '../slices/tagSlice'
import { addNotification } from '../slices/notificationSlice'
import { setVisibilityNotifications } from '../slices/headerSlice'
import {
  setCallbackDests,
  setCallbackTypes,
  chooseCallbackType,
  chooseCallbackDest,
  setReleaseLineAnswer,
  setCallToEnd,
} from '../slices/scriptSlice'
import { setCurrTemplate } from '../slices/scriptEditorSlice'

interface GetScriptByNameType extends ApiDefaultType {
  campaignName: string
}

interface ScriptByCampaignIdType extends ApiDefaultType {
  campaignId: number
}

interface ScriptByIdType extends ApiDefaultType {
  id: string
}

interface AddUpdateScriptType extends ApiDefaultType {
  scriptRequest: ScriptRequest
}

interface DefaultAgentRequestType extends ApiDefaultType {
  agentId: string
}

interface PreviewDialType extends DefaultAgentRequestType {
  contactNumber: ContactNumber
}

interface EndCallType extends DefaultAgentRequestType {
  completionCode: CompletionCodePom
}

interface GetDestsType extends ApiDefaultType {
  type: string
}

interface CreateCallbackType extends ApiDefaultType, CreateCallbackRequest {}

export const updateScript = createAsyncThunk(
  'script/updateScript',
  async ({ scriptRequest, api }: AddUpdateScriptType, { dispatch }) => {
    const { status } = await api.updateScriptUsingPut(scriptRequest, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(getAllScripts({ api }));
    dispatch(setVisibilityNotifications(true))
    return dispatch(
      addNotification({
        id: Date.now(),
        icon: status === 200 ? 'info' : 'warning',
        title: 'Сохранение',
        text:
          status === 200 ? 'Скрипт успешно обновлен' : 'Что-то пошло не так',
      })
    )
  }
)

export const createScript = createAsyncThunk(
  'script/createScript',
  async ({ scriptRequest, api }: AddUpdateScriptType, { dispatch }) => {
    const { status, data } = await api.addScriptUsingPost(scriptRequest, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(setVisibilityNotifications(true))
    if (status === 200) {
      dispatch(getAllScripts({ api }))
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title: 'Сохранение',
          text: 'Скрипт успешно создан',
        })
      )
      return data
    } else {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Сохранение',
          text: 'Что-то пошло не так',
        })
      )
      return undefined
    }
  }
)

export const getAllScripts = createAsyncThunk(
  'script/getAllScripts',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.getAllScriptsUsingGet()
    return data as ScriptResponse[]
  }
)

export const getCallbackTypes = createAsyncThunk(
  'script/getCallbackTypes',
  async ({ api, agentId }: DefaultAgentRequestType, { dispatch }) => {
    dispatch(chooseCallbackType(null))
    dispatch(chooseCallbackDest(null))
    dispatch(setCallbackTypes([]))
    dispatch(setCallbackDests(null))
    await api.getCallbackTypesUsingGet({ name: agentId })
  }
)

export const getCallbackDests = createAsyncThunk(
  'script/getCallbackTypes',
  async ({ api, type }: GetDestsType, { dispatch }) => {
    dispatch(setCallbackDests(null))
    dispatch(chooseCallbackDest(null))
    await api.getCallbackDestsForTypeUsingGet({ type })
  }
)

export const createCallback = createAsyncThunk(
  'script/createCallback',
  async ({ api, ...requestData }: CreateCallbackType) => {
    await api.createCallbackUsingPost({ ...requestData })
  }
)

export const getScriptById = createAsyncThunk(
  'script/getScriptById',
  async ({ campaignId, api }: ScriptByCampaignIdType) => {
    const response = await api.getScriptByIdUsingGet(String(campaignId))
    return response.json()
  }
)

export const getScriptByCampaignName = createAsyncThunk(
  'script/getScriptByCampaignName',
  async ({ campaignName, api }: GetScriptByNameType) => {
    const response = await api.getScriptByCampaignNameUsingGet(campaignName)
    return response.json()
  }
)

export const getScriptByCampaignId = createAsyncThunk(
  'script/getScriptByCampaignId',
  async ({ campaignId, api }: ScriptByCampaignIdType) => {
    const response = await api.getScriptByCampaignIdUsingGet(campaignId)
    return response.json()
  }
)

export const deleteScript = createAsyncThunk(
  'script/deleteScript',
  async ({ id, api }: ScriptByIdType, { dispatch }) => {
    const { status } = await api.deleteScriptUsingDelete(id)
    dispatch(setVisibilityNotifications(true))
    if (status === 204) {
        dispatch(getAllScripts({ api }));
        dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title: 'Удаление',
          text: 'Скрипт успешно удален',
        })
      )
      dispatch(setCurrTemplate(undefined))
      return id
    } else {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Удаление',
          text: 'Что-то пошло не так',
        })
      )
      return undefined
    }
  }
)

export const linkScriptTemplateToCampaign = createAsyncThunk(
  'script/linkScript',
  async ({ scriptRequest, api }: AddUpdateScriptType, { dispatch }) => {
    const { status, data } = await api.linkScriptTemplateToCampaignUsingPost(
      scriptRequest,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    if (status === 200) {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title: 'Привязка',
          text: 'Скрипт успешно привязан',
        })
      )
      return data
    } else {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Привязка',
          text: 'Не удалось привязать скрипт',
        })
      )
      return undefined
    }
  }
)

export const onJobAttachedGetScript = createAsyncThunk(
  'script/onJobAttachedGetScript',
  async ({ campaignName, api }: GetScriptByNameType, { dispatch }) => {
    const campaignResponse = await api.findCampaignByNameUsingGet(campaignName)
    const campaign = campaignResponse.data
    dispatch(
      setCampaign({
        campaignId: campaign.campaignId || -1,
        campaignName: campaign.campaignName || '',
      })
    )
    dispatch(setCurrentCampaign(campaign))
    dispatch(setTags(campaign.tags!))
    if (campaign.campaignId) {
      const scriptResponse = await api.getScriptByCampaignIdUsingGet(
        campaign.campaignId
      )
      return scriptResponse.data
    }
    return null
  }
)

export const cancelDial = createAsyncThunk(
  'script/cancelDial',
  async ({ agentId, api }: DefaultAgentRequestType) => {
    const { data } = await api.previewCancelUsingPost(
      { agentId },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return data
  }
)

export const previewDial = createAsyncThunk(
  'script/previewDial',
  async ({ agentId, api, contactNumber }: PreviewDialType) => {
    const { data } = await api.previewDialUsingPost({
      agentId,
      contactNumber,
    })
    return data
  }
)

export const getAllCampaigns = createAsyncThunk(
  'script/getAllCampaigns',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllCampaignsUsingGet()
    return data as CampaignResponse[]
  }
)

export const releaseLine = createAsyncThunk(
  'script/releaseLine',
  async ({ api, agentId }: DefaultAgentRequestType, { dispatch }) => {
    const { data }: { data: WrapupPomUniversalResponse } =
      await api.releaseLineUsingPost(
        { agentId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
    if (data.message === 'SUCCESS') {
      dispatch(setCallToEnd())
      dispatch(setReleaseLineAnswer(data))
    }
  }
)

export const endCall = createAsyncThunk(
  'script/goToNextCall',
  async ({ api, agentId, completionCode }: EndCallType) => {
    const { data } = await api.wrapupContactUsingPost({
      agentId,
      completionCode,
    })
    return data
  }
)

export const extendWrapup = createAsyncThunk(
  'script/extendWrapup',
  async ({ api, agentId }: DefaultAgentRequestType) => {
    const { data } = await api.extendWrapupUsingPost(
      {
        agentId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return data
  }
)

export const getCompletionCodeList = createAsyncThunk(
  'script/getCompletionCodes',
  async ({ api, campaignId }: ScriptByCampaignIdType) => {
    const { data } = await api.getAllByCampaignIdUsingGet(campaignId)
    return data as CompletionCode[]
  }
)

export const manualCallConnect = createAsyncThunk(
  'script/manualCallConnect',
  async ({ api, agentId }: DefaultAgentRequestType) => {
    await api.manualCallConnectUsingPost(
      { agentId },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
)

export const addToDNC = createAsyncThunk(
  'script/addToDNC',
  async ({ api }: ApiDefaultType) => {
    await api.addToDncUsingGet({})
  }
)
