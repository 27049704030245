import './InfoItem.sass'
import React, { memo } from 'react'

interface InfoItemProps {
  inverted?: boolean
  active?: boolean
  data: string | number
  title: string | number
  small?: boolean
  dataIcon?: JSX.Element | undefined
}

function InfoItem({
  small,
  title,
  data,
  active,
  inverted,
  dataIcon,
}: InfoItemProps) {
  return (
    <div className={'info-item' + (inverted ? ' inverted' : '')}>
      <div
        className={
          'info-item-data' +
          (active ? '-active' : '') +
          (inverted ? ' inverted' : '') +
          (small ? ' small' : '')
        }
      >
        {dataIcon}
        <b>{data}</b>
      </div>
      <div
        className={
          'info-item-title' +
          (inverted ? ' inverted' : '') +
          (small ? ' small' : '')
        }
      >
        {title}
      </div>
    </div>
  )
}

export default memo(InfoItem)
