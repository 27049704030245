import { useCallback, useState } from 'react'
import { AgentGroup, CampaignResponse } from '../../../../../api/data-contracts'
import DashboadIcon from '../../../../../components/Icons/Dashboard/Dasboard'
import Arrow from '../../../../../components/Icons/ScriptDev/Sider/Arrow'
import Stack from '../../../../../components/Icons/Stack/Stack'
import ChatCircleMini from '../../../../../components/Icons/ChatCircleMini/ChatCircleMini'
import { selectedItem } from '../../SettingsContent'
import './DropDown.sass'

interface DropDownProps {
  type: 'dash' | 'topdash' | 'tags'
  groups?: AgentGroup[]
  campaigns?: CampaignResponse[]
  hadler: (params: selectedItem) => void
  selectedItem?: selectedItem
}
export default function DropDown({
  type,
  groups,
  campaigns,
  hadler,
  selectedItem,
}: DropDownProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpenDD = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  let icon = <div />
  let headerName = ''

  switch (type) {
    case 'dash':
      icon = <DashboadIcon isOpen={isOpen} />
      headerName = 'Настройки дашборда'
      break
    case 'topdash':
      icon = <Stack isOpen={isOpen} />
      headerName = 'Настройки виджетов'
      break
    case 'tags':
      icon = <ChatCircleMini isOpen={isOpen} />
      headerName = 'Настройки чатов'
      break
  }

  const agentGroupsList = groups?.map((company) => {
    return (
      <div
        className={
          selectedItem?.id === company.agentGroupId &&
          selectedItem?.type === type
            ? 'settings-side-drop-down-content-item-selected'
            : 'settings-side-drop-down-content-item'
        }
        key={company.agentGroupId}
        onClick={() =>
          hadler({
            id: company.agentGroupId,
            type: type,
          })
        }
      >
        {company.agentGroupName}
      </div>
    )
  })

  const campaignsList = campaigns?.map((campaign) => {
    return (
      <div
        className={
          selectedItem?.id === campaign.campaignId &&
          selectedItem?.type === type
            ? 'settings-side-drop-down-content-item-selected'
            : 'settings-side-drop-down-content-item'
        }
        key={campaign.campaignId}
        onClick={() =>
          hadler({
            id: campaign.campaignId,
            type: type,
          })
        }
      >
        {campaign.campaignName}
      </div>
    )
  })

  return (
    <div className="settings-side-drop-down">
      <div className="settings-side-drop-down-header" onClick={handleOpenDD}>
        {icon}
        <div className="dd-header-name">{headerName}</div>
        <div className="dd-header-arrow">
          <Arrow isActive={isOpen} />
        </div>
      </div>
      <div
        className={
          isOpen
            ? 'settings-side-drop-down-content-open'
            : 'settings-side-drop-down-content'
        }
      >
        {(type === 'dash' || type === 'topdash') && agentGroupsList}
        {type === 'tags' && (
          <>
            {campaignsList}
            <div
              className={
                selectedItem?.type === 'tags-temps'
                  ? 'settings-side-drop-down-content-item-selected bold'
                  : 'settings-side-drop-down-content-item bold'
              }
              onClick={() =>
                hadler({
                  type: 'tags-temps',
                })
              }
            >
              Шаблоны
            </div>
          </>
        )}
      </div>
    </div>
  )
}
