import './CampaignCard.sass'
import CompanyProgress from '../../../Dashboard/CompanyProgress/CompanyProgress'
import Calls from '../../../Dashboard/Calls/Calls'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hook'
import { campaignSelector } from '../../../../store/selectors/campaign'
import Tag from '../../../../components/Tag/Tag'
import CampaignTagIcon from '../../../../components/Icons/Campaign/CampaignTagIcon'
import { useApi } from '../../../../store/hooks/request'
import { useEffect } from 'react'
import { getCampaignCard } from '../../../../store/requests/campaign'

interface CampaignCardType {
  pickedJob: number | undefined
  activeCampaign: number | undefined
}

export default function CampaignCard({
  pickedJob,
  activeCampaign,
}: CampaignCardType) {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { campaignCard } = useAppSelector(campaignSelector)

  useEffect(() => {
    if (activeCampaign && pickedJob !== undefined)
      dispatch(
        getCampaignCard({
          api,
          campaignId: activeCampaign,
          jobId: pickedJob,
        })
      )
    // eslint-disable-next-line
  }, [pickedJob, activeCampaign])

  // const compCodes = campaignCard?.completionCodesCount || {}

    return (
    <div className="campaign-card">
      <div className="campaign-card-script-and-launched">
        <span>Запущена </span>
        <span>Скрипт: {campaignCard?.isScriptExists}</span>
      </div>
      <div className="campaign-card-dates">
        <span>
          {campaignCard?.jobStartTime} - {campaignCard?.estimatedJobEndTime}
        </span>
        <span>Актуальна до: {campaignCard?.elapsedTime}</span>
        <span>Осталось: {campaignCard?.leftPeriod}</span>
      </div>
      <span className="campaign-card-span-bold-campaign">
        {campaignCard?.campaignName}
      </span>
      <div className="campaign-card-description">
        <span className="campaign-card-span-bold">Описание кампании</span>
        <span> {campaignCard?.campaignDesc}</span>
      </div>
      <div className="campaign-card-rules">
        <span className="campaign-card-span-bold">Правила</span>
        <span>
          Время на обработку после окончания вызова {campaignCard?.acwTime}
        </span>
        <span>
          Разрешенное кол-во запросов времени на завершение вызова{' '}
          {campaignCard?.numOfACWExtns}
        </span>
        <span>Код завершения по умолчанию {campaignCard?.defaultCompCode}</span>
        <span>
          Кол-во вызовов инициируемых POM на одного агента{' '}
          {campaignCard?.overdialRatio}
        </span>
      </div>
      <div className="campaign-card-type">
        <span className="campaign-card-span-bold">Тип кампании</span>
        <div className="campaign-card-tag">
          <Tag
            obj={{
              text: campaignCard?.campaignType,
              color: 'grey',
            }}
            type="profile"
            icon={{
              element: <CampaignTagIcon />,
              position: 'left',
            }}
          />
        </div>
      </div>
      <div className="campaign-card-progress">
        <span className="campaign-card-span-bold">Прогресс кампании</span>
        <CompanyProgress
          data={{
            filtered: campaignCard?.filteredContactCount,
            processed: campaignCard?.processedContactCount,
            notDone: campaignCard?.contactsNotDoneCount,
          }}
        />
      </div>
      {/*<div className="campaign-card-completion-codes">*/}
      {/*    <span className="campaign-card-span-bold">*/}
      {/*        Целевое кол-во кодов завершения:*/}
      {/*    </span>*/}
      {/*    {compCodes &&*/}
      {/*        Object.keys(compCodes).map((key) => (*/}
      {/*            <span key={key}>*/}
      {/*                Код завершения: {key}*/}
      {/*                Кол-во: {compCodes[key]}*/}
      {/*            </span>*/}
      {/*        ))}*/}
      {/*</div>*/}
      <div className="campaign-card-calls">
        <Calls
          data={{
            succes: campaignCard?.callsSuccess,
            total: campaignCard?.overdialRatio,
            deals: campaignCard?.callsClosure,
            RTC: campaignCard?.callsRpc,
          }}
        />
      </div>
      <div className="campaign-card-success-codes">
        <span className="campaign-card-span-bold">Код завершения: </span>
        <span>Кол-во:</span>
      </div>
      <div className="campaign-card-success-codes-values"></div>
      <div className="campaign-card-success-operators">
        <span>
          Кол-во операторов сейчас / максимум:{' '}
          {campaignCard?.agentCount + ' / ' + campaignCard?.maxAgents}
        </span>
          {/*@ts-ignore*/}
        <span>Требуемые навыки: {campaignCard?.inboundSkillParameters}</span>
        <span>Среднее время ответа:</span>
        <span>
          Процент звонков с высоким уровнем обслуживания клиента:{' '}
          {campaignCard?.callsRpc}
        </span>
        <span>Максимальное время ожидания ответа на звонок из очереди:</span>
      </div>
    </div>
  )
}
