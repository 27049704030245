import { useEffect, useRef, useState } from 'react'
import {
  AgentStatusForSupervisor,
  CampaignResponse,
  CompletionCodeEntity,
} from '../../../../api/data-contracts'
import { AgentsAndCompainsAndCodesType } from '../../SupervisorDashboard'
import './CampainsDropDown.sass'
interface CampainsDropDownProps {
  compains?: AgentsAndCompainsAndCodesType['compains']
  handleSelectFilters: (
    selected:
      | Pick<AgentStatusForSupervisor, 'agentId' | 'agentName'>
      | Pick<CampaignResponse, 'campaignId' | 'campaignName'>
      | Pick<CompletionCodeEntity, 'codeId' | 'codeName'>,
    type: 'agent' | 'comp' | 'code'
  ) => void
  selectedCompains: (string | undefined)[]
}
export default function CampainsDropDown({
  compains,
  handleSelectFilters,
  selectedCompains,
}: CampainsDropDownProps) {
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)
  const handleClickOutside: EventListener = (event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  return (
    <div className="compains-dd-container" ref={ref}>
      <div className="compains-dd-header" onClick={() => setIsOpen(!isOpen)}>
        Компания: {selectedCompains.join(',')}
      </div>
      <div
        className={isOpen ? 'compains-dd-content open' : 'compains-dd-content'}
      >
        {compains
          ?.filter((comp) => !selectedCompains.includes(comp.campaignName))
          .map((compain) => {
            return (
              <div
                key={compain.campaignId}
                className="compains-dd-content-item"
                onClick={() => {
                  handleSelectFilters(
                    {
                      campaignId: compain.campaignId,
                      campaignName: compain.campaignName,
                    },
                    'comp'
                  )
                  setIsOpen(!isOpen)
                }}
              >
                {compain.campaignName}
              </div>
            )
          })}
      </div>
    </div>
  )
}
