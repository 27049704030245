import { AgentJobStatisticsNotification } from '../../../api/data-contracts'

export const generateOperatorsData = (
  data: AgentJobStatisticsNotification['callStatePercentageMap']
) => {
  const colorsMap: string[] = [
    '#286DAD',
    '#CCDFE5',
    '#C15747',
    '#E8B531',
    '#b4eb34',
    '#34eb6e',
    '#eb3483',
  ]
  if (!data) return [{ title: '-', value: 100, color: '#c2e1d0' }]
  const generatedData = Object.keys(data).map((company, index) => {
    return {
      title: company,
      value: data[company],
      color: colorsMap[index],
    }
  })
  return generatedData
}
