import './ViewWithScript.sass'
import React from 'react'
import SubHeader from './SubHeader/SubHeader'
import Script from '../Script/Script'
import { scriptSelector } from '../../store/selectors/script'
import { useAppSelector } from '../../store/hooks/hook'

interface ViewWithScriptProps {
  ContentComponent: JSX.Element
  SubHeaderComponent: JSX.Element
}

export default function ViewWithScript({
  ContentComponent,
  SubHeaderComponent,
}: ViewWithScriptProps) {
  const { isExpanded } = useAppSelector(scriptSelector)

  return (
    <div className="view-content">
      <SubHeader SubHeaderComponent={SubHeaderComponent} />
      <div className="content-with-script-wrapper">
        <div
          className={
            'content-with-script-content' + (isExpanded ? ' collapsed' : '')
          }
        >
          {ContentComponent}
        </div>
        <Script />
      </div>
    </div>
  )
}
