import './Header.sass'
import React from 'react'
import SearchIcon from '../../../../../components/Icons/Chat/SearchIcon/SearchIcon'

export default function Header() {
  return (
    <div className="s-header">
      <div className="s-header-left">
        {/*  TODO Добавить метки/фильтры для поиска  */}
      </div>
      <div className="s-header-right">
        <div className="s-header-right-icon">
          <SearchIcon />
        </div>
      </div>
    </div>
  )
}
