import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'
import HeaderPart from './HeaderPart/HeaderPart'
import ContentPart from './ContentPart/ContentPart'
import './Chat.sass'

export default function Chat() {
  return (
    <div className="chat-view-wrapper">
      <ViewWithScript
        ContentComponent={<ContentPart />}
        SubHeaderComponent={<HeaderPart />}
      />
    </div>
  )
}
