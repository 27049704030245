import Checkmark from '../../../../../../components/Icons/Checkmark/Checkmark'
import './ColorSquare.sass'

interface ColorSquareProps {
  color: string
  isChosen: boolean
  stroke: string
}

export default function ColorSquare({
  color,
  isChosen,
  stroke,
}: ColorSquareProps) {
  return (
    <div style={{ background: color }} className="color-square">
      {isChosen && <Checkmark stroke={stroke} />}
    </div>
  )
}
