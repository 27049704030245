import { memo } from 'react'
import { AgentJobStatisticsNotification } from '../../../api/data-contracts'
import CallTime from '../../../components/Icons/Dashboard/CallTime'
import './AverageCallTime.sass'
interface AverageCallTimeProps {
  data?: {
    total: AgentJobStatisticsNotification['talkTime']
    company: AgentJobStatisticsNotification['avgTalkTime']
  }
}
function AverageCallTime({ data }: AverageCallTimeProps) {
  return (
    <div className="dashboard-widget-container average-calls-container">
      <div className="average-calls-header">
        <span>Среднее время вызова</span>
      </div>
      <div className="average-calls-content">
        <div className="average-calls-content-counters">
          <span>Всего:</span>
          <div className="average-calls-content-counters-data">
            <CallTime />
            <span>{data?.total ?? '--'}</span>
          </div>
        </div>
        <div className="average-calls-content-counters">
          <span>По кампаниям:</span>
          <div className="average-calls-content-counters-data">
            <span>{data?.company ?? '--'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(AverageCallTime)
