import React from 'react'
import { getSmoothStepPath } from 'react-flow-renderer'

export default function ConnectionEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: any) {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <g>
      <path className="react-flow__edge-path animated" d={edgePath} />
      <circle
        cx={targetX}
        cy={targetY}
        fill="#f4fafb"
        r={3}
        stroke="#A1BDCA"
        strokeWidth={1.5}
      />
    </g>
  )
}
