import React, { ChangeEventHandler } from 'react'
import './ShortNote.sass'

interface ShortNoteProps {
  className?: string
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
}

export default function ShortNote({
  className,
  value,
  onChange,
  placeholder,
}: ShortNoteProps) {
  return (
    <input
      className={className ? 'short-note ' + className : 'short-note'}
      placeholder={placeholder || 'Текст'}
      value={value}
      onChange={onChange}
    />
  )
}
