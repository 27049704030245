import { memo } from 'react'
import { AgentJobStatisticsNotification } from '../../../api/data-contracts'
import Clock2 from '../../../components/Icons/Dashboard/Clock2'
import './WorkTime.sass'
interface WorkTimeProps {
  data?: AgentJobStatisticsNotification['totalJobTime']
}
function WorkTime({ data }: WorkTimeProps) {
  return (
    <div className="dashboard-widget-container work-time-container">
      <div className="work-time-header">
        <span>Время работы</span>
      </div>
      <div className="work-time-content">
        <Clock2 />
        <h3>{data ?? '--:--'}</h3>
      </div>
    </div>
  )
}

export default memo(WorkTime)
