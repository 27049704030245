import { Tag as TagType } from '../../../../api/data-contracts'
import Tag from '../../../../components/Tag/Tag'
import './TagEditor.sass'
import { useCallback, useState } from 'react'
import TagEditorModal from './TagEditorModal/TagEditorModal'
import PlusCircle from '../../../../components/Icons/ScriptDev/Sider/PlusCircle'
import { useApi } from '../../../../store/hooks/request'
import { useAppDispatch } from '../../../../store/hooks/hook'
import { saveUpdateTag } from '../../../../store/requests/tag'
import TagCompanyModal from './TagCompanyModal/TagCompanyModal'

interface TagEditorProps {
  tags: TagType[]
  campaignId?: number
  isTemplate: boolean
}

export default function TagEditor({
  tags,
  campaignId,
  isTemplate,
}: TagEditorProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [companyModalTag, setCompanyModalTag] = useState<TagType | null>(null)
  const { api } = useApi()
  const dispatch = useAppDispatch()

  const toggleModalOpen = () => setModalOpen(!modalOpen)
  const handleMoveTagToCompany = (tag: TagType | null) =>
    setCompanyModalTag(tag)

  const handleMoveTagToTemplate = useCallback(
    (tag) => {
      dispatch(saveUpdateTag({ api, tag, isTemplate: true }))
    },
    // eslint-disable-next-line
    [dispatch]
  )

  return (
    <>
      <div className="tags-editor">
        <div className="tags-editor-header">
          Настройки быстрых действий
          <div onClick={toggleModalOpen}>
            <PlusCircle />
          </div>
        </div>
        <div className="tags-editor-wrapper">
          <div className="tags-editor-wrapper-header">
            Список быстрых действий
          </div>
          {tags.map((tag) => (
            <Tag
              type="campaign"
              obj={{
                id: tag.tagId,
                text: tag.tagName,
                ...tag,
              }}
              key={tag.tagId}
              className="tags-editor-item"
              onClose={
                isTemplate ? undefined : () => handleMoveTagToTemplate(tag)
              }
              onClick={
                isTemplate ? () => handleMoveTagToCompany(tag) : undefined
              }
            />
          ))}
        </div>
      </div>
      {modalOpen && (
        <TagEditorModal
          onClose={toggleModalOpen}
          campaignId={campaignId}
          isTemplate={isTemplate}
        />
      )}
      {companyModalTag && (
        <TagCompanyModal
          onClose={() => handleMoveTagToCompany(null)}
          tag={companyModalTag}
        />
      )}
    </>
  )
}
