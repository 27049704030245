import React from 'react'
import { IconType } from '../types'

export default function Break(props: IconType) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M17.0007 9.33301C17.0007 8.78072 16.5529 8.33301 16.0007 8.33301C15.4484 8.33301 15.0007 8.78072 15.0007 9.33301V15.9997C15.0007 16.3445 15.1783 16.6649 15.4707 16.8477L19.4707 19.3477C19.939 19.6404 20.5559 19.498 20.8486 19.0297C21.1414 18.5613 20.999 17.9444 20.5306 17.6517L17.0007 15.4454V9.33301Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0007 4.33301C9.55733 4.33301 4.33398 9.55635 4.33398 15.9997C4.33398 22.443 9.55733 27.6663 16.0007 27.6663C22.444 27.6663 27.6673 22.443 27.6673 15.9997C27.6673 9.55635 22.444 4.33301 16.0007 4.33301ZM6.33398 15.9997C6.33398 10.6609 10.6619 6.33301 16.0007 6.33301C21.3394 6.33301 25.6673 10.6609 25.6673 15.9997C25.6673 21.3384 21.3394 25.6663 16.0007 25.6663C10.6619 25.6663 6.33398 21.3384 6.33398 15.9997Z"
        fill="white"
      />
    </svg>
  )
}
