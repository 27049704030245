import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hook'
import React, { useEffect, useState } from 'react'
import { getAllCallbacks } from '../../../../store/requests/campaign'
import { campaignSelector } from '../../../../store/selectors/campaign'
import { useApi } from '../../../../store/hooks/request'
import PlannedCall from './PlannedCall'

interface CampaignCallbacksType {
  jobId?: number
  pickedCampaign?: string
}

export default function CampaignCallbacks({
  jobId,
  pickedCampaign,
}: CampaignCallbacksType) {
  const dispatch = useAppDispatch()
  const { campaignCardCallbacks } = useAppSelector(campaignSelector)
  const { api } = useApi()
  const [showCalls, setShowCalls] = useState(false)

  const handleShowCalls = () => {
    setShowCalls(!showCalls)
  }
  useEffect(() => {
    if (pickedCampaign && jobId !== undefined) {
      dispatch(
        getAllCallbacks({
          api: api,
          campaignName: pickedCampaign,
          jobId: jobId,
        })
      )
    }
    //eslint-disable-next-line
  }, [dispatch, jobId, pickedCampaign])
  return (
    <div className="campaign-callbacks">
      {campaignCardCallbacks?.callbackInfoList?.length ? (
        <table className="table-container">
          <thead>
            <tr>
              <th>ID запланированного вызова</th>
              <th>Тип</th>
              <th>Оператор</th>
              <th>Статус</th>
              <th>Callback</th>
            </tr>
          </thead>
          <tbody>
            {campaignCardCallbacks?.callbackInfoList?.map((e) => (
              <tr onClick={handleShowCalls} key={e.jobId}>
                <td>{e.callbackId}</td>
                <td>{e.callbackType}</td>
                <td>{e.userContactId}</td>
                <td>{e.status}</td>
                <td>
                  {e.startTime} - {e.endTime}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="table-container-error">
          Данных нет или что-то пошло не так...
        </div>
      )}
      {showCalls && <PlannedCall handleClose={handleShowCalls} />}
    </div>
  )
}
