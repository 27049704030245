import './Button.sass'
import React from 'react'
import ExitIcon from '../ExitIcon/ExitIcon'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { sendRequest } from '../../../store/requests/header'
import { useApi } from '../../../store/hooks/request'
import { userSelector } from '../../../store/selectors/user'
import { logoff } from '../../../store/requests/user'

interface ButtonProps {
  disabled?: boolean
}

function cookiesDelete() {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;'
    document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
  localStorage.clear()
}

export default function Button({ disabled = false }: ButtonProps) {
  const dispatch = useAppDispatch()
  const { api, logoffApi } = useApi()
  const { agentId, role, agentName } = useAppSelector(userSelector)

  const handleExit = React.useCallback(() => {
    if (role && role[0] !== 'ROLE_USER') {
      dispatch(logoff({ logoffApi }))
    } else if (role) {
      dispatch(sendRequest({ api, agentId, agentName, type: 'LOGOUT_REQUEST' }))
    }
    cookiesDelete()
    // eslint-disable-next-line
  }, [agentId, agentName])

  return (
    <div
      className={
        'profile-modal-button-inner-container' + (disabled ? ' disabled' : '')
      }
      onClick={disabled ? () => {} : handleExit}
    >
      <ExitIcon disabled={disabled} />
      <div
        className={'profile-modal-button-text' + (disabled ? ' disabled' : '')}
      >
        Выход
      </div>
    </div>
  )
}
