import CRUDTable, {
  Fields,
  Field,
  UpdateForm,
  DeleteForm,
  CreateForm,
} from 'react-crud-table'
import { useApi } from '../../../store/hooks/request'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { adminSelector } from '../../../store/selectors/admin'
import React, { useEffect } from 'react'
import {
  deleteAgentProfile,
  findAllAgentProfile,
  saveOrUpdateProfile,
} from '../../../store/requests/admin'
import { AdminAgentProfileDto } from '../../../api/data-contracts'

export default function AdminAgentProfileEntity() {
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const { agentProfile } = useAppSelector(adminSelector)

  useEffect(() => {
    dispatch(findAllAgentProfile({ api }))
    //eslint-disable-next-line
  }, [])

  const agentService = {
    save: (task: AdminAgentProfileDto) => {
      dispatch(
        saveOrUpdateProfile({
          ...task,
          api,
        })
      )
      return Promise.resolve(task)
    },
    update: (data: AdminAgentProfileDto) => {
      const task: AdminAgentProfileDto | undefined = agentProfile.find(
        (t) => t.agentId === data.agentId
      )
      dispatch(
        saveOrUpdateProfile({
          ...data,
          api,
        })
      )
      return Promise.resolve(task)
    },
    delete: (data: AdminAgentProfileDto) => {
      const task = agentProfile.find((t) => t.agentId !== data.agentId)
      dispatch(
        deleteAgentProfile({
          ...data,
          api,
        })
      )
      return Promise.resolve(task)
    },
  }

  return (
    <div className="table-container">
      <CRUDTable caption="Профили агента" items={agentProfile}>
        <Fields>
          <Field name="agentId" label="id" />
          <Field name="agentOrg" label="Организация" />
          <Field name="agentName" label="Имя" />
          <Field name="outboundSkills" label="outboundSkills" />
          <Field name="attributeSkills" label="attributeSkills" />
          <Field name="loggedInSkill" label="loggedInSkill" />
          <Field name="agentGroupId" label="agentGroupId" />
          <Field name="campaignId" label="Id кампании" />
          <Field name="huntingGroup" label="Hunting group" />
        </Fields>
        <CreateForm
          title="Создать"
          message="Создать"
          onSubmit={agentService.save}
          trigger="Создать"
          submitText="Создать"
        />
        <UpdateForm
          title="Обновить"
          message=""
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={agentService.update}
        />
        <DeleteForm
          title="Task Delete Process"
          message="Are you sure you want to delete the task?"
          trigger="Удалить"
          submitText="Удалить"
          onSubmit={agentService.delete}
        />
      </CRUDTable>
    </div>
  )
}
