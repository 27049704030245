import React from 'react'

export default function Bell() {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.89453 21H16.1445"
        stroke="#394145"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00858 9.74994C6.00729 8.86045 6.1894 7.97951 6.54441 7.1579C6.89942 6.33628 7.42031 5.59025 8.07706 4.9628C8.7338 4.33535 9.51342 3.83889 10.371 3.50204C11.2285 3.16519 12.147 2.99461 13.0736 3.00013C16.9397 3.02772 20.0322 6.11275 20.0322 9.83468V10.4999C20.0322 13.8577 20.764 15.8061 21.4085 16.871C21.4779 16.9848 21.5148 17.1142 21.5156 17.246C21.5163 17.3779 21.4808 17.5076 21.4127 17.6221C21.3445 17.7366 21.2461 17.8318 21.1274 17.8982C21.0086 17.9645 20.8738 17.9996 20.7364 17.9999H5.30356C5.16618 17.9996 5.03132 17.9645 4.91259 17.8981C4.79386 17.8318 4.69545 17.7366 4.6273 17.622C4.55915 17.5075 4.52367 17.3778 4.52443 17.2459C4.52518 17.114 4.56216 16.9846 4.63163 16.8709C5.27648 15.8059 6.00858 13.8575 6.00858 10.4999L6.00858 9.74994Z"
        stroke="#394145"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
