export interface DashboadIconProp {
  isOpen: boolean
}
export default function DashboadIcon({ isOpen }: DashboadIconProp) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 12.5H12.5V18.5H18.5V12.5Z"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.25 14.75H21.5V18.5H25.25V14.75Z"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 21.5H12.5V27.5H18.5V21.5Z"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 21.5H21.5V27.5H27.5V21.5Z"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
