import './Step.sass'
import React, { Dispatch, useMemo } from 'react'
import { Button, Step as StepModel } from '../../../api/data-contracts'
import RadioButton from '../Elements/Buttons/RadioButton/RadioButton'
import ArrowLeft from '../../Icons/Arrows/ArrowLeft'
import ShortNote from '../Elements/Notes/ShortNote/ShortNote'
import RectangleButton from '../Elements/Buttons/RectangleButton/RectangleButton'
import DateInput from '../../DateInputs/DateInput/DateInput'
import { useAppSelector } from '../../../store/hooks/hook'
import { userSelector } from '../../../store/selectors/user'
import { scriptSelector } from '../../../store/selectors/script'
import { campaignSelector } from '../../../store/selectors/campaign'
import Callback from '../Callback/Callback'

interface StepProps {
  step: StepModel
  setStep: Dispatch<number>
  goBack(): void
}

export default function Step({ step, setStep, goBack }: StepProps) {
  const { customerDetails } = useAppSelector(scriptSelector)
  const { campaignInfo } = useAppSelector(campaignSelector)
  const { agentName } = useAppSelector(userSelector)
  const { orderNumber, elements, text, type } = step

  const constructedText = useMemo(() => {
    const name =
      customerDetails?.lastName + ' ' + customerDetails?.firstName || ''
    const campaignName = campaignInfo.campaignName
    const wordsArray = text?.split(' ')

    return wordsArray?.map((word, index) => {
      if (word === '$operatorName' && agentName)
        return (
          <span className="step-variable-value" key={index}>
            {agentName}{' '}
          </span>
        )
      if (word === '$clientName' && name)
        return (
          <span className="step-variable-value" key={index}>
            {name}{' '}
          </span>
        )
      if (word === '$companyName' && campaignName)
        return (
          <span className="step-variable-value" key={index}>
            {campaignName}{' '}
          </span>
        )
      return <span key={index}>{word} </span>
    })
  }, [customerDetails, campaignInfo, agentName, text])

  const layout = elements?.map((elem: any) => {
    const {
      elementName,
      radioButtonHeader,
      radioButtonOptions,
      checkBoxHeader,
      // variableValue,
      // datetime,
      buttonHeader,
      buttonNextStep,
    } = elem

    switch (elementName) {
      case 'RadioButton':
        return (
          <div key={orderNumber}>
            <div className="step-element-header">{radioButtonHeader}</div>
            {radioButtonOptions &&
              Object.keys(radioButtonOptions).map((key) => (
                <div
                  key={key}
                  onClick={() => setStep(radioButtonOptions[key] - 1)}
                >
                  <RadioButton className="step-element" label={key} />
                </div>
              ))}
          </div>
        )
      case 'Button':
        return (
          <RectangleButton
            key={`${orderNumber}-Button`}
            className="step-element"
            label={buttonHeader}
            onClick={() => setStep(Number(buttonNextStep - 1))}
          />
        )
      case 'DatePicker':
        return (
          <DateInput
            key={`${orderNumber}-DatePicker`}
            className="step-element"
            onChange={() => {}}
          />
        )
      case 'Checkbox':
        return (
          <div key={`${orderNumber}-cb-${checkBoxHeader}`}>
            <RadioButton
              className="step-element"
              label={checkBoxHeader}
              onClick={() => setStep(Number(orderNumber) + 1)}
            />
          </div>
        )
      case 'InputField':
        return (
          <ShortNote
            key={`${orderNumber}-InputField`}
            className="step-element"
            value=""
            onChange={() => {}}
          />
        )
      default:
        return <div key={`${orderNumber}-smth`} />
    }
  })

  if (type === 'callback') {
    const button = step.elements?.find(
      (el: any) => el.elementName === 'Button'
    ) as Button
    const nextStep = button?.buttonNextStep
    return (
      <Callback
        onClose={goBack}
        onSuccess={() => {
          if (nextStep !== undefined) setStep(nextStep - 1)
        }}
      />
    )
  }

  return (
    <div className="step">
      <div className="step-header">
        {Number(orderNumber) !== 1 && (
          <div className="step-header-arrow" onClick={() => goBack()}>
            <ArrowLeft />
          </div>
        )}
        <div className="step-title-name">Разговор</div>
      </div>
      {constructedText && (
        <div className="step-top-text">
          {constructedText?.map((text) => text)}
        </div>
      )}
      {layout}
    </div>
  )
}
