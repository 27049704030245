import { ChatGroupMessage } from '../../api/data-contracts'
import { useSubscription } from 'react-stomp-hooks'
import { ChatMessage } from '../../api/data-contracts'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import { addIncomingMessage } from '../../store/slices/chatSlice'

export default function useChatSubscriptions() {
  const { agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  useSubscription(`/agent/${agentId}/chat/messages`, (mes) => {
    const message: ChatMessage = JSON.parse(mes.body)
    dispatch(addIncomingMessage({ roomType: 'oneToOne', message }))
  })

  useSubscription(`/agent/${agentId}/chat-group/messages`, (mes) => {
    const message: ChatGroupMessage = JSON.parse(mes.body)
    dispatch(addIncomingMessage({ roomType: 'group', message }))
  })
}
