import React from 'react'

export interface PropsType {
  size?: number
}

export default function Check({ size }: PropsType) {
  return (
    <svg
      width={size ? size : '16'}
      height={size ? size : '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 4.50049L6.5 11.5002L3 8.00049"
        stroke="#31A26B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
