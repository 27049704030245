import React from 'react'
import Button, { ButtonProps } from '../../Button/Button'
import '../Modal.sass'

type TButton = {
  type?: ButtonProps['type']
  label: string
  disabled?: boolean
  onClick?(): void
}

type Props = {
  opened: boolean
  onClose(): void
  title: string
  description?:
    | (() => string | React.ReactElement)
    | string
    | React.ReactElement
  closeModalAfterButtonClick?: boolean
  leftButton: TButton
  rightButton: TButton
}

export const CommonTwoButtonsModal = React.memo<Props>(
  ({
    opened,
    onClose,
    closeModalAfterButtonClick = false,
    leftButton,
    rightButton,
    title,
    description,
  }) => {
    const handleButtonClick = (button: 'left' | 'right') => () => {
      if (button === 'left' && leftButton.onClick) {
        leftButton.onClick()
      } else if (button === 'right' && rightButton.onClick) {
        rightButton.onClick()
      }
      if (closeModalAfterButtonClick) {
        onClose()
      }
    }

    const renderDescription = () => {
      if (!description) {
        return null
      }
      if (typeof description === 'string') {
        return <p>{description}</p>
      }
      if (typeof description === 'function') {
        const result = description()
        if (typeof result === 'string') {
          return <p>{result}</p>
        }
        return result
      }
      return description
    }

    return opened ? (
      <div className="modal">
        <div className="modal-container">
          <div className="modal-articles">
            <p className="modal-articles-title">{title}</p>

            <div className="modal-articles-body">{renderDescription()}</div>
          </div>

          <div className="modal-btns-group">
            <div className="modal-btns-wrapper">
              <Button
                type={leftButton.type}
                text={leftButton.label}
                disabled={leftButton.disabled}
                onClick={handleButtonClick('left')}
              />
            </div>
            <div className="modal-btns-wrapper">
              <Button
                type={rightButton.type}
                text={rightButton.label}
                disabled={rightButton.disabled}
                onClick={handleButtonClick('right')}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null
  }
)
