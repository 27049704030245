import React, { memo } from 'react'
import MessageStatus from '../../../../../components/Icons/Chat/MessageStatus/MessageStatus'
import Avatar from '../../../../../components/Avatar/Avatar'
import './DiologPreview.sass'
import Notifier from '../../../../../components/Header/Notifier/Notifier'

interface DialogPreviewProps {
  selected: boolean
  username: string
  group?: string | undefined
  message?: string
  messageTime?: string
  unreadMessages: number
  onClick: () => void
}

function DialogPreview(props: DialogPreviewProps) {
  return (
    <div
      className={
        props.selected
          ? 'dialog-preview-container active'
          : 'dialog-preview-container'
      }
      onClick={props.onClick}
    >
      <div className="dialog-preview-avatar">
        <Avatar hasStatus={false} />
      </div>
      <div className="dialog-preview-content">
        <div className="dialog-preview-user-name-group-container">
          <span className="user-name">{props.username}</span>
          <div className="dialog-preview-user-name-group-container-wrapper">
            <span className="user-group">{props.group}</span>
            {props.messageTime && (
              <span className="message-time">{props?.messageTime}</span>
            )}
          </div>
        </div>
        <div className="dialog-preview-message-container">
          <MessageStatus />
          <span className="user-message">{props?.message}</span>
          <div className="unread-messages-amount">
            {props.unreadMessages ? (
              <Notifier count={props.unreadMessages} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(DialogPreview)
