import { DashboardItems } from '../../Settings/SettingsContent/DashbordEditor/types'
import { TopDashboardItems } from '../../Settings/SettingsContent/TopDashEditor/types'
import { DashboardDropProp } from '../types'

export default function CallsDrop({ onAddItem, type }: DashboardDropProp) {
  const dropLayout = {
    x: 0,
    y: 0,
    w: type === 'dash' ? 6 : 2,
    h: 1,
    minW: type === 'dash' ? 3 : 2,
    i: type === 'dash' ? DashboardItems.Calls : TopDashboardItems.Calls,
  }
  return (
    <svg
      onClick={() => onAddItem(dropLayout)}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M16.6697 19.7014C17.4476 21.2919 18.7369 22.5753 20.3309 23.346C20.4475 23.4013 20.5765 23.4252 20.7052 23.4155C20.8339 23.4058 20.9579 23.3627 21.0648 23.2905L23.4119 21.7254C23.5157 21.6562 23.6352 21.6139 23.7594 21.6025C23.8837 21.5911 24.0088 21.6109 24.1235 21.66L28.5144 23.5419C28.6636 23.6052 28.7881 23.7154 28.8693 23.8556C28.9504 23.9959 28.9838 24.1588 28.9643 24.3197C28.8255 25.4057 28.2956 26.4039 27.4739 27.1273C26.6521 27.8508 25.5948 28.2499 24.5 28.25C21.1185 28.25 17.8755 26.9067 15.4844 24.5156C13.0933 22.1245 11.75 18.8815 11.75 15.5C11.7501 14.4051 12.1492 13.3479 12.8726 12.5261C13.5961 11.7044 14.5943 11.1745 15.6803 11.0357C15.8412 11.0162 16.004 11.0496 16.1443 11.1307C16.2846 11.2118 16.3947 11.3364 16.4581 11.4855L18.3416 15.8803C18.3903 15.994 18.4101 16.118 18.3994 16.2412C18.3886 16.3644 18.3475 16.483 18.2798 16.5865L16.7201 18.9696C16.6491 19.0768 16.6072 19.2006 16.5983 19.3288C16.5895 19.4571 16.614 19.5855 16.6697 19.7014V19.7014Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
