import React from 'react'

export default function DDArrowDown() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 9L12 15L7 9H17Z" fill="#397FBF" />
    </svg>
  )
}
