import React from 'react'
import { getSmoothStepPath, getEdgeCenter } from 'react-flow-renderer'
import Trash from '../../../../components/Icons/ScriptDev/Nodes/Trash'

export default function DefaultEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}: any) {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path ad-edge"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={24}
        height={24}
        x={edgeCenterX - 24 / 2}
        y={edgeCenterY - 24 / 2}
        className="ad-edge-trash"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Trash onClick={data.onDelEdge} />
      </foreignObject>
    </>
  )
}
