import React from 'react'
import './Start.sass'
import { Handle, Position } from 'react-flow-renderer'
import StartIcon from '../../../../../components/Icons/ScriptDev/Nodes/Start'

export default function Start() {
  return (
    <div className="ad-node-start">
      <div className="ad-node-start-icon">
        <StartIcon />
      </div>
      <div className="ad-node-start-text">Старт</div>
      <Handle
        type="source"
        id="1"
        position={Position.Right}
        isConnectable
        className="ad-handle ad-handle-start"
      />
    </div>
  )
}
