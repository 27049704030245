import './Script.sass'
import React, { useCallback, useEffect, useState } from 'react'
import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'
import SubSider from './SubSider/SubSider'
import Content from './Content/Content'
import SubSiderHeader from './SubSider/Header/Header'
import TargetHeader from './Content/Header/Target/Header'
import PreviewHeader from './Content/Header/Preview/Header'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { getAllCampaigns, getAllScripts } from '../../store/requests/script'
import { useApi } from '../../store/hooks/request'
import Preview from './Content/Preview/Preview'
import {
  addInterimScripts,
  handlingCampaignModal,
  setCurrCampaign,
  setCurrTemplate,
} from '../../store/slices/scriptEditorSlice'
import { CampaignResponse, ScriptResponse } from '../../api/data-contracts'
import { scriptEditorSelector } from '../../store/selectors/scriptEditor'
import CampaignModal from '../Campaign/CampaignModal/CampaignModal'

export interface HandlePropsType {
  handleCampaign: (id: CampaignResponse['campaignId']) => void
}

export default function Script() {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const {
    scripts,
    campaigns,
    currentTemplate,
    currentCampaign,
    campaignIsOpen,
  } = useAppSelector(scriptEditorSelector)
  const [init, setInit] = useState(true)
  const [activeCampaign, setActiveCampaign] =
    useState<CampaignResponse['campaignId']>(undefined)
  const [activeTemplate, setActiveTemplate] = useState<string | undefined>(
    undefined
  )
  const [campaign, setCampaign] = useState<CampaignResponse | undefined>(
    undefined
  )
  const [template, setTemplate] = useState<ScriptResponse | undefined>(
    undefined
  )

  const handleCloseCampaignInfo = useCallback(
    () => dispatch(handlingCampaignModal(false)),
    [dispatch]
  )

  useEffect(() => {
    dispatch(handlingCampaignModal(false))
  }, [dispatch])

  useEffect(() => {
    dispatch(addInterimScripts())
  }, [campaigns, dispatch])

  useEffect(
    () =>
      setCampaign(
        campaigns.find(({ campaignId }) => campaignId === currentCampaign)
      ),
    [currentCampaign, campaigns]
  )
  useEffect(
    () => setTemplate(scripts.find(({ id }) => id === activeTemplate)),
    [scripts, activeTemplate]
  )

  const handleCampaign = useCallback(
    (id: CampaignResponse['campaignId']) => {
      setActiveCampaign(id)
      setInit(false)
      dispatch(setCurrCampaign(id))
      dispatch(setCurrTemplate(undefined))
    },
    [dispatch]
  )
  const handleTemplate = useCallback(
    (id) => {
      dispatch(setCurrCampaign(undefined))
      dispatch(setCurrTemplate(id))
      setInit(false)
    },
    [dispatch]
  )

  useEffect(() => {
    if (!init) {
      setActiveTemplate(currentTemplate)
      currentTemplate && setActiveCampaign(undefined)
    }
  }, [currentTemplate, init])

  useEffect(() => {
    dispatch(getAllCampaigns({ api }))
    dispatch(getAllScripts({ api }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <>
      <ViewWithScript
        ContentComponent={
          <div className="script-main-content">
            <div className="script-main-content-left">
              <SubSider
                handleCampaign={handleCampaign}
                handleTemplate={handleTemplate}
                campaignId={activeCampaign}
                templateId={activeTemplate}
              />
            </div>
            <div className="script-main-content-right">
              {activeTemplate ? (
                <Content />
              ) : activeCampaign ? (
                <Content />
              ) : (
                <Preview handleCampaign={handleCampaign} />
              )}
            </div>
          </div>
        }
        SubHeaderComponent={
          <div className="script-subheader">
            <div className="script-subheader-sider">
              <SubSiderHeader title="Скрипт" />
            </div>
            <div className="script-subheader-main">
              {activeTemplate ? (
                <TargetHeader
                  title={template?.templateName}
                  tag={template?.templateName}
                />
              ) : activeCampaign ? (
                <TargetHeader
                  title={campaign?.campaignName}
                  tag={campaign?.campaignName}
                />
              ) : (
                <PreviewHeader />
              )}
            </div>
          </div>
        }
      />
      {campaignIsOpen && (
        <div
          className="script-campaign-wrapper"
          onClick={handleCloseCampaignInfo}
        >
          <CampaignModal
            title={campaign?.campaignName}
            description={campaign?.campaignDesc}
            strategy={campaign?.campaignStrategy}
            type="Finite"
            contactList={[]}
          />
        </div>
      )}
    </>
  )
}
