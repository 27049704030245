import './Close.sass'
import React from 'react'

interface CloseProps {
  onClose?: React.MouseEventHandler<SVGSVGElement>
  size?: number
  dark?: boolean
}

export default function Close({ onClose, size, dark }: CloseProps) {
  return (
    <svg
      width={size ? size : '24'}
      height={size ? size : '24'}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ad-icon-close"
      onClick={onClose}
    >
      <path
        d="M6.16602 6.16602L17.8333 17.8333"
        stroke={dark ? '#556268' : '#a1bdca'}
        strokeWidth={dark ? '3' : '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.834 6.16602L6.16672 17.8333"
        stroke={dark ? '#556268' : '#a1bdca'}
        strokeWidth={dark ? '3' : '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
