import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from '../../api/Api'
import {
  AgentStateChangeRequest,
  AgentRequestNotification,
} from '../../api/data-contracts'
import { addNotification } from '../slices/notificationSlice'

interface DefaultRequestType {
  api: Api<unknown>
}

interface ChangeStateType extends AgentStateChangeRequest, DefaultRequestType {}
export interface ChangeStateRequestType
  extends DefaultRequestType,
    AgentRequestNotification {}

export const changeState = createAsyncThunk(
  'header/changeState',
  async ({ api, ...requestData }: ChangeStateType) => {
    await api.changeAgentStateUsingPost(requestData)
  }
)

export const sendRequest = createAsyncThunk(
  'header/sendRequest',
  async ({ api, ...requestData }: ChangeStateRequestType, { dispatch }) => {
    try {
      await api.receiveAgentRequestNotificationUsingPost(requestData)

      let requestType = 'Запрос'
      switch (requestData.type) {
        case 'BREAK_REQUEST':
          requestType = 'Запрос на перерыв'
          break
        case 'HELP_REQUEST':
          requestType = 'Запрос на помощь'
          break
        case 'LOGOUT_REQUEST':
          requestType = 'Запрос на выход из системы'
      }

      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title: requestType,
          text: 'Запрос был успешно отправлен',
        })
      )
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: 'Не удалось отправить запрос',
        })
      )
    }
    return requestData
  }
)

export const getAllReasons = createAsyncThunk(
  'header/getAllReasons',
  async ({ api }: DefaultRequestType) => {
    const { data } = await api.getAllReasonsUsingGet()
    return data
  }
)
