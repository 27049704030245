import { DashboardItems } from '../../Settings/SettingsContent/DashbordEditor/types'
import { TopDashboardItems } from '../../Settings/SettingsContent/TopDashEditor/types'
import { DashboardDropProp } from '../types'

export default function WorkTimeDrop({ onAddItem, type }: DashboardDropProp) {
  const dropLayout = {
    x: 0,
    y: 0,
    w: type === 'dash' ? 2 : 2,
    h: 1,
    minW: type === 'dash' ? 2 : 2,
    i: type === 'dash' ? DashboardItems.WorkTime : TopDashboardItems.WorkTime,
  }
  return (
    <svg
      onClick={() => onAddItem(dropLayout)}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M20 26.75C23.7279 26.75 26.75 23.7279 26.75 20C26.75 16.2721 23.7279 13.25 20 13.25C16.2721 13.25 13.25 16.2721 13.25 20C13.25 23.7279 16.2721 26.75 20 26.75Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20 16.25V20H23.75"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 14.387L16.8883 10.8662C16.9196 10.6933 17.0107 10.5369 17.1455 10.4243C17.2804 10.3117 17.4505 10.25 17.6263 10.25H22.3737C22.5494 10.25 22.7196 10.3117 22.8544 10.4243C22.9893 10.5369 23.0804 10.6933 23.1117 10.8662L23.75 14.387"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 25.6133L16.8883 29.1341C16.9196 29.307 17.0107 29.4634 17.1455 29.576C17.2804 29.6886 17.4505 29.7503 17.6263 29.7503H22.3737C22.5494 29.7503 22.7196 29.6886 22.8544 29.576C22.9893 29.4634 23.0804 29.307 23.1117 29.1341L23.75 25.6133"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
