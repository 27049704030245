import React, { memo } from 'react'
import './Pallet.sass'
import ReactTooltip from 'react-tooltip'
import Microphone from '../../../../components/Icons/ScriptDev/Pallet/Microphone'
import CallBack from '../../../../components/Icons/ScriptDev/Pallet/CallBack'
import CallFinish from '../../../../components/Icons/ScriptDev/Pallet/CallFinish'
import Custom from '../../../../components/Icons/ScriptDev/Pallet/Custom'

interface PalletPropsType {
  onClick: (type: string) => void
}

const palletList = [
  { id: 1, Component: Microphone, type: 'talking', tip: 'Разговор' },
  { id: 2, Component: CallBack, type: 'callback', tip: 'Обратный вызов' },
  { id: 3, Component: CallFinish, type: 'finish', tip: 'Завершение вызова' },
  { id: 4, Component: Custom, type: 'custom', tip: 'Свой блок' },
]

function Pallet({ onClick }: PalletPropsType) {
  return (
    <div className="ad-pallet">
      {palletList.map(({ id, tip, Component, type }) => (
        <div
          className="ad-pallet-button"
          onClick={() => onClick(type)}
          key={id}
          data-for="pallet"
          data-tip={tip}
        >
          <Component />
        </div>
      ))}
      <ReactTooltip
        effect="solid"
        id="pallet"
        className="ad-pallet-tooltip"
        delayHide={300}
        delayShow={300}
        place="right"
        arrowColor="#AD3928"
      />
    </div>
  )
}

export default memo(Pallet)
