import './ScriptHeader.sass'
import React, { useEffect, useState } from 'react'
import InfoItem from '../../InfoItem/InfoItem'
import ArrowRight from '../../Icons/Arrows/ArrowRight'
import { scriptSelector } from '../../../store/selectors/script'
import { setExpanded } from '../../../store/slices/scriptSlice'
import Avatar from '../../Avatar/Avatar'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import moment from 'moment'
import { endCall } from '../../../store/requests/script'
import { useApi } from '../../../store/hooks/request'
import PhoneIncoming from '../../Icons/PhoneIncoming/PhoneIncoming'
import PhoneOutgoing from '../../Icons/PhoneOutgoing/PhoneOutgoing'

export default function ScriptHeader() {
  const {
    isExpanded,
    callStart,
    customerDetails,
    nextCallTimer,
    releaseLineAnswer,
    callState,
    blendingStatus,
  } = useAppSelector(scriptSelector)
  const [time, setTime] = useState<string>('00:00')

  const dispatch = useAppDispatch()
  const { api } = useApi()

  const handleExpand = () => dispatch(setExpanded(!isExpanded))

  const setCallTime = (callSeconds: number) => {
    callSeconds > 3600
      ? setTime(moment.utc(callSeconds * 1000).format('hh:mm:ss'))
      : setTime(moment.utc(callSeconds * 1000).format('mm:ss'))
  }

  const isInCall = callState === 'Talking'

  const name =
    customerDetails?.lastName + ' ' + customerDetails?.firstName || ''
  const timerActive = isInCall || !!nextCallTimer
  const headerData = timerActive ? time : 'Ожидание'
  const headerTitle = isInCall ? name : 'Клиент не подключен'

  useEffect(() => {
    const callInterval = setInterval(() => {
      if (callStart && !nextCallTimer) {
        const callSeconds = moment().diff(moment.unix(callStart), 'seconds')
        setCallTime(callSeconds)
      }
    }, 1000)

    const nextCallInterval = setInterval(() => {
      if (nextCallTimer) {
        const remainingSeconds = moment
          .unix(nextCallTimer)
          .diff(moment(), 'seconds')
        setCallTime(remainingSeconds)
        if (remainingSeconds < 1 && releaseLineAnswer) {
          const { agentId, defaultCompCode } = releaseLineAnswer
          dispatch(
            endCall({
              api,
              agentId: agentId!,
              completionCode: defaultCompCode!,
            })
          )
          clearInterval(callInterval)
          clearInterval(nextCallInterval)
        }
      }
    }, 1000)

    if (!callStart && !nextCallTimer) {
      setTime('00:00')
      clearInterval(callInterval)
      clearInterval(nextCallInterval)
    }

    return () => {
      clearInterval(callInterval)
      clearInterval(nextCallInterval)
    }

    // eslint-disable-next-line
  }, [callStart, time, nextCallTimer, releaseLineAnswer])

  const headerIcon =
    blendingStatus === 'BLEND_TO_INBOUND' ? (
      <PhoneIncoming className="info-item-data-icon" />
    ) : (
      <PhoneOutgoing className="info-item-data-icon" />
    )

  return (
    <div
      style={{ background: !isInCall ? '#286DAD' : '#AD3928' }}
      className="script-header-wrapper"
      onClick={handleExpand}
    >
      <div className="script-header-icon">
        <Avatar />
      </div>
      <InfoItem
        inverted
        data={headerData}
        title={headerTitle}
        dataIcon={timerActive ? headerIcon : undefined}
      />
      <div className={'script-header-arrow' + (isExpanded ? ' rotated' : '')}>
        <ArrowRight />
      </div>
    </div>
  )
}
