import './Tooltip.sass'

interface TooltipType {
  text: string
  position?: 'top' | 'left' | 'bottom' | 'right'
  component: JSX.Element
}

export default function Tooltip({ text, component }: TooltipType) {
  return (
    <div className="tooltip">
      {component}
      <div className="tooltip-text">{text}</div>
    </div>
  )
}
