import React from 'react'
import './Preview.sass'
import Plus from '../../../../components/Icons/ScriptDev/Nodes/Plus'
import Card from './Card/Card'
import { useAppSelector } from '../../../../store/hooks/hook'
import { HandlePropsType } from '../../Script'
import { scriptEditorSelector } from '../../../../store/selectors/scriptEditor'
import { useAddNewScript } from '../../hooks/useAddNewScript'

export default function Preview({ handleCampaign }: HandlePropsType) {
  const { toggleOpenModal, renderModal } = useAddNewScript()

  const { campaigns, scripts } = useAppSelector(scriptEditorSelector)

  return (
    <div className="script-preview">
      {campaigns.map((el) => (
        <Card
          key={el.campaignId}
          card={{
            id: el.campaignId,
            title: el.campaignName,
            tag: scripts.find(({ campaignId }) => el.campaignId === campaignId)
              ? el.campaignName
              : undefined,
            description: el.campaignDesc,
          }}
          handleCampaign={() => handleCampaign(el.campaignId)}
        />
      ))}
      <div className="script-preview-add-btn" onClick={toggleOpenModal}>
        <Plus type="white" />
      </div>
      {renderModal()}
    </div>
  )
}
