import React, { MouseEventHandler } from 'react'

interface LightningProps {
  size?: number
  onClick?: MouseEventHandler<HTMLOrSVGElement>
}

export default function Lightning(props: LightningProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="lightning-icon"
      onClick={props.onClick}
    >
      <path
        d="M11 1L1 13H10L9 21L19 9H10L11 1Z"
        stroke="#556268"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
