import React from 'react'

export default function ChatCircle() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ad-icon-chat-circle"
    >
      <path
        d="M3.19421 13.1954C2.35557 11.7816 2.06187 10.1104 2.36826 8.49539C2.67466 6.88042 3.56007 5.43285 4.85823 4.4245C6.15639 3.41614 7.77801 2.91638 9.41858 3.01904C11.0591 3.1217 12.6058 3.81973 13.7681 4.98206C14.9305 6.14438 15.6285 7.69104 15.7312 9.33161C15.8338 10.9722 15.3341 12.5938 14.3257 13.892C13.3174 15.1901 11.8698 16.0755 10.2549 16.382C8.63989 16.6884 6.96862 16.3947 5.55486 15.556L5.55488 15.556L3.22356 16.222C3.1271 16.2496 3.02504 16.2509 2.92793 16.2257C2.83082 16.2005 2.74221 16.1499 2.67128 16.0789C2.60035 16.008 2.54967 15.9194 2.52451 15.8223C2.49935 15.7252 2.50061 15.6231 2.52817 15.5267L3.19426 13.1953L3.19421 13.1954Z"
        stroke="#CCDFE5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7.5V11.625"
        stroke="#CCDFE5"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.0625 9.5625L6.9375 9.5625"
        stroke="#CCDFE5"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.2017 18.9984C10.9044 19.7081 11.7558 20.2531 12.6945 20.5941C13.6332 20.9352 14.6358 21.0638 15.6302 20.9708C16.6246 20.8777 17.586 20.5653 18.4451 20.056L20.7764 20.722C20.8729 20.7495 20.9749 20.7508 21.072 20.7256C21.1692 20.7005 21.2578 20.6498 21.3287 20.5789C21.3996 20.5079 21.4503 20.4193 21.4755 20.3222C21.5006 20.2251 21.4994 20.123 21.4718 20.0266L20.8057 17.6953C21.3975 16.6972 21.7222 15.5634 21.7483 14.4033C21.7743 13.2432 21.5009 12.096 20.9545 11.0723C20.4081 10.0486 19.6071 9.18305 18.6288 8.55908"
        stroke="#CCDFE5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
