import './Content.sass'
import React, { useEffect } from 'react'
import Table from './Table/Table'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hook'
import { useApi } from '../../../../store/hooks/request'
import { userSelector } from '../../../../store/selectors/user'
import { getAgents } from '../../../../store/requests/user'
import Controls from './Controls/Controls'

const header = ['Оператор', 'Кампания', 'Статус']

export default function Content() {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { agentsForSuper } = useAppSelector(userSelector)

  useEffect(() => {
    dispatch(getAgents({ api }))
    // eslint-disable-next-line
  }, [dispatch])

  return (
    <div className="call-super">
      <div className="call-super-content">
        <Table header={header} body={agentsForSuper} />
      </div>
      <Controls />
    </div>
  )
}
