import './Button.sass'
import React from 'react'

export interface ButtonProps {
  type?: 'outline'
  onClick: React.MouseEventHandler<HTMLDivElement>
  text: string
  className?: string
  disabled?: boolean
}

export default function Button({
  type,
  onClick,
  text,
  className,
  disabled,
}: ButtonProps) {
  return (
    <div
      className={
        (disabled ? 'button-disabled ' : '') +
        (type === 'outline' ? 'button-outline ' : 'button ') +
        className
      }
      onClick={!disabled ? onClick : undefined}
    >
      <div
        className={type === 'outline' ? 'button-outline-text' : 'button-text'}
      >
        {text}
      </div>
    </div>
  )
}
