import { memo, useCallback, useMemo } from 'react'
import ChatList from '../InnerChatArea/ChatList/ChatList'
import Dialog from '../InnerChatArea/DialogArea/Dialog'
import './ContentPart.sass'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { chatSelector } from '../../../store/selectors/chat'
import { SelectedChat } from '../../../store/slices/chatSlice'
import { setSelectedChat } from '../../../store/slices/chatSlice'

function ContentPart() {
  const { group, oneToOne, selectedChat } = useAppSelector(chatSelector)
  const chats = useMemo(() => ({ group, oneToOne }), [group, oneToOne])
  const dispatch = useAppDispatch()

  const currentChat = useMemo(() => {
    return selectedChat?.type
      ? chats[selectedChat.type][
          chats[selectedChat.type].findIndex(
            (el) => el.chatId === selectedChat.id
          )
        ]
      : undefined
  }, [selectedChat, chats])

  const roomHandler = useCallback(
    (id: SelectedChat['id'], type: SelectedChat['type']) => {
      dispatch(setSelectedChat({ id, type }))
    },
    [dispatch]
  )

  return (
    <div className="chat-content">
      <ChatList
        chats={chats}
        onClick={roomHandler}
        selectedChatId={selectedChat?.id}
      />
      <Dialog {...currentChat} type={selectedChat?.type} />
    </div>
  )
}

export default memo(ContentPart)
