import './Header.sass'
import React, {
  JSXElementConstructor,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import FinishIcon from '../../../../../../components/Icons/ScriptDev/Pallet/CallFinish'
import CallbackIcon from '../../../../../../components/Icons/ScriptDev/Pallet/CallBack'
import TalkingIcon from '../../../../../../components/Icons/ScriptDev/Pallet/Microphone'
import CustomIcon from '../../../../../../components/Icons/ScriptDev/Pallet/Custom'
import Drag from '../../../../../../components/Icons/ScriptDev/Nodes/Drag'
import { Title } from '../../../../../../api/data-contracts'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../store/hooks/hook'
import {
  removeEdge,
  removeStep,
  updateStepTitle,
} from '../../../../../../store/slices/scriptEditorSlice'
import Trash from '../../../../../../components/Icons/ScriptDev/Nodes/Trash'
import Pencil from '../../../../../../components/Icons/ScriptDev/Nodes/Pencil'
import { applyNodeChanges, useEdges, useReactFlow } from 'react-flow-renderer'
import Input from '../../../../../../components/Input/Input'
import Accept from '../Accept/Accept'
import { scriptEditorSelector } from '../../../../../../store/selectors/scriptEditor'

interface NodeHeaderProps {
  type: 'callback' | 'talking' | 'custom' | 'finish'
  title?: Title
  step: number
}
interface Node {
  title: string
  icon: JSXElementConstructor<object>
}

function Header({ type, title, step }: NodeHeaderProps) {
  const dispatch = useAppDispatch()
  const [nodeSettings, setNodeSettings] = useState<Node | null>(null)
  const [editing, setEditing] = useState(false)
  const [newTitle, setNewTitle] = useState('')
  const { setNodes } = useReactFlow()
  const { mode } = useAppSelector(scriptEditorSelector)
  const edges = useEdges()
  const handleEditing = useCallback(() => {
    if (mode === 'editing') {
      setEditing(true)
      setNewTitle('')
    }
  }, [mode])
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setNewTitle(e.currentTarget.value),
    []
  )

  const handleCancelEditing = useCallback(() => setEditing(false), [])
  const handleAcceptEditing = useCallback(() => {
    if (!!newTitle) {
      setNodeSettings({
        title: newTitle,
        icon: nodeSettings?.icon ? nodeSettings.icon : CustomIcon,
      })
      dispatch(
        updateStepTitle({
          step,
          text: newTitle,
        })
      )
    }
    setEditing(false)
  }, [newTitle, dispatch, step, nodeSettings?.icon])
  const handleRemoveStep = useCallback(() => {
    if (mode === 'editing') {
      edges
        .filter(({ target }) => +target === step)
        .forEach(({ id }) => dispatch(removeEdge(id)))
      dispatch(removeStep(step))
      setNodes((ns) =>
        applyNodeChanges([{ id: String(step), type: 'remove' }], ns)
      )
    }
  }, [step, dispatch, setNodes, edges, mode])

  useEffect(() => {
    switch (type) {
      case 'callback':
        setNodeSettings({
          title: title?.name ? title.name : 'Обратный вызов',
          icon: CallbackIcon,
        })
        break
      case 'talking':
        setNodeSettings({
          title: title?.name ? title.name : 'Разговор',
          icon: TalkingIcon,
        })
        break
      case 'custom':
        setNodeSettings({
          title: title?.name ? title.name : 'Кастом',
          icon: CustomIcon,
        })
        break
      case 'finish':
        setNodeSettings({
          title: title?.name ? title.name : 'Завершение вызова',
          icon: FinishIcon,
        })
        break
    }
  }, [type, title])

  return (
    <div className="ad-node-header">
      <div className="ad-node-header-left">
        <div className="ad-node-header-icon">
          {nodeSettings && <nodeSettings.icon />}
        </div>
        {editing ? (
          <div className="ad-node-header-input">
            <Input
              span=""
              placeholder={nodeSettings?.title}
              onChange={onChange}
            />
          </div>
        ) : (
          <div className="ad-node-header-title">{nodeSettings?.title}</div>
        )}
      </div>
      <div className="ad-node-header-tools">
        {editing ? (
          <Accept
            onAccept={handleAcceptEditing}
            onCancel={handleCancelEditing}
          />
        ) : (
          <>
            {title?.editable && <Pencil onClick={handleEditing} />}
            <Trash onClick={handleRemoveStep} />
            <Drag />
          </>
        )}
      </div>
    </div>
  )
}

export default memo(Header)
