import React from 'react'
import { IconType } from './types'

export default function Campaign({ isActive }: IconType) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2505 6.75H3.75049C3.33627 6.75 3.00049 7.08579 3.00049 7.5V19.5C3.00049 19.9142 3.33627 20.25 3.75049 20.25H20.2505C20.6647 20.25 21.0005 19.9142 21.0005 19.5V7.5C21.0005 7.08579 20.6647 6.75 20.2505 6.75Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 6.75V5.25C15.75 4.85218 15.592 4.47064 15.3107 4.18934C15.0294 3.90804 14.6478 3.75 14.25 3.75H9.75C9.35218 3.75 8.97064 3.90804 8.68934 4.18934C8.40804 4.47064 8.25 4.85218 8.25 5.25V6.75"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0006 11.8415C18.2653 13.424 15.16 14.255 11.9999 14.25C8.84026 14.255 5.73551 13.4243 3.00049 11.8423"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 11.25H13.125"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
