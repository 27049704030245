import { memo, useMemo } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { AgentJobStatisticsNotification } from '../../../api/data-contracts'
import { generateOperatorsData } from './helpers'
import './OperatorStatuses.sass'

interface OperatorStatusesProps {
  data?: AgentJobStatisticsNotification['callStatePercentageMap']
}

function OperatorStatuses({ data }: OperatorStatusesProps) {
  const operatorsData = useMemo(() => generateOperatorsData(data), [data])

  return (
    <div className="dashboard-widget-container operator-statuses-container">
      <div className="operator-statuses-header">
        <span>Статусы операторов</span>
      </div>
      <div className="operator-statuses-content">
        <>
          <div className="round-chart-container">
            <PieChart
              data={operatorsData}
              lineWidth={18}
              paddingAngle={18}
              rounded
              label={({ dataEntry }) =>
                data ? `${Math.floor(dataEntry.percentage)}%` : ''
              }
              labelStyle={(index) => ({
                fill: operatorsData![index].color,
                fontSize: '8px',
                fontFamily: 'Roboto',
              })}
              labelPosition={65}
            />
          </div>
          <div className="operator-statuses-data">
            {data ? (
              operatorsData?.map((el, index) => {
                return (
                  <div className="data" key={index}>
                    <div
                      className="round"
                      style={{ background: el.color }}
                    ></div>
                    <span>{el.title}</span>
                  </div>
                )
              })
            ) : (
              <div className="data">
                <span>-</span>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  )
}

export default memo(OperatorStatuses)
