import { IconType } from '../Navigation/types'

export default function ChatGroupRoomControllerIcon({ isActive }: IconType) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.999H3.75C3.55109 21.999 3.36032 21.92 3.21967 21.7794C3.07902 21.6387 3 21.4479 3 21.249V12.999C3 10.6121 3.94821 8.32288 5.63604 6.63505C7.32386 4.94723 9.61304 3.99902 12 3.99902H12C14.3869 3.99902 16.6761 4.94724 18.364 6.63506C20.0518 8.32289 21 10.6121 21 12.999V12.999C21 15.386 20.0518 17.6752 18.364 19.363C16.6761 21.0508 14.387 21.999 12 21.999V21.999Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5117 21.9999C12.1328 23.7546 13.2824 25.2737 14.8024 26.3481C16.3224 27.4226 18.1381 27.9995 19.9995 27.9995H28.2495C28.4484 27.9995 28.6391 27.9205 28.7798 27.7798C28.9205 27.6392 28.9995 27.4484 28.9995 27.2495V18.9995C28.9994 16.6976 28.1175 14.4833 26.535 12.8118C24.9524 11.1402 22.7896 10.1386 20.4912 10.0127"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
