import React from 'react'

export default function Microphone() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.25H12C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75H12C11.5075 15.75 11.0199 15.653 10.5649 15.4645C10.11 15.2761 9.69657 14.9999 9.34835 14.6517C9.00013 14.3034 8.72391 13.89 8.53545 13.4351C8.347 12.9801 8.25 12.4925 8.25 12V5.99999C8.25 5.00543 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25V2.25Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.75V21.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7087 12.75C18.5241 14.4001 17.7378 15.9243 16.5002 17.0312C15.2625 18.1381 13.6603 18.75 11.9999 18.75C10.3395 18.75 8.73736 18.1381 7.49972 17.0312C6.26207 15.9243 5.47578 14.4001 5.29114 12.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
