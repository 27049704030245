import React from 'react'
import { IconType } from './IconType'

export default function Microphone({ isActive }: IconType) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 3.75H20C21.6576 3.75 23.2473 4.40848 24.4194 5.58058C25.5915 6.75269 26.25 8.3424 26.25 10V20C26.25 21.6576 25.5915 23.2473 24.4194 24.4194C23.2473 25.5915 21.6576 26.25 20 26.25H20C19.1792 26.25 18.3665 26.0883 17.6082 25.7742C16.8499 25.4602 16.1609 24.9998 15.5806 24.4194C15.0002 23.8391 14.5398 23.1501 14.2258 22.3918C13.9117 21.6335 13.75 20.8208 13.75 20V9.99999C13.75 8.34239 14.4085 6.75268 15.5806 5.58058C16.7527 4.40848 18.3424 3.75 20 3.75V3.75Z"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 31.25V36.25"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1813 21.25C30.8736 24.0002 29.5631 26.5405 27.5004 28.3853C25.4376 30.2301 22.7673 31.25 20 31.25C17.2326 31.25 14.5624 30.2301 12.4996 28.3853C10.4369 26.5405 9.12639 24.0002 8.81866 21.25"
        stroke={isActive ? '#fff' : '#AD3928'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
