import { IconType } from '../Navigation/types'

export default function ChatRoomControllerIcon({ isActive }: IconType) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 26.999H5.95833C5.70417 26.999 5.46041 26.8981 5.28069 26.7183C5.10097 26.5386 5 26.2949 5 26.0407V15.499C5 13.9888 5.29746 12.4934 5.87538 11.0981C6.45331 9.70291 7.30039 8.43516 8.36827 7.36729C9.43614 6.29942 10.7039 5.45233 12.0991 4.87441C13.4944 4.29648 14.9898 3.99902 16.5 3.99902H16.5C18.0102 3.99902 19.5056 4.29648 20.9009 4.87441C22.2961 5.45234 23.5639 6.29942 24.6317 7.3673C25.6996 8.43517 26.5467 9.70292 27.1246 11.0982C27.7025 12.4934 28 13.9888 28 15.499V15.499C28 18.549 26.7884 21.4741 24.6317 23.6308C22.4751 25.7874 19.55 26.999 16.5 26.999Z"
        stroke={isActive ? '#ad3928' : '#545454'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
