import './Sidebar.sass'
import React from 'react'
import Navigator from './Navigator/Navigator'
import CollapseIn from '../Icons/Navigation/Collapse/CollapseIn'
import CollapseOut from '../Icons/Navigation/Collapse/CollapseOut'
import { headerSelector } from '../../store/selectors/header'
import {
  setCollapse,
  setVisibilityProfileModal,
} from '../../store/slices/headerSlice'
import Avatar from '../Avatar/Avatar'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'

interface SidebarProps {
  className: string
}

export default function Sidebar(props: SidebarProps) {
  const dispatch = useAppDispatch()
  const { isCollapsed } = useAppSelector(headerSelector)
  const { role } = useAppSelector(userSelector)

  const handleCollapse = React.useCallback(
    () => dispatch(setCollapse(!isCollapsed)),
    [dispatch, isCollapsed]
  )

  React.useEffect(() => {
    dispatch(setVisibilityProfileModal(false))
  }, [dispatch])

  return (
    <div
      className={`sidebar ${props.className} ${
        isCollapsed ? ' sidebar-full' : ''
      }`}
    >
      <div
        className={`sidebar-avatar-wrapper ${
          !isCollapsed ? 'sidebar-avatar-wrapper-full' : ''
        }`}
      >
        <Avatar hasStatus={true} />
      </div>
      <Navigator />
      {role && role[0] === 'ROLE_ADMIN' ? null : (
        <div className="collapse-container" onClick={handleCollapse}>
          {isCollapsed ? <CollapseOut /> : <CollapseIn />}
        </div>
      )}
    </div>
  )
}
