import './Ghost.sass'
import React from 'react'

interface IconProps {
  load?: boolean
}

export default function Ghost({ load }: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`ad-ghost ${load ? 'ad-ghost-load' : ''}`}
    >
      <path
        className="ad-ghost-path"
        d="M12.5 16C13.3284 16 14 15.3284 14 14.5C14 13.6716 13.3284 13 12.5 13C11.6716 13 11 13.6716 11 14.5C11 15.3284 11.6716 16 12.5 16Z"
      />
      <path
        className="ad-ghost-path"
        d="M19.5 16C20.3284 16 21 15.3284 21 14.5C21 13.6716 20.3284 13 19.5 13C18.6716 13 18 13.6716 18 14.5C18 15.3284 18.6716 16 19.5 16Z"
      />
      <path
        d="M27 27L23.3333 24L19.6667 27L16 24L12.3333 27L8.66667 24L5 27V15C5 12.0826 6.15893 9.28473 8.22183 7.22183C10.2847 5.15893 13.0826 4 16 4C18.9174 4 21.7153 5.15893 23.7782 7.22183C25.8411 9.28473 27 12.0826 27 15V27Z"
        className="ad-ghost-outer"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    // <svg
    //         version="1.1"
    //         xmlns="http://www.w3.org/2000/svg"
    //         x="0px"
    //         y="0px"
    //         viewBox="0 0 512 512"
    //         className="ghost"
    //     >
    //         <g>
    //             <path
    //                 d="M172.524,166.952c-36.823,0-66.781,29.958-66.781,66.781c0,36.823,29.958,66.781,66.781,66.781
    // 			c36.823,0,66.781-29.958,66.781-66.781C239.305,196.91,209.347,166.952,172.524,166.952z M204.432,243.577
    // 			c-2.617-5.8-8.437-9.844-15.213-9.844c-9.22,0-16.695,7.475-16.695,16.695c0,6.776,4.044,12.596,9.844,15.213
    // 			c-3.113,0.963-6.419,1.483-9.844,1.483c-18.411,0-33.39-14.979-33.39-33.39s14.979-33.39,33.39-33.39s33.39,14.979,33.39,33.39
    // 			C205.914,237.158,205.395,240.463,204.432,243.577z"
    //             />
    //         </g>
    //         <g>
    //             <path
    //                 d="M339.476,166.952c-36.823,0-66.781,29.958-66.781,66.781c0,36.823,29.958,66.781,66.781,66.781
    // 			c36.823,0,66.781-29.958,66.781-66.781C406.257,196.91,376.299,166.952,339.476,166.952z M371.384,243.577
    // 			c-2.617-5.8-8.437-9.844-15.213-9.844c-9.22,0-16.695,7.475-16.695,16.695c0,6.776,4.044,12.596,9.844,15.213
    // 			c-3.113,0.963-6.419,1.483-9.844,1.483c-18.411,0-33.39-14.979-33.39-33.39s14.979-33.39,33.39-33.39s33.39,14.979,33.39,33.39
    // 			C372.866,237.158,372.347,240.463,371.384,243.577z"
    //             />
    //         </g>
    //         <g>
    //             <path
    //                 d="M256,0C136.325,0,38.962,97.363,38.962,217.038v278.254c0,15.982,20.441,22.83,30.051,10.017l36.729-48.973l36.729,48.973
    // 			c6.679,8.905,20.047,8.887,26.712,0l36.729-48.973l36.729,48.973c6.679,8.905,20.046,8.887,26.712,0l36.729-48.973l36.729,48.973
    // 			c6.679,8.905,20.046,8.887,26.712,0l36.729-48.973l36.729,48.973c9.591,12.79,30.051,6.031,30.051-10.017V217.038
    // 			C473.038,97.363,375.675,0,256,0z M439.647,445.206l-20.034-26.712c-6.679-8.905-20.046-8.887-26.712,0l-36.729,48.973
    // 			l-36.729-48.973c-6.679-8.905-20.047-8.887-26.712,0L256,467.466l-36.729-48.973c-6.679-8.905-20.047-8.887-26.712,0
    // 			l-36.729,48.973l-36.729-48.973c-6.679-8.905-20.047-8.887-26.712,0l-20.034,26.712V217.038
    // 			C72.353,115.775,154.737,33.39,256,33.39s183.647,82.384,183.647,183.647V445.206z"
    //             />
    //         </g>
    //     </svg>
  )
}
