import React, { memo, useCallback, useEffect, useState } from 'react'
import './Message.sass'
import Textarea from 'react-textarea-autosize'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../store/hooks/hook'
import { updateStepText } from '../../../../../../store/slices/scriptEditorSlice'
import { scriptEditorSelector } from '../../../../../../store/selectors/scriptEditor'

interface MessageProps {
  msg?: string
  step: number
  firstText?: boolean
  customElement?: 'input' | 'radio'
}

function Message({ msg = '', step, firstText, customElement }: MessageProps) {
  const dispatch = useAppDispatch()
  const [text, setText] = useState('')
  const { mode } = useAppSelector(scriptEditorSelector)
  const handleChange = useCallback(
    (e) => {
      const text = e.currentTarget.value
      setText(text)
      dispatch(
        updateStepText({
          text,
          step,
          firstText,
          customElement,
        })
      )
    },
    [dispatch, step, firstText, customElement]
  )

  useEffect(() => setText(msg), [msg])

  return (
    <div className="node-message">
      <div className="node-message-title">
        {firstText ? 'Приветствие' : 'Сообщение'}
      </div>
      <Textarea
        className="node-message-body"
        placeholder={
          firstText ? 'Введите текст приветствия' : 'Введите текст сообщения'
        }
        onChange={handleChange}
        value={text || ''}
        disabled={mode === 'view'}
      />
    </div>
  )
}

export default memo(Message)
