import {
  AgentStatusForSupervisor,
  CampaignResponse,
  CompletionCodeEntity,
} from '../../../api/data-contracts'
import ClearIcon from '../../../components/Icons/ClearIcon/ClearIcon'
import {
  AgentsAndCompainsAndCodesType,
  tabStateType,
} from '../SupervisorDashboard'
import CampainsDropDown from './CampainsDropDown/CampainsDropDown'
import CodesDropDown from './CodesDropDown/CodesDropDown'
import OperatorsDropDown from './OperatorsDropDown/OperatorsDropDown'
import './SupervisorDashboardSubheader.sass'

interface SupervisorDashboardSubheaderProps {
  data?: AgentsAndCompainsAndCodesType
  handleSelectFilters: (
    selected:
      | Pick<AgentStatusForSupervisor, 'agentId' | 'agentName'>
      | Pick<CampaignResponse, 'campaignId' | 'campaignName'>
      | Pick<CompletionCodeEntity, 'codeId' | 'codeName'>,
    type: 'agent' | 'comp' | 'code'
  ) => void
  handleClear: () => void
  type: tabStateType
  selectedFilters: {
    operators: (string | undefined)[]
    codes: (string | undefined)[]
    compains: (string | undefined)[]
  }
}

export default function SupervisorDashboardSubheader({
  data,
  handleSelectFilters,
  handleClear,
  type,
  selectedFilters,
}: SupervisorDashboardSubheaderProps) {
  return (
    <div className="supervisor-dashboard-subheader">
      {(type === 'stats' && (
        <>
          <OperatorsDropDown
            selectedOperators={selectedFilters.operators}
            operators={data?.agents}
            handleSelectFilters={handleSelectFilters}
          />
          <CampainsDropDown
            selectedCompains={selectedFilters.compains}
            compains={data?.compains}
            handleSelectFilters={handleSelectFilters}
          />
        </>
      )) ||
        (type === 'ops' && (
          <>
            {' '}
            <OperatorsDropDown
              selectedOperators={selectedFilters.operators}
              operators={data?.agents}
              handleSelectFilters={handleSelectFilters}
            />
            <CodesDropDown
              selectedCodes={selectedFilters.codes}
              handleSelectFilters={handleSelectFilters}
              codes={data?.codes}
            />
          </>
        )) ||
        (type === 'comp' && (
          <>
            <CampainsDropDown
              selectedCompains={selectedFilters.compains}
              compains={data?.compains}
              handleSelectFilters={handleSelectFilters}
            />
            <CodesDropDown
              selectedCodes={selectedFilters.codes}
              handleSelectFilters={handleSelectFilters}
              codes={data?.codes}
            />
          </>
        ))}

      <ClearIcon handleClear={handleClear} />
    </div>
  )
}
