import './Avatar.sass'
import React from 'react'
import { headerSelector } from '../../store/selectors/header'
import { setVisibilityProfileModal } from '../../store/slices/headerSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'

interface AvatarProps {
  hasStatus?: boolean
  forMessages?: boolean
}

export default function Avatar(props: AvatarProps) {
  const { visibilityProfileModal, agentStateNotify } =
    useAppSelector(headerSelector)
  const dispatch = useAppDispatch()

  const handleClick = React.useCallback(
    () => dispatch(setVisibilityProfileModal(!visibilityProfileModal)),
    [dispatch, visibilityProfileModal]
  )

  return (
    <div
      className={
        props.forMessages ? 'avatar-container-messages' : 'avatar-container'
      }
      onClick={handleClick}
    >
      {props.hasStatus && (
        <div
          className={`avatar-status ${
            agentStateNotify !== 'NotReady' && agentStateNotify !== null
              ? 'avatar-status-green'
              : 'avatar-status-yellow'
          }`}
        />
      )}
    </div>
  )
}
