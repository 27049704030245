import React, { MouseEventHandler } from 'react'

interface ThreeDotsProps {
  size?: number
  onClick?: MouseEventHandler<HTMLOrSVGElement>
}

export default function ThreeDots(props: ThreeDotsProps) {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="three-dots"
    >
      <path
        d="M2 9.125C2.62132 9.125 3.125 8.62132 3.125 8C3.125 7.37868 2.62132 6.875 2 6.875C1.37868 6.875 0.875 7.37868 0.875 8C0.875 8.62132 1.37868 9.125 2 9.125Z"
        fill="#A1BDCA"
      />
      <path
        d="M2 3.125C2.62132 3.125 3.125 2.62132 3.125 2C3.125 1.37868 2.62132 0.875 2 0.875C1.37868 0.875 0.875 1.37868 0.875 2C0.875 2.62132 1.37868 3.125 2 3.125Z"
        fill="#A1BDCA"
      />
      <path
        d="M2 15.125C2.62132 15.125 3.125 14.6213 3.125 14C3.125 13.3787 2.62132 12.875 2 12.875C1.37868 12.875 0.875 13.3787 0.875 14C0.875 14.6213 1.37868 15.125 2 15.125Z"
        fill="#A1BDCA"
      />
    </svg>
  )
}
