interface JobIconType {
  onClick: () => void,
  color: string
}

export default function PlayIcon({ onClick, color }: JobIconType) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M21.3889 11.3609L7.8911 3.112C7.77741 3.04252 7.64726 3.00458 7.51404 3.00208C7.38083 2.99959 7.24935 3.03263 7.13314 3.0978C7.01692 3.16298 6.92017 3.25793 6.85283 3.37291C6.7855 3.48788 6.75 3.61871 6.75 3.75195V20.2498C6.75 20.3831 6.7855 20.5139 6.85283 20.6289C6.92017 20.7438 7.01692 20.8388 7.13314 20.904C7.24935 20.9691 7.38083 21.0022 7.51404 20.9997C7.64726 20.9972 7.77741 20.9593 7.8911 20.8898L21.3889 12.6408C21.4985 12.5738 21.5891 12.4798 21.652 12.3677C21.7148 12.2557 21.7478 12.1294 21.7478 12.0009C21.7478 11.8724 21.7148 11.7461 21.652 11.634C21.5891 11.522 21.4985 11.4279 21.3889 11.3609Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
