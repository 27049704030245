import React, { memo } from 'react'
import Avatar from '../../../../../../components/Avatar/Avatar'
import Tag from '../../../../../../components/Tag/Tag'
import ChatCircle from '../../../../../../components/Icons/ChatCircle/ChatCircle'
import CloseCircle from '../../../../../../components/Icons/CloseCircle/CloseCircle'
import { body } from '../Table'

interface BodyRowPropsType {
  bodyRows: body[]
  onLogout: (id: string, name: string) => void
  onCall: (id: string, name: string) => void
}

function BodyRows({ bodyRows, onLogout, onCall }: BodyRowPropsType) {
  const unavailableDeleteStatuses = ['LOGOFF', 'FORCE_LOGOUT', 'LOGOUT']

  // @ts-ignore
  return (
    <>
      {bodyRows.map(
        (
          { agentId, agentName, campaignName, state, tagColor, tagText, icon },
          i
        ) => (
          <div
            className={`ad-table-body-row${
              bodyRows.length - 1 === i ? '-last' : ''
            }`}
            key={agentId}
          >
            <div className="ad-table-body-row-col">
              <Avatar />
              <div className="ad-table-body-row-col-value ad-table-margin">
                {agentName}
              </div>
            </div>
            <div className="ad-table-body-row-col">
              <div className="ad-table-body-row-col-value">{campaignName}</div>
            </div>
            <div className="ad-table-body-row-col-short">
              <div className="ad-table-body-row-col-tag">
                <Tag
                  obj={{
                    id: 1,
                    color: tagColor,
                    text: tagText,
                  }}
                  icon={{
                    element: icon || <div />,
                    position: 'left',
                  }}
                  type="call-table"
                />
              </div>
              <div className="ad-table-body-row-col-icons">
                {state === 'ONCALL' && (
                  <div
                    className="ad-table-body-row-col-icon"
                    style={{
                      marginRight: '4px',
                    }}
                    onClick={() => onCall(agentId || '', agentName || '')}
                  >
                    <ChatCircle />
                  </div>
                )}
                {/*@ts-ignore*/}
                {!unavailableDeleteStatuses.includes(state) ? (
                  <div
                    className="ad-table-body-row-col-icon"
                    onClick={() => onLogout(agentId || '', agentName || '')}
                  >
                    <CloseCircle />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
      )}
    </>
  )
}

export default memo(BodyRows)
