interface SaveDashboardProps {
  onSave: () => void
}
export default function SaveDashboard({ onSave }: SaveDashboardProps) {
  return (
    <svg
      onClick={onSave}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.125 17.75L18.625 23L15.875 20.375"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 29C24.9706 29 29 24.9706 29 20C29 15.0294 24.9706 11 20 11C15.0294 11 11 15.0294 11 20C11 24.9706 15.0294 29 20 29Z"
        stroke="#A1BDCA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
