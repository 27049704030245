import CRUDTable, {
  Fields,
  Field,
  UpdateForm,
  DeleteForm,
  CreateForm,
} from 'react-crud-table'
import React, { useEffect } from 'react'
import {
  deleteCampaign,
  findAllCampaign,
  saveOrUpdateCampaign,
} from '../../../store/requests/admin'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { useApi } from '../../../store/hooks/request'
import { adminSelector } from '../../../store/selectors/admin'
import '../AgentGroupEntity/crud-style.sass'
import { AdminCampaignDto, AdminJobDto } from '../../../api/data-contracts'

export default function CampaignController() {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { agentCampaigns } = useAppSelector(adminSelector)

  useEffect(() => {
    dispatch(findAllCampaign({ api }))
    //eslint-disable-next-line
  }, [])

  const service = {
    create: (task: AdminCampaignDto) => {
      dispatch(
        saveOrUpdateCampaign({
          ...task,
          api,
        })
      )
      return Promise.resolve(task)
    },
    update: (data: AdminCampaignDto) => {
      const task: AdminCampaignDto | undefined = agentCampaigns.find(
        (t) => t.campaignId === data.campaignId
      )
      dispatch(saveOrUpdateCampaign({ ...data, api }))
      return Promise.resolve(task)
    },
    delete: (data: AdminCampaignDto) => {
      const task = agentCampaigns.find((t) => t.campaignId === data.campaignId)
      dispatch(deleteCampaign({ ...data, api }))
      return Promise.resolve(task)
    },
  }

  let jobs: AdminJobDto[] = []

  agentCampaigns.forEach((e) => e.jobs && jobs.push(...e.jobs))

  return (
    <div className="table-container">
      <CRUDTable caption="Кампании" items={agentCampaigns}>
        <Fields>
          <Field name="campaignId" label="id" />
          <Field name="campaignName" label="Название" />
          <Field name="campaignDesc" label="Описание" />
          <Field name="campaignStrategy" label="Стратегия" />
          <Field name="campaignType" label="Тип" />
          <Field
            name="contactList"
            label="Список контактов"
            hideInCreateForm
            hideInUpdateForm
          />
        </Fields>
        <CreateForm
          title="Создать"
          message="Создать"
          onSubmit={service.create}
          trigger="Создать"
          submitText="Создать"
        />
        <UpdateForm
          title="Обновить"
          message="Обновить"
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={service.update}
        />
        <DeleteForm
          title="Удалить"
          message="Уверены?"
          trigger="Удалить"
          submitText="Удалить"
          onSubmit={service.delete}
        />
        <button className="button-supervisor" />
      </CRUDTable>

      <CRUDTable caption="Задачи" items={jobs || []}>
        <Fields>
          <Field
            name="jobId"
            label="Id работы"
            hideInCreateForm
            hideInUpdateForm
          />
          <Field name="campaignName" label="Название работы" />
          <Field
            name="lastUpdated"
            label="Обновлена последний раз: "
            hideInCreateForm
            hideInUpdateForm
          />
          <Field
            name="jobStatus"
            label="Статус работы"
            hideInCreateForm
            hideInUpdateForm
          />
        </Fields>
      </CRUDTable>
    </div>
  )
}
