import React from 'react'

export default function Clock2() {
  return (
    <React.Fragment>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="16" fill="#EBF5F7" />
        <path
          d="M28 37C32.9706 37 37 32.9706 37 28C37 23.0294 32.9706 19 28 19C23.0294 19 19 23.0294 19 28C19 32.9706 23.0294 37 28 37Z"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M28 23V28H33"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 20.516L23.851 15.8216C23.8928 15.5911 24.0142 15.3826 24.1941 15.2324C24.3739 15.0823 24.6007 15 24.835 15H31.165C31.3993 15 31.6261 15.0823 31.8059 15.2324C31.9858 15.3826 32.1072 15.5911 32.149 15.8216L33 20.516"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 35.4839L23.851 40.1783C23.8928 40.4088 24.0142 40.6173 24.1941 40.7675C24.3739 40.9176 24.6007 40.9999 24.835 40.9999H31.165C31.3993 40.9999 31.6261 40.9176 31.8059 40.7675C31.9858 40.6173 32.1072 40.4088 32.149 40.1783L33 35.4839"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </React.Fragment>
  )
}
