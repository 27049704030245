import './ChatListHeader.sass'
import React from 'react'
import ThreeDots from '../../../../../components/Icons/ThreeDots/ThreeDots'

export default function ChatListHeader() {
  return (
    <div className="chat-list-header">
      <div className="chat-list-header-title">
        <span>Список диалогов</span>
        <ThreeDots />
      </div>
    </div>
  )
}
