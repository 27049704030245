import { Authorize } from '../../api/Authorize'
import { Api } from '../../api/Api'
import { useAppSelector } from './hook'
import { userSelector } from '../selectors/user'
import { Logoff } from '../../api/Logoff'
import { FailedAuthorization } from '../../api/FailedAuthorization'

export const useApi = () => {
  const options = {
    baseUrl: '/core',
  }
  const { auth } = useAppSelector(userSelector)

  const authApi = new Authorize(options)
  const failedAuthApi = new FailedAuthorization(options)
  const api = new Api({
    ...options,
    baseApiParams: {
      headers: {
        Authorization: auth || '',
      },
    },
  })
  const logoffApi = new Logoff({
    ...options,
    baseApiParams: {
      headers: {
        Authorization: auth || '',
      },
    },
  })

  return {
    authApi,
    api,
    logoffApi,
    failedAuthApi,
  }
}
