import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import './CompanyProgress.sass'
import GreenRound from '../../../components/Icons/Dashboard/GreenRound'
import SemiGreenRound from '../../../components/Icons/Dashboard/SemiGreenRound'
import GreyRound from '../../../components/Icons/Dashboard/GreyRound'
import { AgentJobStatisticsNotification } from '../../../api/data-contracts'
import { memo } from 'react'
interface CompanyProgressProps {
  data?: {
    filtered: AgentJobStatisticsNotification['filteredContactCount']
    processed: AgentJobStatisticsNotification['processedContactCount']
    notDone: AgentJobStatisticsNotification['contactsNotDoneCount']
  }
}
function CompanyProgress({ data }: CompanyProgressProps) {
  const progressPercents =
    data?.processed && data?.filtered
      ? (data?.processed * 100) / data?.filtered
      : 0

  return (
    <div className="dashboard-widget-container progress-company-container">
      <div className="progress-company-header">
        <span>Прогресс текущей кампании</span>
      </div>
      <div className="progress-company-content">
        <div className="circle-container">
          <CircularProgressbar
            minValue={0}
            value={Number(progressPercents.toFixed(0))}
            text={`${progressPercents.toFixed(0)}%`}
            circleRatio={0.5}
            strokeWidth={8}
            styles={{
              ...buildStyles({
                pathTransitionDuration: 0.7,
                pathColor: `#31A26B`,
                rotation: -1 / 4,
                strokeLinecap: 'round',
                trailColor: '#31a26b52',
              }),
              text: {
                fill: '#142328',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '40px',
              },
            }}
          />
        </div>
        <div className="progress-content">
          <div className="progress-data">
            <div className="data">
              <GreenRound />
              <h3>{data?.processed ?? '--'}</h3>
              <span>Обработанно</span>
            </div>
            <div className="data">
              <SemiGreenRound />
              <h3>{data?.notDone ?? '--'}</h3>
              <span>Осталось</span>
            </div>
            <div className="data">
              <GreyRound />
              <h3>{data?.filtered ?? '--'}</h3>
              <span>Всего</span>
            </div>
          </div>
          <div className="progress-bar"></div>
        </div>
      </div>
    </div>
  )
}
export default memo(CompanyProgress)
