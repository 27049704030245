import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ChatMessage,
  ChatRoom,
  ChatGroupRoomDto,
  ChatGroupMessage,
} from '../../api/data-contracts'
import { fetchChatRoomsAndMessages } from '../requests/chat'

export interface OneToOneChatStoreItem extends ChatRoom {
  messages?: ChatMessage[]
}

export interface GroupChatStoreItem extends ChatGroupRoomDto {
  messages?: ChatGroupMessage[]
}

export interface SelectedChat {
  type?: 'group' | 'oneToOne'
  id?: number
}

export interface ChatTypes {
  oneToOne: OneToOneChatStoreItem[]
  group: GroupChatStoreItem[]
}

export interface ChatStore extends ChatTypes {
  selectedChat: SelectedChat | null
  searchString: string | null
}

export interface addIncomingMessagePayloadType {
  roomType: 'oneToOne' | 'group'
  message: ChatMessage | ChatGroupMessage
}

const initialState: ChatStore = {
  oneToOne: [],
  group: [],
  selectedChat: null,
  searchString: null,
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    dropChatSlice: () => initialState,
    addIncomingMessage: (
      state,
      { payload }: PayloadAction<addIncomingMessagePayloadType>
    ) => {
      const roomIndex = state[payload.roomType].findIndex(
        (room) => room.chatId === payload.message.chatId
      )
      state[payload.roomType][roomIndex].messages?.push(payload.message)
    },
    setSelectedChat(
      state,
      { payload }: PayloadAction<ChatStore['selectedChat']>
    ) {
      state.selectedChat = payload
    },
    setSearchString(state, { payload }: PayloadAction<string | null>) {
      state.searchString = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchChatRoomsAndMessages.fulfilled,
      (state, { payload }) => {
        state.group = payload.group?.sort((first, second) => {
          const firstMessage = first?.messages
          const secondMessage = second?.messages

          if (firstMessage?.length && !secondMessage?.length) {
            return -1
          }

          return 1
        })

        state.oneToOne = payload.oneToOne?.sort((first, second) => {
          const firstMessage = first?.messages
          const secondMessage = second?.messages

          if (firstMessage?.length && !secondMessage?.length) {
            return -1
          }

          return 1
        })
      }
    )
  },
})

export const chatReducer = chatSlice.reducer
export const {
  addIncomingMessage,
  dropChatSlice,
  setSelectedChat,
  setSearchString,
} = chatSlice.actions
