interface JobIconType {
  onClick: () => void,
  color: string
}

export default function PauseIcon({ onClick, color }: JobIconType) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{fill: "currentcolor"}}
    >
      <path
        d="M18.75 3.75H15.375C14.9608 3.75 14.625 4.08579 14.625 4.5V19.5C14.625 19.9142 14.9608 20.25 15.375 20.25H18.75C19.1642 20.25 19.5 19.9142 19.5 19.5V4.5C19.5 4.08579 19.1642 3.75 18.75 3.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.625 3.75H5.25C4.83579 3.75 4.5 4.08579 4.5 4.5V19.5C4.5 19.9142 4.83579 20.25 5.25 20.25H8.625C9.03921 20.25 9.375 19.9142 9.375 19.5V4.5C9.375 4.08579 9.03921 3.75 8.625 3.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  )
}
