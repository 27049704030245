import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { adminSelector } from '../../store/selectors/admin'
import './AgentGroupEntity/crud-style.sass'
import React, { useState } from 'react'
import {
  clearExtension,
  findAllSupervisor,
  findAllUser,
  unAssignGroup,
  unAssignSupervisor,
} from '../../store/requests/admin'
import { useApi } from '../../store/hooks/request'

interface AssignModalType {
  type: 'supervisor' | 'agentGroup' | 'extension'
  freeClose: () => void
}

export default function UnAssignModal(props: AssignModalType) {
  const { supervisor, agentGroup, user } = useAppSelector(adminSelector)
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const [unSuperValue, setUnSuperValue] = useState('')
  const [unGroupValue, setUnGroupValue] = useState(0)
  const [rmExtension, setRmExtension] = useState('')

  const handleSuperChange = (event: any) => {
    setUnSuperValue(event.target.value)
  }

  const handleGroupChange = (event: any) => {
    setUnGroupValue(event.target.value)
    parseInt(event.target.value, 10)
  }

  const handleRmExChange = (event: any) => {
    setRmExtension(event.target.value)
  }

  const handleUnAssignSupervisor = () => {
    dispatch(
      unAssignSupervisor({
        api,
        agentGroupId: parseInt(String(unGroupValue), 10),
        supervisorUserName: unSuperValue,
      })
    )
  }

  const handleUnAssignGroup = () => {
    dispatch(
      unAssignGroup({
        api,
        agentGroupId: parseInt(String(unGroupValue), 10),
        supervisorUserName: unSuperValue,
      })
    )
    dispatch(findAllSupervisor({ api }))
  }

  const handleRemoveExtension = () => {
    dispatch(
      clearExtension({
        api,
        id: rmExtension,
      })
    )
    dispatch(findAllUser({ api }))
  }

  return (
    <div className="crud-modal-wrapper">
      <div className="crud-modal-wrapper__background" onClick={props.freeClose}>
        <div
          className="crud-modal-wrapper__modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="crud-modal-form">
            {props.type === 'extension' ? (
              <label>
                Выберете юзера
                <select value={rmExtension} onChange={handleRmExChange}>
                  <option></option>
                  {user.map((e) => (
                    <option value={e.login} label={e.login}></option>
                  ))}
                </select>
              </label>
            ) : (
              <>
                <label>
                  Выберите супервизора
                  <select value={unSuperValue} onChange={handleSuperChange}>
                    <option></option>
                    {supervisor.map((e) => (
                      <option
                        value={e.userName}
                        label={e.supervisorName}
                      ></option>
                    ))}
                  </select>
                </label>
                <label>
                  Выберете группу
                  <select value={unGroupValue} onChange={handleGroupChange}>
                    <option></option>
                    {agentGroup.map((e) => (
                      <option
                        value={e.agentGroupId}
                        label={e.agentGroupName}
                      ></option>
                    ))}
                  </select>
                </label>
              </>
            )}
            {props.type === 'supervisor' ? (
              <button
                onClick={handleUnAssignSupervisor}
                className="button-supervisor-red"
              >
                Отвязать
              </button>
            ) : props.type === 'agentGroup' ? (
              <button
                onClick={handleUnAssignGroup}
                className="button-supervisor-red"
              >
                Отвязать
              </button>
            ) : (
              <button
                onClick={handleRemoveExtension}
                className="button-supervisor-red"
              >
                Удалить
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
