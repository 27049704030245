import { Responsive, WidthProvider } from 'react-grid-layout'
import { AgentJobStatisticsResponse } from '../../../../api/data-contracts'
import AverageCallTime from '../../../Dashboard/AverageCallTime/AverageCallTime'
import Calls from '../../../Dashboard/Calls/Calls'
import CompanyProgress from '../../../Dashboard/CompanyProgress/CompanyProgress'
import OperatorStatuses from '../../../Dashboard/OperatorStatuses/OperatorStatuses'
import PlannedCalls from '../../../Dashboard/PlannedCalls/PlannedCalls'
import StartShift from '../../../Dashboard/StartShift/StartShift'
import WorkTime from '../../../Dashboard/WorkTime/WorkTime'
import {
  DashboardItems,
  initialLayouts,
} from '../../../Settings/SettingsContent/DashbordEditor/types'

const ResponsiveGridLayout = WidthProvider(Responsive)
interface SupervisorStatisticProps {
  statistic?: AgentJobStatisticsResponse
}
export default function SupervisorStatistic({
  statistic,
}: SupervisorStatisticProps) {
  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-title">Статистика</div>
      <ResponsiveGridLayout
        layouts={initialLayouts}
        breakpoints={{
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        cols={{ lg: 8, md: 6, sm: 4, xs: 2, xxs: 1 }}
        rowHeight={250}
        isDraggable={false}
        isResizable={false}
        margin={[32, 32]}
      >
        {initialLayouts?.lg.map((dash) => {
          switch (dash.i) {
            case DashboardItems.WorkTime:
              return (
                <div className="widget" key={DashboardItems.WorkTime}>
                  <WorkTime data={statistic?.totalJobTime} />
                </div>
              )
            case DashboardItems.StartShift:
              return (
                <div className="widget" key={DashboardItems.StartShift}>
                  <StartShift data={statistic?.loginTimeStamp} />
                </div>
              )
            case DashboardItems.AverageCallTime:
              return (
                <div className="widget" key={DashboardItems.AverageCallTime}>
                  <AverageCallTime
                    data={{
                      total: statistic?.talkTime,
                      company: statistic?.avgTalkTime,
                    }}
                  />
                </div>
              )
            case DashboardItems.CompanyProgress:
              return (
                <div className="widget" key={DashboardItems.CompanyProgress}>
                  <CompanyProgress
                    data={{
                      filtered: statistic?.filteredContactCount,
                      processed: statistic?.processedContactCount,
                      notDone: statistic?.contactsNotDoneCount,
                    }}
                  />
                </div>
              )
            case DashboardItems.Calls:
              return (
                <div className="widget" key={DashboardItems.Calls}>
                  <Calls
                    data={{
                      total: statistic?.callCount,
                      succes: statistic?.callsSuccess,
                      RTC: statistic?.callsRpc,
                      deals: statistic?.callsClosure,
                    }}
                  />
                </div>
              )
            case DashboardItems.OperatorStatuses:
              return (
                <div className="widget" key={DashboardItems.OperatorStatuses}>
                  <OperatorStatuses data={statistic?.callStatePercentageMap} />
                </div>
              )
            case DashboardItems.PlannedCalls:
              return (
                <div className="widget" key={DashboardItems.PlannedCalls}>
                  <PlannedCalls
                    data={{
                      filtered: statistic?.agentSpecificCallbackCount,
                      total: statistic?.currentCallbackCount,
                    }}
                  />
                </div>
              )

            default:
              return <></>
          }
        })}
      </ResponsiveGridLayout>
    </div>
  )
}
