import './Call.sass'
import React from 'react'
import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'
import ShiftSummary from './ShiftSummary/ShiftSummary'
import CallContent from './CallContent/CallContent'
import Header from './Super/Header/Header'
import Content from './Super/Content/Content'
import { useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'

export default function Call() {
  const { role } = useAppSelector(userSelector)

  return (
    <ViewWithScript
      SubHeaderComponent={
        role && role[0] === 'ROLE_USER' ? <ShiftSummary /> : <Header />
      }
      ContentComponent={
        role && role[0] === 'ROLE_USER' ? <CallContent /> : <Content />
      }
    />
  )
}
