import {
  AdminAgentGroupDto,
  AdminAgentProfileDto,
  AdminCampaignDto,
  AdminChatGroupRoomDto,
  AdminChatRoomDto,
  AdminSupervisorDto,
  AdminUserDto,
  CommonSettingsResponse,
} from '../../api/data-contracts'
import { createSlice } from '@reduxjs/toolkit'
import {
  changeCommonSettings,
  deleteAgentGroup,
  deleteAgentProfile,
  deleteCampaign,
  deleteChatGroup,
  deleteChatRoom,
  deleteSupervisor,
  deleteUser,
  findAllAgentGroup,
  findAllAgentProfile,
  findAllCampaign,
  findAllChatGroup,
  findAllChatRoom,
  findAllSupervisor,
  findAllUser,
  getCommonSettings,
  saveOrUpdateCampaign,
  saveOrUpdateChatGroup,
  saveOrUpdateChatRoom,
  saveOrUpdateGroup,
  saveOrUpdateProfile,
  saveOrUpdateSupervisor,
  saveUser,
  updateUser,
} from '../requests/admin'

export interface AdminStore {
  agentGroup: AdminAgentGroupDto[]
  agentProfile: AdminAgentProfileDto[]
  agentCampaigns: AdminCampaignDto[]
  chatGroupRoom: AdminChatGroupRoomDto[]
  chatRoom: AdminChatRoomDto[]
  supervisor: AdminSupervisorDto[]
  user: AdminUserDto[]
  commonSettings: CommonSettingsResponse[]
}

const initialState: AdminStore = {
  agentGroup: [],
  agentProfile: [],
  agentCampaigns: [],
  chatGroupRoom: [],
  chatRoom: [],
  supervisor: [],
  user: [],
  commonSettings: [],
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    dropAdminSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(findAllAgentGroup.fulfilled, (state, { payload }) => {
      state.agentGroup = payload
    })
    builder.addCase(findAllAgentProfile.fulfilled, (state, { payload }) => {
      state.agentProfile = payload
    })
    builder.addCase(findAllCampaign.fulfilled, (state, { payload }) => {
      state.agentCampaigns = payload
    })
    builder.addCase(findAllChatGroup.fulfilled, (state, { payload }) => {
      state.chatGroupRoom = payload
    })
    builder.addCase(findAllChatRoom.fulfilled, (state, { payload }) => {
      state.chatRoom = payload
    })
    builder.addCase(findAllSupervisor.fulfilled, (state, { payload }) => {
      state.supervisor = payload
    })
    builder.addCase(findAllUser.fulfilled, (state, { payload }) => {
      state.user = payload
    })
    builder.addCase(getCommonSettings.fulfilled, (state, { payload }) => {
      state.commonSettings = payload
    })
    builder.addCase(saveOrUpdateProfile.fulfilled, (state, { payload }) => {
      if (
        state.agentProfile.findIndex((e) => e.agentId === payload.agentId) ===
        -1
      ) {
        state.agentProfile.push(payload)
      } else
        state.agentProfile = state.agentProfile.map((e) => {
          if (e.agentId === payload.agentId) return { ...e, ...payload }
          return e
        })
    })
    builder.addCase(saveOrUpdateGroup.fulfilled, (state, { payload }) => {
      if (
        state.agentGroup.findIndex(
          (e) => e.agentGroupId === payload.agentGroupId
        ) === -1
      ) {
        state.agentGroup.push(payload)
      } else
        state.agentGroup = state.agentGroup.map((e) => {
          if (e.agentGroupId === payload.agentGroupId)
            return { ...e, ...payload }
          return e
        })
    })
    builder.addCase(saveOrUpdateCampaign.fulfilled, (state, { payload }) => {
      if (
        state.agentCampaigns.findIndex(
          (e) => e.campaignId === payload.campaignId
        ) === -1
      ) {
        state.agentCampaigns.push(payload)
      } else
        state.agentCampaigns = state.agentCampaigns.map((e) => {
          if (e.campaignId === payload.campaignId) return { ...e, ...payload }
          return e
        })
    })
    builder.addCase(saveOrUpdateChatRoom.fulfilled, (state, { payload }) => {
      if (state.chatRoom.findIndex((e) => e.chatId === payload.chatId) === -1) {
        state.chatRoom.push(payload)
      } else
        state.chatRoom = state.chatRoom.map((e) => {
          if (e.chatId === payload.chatId) return { ...e, ...payload }
          return e
        })
    })
    builder.addCase(saveOrUpdateChatGroup.fulfilled, (state, { payload }) => {
      if (
        state.chatGroupRoom.findIndex((e) => e.chatId === payload.chatId) === -1
      ) {
        state.chatGroupRoom.push(payload)
      } else
        state.chatGroupRoom = state.chatGroupRoom.map((e) => {
          if (e.chatId === payload.chatId) return { ...e, ...payload }
          return e
        })
    })
    builder.addCase(saveUser.fulfilled, (state, { payload }) => {
      state.user = state.user.map((e) => {
        if (e.login === payload.login) return { ...e, ...payload }
        return e
      })
    })
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.user = state.user.map((e) => {
        if (e.login === payload.login) return { ...e, ...payload }
        return e
      })
    })
    builder.addCase(saveOrUpdateSupervisor.fulfilled, (state, { payload }) => {
      if (
        state.supervisor.findIndex((e) => e.pimUserId === payload.pimUserId) ===
        -1
      ) {
        state.supervisor.push(payload)
      } else
        state.supervisor = state.supervisor.map((e) => {
          if (e.pimUserId === payload.pimUserId) return { ...e, ...payload }
          return e
        })
    })
    builder.addCase(changeCommonSettings.fulfilled, (state, { payload }) => {
      state.commonSettings = state.commonSettings.map((e) => {
        if (e.id === payload.id) return { ...e, ...payload }
        return e
      })
    })
    builder.addCase(deleteAgentGroup.fulfilled, (state, { payload }) => {
      state.agentGroup = state.agentGroup.filter(
        (e) => e.agentGroupId !== payload
      )
    })
    builder.addCase(deleteAgentProfile.fulfilled, (state, { payload }) => {
      state.agentProfile = state.agentProfile.filter(
        (e) => e.agentId !== payload
      )
    })
    builder.addCase(deleteCampaign.fulfilled, (state, { payload }) => {
      state.agentCampaigns = state.agentCampaigns.filter(
        (e) => e.campaignId !== payload
      )
    })
    builder.addCase(deleteChatRoom.fulfilled, (state, { payload }) => {
      state.chatRoom = state.chatRoom.filter((e) => e.chatId !== payload)
    })
    builder.addCase(deleteChatGroup.fulfilled, (state, { payload }) => {
      state.chatGroupRoom = state.chatGroupRoom.filter(
        (e) => e.chatId !== payload
      )
    })
    builder.addCase(deleteSupervisor.fulfilled, (state, { payload }) => {
      state.supervisor = state.supervisor.filter((e) => e.pimUserId !== payload)
    })
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.user = state.user.filter((e) => e.login !== payload)
    })
  },
})

export const { dropAdminSlice } = adminSlice.actions

export const adminReducer = adminSlice.reducer
