import { AgentsCompletionCodeStatisticsResponse } from '../../../../api/data-contracts'
import { AgentsAndCompainsAndCodesType } from '../../SupervisorDashboard'
import './SupervisorOperators.sass'
interface SupervisorOperatorsProps {
  data?: AgentsCompletionCodeStatisticsResponse[]
  agents?: AgentsAndCompainsAndCodesType['agents']
}
const complitionRender = (
  complite: AgentsCompletionCodeStatisticsResponse['completionCode']
) => {
  return Object.entries(complite!).map((entry) => {
    return (
      <div className="supervisor-operators-row-counter">
        <span>{entry[0]}</span>
        <span>{entry[1]}</span>
      </div>
    )
  })
}

export default function SupervisorOperators({
  data,
}: SupervisorOperatorsProps) {
  return (
    <div className="supervisor-operators-container">
      {data?.map((operator) => {
        return (
          <div className="supervisor-operators-row" key={operator.agentName}>
            <div className="supervisor-operators-row-operator">
              {operator.agentName}
            </div>
            <div className="supervisor-operators-row-content">
              {complitionRender(operator.completionCode)}
            </div>
          </div>
        )
      })}
    </div>
  )
}
