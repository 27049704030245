import Button from '../../../../../components/Button/Button'
import React, {
  useState,
  ChangeEventHandler,
  useCallback,
  useEffect,
} from 'react'
import Input from '../../../../../components/Input/Input'
import { Tag } from '../../../../../api/data-contracts'
import { useAppDispatch } from '../../../../../store/hooks/hook'
import { useApi } from '../../../../../store/hooks/request'
import { saveUpdateTag } from '../../../../../store/requests/tag'
import ColorSquare from './ColorSquare/ColorSquare'
import { colors } from '../../../../../store/colors'

interface TagEditorModalProps {
  onClose(): void
  campaignId?: number
  isTemplate: boolean
}

export default function TagEditorModal({
  onClose,
  campaignId,
  isTemplate,
}: TagEditorModalProps) {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const [tag, setTag] = useState<Tag>({})

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target
    if (value.length < 51) setTag({ ...tag, [name]: value })
  }

  const handleCreate = useCallback(() => {
    dispatch(saveUpdateTag({ isTemplate, tag, api }))
    onClose()
    //eslint-disable-next-line
  }, [tag, dispatch])

  const handleChangeColor = (color: string) => setTag({ ...tag, color })

  useEffect(() => {
    setTag({ campaignId })
  }, [campaignId])

  return (
    <div className="modal">
      <div className="modal-container" style={{ height: '370px' }}>
        <div className="modal-articles">
          <p className="modal-articles-title">Создание действия</p>
          <div className="modal-articles-body">
            <div>
              <Input
                span="Название действия"
                placeholder="Введите название действия"
                name="tagName"
                onChange={handleChange}
              />
              <Input
                span="Описание действия"
                placeholder="Введите описание действия"
                name="tagDescription"
                onChange={handleChange}
              />
              <div className="tags-editor-color-map">
                {colors.map((color) => (
                  <div
                    onClick={() => handleChangeColor(color.background)}
                    key={color.background}
                  >
                    <ColorSquare
                      color={color.background}
                      stroke={color.color}
                      isChosen={tag.color === color.background}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="modal-btns-group">
          <div className="modal-btns-wrapper">
            <Button text="Создать" onClick={handleCreate} />
          </div>
          <div className="modal-btns-wrapper">
            <Button text="Отмена" type="outline" onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  )
}
