import './Tag.sass'
import React from 'react'
import CloseIcon from '../Icons/Chat/CloseIcon/CloseIcon'
import { TagType } from '../../store/slices/tagSlice'

interface TagProps {
  obj: TagType
  hasClose?: boolean
  onClick?: (name: string) => void
  onClose?: React.MouseEventHandler<HTMLDivElement>
  className?: string
  icon?: {
    element: JSX.Element
    position: 'left' | 'right'
  }
  type: 'profile' | 'chat' | 'script-header' | 'campaign' | 'call-table'
}

export default function Tag({
  obj,
  onClick,
  onClose,
  className,
  icon,
  type,
}: TagProps) {
  return (
    <div
      className={
        `tag-container-${type} ` +
          (onClick ? ' tag-container-active ' : '') +
          className || ''
      }
      style={
        obj.tagColor
          ? {
              background: obj.tagColor.background,
              color: obj.tagColor.color,
            }
          : { background: '#F4FAFB', color: '#556268' }
      }
      onClick={onClick ? () => onClick(obj.tagDescription!) : () => {}}
    >
      {icon && (
        <div
          className={`tag-container-icon${
            type === 'call-table' ? ' tag-small-margin' : ''
          }`}
        >
          {icon?.element}
        </div>
      )}
      <div>{obj.text ?? obj.tagName}</div>
      {onClose && (
        <div className="tag-close" onClick={onClose}>
          <CloseIcon stroke={obj.color!} />
        </div>
      )}
    </div>
  )
}
