import CRUDTable, {
  CreateForm,
  DeleteForm,
  Field,
  Fields,
  UpdateForm,
} from 'react-crud-table'
import { useApi } from '../../../store/hooks/request'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { adminSelector } from '../../../store/selectors/admin'
import React, { useEffect, useState } from 'react'
import {
  deleteUser,
  findAllUser,
  saveUser,
  updateUser,
} from '../../../store/requests/admin'
import {
  AdminUserDto,
  AdminUserToSaveRequest,
} from '../../../api/data-contracts'
import UnAssignModal from '../UnAssignModal'

enum Roles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_SUPERVISOR = 'ROLE_SUPERVISOR',
}

const ROLES: Record<Roles, string> = {
  [Roles.ROLE_ADMIN]: 'Админ',
  [Roles.ROLE_USER]: 'Пользователь',
  [Roles.ROLE_SUPERVISOR]: 'Супервизор',
}

export default function User() {
  const { api } = useApi()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(adminSelector)
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    dispatch(findAllUser({ api }))
    //eslint-disable-next-line
  }, [])

  const userService = {
    create: (task: AdminUserToSaveRequest) => {
      console.log(task)
      let localTask
      if (task.role !== Roles.ROLE_SUPERVISOR) {
        const { pimUserId, ...other } = task
        localTask = { ...other, role: task.role ?? Roles.ROLE_ADMIN }
      } else {
        localTask = { ...task, role: task.role ?? Roles.ROLE_ADMIN }
      }

      dispatch(saveUser({ ...localTask, api }))
      return Promise.resolve(task)
    },
    update: (data: AdminUserDto) => {
      const task = user.find((t) => t.login === data.login)
      dispatch(updateUser({ ...data, api }))
      return Promise.resolve(task)
    },
    delete: (data: AdminUserDto) => {
      const task = user.find((t) => t.login !== data.login)
      dispatch(deleteUser({ ...data, api }))
      return Promise.resolve(task)
    },
  }

  const exUser = user.map((e) => ({
    ...e,
    currentExtension: e.currentExtension,
    role: (
      <div>
        {e.roles?.map((e) => (
          <div key={e.id}>{e.name}</div>
        ))}
      </div>
    ),
  }))

  const SelectRenderer = ({ field }: any) => (
    <select {...field}>
      {Object.keys(ROLES).map((item) => (
        <option value={item}>{ROLES[item as Roles]}</option>
      ))}
    </select>
  )

  return (
    <div className="table-container">
      <button className="button-supervisor-red" onClick={handleShowModal}>
        Очистить телефон
      </button>
      <CRUDTable caption="User" items={exUser}>
        <Fields>
          <Field name="login" label="Логин" hideInUpdateForm />
          <Field name="password" label="Пароль" />

          <Field
            type="number"
            name="pimUserId"
            label="pimUserId"
            hideFromTable
          />

          <Field
            type="number"
            name="currentExtension"
            label="currentExtension"
            hideInCreateForm
            hideInUpdateForm
          />

          <Field
            name="role"
            label="Роль"
            hideInUpdateForm
            render={SelectRenderer}
          />
        </Fields>
        <CreateForm
          title="Создать"
          message="Создать"
          trigger="Создать"
          submitText="Создать"
          onSubmit={userService.create}
        />
        <UpdateForm
          title="Обновить"
          message="Обновить"
          trigger="Обновить"
          submitText="Обновить"
          onSubmit={userService.update}
        />
        <DeleteForm
          title="Удалить"
          message="Уверены?"
          trigger="Удалить"
          submitText="Удалить"
          onSubmit={userService.delete}
        />
      </CRUDTable>
      {showModal && (
        <UnAssignModal type="extension" freeClose={handleShowModal} />
      )}
    </div>
  )
}
