import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from '../../api/Api'
import { AgentStateChangeRequest } from '../../api/data-contracts'

interface ChangeUserStatusType extends AgentStateChangeRequest {
  api: Api<unknown>
}

export const changeUserStatus = createAsyncThunk(
  'header/changeUserStatus',
  async ({ api, ...requestData }: ChangeUserStatusType) => {
    await api.changeAgentStateUsingPost(requestData)
  }
)
