import { DashboardItems } from '../../Settings/SettingsContent/DashbordEditor/types'
import { DashboardDropProp } from '../types'

export default function PlannedCallsDrop({ onAddItem }: DashboardDropProp) {
  return (
    <svg
      onClick={() =>
        onAddItem({
          x: 0,
          y: 0,
          w: 6,
          h: 1,
          minW: 3,
          i: DashboardItems.PlannedCalls,
        })
      }
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M20 23C22.0711 23 23.75 21.3211 23.75 19.25C23.75 17.1789 22.0711 15.5 20 15.5C17.9289 15.5 16.25 17.1789 16.25 19.25C16.25 21.3211 17.9289 23 20 23Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M13.981 26.6913C14.5459 25.5806 15.4072 24.6478 16.4695 23.9963C17.5318 23.3448 18.7536 23 19.9998 23C21.2459 23 22.4678 23.3448 23.5301 23.9963C24.5924 24.6478 25.4537 25.5806 26.0186 26.6913"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.75 14.75C27.5784 14.75 28.25 14.0784 28.25 13.25C28.25 12.4216 27.5784 11.75 26.75 11.75C25.9216 11.75 25.25 12.4216 25.25 13.25C25.25 14.0784 25.9216 14.75 26.75 14.75Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9361 18.9219C28.9787 19.2797 29.0001 19.6397 29 20C29 21.78 28.4722 23.5201 27.4832 25.0001C26.4943 26.4802 25.0887 27.6337 23.4442 28.3149C21.7996 28.9961 19.99 29.1743 18.2442 28.8271C16.4984 28.4798 14.8947 27.6226 13.636 26.364C12.3774 25.1053 11.5202 23.5016 11.1729 21.7558C10.8257 20.01 11.0039 18.2004 11.6851 16.5558C12.3663 14.9113 13.5198 13.5057 14.9999 12.5168C16.4799 11.5278 18.22 11 20 11C20.2816 11 20.56 11.0127 20.8352 11.0382"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
