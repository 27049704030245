import { DashboadIconProp } from '../Dashboard/Dasboard'

export default function ChatCircleMini({ isOpen }: DashboadIconProp) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2589 24.5939C11.1408 22.7089 10.7492 20.4805 11.1577 18.3272C11.5662 16.1739 12.7468 14.2438 14.4776 12.8993C16.2085 11.5549 18.3707 10.8885 20.5581 11.0254C22.7455 11.1623 24.8078 12.093 26.3575 13.6427C27.9073 15.1925 28.838 17.2547 28.9749 19.4421C29.1118 21.6296 28.4455 23.7917 27.101 25.5226C25.7565 27.2535 23.8264 28.4341 21.6732 28.8426C19.5199 29.2511 17.2915 28.8596 15.4065 27.7414L15.4065 27.7413L12.2981 28.6294C12.1695 28.6662 12.0334 28.6678 11.9039 28.6343C11.7744 28.6007 11.6563 28.5332 11.5617 28.4386C11.4671 28.344 11.3996 28.2259 11.366 28.0964C11.3325 27.9669 11.3341 27.8308 11.3709 27.7022L12.259 24.5938L12.2589 24.5939Z"
        stroke={isOpen ? '#C15747' : '#A1BDCA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
