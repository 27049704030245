import { dropStompSlice } from '../slices/stompSlice'
import { dropTagSlice } from '../slices/tagSlice'
import { dropUserSlice, setLogoutingStatus } from '../slices/userSlice'
import { dropScriptSlice } from '../slices/scriptSlice'
import { dropScriptEditorSlice } from '../slices/scriptEditorSlice'
import { dropHeaderSlice } from '../slices/headerSlice'
import { dropDashboardSlice } from '../slices/dashboardSlice'
import { dropNotificationsSlice } from '../slices/notificationSlice'
import { dropAdminSlice } from '../slices/adminSlice'
import { dropChatSlice } from '../slices/chatSlice'
import { dropCampaignSlice } from '../slices/campaignSlice'
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'

export const logout = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  dispatch(dropStompSlice())
  dispatch(dropTagSlice())
  dispatch(dropUserSlice())
  dispatch(dropScriptSlice())
  dispatch(dropScriptEditorSlice())
  dispatch(dropHeaderSlice())
  dispatch(dropDashboardSlice())
  dispatch(dropNotificationsSlice())
  dispatch(dropAdminSlice())
  dispatch(dropChatSlice())
  dispatch(dropCampaignSlice())
  dispatch(setLogoutingStatus(false))
}
