import './HeaderPart.sass'
import React, { memo, useMemo } from 'react'
import ChatListHeader from '../InnerChatArea/ChatList/ChatListHeader/ChatListHeader'
import ChatSubheader from '../InnerChatArea/DialogArea/ChatSubheader/ChatSubheader'
import { useAppSelector } from '../../../store/hooks/hook'
import { chatSelector } from '../../../store/selectors/chat'
import { ChatRoom } from '../../../api/data-contracts'
import { userSelector } from '../../../store/selectors/user'

function HeaderPart() {
  const { group, oneToOne, selectedChat } = useAppSelector(chatSelector)
  const { role } = useAppSelector(userSelector)

  const currChat: ChatRoom | undefined = [...group, ...oneToOne].find(
    ({ chatId }) => chatId === selectedChat?.id
  )

  const isSupervisor = useMemo(() => {
    return role![0] === 'ROLE_SUPERVISOR'
  }, [role])

  const name =
    (isSupervisor ? currChat?.senderName : currChat?.recipientName) ??
    currChat?.senderId ??
    currChat?.recipientId

  return (
    <div className="chat-header-wrapper">
      <ChatListHeader />
      <ChatSubheader name={name || ''} status="Готов" />
    </div>
  )
}
export default memo(HeaderPart)
