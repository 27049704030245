import React from 'react'
import './Card.sass'
import Template from '../../../../../components/Icons/ScriptDev/Sider/Template'
import Tag from '../../../../../components/Tag/Tag'
import { CampaignResponse } from '../../../../../api/data-contracts'

interface CardProps {
  card: {
    id: CampaignResponse['campaignId']
    title: CampaignResponse['campaignName']
    description: CampaignResponse['campaignDesc']
    tag?: string
  }
  handleCampaign: (id: CampaignResponse['campaignId']) => void
}

function Card({
  handleCampaign,
  card: { id, title, description, tag },
}: CardProps) {
  return (
    <div className="script-preview-card" onClick={() => handleCampaign(id)}>
      <div className="script-preview-card-title">
        <div className="script-preview-card-title-icon">
          <Template isCard />
        </div>
        <div className="script-preview-card-title-text">{title}</div>
      </div>
      <div className="script-preview-card-preview" />
      <div className="script-preview-card-description">
        <div className="script-preview-card-description-title">Описание</div>
        <div className="script-preview-card-description-text">
          {description}
        </div>
      </div>
      <div className="script-preview-card-tag">
        {tag ? (
          <Tag type="chat" obj={{ id: 1, color: 'grey', text: tag }} />
        ) : (
          <Tag type="chat" obj={{ id: 1, color: 'red', text: 'Нет скрипта' }} />
        )}
      </div>
    </div>
  )
}

export default React.memo(Card)
