import { useEffect, useMemo, useRef, useState } from 'react'
import {
  AgentStatusForSupervisor,
  CampaignResponse,
  CompletionCodeEntity,
} from '../../../../api/data-contracts'
import { AgentsAndCompainsAndCodesType } from '../../SupervisorDashboard'
import './CodesDropDown.sass'

interface CodesDropDownProps {
  codes?: AgentsAndCompainsAndCodesType['codes']
  handleSelectFilters: (
    selected:
      | Pick<AgentStatusForSupervisor, 'agentId' | 'agentName'>
      | Pick<CampaignResponse, 'campaignId' | 'campaignName'>
      | Pick<CompletionCodeEntity, 'codeId' | 'codeName'>,
    type: 'agent' | 'comp' | 'code'
  ) => void
  selectedCodes: (string | undefined)[]
}
export default function CodesDropDown({
  handleSelectFilters,
  codes,
  selectedCodes,
}: CodesDropDownProps) {
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)
  const handleClickOutside: EventListener = (event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }
  const uniqCodes = useMemo(() => {
    return [
      ...new Set(
        codes?.map((el) => {
          delete el.id
          delete el.campaignId

          return JSON.stringify(el)
        })
      ),
    ].map(
      (el) =>
        JSON.parse(el) as Pick<CompletionCodeEntity, 'codeId' | 'codeName'>
    )
  }, [codes])
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  return (
    <div className="codes-dd-container" ref={ref}>
      <div className="codes-dd-header" onClick={() => setIsOpen(!isOpen)}>
        Коды завершения:{selectedCodes.join(',')}
      </div>
      <div className={isOpen ? 'codes-dd-content open' : 'codes-dd-content'}>
        {uniqCodes
          ?.filter((code) => !selectedCodes.includes(code.codeName))
          .map((code) => {
            return (
              <div
                key={code.codeId}
                className="codes-dd-content-item"
                onClick={() => {
                  handleSelectFilters(
                    {
                      codeId: code.codeId,
                      codeName: code.codeName,
                    },
                    'code'
                  )
                  setIsOpen(!isOpen)
                }}
              >
                {code.codeName}
              </div>
            )
          })}
      </div>
    </div>
  )
}
