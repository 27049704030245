import React from 'react'

export default function For() {
  return (
    <React.Fragment>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="16" fill="#EBF5F7" />
        <path
          d="M28 40C34.6274 40 40 34.6274 40 28C40 21.3726 34.6274 16 28 16C21.3726 16 16 21.3726 16 28C16 34.6274 21.3726 40 28 40Z"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M28 32C30.7614 32 33 29.7614 33 27C33 24.2386 30.7614 22 28 22C25.2386 22 23 24.2386 23 27C23 29.7614 25.2386 32 28 32Z"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M19.9749 36.9218C20.7281 35.4408 21.8765 34.1971 23.2929 33.3284C24.7093 32.4598 26.3384 32 28 32C29.6615 32 31.2906 32.4598 32.707 33.3284C34.1234 34.1971 35.2718 35.4407 36.0251 36.9217"
          stroke="#A1BDCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </React.Fragment>
  )
}
