import {
  CallbackInfoListResponse,
  CampaignCardJobStatisticsResponse,
  CampaignCardOperatorResponse,
  CampaignDetailsResponse,
  Job,
} from '../../api/data-contracts'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  campaignRequest,
  getAllCallbacks,
  getAllOperators,
  getCampaignCard,
} from '../requests/campaign'

export interface CampaignStore {
  currentCampaign: CampaignDetailsResponse | null
  campaignInfo: {
    campaignName: string
    campaignId: number
  }
  campaignsJobs: Array<Job>
  campaignCardCallbacks: CallbackInfoListResponse | null
  campaignCardOperators: CampaignCardOperatorResponse[]
  campaignCard: CampaignCardJobStatisticsResponse | null
}

const initialState: CampaignStore = {
  currentCampaign: null,
  campaignInfo: {
    campaignName: '',
    campaignId: -1,
  },
  campaignsJobs: [],
  campaignCardCallbacks: null,
  campaignCardOperators: [],
  campaignCard: null,
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    dropCampaignSlice: () => initialState,
    setCampaign(
      state: CampaignStore,
      { payload }: PayloadAction<CampaignStore['campaignInfo']>
    ) {
      state.campaignInfo = payload
    },
    setCurrentCampaign(
      state: CampaignStore,
      { payload }: PayloadAction<CampaignStore['currentCampaign']>
    ) {
      state.currentCampaign = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(campaignRequest.fulfilled, (state, { payload }) => {
      if (payload) state.currentCampaign = payload
    })
    builder.addCase(getAllCallbacks.fulfilled, (state, { payload }) => {
      state.campaignCardCallbacks = payload
    })
    builder.addCase(getAllOperators.fulfilled, (state, { payload }) => {
      state.campaignCardOperators = payload
    })
    builder.addCase(getCampaignCard.fulfilled, (state, { payload }) => {
      state.campaignCard = payload
    })
  },
})

export const { setCampaign, dropCampaignSlice, setCurrentCampaign } =
  campaignSlice.actions

export const campaignReducer = campaignSlice.reducer
