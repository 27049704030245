import React, { memo, useEffect, useState } from 'react'
import Default from './Default'
import Message from '../Components/Message/Message'
import Div from '../Components/Division/Division'
import Button from '../Components/Button/Button'
import { RadioButton, Step } from '../../../../../api/data-contracts'
import ActionHeader from '../Components/ActionHeader/ActionHeader'
import AddBtn from '../Components/AddBtn/AddBtn'
import { useAppSelector } from '../../../../../store/hooks/hook'
import { scriptEditorSelector } from '../../../../../store/selectors/scriptEditor'

export interface NodePropsType {
  data: Step
}

function Talking({ data }: NodePropsType) {
  const [btns, setBtns] = useState<Record<string, number> | undefined>(
    undefined
  )
  const [msg, setMsg] = useState('')
  const { scripts, currentCampaign, currentTemplate } =
    useAppSelector(scriptEditorSelector)

  useEffect(() => {
    const script = scripts.find((script) =>
      currentTemplate
        ? script.id === currentTemplate
        : script.campaignId === currentCampaign
    )
    if (script) {
      const steps = script.steps
      if (steps)
        if (steps.length) {
          const step = steps!.find(
            (step) => step.orderNumber === data.orderNumber
          )
          if (step) {
            const elems = step.elements && (step.elements[0] as RadioButton)
            elems?.radioButtonHeader && setMsg(elems.radioButtonHeader)
            setBtns(elems?.radioButtonOptions)
          }
        }
    }
  }, [scripts, data, currentCampaign, currentTemplate])

  return (
    <Default data={data} type="talking">
      <div className="ad-node-talking-body">
        <Message firstText msg={data.text} step={data.orderNumber || 0} />
        {btns && (
          <>
            <ActionHeader text="Переключатель" />
            <Message step={data.orderNumber || 0} msg={msg} />
            {Object.keys(btns).map((key, i) => (
              <React.Fragment key={i}>
                <Button step={data.orderNumber || 0} text={key} i={i} />
                <Div />
              </React.Fragment>
            ))}
            <AddBtn step={data.orderNumber || 0} />
          </>
        )}
      </div>
    </Default>
  )
}

export default memo(Talking)
