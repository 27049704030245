/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client'

export class Logoff<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags authorization-controller
   * @name LogoffUsingGet
   * @summary logoff
   * @request GET:/logoff
   */
  logoffUsingGet = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<void, void>({
      path: `/logoff`,
      method: 'GET',
      query: query,
      ...params,
    })
}
