import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from '../../api/Api'
import { ProcessNotificationRequest } from '../../api/data-contracts'

interface GetSupervisorNotificationsType {
  api: Api<unknown>
  supervisorUsername: string
}

interface ProceedNotificationType extends ProcessNotificationRequest {
  api: Api<unknown>
}

export const getSupervisorNotifications = createAsyncThunk(
  'notifications/getSupervisorNotifications',
  async ({ api, supervisorUsername }: GetSupervisorNotificationsType) => {
    const { data } = await api.getAllUnprocessedNotificationsUsingGet(
      supervisorUsername
    )
    return data
  }
)

export const proceedNotification = createAsyncThunk(
  'notifications/proceedNotification',
  async ({ api, ...requestData }: ProceedNotificationType) => {
    await api.receiveProcessedNotificationAndSendResultToAgentUsingPost(
      requestData
    )
    return requestData.notificationId
  }
)
