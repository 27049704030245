import React from 'react'
import './EyeClosed.sass'

interface EyeClosedProps {
  size?: number
  onClick: React.MouseEventHandler<SVGSVGElement>
}

export default function EyeClosed(props: EyeClosedProps) {
  return (
    <svg
      width={props.size ? props.size : 16}
      height={props.size ? props.size : 16}
      viewBox="0 0 32 32"
      fill="none"
      className="eye-closed"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M25.1438 15.9131L27.995 20.8516"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2727 18.6577L20.1619 23.7007"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7163 18.6553L11.8269 23.6991"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.85106 15.9092L3.98608 20.8715"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13.1094C6.10156 15.7107 9.95426 19 16.0001 19C22.0459 19 25.8986 15.7107 28.0001 13.1094"
        stroke="#AFAFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
