import { createAsyncThunk } from '@reduxjs/toolkit'
import ApiDefaultType from '../types'
import { Tag } from '../../api/data-contracts'

interface SaveUpdateTagProps extends ApiDefaultType {
  tag: Tag
  isTemplate: boolean
}

export const getAllTags = createAsyncThunk(
  'tags/getAllTags',
  async ({ api }: ApiDefaultType) => {
    const { data } = await api.findAllUsingGet7()
    return data
  }
)

export const saveUpdateTag = createAsyncThunk(
  'tags/saveUpdateTag',
  async ({ api, tag, isTemplate }: SaveUpdateTagProps, { dispatch }) => {
    const body = isTemplate
      ? {
          ...tag,
          campaignId: undefined,
          template: true,
        }
      : { ...tag, template: false }

    await api.createOrUpdateUsingPost(body)
    dispatch(getAllTags({ api }))
  }
)
