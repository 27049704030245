import React, {memo, useCallback, useEffect, useState} from 'react'
import './ChatList.sass'
import DialogPreview from './DialogPreview/DialogPreview'
import {ChatTypes, SelectedChat} from '../../../../store/slices/chatSlice'
import {useAppSelector} from '../../../../store/hooks/hook'
import {userSelector} from '../../../../store/selectors/user'
import {getLastMessage, getLastMessageTimestamp, getTimeFromLastMessage, sortedMessages,} from './utils'
import {useApi} from '../../../../store/hooks/request'
import {useDispatch} from 'react-redux'
import {getAgentCallStatusUsing} from '../../../../store/requests/user'
import {useStompClient} from "react-stomp-hooks";
import {fetchChatRoomsAndMessages} from "../../../../store/requests/chat";

interface ChatListProps {
    selectedChatId: SelectedChat['id']
    chats?: ChatTypes
    onClick: (chatId: SelectedChat['id'], type: SelectedChat['type']) => void
}

const countUnreadMessages = (messages, agentId) => messages.filter(msg => !msg.read && msg.senderId !== agentId).length;

function ChatList({chats, onClick, selectedChatId}: ChatListProps) {
    const [selectedTab, setSelectedTab] = useState<"oneToOne" | "group">("oneToOne")

    const handleTabChange = (tab) => {
        setSelectedTab(tab)
    }

    const stomp = useStompClient()
    const {agentId} = useAppSelector(userSelector)
    const dispatch = useDispatch()
    const {api} = useApi()

    useEffect(() => {
        if (agentId) {
            dispatch(getAgentCallStatusUsing({agentId, api}))
        }
    }, [agentId, api, dispatch])

    const groupChats = useCallback(() => {
        return sortedMessages(chats?.group ?? [])
            .map((el) => {
                const username = el.chatName as string;
                const lastMessage = getLastMessage(el.messages);
                const lastMessageTimestamp = getLastMessageTimestamp(el.messages);
                const lastMessageTime = getTimeFromLastMessage(lastMessageTimestamp);
                const unreadMessages = countUnreadMessages(el.messages, agentId);
                return (
                    <DialogPreview
                        selected={selectedChatId === el.chatId}
                        key={el.chatId}
                        username={username}
                        group={'Группа'}
                        message={lastMessage}
                        messageTime={lastMessageTime}
                        unreadMessages={unreadMessages}
                        onClick={() => {
                            onClick(el.chatId!, 'group');
                        }}
                    />
                );
            });
    }, [agentId, onClick, selectedChatId, chats?.group]);
    const personalChats = useCallback(() => {

        return sortedMessages(chats?.oneToOne ?? [])?.map((el) => {
            const username = agentId === el.senderId! ? el.recipientName! : el.senderName!
            const lastMessage = getLastMessage(el.messages)
            const lastMessageTimestamp = getLastMessageTimestamp(el.messages)
            const lastMessageTime = getTimeFromLastMessage(lastMessageTimestamp)
            const unreadMessages = countUnreadMessages(el.messages, agentId);
            return (
                <DialogPreview
                    selected={selectedChatId === el.chatId}
                    key={el.chatId}
                    username={username}
                    group={'Личные'}
                    message={lastMessage}
                    messageTime={lastMessageTime}
                    unreadMessages={unreadMessages}
                    onClick={() => {
                        onClick(el.chatId!, 'oneToOne')
                    }}
                />
            );
        });

    }, [agentId, onClick, selectedChatId, chats?.oneToOne]);

    useEffect(() => {
        if (selectedChatId) {
            const selectedOneToOneChat = chats?.oneToOne.find(chat => chat.chatId === selectedChatId);
            const selectedGroupChat = chats?.group.find(chat => chat.chatId === selectedChatId);

            if (selectedOneToOneChat) {
                const lastMessage = selectedOneToOneChat?.messages?.[selectedOneToOneChat.messages.length - 1];

                if (lastMessage && !lastMessage.read && lastMessage.senderId !== agentId) {
                    // Use stomp to send the messageId to the server
                    stomp?.publish({
                        destination: `/sockets/chat/read/${lastMessage.chatId}`,
                        body: JSON.stringify({})
                    });
                    dispatch(fetchChatRoomsAndMessages({api, agentId}))
                }
            }

            if (selectedGroupChat) {
                const lastMessage = selectedGroupChat?.messages?.[selectedGroupChat.messages.length - 1];

                if (lastMessage && !lastMessage.read && lastMessage.senderId !== agentId) {
                    // Use stomp to send the messageId to the server
                    stomp?.publish({
                        destination: `/sockets/chat/group/read/${lastMessage.chatId}`,
                        body: JSON.stringify({})
                    });
                    dispatch(fetchChatRoomsAndMessages({api, agentId}))
                }

            }

        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChatId, stomp, dispatch]);
    // Conditional rendering logic for the group tab based on the presence of group chats

    return (
        <div className="chat-list-container">
            <div className="chat-list-container">
                <div className="tab-buttons">
                    <button
                        onClick={() => handleTabChange('oneToOne')}
                        className={`tab-button ${selectedTab === 'oneToOne' ? 'active' : ''}`}
                    >
                        Личные
                    </button>
                    <button
                        onClick={() => handleTabChange('group')}
                        className={`tab-button ${selectedTab === 'group' ? 'active' : ''}`}
                    >
                        Группы
                    </button>
                </div>
                <div className="chat-list">
                    {selectedTab === 'oneToOne' ? personalChats() : groupChats()}
                </div>
            </div>
        </div>
    )
}

export default memo(ChatList)
