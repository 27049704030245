import { DashboardItems } from '../../Settings/SettingsContent/DashbordEditor/types'
import { TopDashboardItems } from '../../Settings/SettingsContent/TopDashEditor/types'
import { DashboardDropProp } from '../types'

export default function AverageCallTimeDrop({
  onAddItem,
  type,
}: DashboardDropProp) {
  const dropLayout = {
    x: 0,
    y: 0,
    w: type === 'dash' ? 5 : 3,
    h: 1,
    minW: type === 'dash' ? 3 : 3,
    i:
      type === 'dash'
        ? DashboardItems.AverageCallTime
        : TopDashboardItems.AverageCallTime,
  }
  return (
    <svg
      onClick={() => onAddItem(dropLayout)}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill="#F4FAFB"
      />
      <path
        d="M20 15.5V20"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8971 22.25L20 20"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2656 17.3486H29.0156V13.5986"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8336 25.8336C24.6798 26.9874 23.2098 27.7732 21.6095 28.0915C20.0092 28.4098 18.3504 28.2464 16.8429 27.622C15.3354 26.9976 14.0469 25.9402 13.1404 24.5835C12.2339 23.2268 11.75 21.6317 11.75 20C11.75 18.3683 12.2339 16.7733 13.1404 15.4165C14.0469 14.0598 15.3354 13.0024 16.8429 12.378C18.3504 11.7536 20.0092 11.5902 21.6095 11.9085C23.2098 12.2269 24.6798 13.0126 25.8336 14.1664L29.0156 17.3484"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
