import React, { memo, useCallback, useState } from 'react'
import Plus from '../../../../../../components/Icons/ScriptDev/Nodes/Plus'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../store/hooks/hook'
import Input from '../../../../../../components/Input/Input'
import Accept from '../Accept/Accept'
import { addBtn } from '../../../../../../store/slices/scriptEditorSlice'
import { scriptEditorSelector } from '../../../../../../store/selectors/scriptEditor'

interface AddBtnProp {
  step: number
}

function AddBtn({ step }: AddBtnProp) {
  const dispatch = useAppDispatch()
  const { mode } = useAppSelector(scriptEditorSelector)
  const [adding, setAdding] = useState(false)
  const [value, setValue] = useState('')
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }, [])
  const onAcceptAdding = useCallback(() => {
    if (value) {
      dispatch(
        addBtn({
          step,
          text: value,
        })
      )
    }
    setAdding(false)
  }, [value, step, dispatch])
  const onCancelAdding = useCallback(() => setAdding(false), [])
  const handleAddBtn = useCallback(
    () => !adding && mode === 'editing' && setAdding(true),
    [adding, mode]
  )

  return (
    <div className="node-button">
      <div
        className={`node-button-wrapper node-button-wrapper-add${
          adding ? ' node-button-wrapper-input' : ''
        }`}
        onClick={handleAddBtn}
      >
        {adding ? (
          <div className="node-button-left node-button-left-input">
            <Input span="" placeholder="Введите название" onChange={onChange} />
            <div className="accept-wrapper">
              <Accept onAccept={onAcceptAdding} onCancel={onCancelAdding} />
            </div>
          </div>
        ) : (
          <div className="node-button-left">
            <div className="node-button-icon">
              <Plus />
            </div>
            <div className="node-button-text node-button-text-add">
              Добавить кнопку
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(AddBtn)
