import React from 'react'

export default function ArrowDown({ isActive }: { isActive?: boolean }) {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.5L4 3.5L1 0.5"
        stroke={isActive ? '#BA4433' : '#142328'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
