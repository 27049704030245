export default function Group2() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25003 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25003 5.25C5.55764 5.25 3.37503 7.43261 3.37503 10.125C3.37503 12.8174 5.55764 15 8.25003 15Z"
        stroke="#556268"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5699 5.43149C15.2404 5.24257 15.9437 5.19953 16.6322 5.30528C17.3208 5.41102 17.9787 5.66309 18.5616 6.0445C19.1445 6.42592 19.6389 6.92782 20.0116 7.51641C20.3842 8.105 20.6264 8.76661 20.7218 9.45667C20.8171 10.1467 20.7636 10.8492 20.5646 11.5168C20.3656 12.1844 20.0259 12.8017 19.5683 13.3269C19.1107 13.8522 18.5459 14.2733 17.9119 14.5619C17.2778 14.8505 16.5893 14.9998 15.8927 14.9999"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.49963 18.5059C2.26101 17.4229 3.27178 16.539 4.44662 15.9288C5.62145 15.3186 6.92586 15.0001 8.24971 15C9.57356 14.9999 10.878 15.3184 12.0529 15.9285C13.2278 16.5386 14.2386 17.4225 15.0001 18.5054"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8927 15C17.2167 14.999 18.5214 15.3171 19.6964 15.9273C20.8713 16.5375 21.882 17.4218 22.6427 18.5054"
        stroke="#556268"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
