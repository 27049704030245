import './InnerModalRadio.sass'
import React, { ChangeEventHandler, useMemo } from 'react'
import RadioInput from '../../../RadioInput/RadioInput'
import { useAppSelector } from '../../../../store/hooks/hook'
import { headerSelector } from '../../../../store/selectors/header'

interface InnerModalProps {
  type: 'break' | 'exit_call' | 'join_call'
  onChange?: ChangeEventHandler<HTMLInputElement>
  curValue?: string
}

export default function InnerModalRadio({
  type,
  onChange,
  curValue,
}: InnerModalProps) {
  const { stateChangeReasons } = useAppSelector(headerSelector)

  const radioList = useMemo(() => {
    return {
      break: stateChangeReasons
        .map((item) => ({
          name: item.reasonLocalizedName,
          value: item.reasonId!,
          data: item,
        }))
        .slice(1),
      exit_call: [
        { name: 'Плохая связь', value: 1, data: {} },
        {
          name: 'Клиент не отвечает и не завершает звонок',
          value: 2,
          data: {},
        },
        { name: 'Клиент ведет себя неподобающе', value: 3, data: {} },
        { name: 'Ошибка RPC', value: 4, data: {} },
        { name: 'Потому что', value: 5, data: {} },
      ],
      join_call: [
        { name: 'Только слушать', value: 'LISTEN_ONLY', data: {} },
        { name: 'Слышат все', value: 'LISTEN_TALK', data: {} },
      ],
    }
  }, [stateChangeReasons])

  return (
    <div className={'inner-modal-' + type}>
      {radioList[type].map(({ name, value }) => (
        <label className="inner-modal-radio-wrapper" key={name}>
          <RadioInput
            name={type}
            value={value}
            className="inner-modal-radio-input"
            onChange={onChange}
            isChecked={type === 'join_call' ? curValue === value : undefined}
          />
          {name}
        </label>
      ))}
    </div>
  )
}
