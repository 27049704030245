import './Campaign.sass'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { campaignSelector } from '../../store/selectors/campaign'
import { campaignRequest } from '../../store/requests/campaign'
import { useApi } from '../../store/hooks/request'
import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'
import Header from '../Script/SubSider/Header/Header'
import { CampaignResponse } from '../../api/data-contracts'
import { setCurrCampaign } from '../../store/slices/scriptEditorSlice'
import { getAllCampaigns } from '../../store/requests/script'
import CampaignSubSider from './SupervisorCampaign/CampaignSubSider/CampaignSubSider'
import CampaignJob from './SupervisorCampaign/CampaignJob/CampaignJob'
import { scriptEditorSelector } from '../../store/selectors/scriptEditor'
import { userSelector } from '../../store/selectors/user'
import CampaignModal from './CampaignModal/CampaignModal'
import SupervisorCampaignContent from './SupervisorCampaign/SupervisorCampaignContent/SupervisorCampaignContent'
import SupervisorCampaignHeader from './SupervisorCampaign/SupervisorCampaignHeader/SupervisorCampaignHeader'

export default function Campaign() {
  const {
    currentCampaign,
    campaignInfo: { campaignId },
  } = useAppSelector(campaignSelector)
  const dispatch = useAppDispatch()
  const { role } = useAppSelector(userSelector)
  const { api } = useApi()
  const { campaigns, scripts } = useAppSelector(scriptEditorSelector)
  const [activeCampaign, setActiveCampaign] =
    useState<CampaignResponse['campaignId']>(undefined)
  const [pickedJob, setPickedJob] = useState<number | undefined>()
  const [pickedCampaign, setPickedCampaign] = useState<string | undefined>()
  const [headerStatus, setHeaderStatus] = useState<string | undefined>()

  const hasScript = campaigns.map((e) =>
    scripts.find(({ campaignId }) => e.campaignId === campaignId)
  )

  const handleCampaign = useCallback(
    (id: CampaignResponse['campaignId']) => {
      setActiveCampaign(id)
      setPickedJob(undefined)
      dispatch(setCurrCampaign(id))
      dispatch(campaignRequest({ api, campaignId: id || -1 }))
    },
    // eslint-disable-next-line
    [dispatch]
  )

  useEffect(() => {
    if (role && role[0] !== 'ROLE_USER') dispatch(getAllCampaigns({ api }))
    // eslint-disable-next-line
  }, [dispatch])

  return role && role[0] === 'ROLE_SUPERVISOR' ? (
    <ViewWithScript
      ContentComponent={
        <div className="campaign-main-content">
          <div className="campaign-main-content-left">
            <CampaignSubSider
              pickedJob={pickedJob}
              handleCampaign={handleCampaign}
              campaignId={activeCampaign}
              setPickedJob={setPickedJob}
              setPickedCampaign={setPickedCampaign}
              setHeaderStatus={setHeaderStatus}
            />
          </div>
          <div className="campaign-main-content-right">
            {pickedJob ? (
              <SupervisorCampaignContent
                pickedJob={pickedJob}
                activeCampaign={activeCampaign}
                pickedCampaign={pickedCampaign}
              />
            ) : pickedCampaign ? (
              <CampaignJob
                setPickedJob={setPickedJob}
                setPickedCampaign={setPickedCampaign}
                hasScript={hasScript}
                id={activeCampaign}
                setHeaderStatus={setHeaderStatus}
              />
            ) : null}
          </div>
        </div>
      }
      SubHeaderComponent={
        <div className="campaign-subheader">
          <div className="campaign-subheader-sider">
            <Header title="Кампании" isCampaign />
          </div>
          <div className="campaign-subheader-main">
            <SupervisorCampaignHeader
              pickedCampaign={pickedCampaign}
              jobId={pickedJob}
              headerStatus={headerStatus}
            />
          </div>
        </div>
      }
    />
  ) : campaignId === -1 ? (
    <CampaignModal
      title="Title"
      description="description"
      strategy="Strategy"
      type="Finite"
      contactList={[]}
    />
  ) : (
    <CampaignModal
      title={currentCampaign?.campaignName}
      description={currentCampaign?.campaignDesc}
      strategy={currentCampaign?.campaignStrategy}
      type={currentCampaign?.campaignType}
      contactList={[]}
    />
  )
}
