import React from 'react'

export default function GreenRound() {
  return (
    <React.Fragment>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="6" stroke="#31A26B" strokeWidth="4" />
      </svg>
    </React.Fragment>
  )
}
