import { useCallback, useEffect, useMemo, useState } from 'react'
import './ChatArea.sass'
import Message from '../../../Message/Message'
import { ChatMessage } from '../../../../../api/data-contracts'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import { userSelector } from '../../../../../store/selectors/user'
import {
  SelectedChat,
  setSearchString,
} from '../../../../../store/slices/chatSlice'
import { chatSelector } from '../../../../../store/selectors/chat'

interface ChatAreaProps {
  messages?: ChatMessage[]
  senderId: string | undefined
  type: SelectedChat['type']
}

export default function ChatArea(props: ChatAreaProps) {
  const { agentId } = useAppSelector(userSelector)
  const { searchString } = useAppSelector(chatSelector)
  const dispatch = useAppDispatch()
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    const messages = document.getElementById('messages')
    messages!.scrollTop = messages!.scrollHeight
  }, [props.messages])

  useEffect(() => {
    const el = document.getElementById(selectedId)
    el?.scrollIntoView()
  }, [selectedId])

  const handleClickOnMsg = useCallback(
    (id?: number) => {
      dispatch(setSearchString(null))
      setSelectedId(String(id))
    },
    [dispatch]
  )

    const sortMessages = (messages: ChatMessage[]) => {
        return [...messages].sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
    }
  const filteredMsgs = useMemo(
    () =>
        sortMessages(props.messages ?? [])
            .filter(({ content }) =>
                content?.toLowerCase().includes(searchString?.toLowerCase() || '')
            )
            .map((el: ChatMessage) => (
          <Message
            id={String(el.messageId!)}
            type={props.type}
            key={el.timestamp}
            text={el.content!}
            time={el.timestamp!}
            from={agentId === el.senderId}
            haveAvatar={agentId === el.senderId}
            fromName={el.senderName}
            read={(agentId === el.senderId) ? true : el.read}
            handleClick={() => handleClickOnMsg(el.messageId)}
          />
        )),
      [agentId, searchString, props.messages, handleClickOnMsg, props.type]
  )
  const msgs = useMemo(
    () =>
        sortMessages(props.messages ?? [])
            .map((el: ChatMessage) => {
        return (
          <Message
            id={String(el.messageId!)}
            key={el.timestamp}
            type={props.type}
            text={el.content!}
            time={el.timestamp!}
            from={agentId === el.senderId}
            haveAvatar={agentId === el.senderId}
            fromName={el.senderName}
            read={(agentId === el.senderId) ? true : el.read}
          />
        )
      }),
      [props.messages, agentId, props.type]
  )

  return (
    <div className="chat-area-container">
      <div className="chat-area-container-messages" id="messages">
        {searchString ? filteredMsgs : msgs}
      </div>
    </div>
  )
}
