import React, {useCallback} from 'react'
import './SupervisorCampaignHeader.sass'
import PlayIcon from '../../../../components/Icons/JobStatus/PlayIcon'
import PauseIcon from '../../../../components/Icons/JobStatus/PauseIcon'
import StopIcon from '../../../../components/Icons/JobStatus/StopIcon'
import {useAppDispatch} from '../../../../store/hooks/hook'
import {pauseJob, resumeJob, stopJob,} from '../../../../store/requests/campaign'
import {useApi} from '../../../../store/hooks/request'

interface CampaignHeaderType {
    pickedCampaign?: string
    jobId?: number
    headerStatus?: string
}

export default function SupervisorCampaignHeader({
                                                     pickedCampaign,
                                                     jobId,
                                                     headerStatus,
                                                 }: CampaignHeaderType) {
    const dispatch = useAppDispatch()
    const {api} = useApi()

    const isJobStopped = headerStatus ? ["JOB_COMPLETED", "JOB_STOPPED", "JOB_STOP", "JOB_STOPPING"].includes(headerStatus) : false;
    const isJobActive = headerStatus === "JOB_ACTIVE";
    const isJobPaused = headerStatus ? ["JOB_PAUSED", "JOB_PAUSED", "JOB_PAUSED"].includes(headerStatus) : false;

    const handleResumeJob = useCallback(() => {
        if (jobId !== undefined) dispatch(resumeJob({jobId, api}))
        //eslint-disable-next-line
    }, [dispatch, jobId])

    const handlePauseJob = useCallback(() => {
        if (jobId !== undefined) dispatch(pauseJob({jobId, api}))
        //eslint-disable-next-line
    }, [dispatch, jobId])

    const handleStopJob = useCallback(() => {
        if (jobId !== undefined) dispatch(stopJob({jobId, api}))
        //eslint-disable-next-line
    }, [dispatch, jobId])

    return (
        <div className="s-header">
            <div className="s-header-left">
                <div className="s-header-left-title">
                    {pickedCampaign
                        ? `${pickedCampaign} ${jobId ? jobId : ''} ${
                            headerStatus ? headerStatus : ''
                        }`
                        : 'Выберите кампанию'}
                </div>
            </div>
            <div className="s-header-right">
                {jobId && (

                    <>

                        <div className="header-icon">
                            <PlayIcon
                                color={isJobPaused ? '#06c167' : '#A1BDCA'}
                                onClick={!isJobStopped && isJobPaused ? handleResumeJob : function () {
                                }}
                            />
                        </div>
                        <div className="header-icon">
                            <PauseIcon
                                color={isJobActive ? '#f6c545' : '#A1BDCA'}
                                onClick={!isJobStopped && isJobActive ? handlePauseJob : function () {
                                }}
                            />
                        </div>
                        <div className="header-icon">
                            <StopIcon
                                color={!isJobStopped ? '#BA4433' : '#A1BDCA'}
                                onClick={!isJobStopped ? handleStopJob : function () {
                                }}
                            />
                        </div>

                    </>
                )}
            </div>
        </div>
    )
}
